import styled from 'styled-components';

export const FormularioCusto = styled.div.attrs({
    className: ``
})`

    .form-label {
        font-size: 0.85rem;
    }

`;

export const QuantidadeParcelas = styled.div.attrs({
    className: ``
})`

    display: flex;
    align-items: center;

    button {
        padding: 0 4px;
    }

    input {
        margin: 0 10px;
        text-align: center;
    }


`;