import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

// comopnentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Card, Button } from 'react-bootstrap';

// componente
export default function WhatsappArquivoNumeros(){

    // estados
    let [numeros, alterarNumeros] = useState([]);
    let [numerosClicados, alterarNumerosClicados] = useState([]);

    // referencias
    let inputRef = React.createRef();

    // inicializa
    useEffect(() => {

        // verifica se já tem uma lista salva
        let listaSalva = JSON.parse(window.localStorage.getItem('listaNumeros')) || [];
        let listaSalvaClicados = JSON.parse(window.localStorage.getItem('listaNumerosClicados')) || [];

        // carrega registros salvos a última vez
        alterarNumeros(listaSalva);
        alterarNumerosClicados(listaSalvaClicados);

    }, []);

    function lerArquivo(e){
        
        var fr=new FileReader();
        fr.onload=function(){
            let novosNumeros = fr.result.split("\n");
            alterarNumeros(novosNumeros);
            window.localStorage.setItem('listaNumeros', JSON.stringify(novosNumeros));
            window.localStorage.setItem('listaNumerosClicados', JSON.stringify([]));
            alterarNumerosClicados([]);
        }
        fr.readAsText(inputRef.current.files[0]);         
    }

    return <>
        <input 
            ref={inputRef}
            type='file' 
            onChange={lerArquivo}
            hidden
        />
        <Button
            onClick={() => {
                inputRef.current.click();
            }}
            className='mb-3'
        >
            Ler arquivo com números
        </Button>

        <Row>
            <Col lg={6}>
                <Card
                    className='border-0'
                >
                    <Card.Body>
                        {numeros.map((numero, a) => 
                            <React.Fragment
                                key={a}
                            >
                                <p className='mb-2'>
                                    <a 
                                        href={`https://api.whatsapp.com/send?phone=${numero}`} 
                                        target="_blank"
                                        onClick={() => {
                                            let numerosClicadosAtualizar = [...numerosClicados, numero];
                                            alterarNumerosClicados(numerosClicadosAtualizar);
                                            window.localStorage.setItem('listaNumerosClicados', JSON.stringify(numerosClicadosAtualizar));
                                        }}
                                        className={[
                                            'p-2 rounded d-block',
                                            numerosClicados.includes(numero) ? 'bg-success text-white' : 'bg-light'
                                        ].join(' ')}
                                    >
                                        {numero}
                                        { numerosClicados.includes(numero) &&
                                            <FontAwesomeIcon className="icone" icon={["fas", 'check']} />
                                        }
                                    </a>
                                </p>
                            </React.Fragment>
                        )}
                    </Card.Body>
                </Card>
            </Col>
        </Row>


    </>

}