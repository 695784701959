import styled from 'styled-components';

export const FechamentoComponent = styled.div.attrs({
    className: ''
})`

    .legenda {
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > span {
            margin-right: 8px;
        }
    }

`