import React, { useState, useEffect } from 'react';
import { Form, Image, Button, Figure, ProgressBar } from 'react-bootstrap';
import styles from './index.module.scss';
import imagemVazia from './imagem.png';

// função
function Foto({
    imagem,
    // caminhoBase,
    onChange: alterouArquivo,
    progresso,
    arquivo
}){

    // imagem temporaria
    let [imagemTemporaria, alterarImagemTemporaria] = useState(imagemVazia);
    // let [arquivo, alterarArquivo] = useState(arquivo);

    // referencias
    let inputRef = React.createRef();

    // inicializa
    useEffect(() => {
        
        // nulo
        if(imagem !== null){
            alterarImagemTemporaria(`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/fotos/${imagem}`);
        }

    }, [imagem]);
    
    return <Form.Group>
        <Figure className={styles.containerImagem}>
            <Image 
                src={imagemTemporaria} 
                alt={'Imagem temporária'}
                rounded 
                fluid  
            />
            {progresso &&
                <div className={styles.carregandoImagem}>
                    <ProgressBar className={styles.barra} animated now={progresso} label={`${progresso}%`} />
                </div>
            }
        </Figure>
        
        <p className="d-flex justify-content-center">
            <input hidden type="file" ref={inputRef} onChange={() => {

                if(inputRef.current.files.length > 0){
                    // função para carregar imagem
                    let reader = new FileReader();

                    // ao carregar imagem
                    reader.onload = (e) => {
                        
                        alterouArquivo(inputRef.current.files[0]);
                        alterarImagemTemporaria(e.target.result);
                    };

                    // pega a imagem
                    reader.readAsDataURL(inputRef.current.files[0]);
                }
            }} />
            <Button variant="padrao" className="my-3" onClick={() => {
                inputRef.current.click();
            }}>Alterar</Button>
        </p>
    </Form.Group>

}

export default Foto;