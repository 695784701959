import React, { useContext } from 'react';
import moment from 'moment';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { InfoComponent } from './components';
import { Card, Row, Col, Badge } from 'react-bootstrap';

// importa contexto
import { FichaCadastrarContexto } from './../FichaCadastrarContexto';

// retorna um card com os dados simples de cliente, para recordação
export default function DadosClienteFormulario({

}){

    // dados do componente principal
    const { dados, dadosCliente } = useContext(FichaCadastrarContexto);


    return <>
        <InfoComponent>
            <Card className="border-0 shadow-sm">
                <Card.Body>
                    <div className="d-flex">
                        <div className="dados flex-grow-1">
                            <Row>
                                <Col lg={8}>
                                    <div className={'text-truncate'}>
                                        <Badge 
                                            className="mr-1 tipo-pessoa" 
                                            variant="secondary"
                                            title={dadosCliente.tipo_pessoa === 'F' ? 'Pessoa Fisica' : 'Pessoa Júridica'}
                                        >
                                            {dadosCliente.tipo_pessoa === 'F' ?
                                                'F' : 'J'
                                            }
                                        </Badge>
                                        { dadosCliente.nome}
                                    </div>
                                </Col>
                                <Col lg={4}><FontAwesomeIcon icon={faCalendarDay} /> {moment(dadosCliente.data_nascimento).format('DD/MM/YYYY')}</Col>
                            </Row>
                            <div className="cidade">
                                {dadosCliente.cidade} / {dadosCliente.estado}
                            </div>
                        </div>
                        { dadosCliente.foto !== null &&
                            <div className="imagem">
                                <img 
                                    className={'rounded'}
                                    src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/fotos/${dadosCliente.foto}`} 
                                />
                            </div>
                        }
                    </div>
                    { (dadosCliente.pre_cliente === 'S' && parseInt(dados.etapa) < 4) && 
                        <>
                            <Badge variant="info">
                                Pre Cliente
                            </Badge>
                        </>
                    }
                </Card.Body>
            </Card>
        </InfoComponent>
    </>

}