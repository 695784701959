import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';


import { Button, Modal, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ConsultarContexto } from './../ConsultarContexto';
import Registro from './Registro';
import PaginacaoComponente from './../../../Componentes/Paginacao';
import Filtros from './Filtros';
import ModalParticipante from './ModalParticipante';
import ModalParticipanteExclusao from './ModalParticipanteExclusao';

// componente
export default function ModalDiarias({
    idSeguroAventura=null,    // para filtrar por eventos
    fechou
}){

    // estados
    const { parametros, alterarParametros } = useContext(ConsultarContexto);
    const [mostrar, alterarMostrar] = useState(true);
    const [ participantes, alterarParticipantes ] = useState([]);
    const [carregando, alterarCarregando] = useState(false);
    const [parametrosDiarias, alterarParametrosDiaras] = useState({
        pagina: 1,
        registrosPorPagina: 8,
        texto: parametros.participante,
        idSeguroAventura: idSeguroAventura,
        cancelado: ['S', 'N'],
        desconsiderarBoleto: 'S',
        desconsiderarFinalizado: 'S',
        data: parametros.data,
        periodo: parametros.periodo
    });
    const [totalRegistros, atualizarTotalRegistros] = useState(0);
    const [editarParticipante, alterarEditarParticipante] = useState(null);
    const [excluirParticipante, alterarExcluirParticipante ] = useState(null);

    // consulta os registros
    async function fazerConsulta(){
        
        alterarCarregando(true);

        try {

            // faz a requisição
            const { data } = await axios.get(`/seguroAventura/participantes/diarias`, { params: parametrosDiarias });
            alterarParticipantes(data.registros);
            atualizarTotalRegistros(parseInt(data.totalRegistros));


        } catch({response}){}
        finally{
            alterarCarregando(false);
        }

    }

    // inicializa
    useEffect(() => {
        fazerConsulta();
    }, [parametrosDiarias]);

    // estados
    return <>

        <Modal 
            hidden={editarParticipante || excluirParticipante !== null}
            show={mostrar} 
            onExited={() => {
                fechou();
            }}
            onHide={() => {
                alterarMostrar(false);
            }}
            size={'lg'}
        >
            <Modal.Header className='border-0 text-center'>
                <Modal.Title className="w-100">
                    Participantes
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Filtros 
                    parametros={parametrosDiarias}
                    alterarParametros={alterarParametrosDiaras}
                />

                { carregando && 
                    <p className="text-center mt-3">
                        <FontAwesomeIcon icon={faSpinner} pulse /> Aguarde!
                    </p>
                }

                {!carregando && 
                    <>
                        <table className="mt-3 table table-hover tabela">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th className="text-center">Nascimento</th>
                                    <th className="text-center">CPF</th>
                                    <th className="text-center">Entrada</th>
                                    <th className="text-center">Saída</th>
                                    <th className="text-center">Horário inclusão</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {participantes.map((registro, a) => 
                                    <Registro 
                                        key={registro.dados.id} 
                                        dados={registro.dados}
                                        evento={registro.evento}
                                        onClickTr={() => console.log('clicou TR')}
                                        clicouEditar={() => {
                                            // history.push(`/painel/proposta-formularios/alterar/${registro.dados.id}`)
                                            alterarEditarParticipante(registro.dados.id);
                                            
                                        }}
                                        clicouExcluir={() => {
                                            alterarExcluirParticipante({
                                                id: registro.dados.id,
                                                tokenParticipante: registro.dados.tokenParticipante
                                            });
                                        }}
                                    />
                                )}
                            </tbody>
                        </table>
                        <PaginacaoComponente
                            pagina={parametrosDiarias.pagina}
                            registrosPorPagina={parametrosDiarias.registrosPorPagina}
                            totalRegistros={totalRegistros}
                            alterouPagina={(pagina) => {
                                alterarParametrosDiaras({...parametrosDiarias, pagina: pagina});
                            }}
                        />
                    </>
                }

            </Modal.Body>
        </Modal>

        {(editarParticipante) && 
            <ModalParticipante 
                id={editarParticipante}
                fechou={() => {
                    alterarEditarParticipante(null)
                }}
                salvou={() => {
                    alterarParametros({...parametros});
                }}
            />
        }

        {(excluirParticipante) &&
            <ModalParticipanteExclusao 
                id={excluirParticipante.id}
                tokenParticipante={excluirParticipante.tokenParticipante}
                fechou={() => alterarExcluirParticipante(null)}
                excluiu={() => alterarParametrosDiaras({...parametrosDiarias})}
            />
        }

    </>

}