import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import axios from 'axios';
import { toast } from 'react-toastify';

// componentes
import useIsMounted from 'ismounted';
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputInteiro from './../../Componentes/InputInteiro';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// cadastro
export default function Cadastrar(){

    // estados
    const isMounted = useIsMounted();
    let history = useHistory();
    let {id} = useParams();
    let login = useSelector(state => state.login);
    let [modo, alterarModo] = useState('cadastro'); // cadastro, edicao
    let [carregando, alterarCarregando] = useState(true);
    let [salvando, alterarSalvando] = useState(false);
    let [dados, alterarDados] = useState({
        id: id || null,
        alvo: '1',
        quantidade: '1',
        periodo: 'D',
        quantidade_lembrar: '1',
        periodo_lembrar: 'D',
        recorrente: 'N',
        recorrente_periodo: 'D',
        recorrente_quantidade: '1',
        limitar_periodo: 'D',
        limitar_quantidade: '1',
        recorrente_repetir: null,
        limitar: 'N',
        descricao: '',
        pre_cliente: 'N',
        proposta: 'N',
        emissao: 'N',
        cotacao: 'N'
    });

    // inicializa
    useEffect(() => {

        // verifica se é alteração
        if(dados.id){

            // altera o modo
            alterarModo('edicao');
            consultarId();

        }else{
            alterarCarregando(false);
        }

    }, []);

    // consulta id
    async function consultarId(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/eventosPadrao/${dados.id}`);
            alterarDados(data.dados);
            alterarCarregando(false);

        }catch({response}){

        }
    }

    // salva
    async function salvar(){
        alterarSalvando(true);

        // inicia
        try{

            // faz a requisição
            let { data } = await axios.post('/eventosPadrao', dados);

            // altera os dados
            dados.id = data.id;
            alterarDados({...dados});

            // finalizado, altera para edição
            toast(({closeToast }) => <>
                <div className="toast-header border-bottom-0">
                    <strong className="mr-auto">Evento automático salvo com sucesso!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>);

        }catch(e){

        }finally{
            if(isMounted.current){
                alterarSalvando(false);
            }
        }
    }

    // periodos padrão
    let periodo = [
        {value: 'D', children: 'Dia(s)'},
        {value: 'S', children: 'Semana(s)'},
        {value: 'M', children: 'Mes(es)'},
        {value: 'A', children: 'Ano(s)'},
    ];

    // carregando
    if(carregando){
        return <div>Carregando...</div>
    }

    return <>
        <Row>
            <Col lg='6'>
                <Card className='border-0 shadow-sm'>
                    <Card.Header className='bg-white border-0 mb-0 pb-0'>
                        <Card.Title>Cadastro de Evento Automático</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Group>
                            <Form.Label>Alvo</Form.Label>
                            <Form.Control
                                as='select'
                                value={dados.alvo}
                                onChange={e => {
                                    dados.alvo = e.target.value;
                                    alterarDados({...dados});
                                }}
                            >
                                {[
                                    {value: '1', children: 'Ficha de Atendimento - Direito'},
                                    {value: '2', children: 'Ficha de Atendimento - Seguro'},
                                    {value: '3', children: 'Ficha de Atendimento - Consultoria'},
                                    {value: '4', children: 'Ficha de Atendimento - Avaliação'},
                                    {value: '5', children: 'Ficha de Atendimento - Imóveis'},
                                    // {value: '6', children: 'Ficha de Atendimento - Usuário'},
                                    {value: '7', children: 'Cliente'},
                                ].map(opcao => 
                                    <option key={opcao.value} {...opcao} />
                                )}
                            </Form.Control>
                            
                        </Form.Group>
                        <div
                            className={'text-right mb-3'}
                        >    
                            {dados.alvo === '2' &&
                                <>
                                    <Form.Check 
                                        type="switch"
                                        id="switch-cotacao-evento-automatico"
                                        label="Cotação"
                                        checked={dados.cotacao === 'S' ? true : false}
                                        onChange={e => {
                                            dados.emissao = e.target.checked ? 'N' : dados.emissao;
                                            dados.proposta = e.target.checked ? 'N' : dados.proposta;
                                            dados.cotacao = e.target.checked ? 'S' : 'N';
                                            alterarDados({...dados});
                                        }}
                                        inline
                                    />
                                    <Form.Check 
                                        type="switch"
                                        id="switch-proposta-evento-automatico"
                                        label="Proposta"
                                        checked={dados.proposta === 'S' ? true : false}
                                        onChange={e => {
                                            dados.cotacao = e.target.checked ? 'N' : dados.cotacao;
                                            dados.emissao = e.target.checked ? 'N' : dados.emissao;
                                            dados.proposta = e.target.checked ? 'S' : 'N';
                                            alterarDados({...dados});
                                        }}
                                        inline
                                    />
                                    <Form.Check 
                                        type="switch"
                                        id="switch-emissao-evento-automatico"
                                        label="Emissao"
                                        checked={dados.emissao === 'S' ? true : false}
                                        onChange={e => {
                                            dados.cotacao = e.target.checked ? 'N' : dados.cotacao;
                                            dados.proposta = e.target.checked ? 'N' : dados.proposta;
                                            dados.emissao = e.target.checked ? 'S' : 'N';
                                            alterarDados({...dados});
                                        }}
                                        inline
                                    />
                                </>
                            }

                            {/* <Form.Group> */}
                                <Form.Check 
                                    type="switch"
                                    id="switch-pre-cliente-evento-automatico"
                                    label="Pré cliente"
                                    checked={dados.pre_cliente === 'S' ? true : false}
                                    onChange={e => {
                                        dados.pre_cliente = e.target.checked ? 'S' : 'N';
                                        alterarDados({...dados});
                                    }}
                                    inline
                                />
                            {/* </Form.Group> */}
                        </div>
                        <Form.Row>
                            <Col md='5'>
                                <Form.Label>Periodo de</Form.Label>
                                <Form.Row>
                                    <Col md='4'>
                                        <InputInteiro 
                                            className='text-center'
                                            valor={dados.quantidade}
                                            onChange={valorNovo => {
                                                dados.quantidade = valorNovo;
                                                alterarDados({...dados});
                                            }}
                                        />
                                    </Col>
                                    <Col md='8'>
                                        <Form.Control 
                                            className='text-center'
                                            as='select'
                                            value={dados.periodo}
                                            onChange={e => {
                                                dados.periodo = e.target.value;
                                                alterarDados({...dados});
                                            }}
                                        >
                                            {periodo.map(opcao => 
                                                <option key={opcao.value} {...opcao} />    
                                            )}
                                        </Form.Control>
                                    </Col>
                                </Form.Row>
                            </Col>
                            <Col md={{span: 5, offset:2}}>
                                <Form.Label>Lembrar antes</Form.Label>
                                <Form.Row>
                                    <Col md='4'>
                                        <InputInteiro 
                                            className='text-center'
                                            valor={dados.quantidade_lembrar}
                                            onChange={valorNovo => {
                                                dados.quantidade_lembrar = valorNovo;
                                                alterarDados({...dados});
                                            }}
                                        />
                                    </Col>
                                    <Col md='8'>
                                        <Form.Control 
                                            className='text-center'
                                            as='select'
                                            value={dados.periodo_lembrar}
                                            onChange={e => {
                                                dados.periodo_lembrar = e.target.value;
                                                alterarDados({...dados});
                                            }}
                                        >
                                            {periodo.map(opcao => 
                                                <option key={opcao.value} {...opcao} />    
                                            )}
                                        </Form.Control>
                                    </Col>
                                </Form.Row>
                            </Col>
                        </Form.Row>

                        <div className='rounded bg-light p-3 mt-3'>
                            <Form.Check 
                                inline
                                label={'Recorrente'}
                                id='recorrente'
                                checked={dados.recorrente === 'S'}
                                onChange={e => {
                                    dados.recorrente = e.target.checked ? 'S' : 'N';
                                    alterarDados({...dados});
                                }}
                            />
                            { dados.recorrente === 'S' &&
                                <Form.Row className='mt-2'>
                                    <Col md='6'>
                                        <Form.Label>Frequência</Form.Label>
                                        <Form.Row>
                                            <Col md='4'>
                                                <InputInteiro 
                                                    className='text-center'
                                                    valor={dados.recorrente_quantidade}
                                                    onChange={valorNovo => {
                                                        dados.recorrente_quantidade = valorNovo;
                                                        alterarDados({...dados});
                                                    }}
                                                />
                                            </Col>
                                            <Col md='8'>
                                                <Form.Control 
                                                    className='text-center'
                                                    as='select'
                                                    value={dados.recorrente_periodo}
                                                    onChange={e => {
                                                        dados.recorrente_periodo = e.target.value;
                                                        alterarDados({...dados});
                                                    }}
                                                >
                                                    {periodo.map(opcao => 
                                                        <option key={opcao.value} {...opcao} />    
                                                    )}
                                                </Form.Control>
                                            </Col>
                                        </Form.Row>
                                    </Col>
                                    <Col md={{span: 4, offset: 2}}>
                                        <Form.Label>Repetiçoes</Form.Label>
                                            <InputInteiro 
                                                className='text-center'
                                                valor={dados.recorrente_repetir}
                                                onChange={valorNovo => {
                                                    dados.recorrente_repetir = valorNovo;
                                                    alterarDados({...dados});
                                                }}
                                                placeholder='Sem limite'
                                                aceitarNull={true}
                                            />
                                    </Col>
                                </Form.Row>
                            }
                        </div>

                        <Form.Group className='mt-3'>
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control 
                                as='textarea'
                                value={dados.descricao}
                                onChange={e => {
                                    dados.descricao = e.target.value;
                                    alterarDados({...dados});
                                }}
                            />
                        </Form.Group>
                    </Card.Body>
                </Card>
                <SalvarFormulario 
                    salvando={salvando}
                    onClick={() => salvar()}
                    cancelar={() => {
                        history.push('/painel/eventos-automaticos');
                    }}
                />
            </Col>
        </Row>
    </>
}

function SalvarFormulario({
    salvando,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <span>Salvar</span>
            }
        </Button>
    </Form.Group>
}
