import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

// componentes
import { Row, Col, Card, Alert, Button, Form } from 'react-bootstrap';
import ComboResponsavel from './../../Componentes/ComboResponsavel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';

// componente
export default function Transferir(){

    // estados
    const { id } = useParams();
    const { login } = useSelector(state => state);
    const history = useHistory();
    let [dados, alterarDados] = useState({
        id: id,
        id_transferir: null
    });
    let [relatorio, alterarRelatorio] = useState(null);
    let [carregando, alterarCarregando] = useState(true);
    let [salvando, alterarSalvando] = useState(false);

    // consulta relatório
    async function consultarRelatorio(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/usuarios/relatorio/${dados.id}`);
            alterarRelatorio(data.dados);
            alterarCarregando(false);

        }catch({response}){}
    }

    // relatório
    useEffect(() => {
        consultarRelatorio();
    }, []);

    // salva
    async function salvar(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/usuarios/transferir`, {
                id_responsavel: dados.id,
                id_transferir: dados.id_transferir
            });
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Dados transferidos!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    {dados.nome}
                </div>
            </>);

            // retorna
            history.push('/painel/usuarios');

        }catch({response}){}
    }

    // carregando
    if(carregando){
        return <div>Carregando...</div>
    }

    return <>
        <Row>
            <Col lg='6'>
                <Card
                    className='border-0 shadow-sm'
                >   
                    <Card.Header className='bg-white border-0'>
                        <Card.Title as='h4'>Informações gerais</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <p>
                            Esses seram os registros transferidos para o usuário escolhido
                        </p>
                        <ul>
                            <li>{relatorio.eventos} eventos não concluidos.</li>
                            <li>{relatorio.clientes} clientes.</li>
                        </ul>

                        <ComboResponsavel 
                            id={dados.id_transferir}
                            alterou={(idNovo) => {
                                dados.id_transferir = idNovo;
                                alterarDados({...dados});
                            }}
                            ignorarIds={[dados.id]}
                        />
                    </Card.Body>
                </Card>

                <ConfirmarTransferir 
                    salvando={salvando}
                    cancelar={() => {
                        history.push('/painel/usuarios');
                    }}
                    desabilitar={dados.id_transferir === null ? true : false}
                    onClick={() => salvar()}
                />
            </Col>
            <Col lg='6'>
                <Alert variant='warning'>
                    Irá alterar o responsavel dos:
                    <ul>
                        <li>Eventos não concluídos</li>
                        <li>Clientes</li>
                    </ul> 
                    para o responsável indicado!
                </Alert>
            </Col>

        </Row>
    </>

}

function ConfirmarTransferir({
    salvando,
    cancelar,
    onClick,
    desabilitar
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar} disabled={salvando}>Cancelar</Button>
        <Button variant="success" disabled={salvando || desabilitar} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Transferindo</span>
                </> : 
                <span>Transferir</span>
            }
        </Button>
    </Form.Group>
}
