import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Card, Row, Col, Form, Button } from 'react-bootstrap';
import FormularioImobiliaria from './FormularioImobiliaria';
import FormularioDadosLocatario from './FormularioDadosLocatario';
import FormularioDadosPessoais from './FormularioDadosPessoais';
import FormularioDadosImovelLocacao from './FormularioDadosImovelLocacao';
import FormularioResidente from './FormularioResidente';
import FormularioReferenciasPessoais from './FormularioReferenciasPessoais';
import Anexos from './../../Painel/Componentes/Anexos';

// componente
export default function Fianca({
    modo='cliente' // cliente, imobiliaria
}){

    // estados
    const history = useHistory();
    let [dados, alterarDados] = useState({
        id: null,
        imobiliaria: '',
        cnpj: '',
        email_acompanhamento: '',
        locatario_cnpj_cpf: '',
        locatario_nome: '',
        locatario_email: '',
        locatario_telefone_residencial: '',
        locatario_telefone_celular: '',
        locatario_data_nascimento: null,
        locatario_estado_civil: '1',
        locatario_residir_imovel: 'S',
        locatario_cep: '',
        locatario_endereco_atual: '',
        locatario_bairro_atual: '',
        locatario_cidade_atual: '',
        locatario_estado_atual: 'AC',
        locatario_tempo_residencia: '1',
        locatario_situacao_residencia: '1',
        locatario_nome_de: '1',
        locatario_nome_proprietario: '',
        locatario_telefone_proprietario: '',
        locatario_cpf_conjugue: '',
        locatario_nome_conjugue: '',
        locatario_conjugue_residir: '',
        locatario_conjugue_arcar_locacao: '',
        profissional_empresa: '',
        profissional_telefone: '',
        profissional_data_adminissao: null,
        profissional_funcao: '',
        profissional_vinculo_empregaticio: '1',
        profissional_renda_mensal: '0',
        profissional_demais_rendas: '0',
        imovel_finalidade: '1',
        imovel_tipo: '1',
        imovel_especificar_outro: '',
        imovel_motivo_locacao: '1',
        imovel_endereco: '',
        imovel_cep: '',
        imovel_bairro: '',
        imovel_cidade: '',
        imovel_estado: 'AC',
        imovel_valor_aluguel: '0',
        imovel_valor_iptu: '0',
        imovel_valor_condominio: '0',
        imovel_valor_luz: '0',
        imovel_valor_agua: '0',
        imovel_valor_gas: '0',
        imovel_cobertura_acessorio: '',
        residente_1_cpf: '',
        residente_1_nome: '',
        residente_1_data_nascimento: null,
        residente_1_grau_parentesco: '',
        residente_2_cpf: '',
        residente_2_nome: '',
        residente_2_data_nascimento: null,
        residente_2_grau_parentesco: '',
        residente_3_cpf: '',
        residente_3_nome: '',
        residente_3_data_nascimento: null,
        residente_3_grau_parentesco: '',
        residente_4_cpf: '',
        residente_4_nome: '',
        residente_4_data_nascimento: null,
        residente_4_grau_parentesco: '',
        preferencias_1_nome_referencia: '',
        preferencias_1_telefone_referencia: '',
        preferencias_1_parentesco: '',
        preferencias_2_nome_referencia: '',
        preferencias_2_telefone_referencia: '',
        preferencias_2_parentesco: '',
    });
    let [salvandoAnexos, alterarSalvandoAnexos] = useState(false);
    let [clicouSalvar, alterarClicouSalvar] = useState(false);
    let [salvando, alterarSalvando] = useState(false);

    useEffect(() => {

        // ao finalizar o cadastro mostra toast de salvo
        if(clicouSalvar && !salvando && !salvandoAnexos){
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Formulário salvo com sucesso!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>);
            history.push('/meuseguro');
        }

    }, [salvando, salvandoAnexos]);

    // salva
    async function salvar(){
        alterarClicouSalvar(true);
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/solicitacaoFormulario`, dados);
            dados.id = data.id;
            alterarDados({...dados});
            setTimeout(() => {
                alterarSalvando(false);
            }, 1000);
        }catch({response}){
            alterarClicouSalvar(false);
        }finally{

        }
        

    }
    
    function funcaoAlterarDados(e){
        dados[e.target.name] = e.target.value;
        alterarDados({...dados});
    }


    return <Container className='mt-4 pb-5'>
        <Row>
            <Col lg={{span:8, offset: 2}}>
                <Card className='border-0 shadow-sm'>
                    <Card.Body>
                        <h3 className='text-center mb-4'>Cadastro fiança {modo === 'cliente' ? 'cliente' : 'imobiliária'}</h3>

                        { modo === 'imobiliaria' &&
                            <FormularioImobiliaria 
                                dados={dados}
                                alterarDados={alterarDados}
                                funcaoAlterarDados={funcaoAlterarDados}
                            />
                        }

                        <hr />
                        
                        <h5 className='text-right mb-4'>DADOS DO LOCATÁRIO</h5>
                        <FormularioDadosLocatario 
                            dados={dados}
                            alterarDados={alterarDados}
                            funcaoAlterarDados={funcaoAlterarDados}
                        />
                        
                        <hr />
                        
                        <h5 className='text-right mb-4'>DADOS PROFISSIONAIS</h5>
                        <FormularioDadosPessoais 
                            dados={dados}
                            alterarDados={alterarDados}
                            funcaoAlterarDados={funcaoAlterarDados}
                        />
                        
                        
                        
                        { modo === 'imobiliaria' &&
                            <>
                                <hr />

                                <h5 className='text-right mb-4'>DADOS DO IMÓVEL PRETENDIDO PARA LOCAÇÃO</h5>
                                <FormularioDadosImovelLocacao 
                                    dados={dados}
                                    alterarDados={alterarDados}
                                    funcaoAlterarDados={funcaoAlterarDados}
                                />
                            </>
                        }
                        
                        <hr />
                        
                        <h5 className='text-right mb-4'>INFORME TODOS OS RESIDENTES MAIORES DE 18 anos</h5>
                        <FormularioResidente 
                            dados={dados}
                            alterarDados={alterarDados}
                            funcaoAlterarDados={funcaoAlterarDados}
                            numeroResidente={'1'}
                        />
                        <FormularioResidente 
                            dados={dados}
                            alterarDados={alterarDados}
                            funcaoAlterarDados={funcaoAlterarDados}
                            numeroResidente={'2'}
                        />
                        <FormularioResidente 
                            dados={dados}
                            alterarDados={alterarDados}
                            funcaoAlterarDados={funcaoAlterarDados}
                            numeroResidente={'3'}
                        />
                        <FormularioResidente 
                            dados={dados}
                            alterarDados={alterarDados}
                            funcaoAlterarDados={funcaoAlterarDados}
                            numeroResidente={'4'}
                        />

                        
                        <hr />
                        
                        <h5 className='text-right mb-4'>PREFERENCIAS PESSOAIS</h5>
                        <h5 className='text-right mb-4'>Pelo menos 2</h5>
                        <FormularioReferenciasPessoais 
                            dados={dados}
                            alterarDados={alterarDados}
                            funcaoAlterarDados={funcaoAlterarDados}
                            numeroReferencia={'1'}
                        />
                        <FormularioReferenciasPessoais 
                            dados={dados}
                            alterarDados={alterarDados}
                            funcaoAlterarDados={funcaoAlterarDados}
                            numeroReferencia={'2'}
                        />

                        <Anexos 
                            tabela='solicitacao_formulario'
                            id={dados.id}
                            salvando={(flag) => {
                                console.log('salvando arquivos', flag);
                                alterarSalvandoAnexos(flag);
                            }}
                            // size='md',
                            // habilitarCadastro=true,
                            habilitarExcluir={false}
                            // login
                            externo={true}
                        />
                        <ul className='mt-3'>
                            <li>Pessoas físicas (idade mínima 18 anos)</li>
                            <li>Cópia CPF/CI ou CNH</li>
                            <li>Se morar em residência alugada - os três últimos recibos de aluguel e cópia do contrato de locação ou uma declaração de que é pontual nos pagamentos e não deixou dividas</li>
                            <li>Empregado com carteira assinada - Último contracheque, ou os três últimos recibos de pagamento, se tiver remuneração variável.</li>
                            <li>Cópia da Carteira Profissional (identificação, registro de trabalho e a última atualização salarial)</li>
                            <li>Se foi admitido há menos de seis meses, cópia do vínculo empregatício anterior</li>
                            <li>Autônomo: Imposto de renda e Extrato bancários dos três últimos meses.</li>
                            <li>Microempresário: Contrato social ou declaração de firma invisual, imposto de Renda na integra e extrato bancário dos três últimos meses.</li>
                            <li>Aposentado ou pensionista: ultimo contracheque, se for funcionário público, ou extrato trimestral do INSS ou outro fundo de pensão.</li>
                            <li>Renda proveniente de alugueis – Documento de propriedade do imóvel (cópia do IPTU, escritura ou Registro Geral de Imóveis.) Contrato de locação e o ultimo recibo de aluguel.</li>
                            <li>Renda proveniente de pensão alimentícia – sentença judicial e recibos dos últimos três meses.</li>
                        </ul>
                        
                        <SalvarFormulario 
                            salvando={salvando || salvandoAnexos}
                            cancelar={() => {
                                history.push('/meuseguro');
                            }}
                            onClick={() => salvar(dados)}
                        />

                    </Card.Body>
                </Card>
            </Col>
        </Row>


    </Container>

}


function SalvarFormulario({
    salvando,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <span>Salvar</span>
            }
        </Button>
    </Form.Group>
}
