import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import ListaCheckbox from './../../../Componentes/ListaCheckbox';
import ComboResponsavelConsulta from './../../../Componentes/ComboResponsavelConsulta';

// cria os parametros
export default function Parametros({
    parametros, 
    alterarParametros
}){

    // expande filtros
    let [mostrarMais, expandirParametros] = useState(false); 

    // faz as consultas
    useEffect(() => {


    }, []);

    // parametros
    return <div className="parametros">
        <div className="parametros-inicial">
            <Link to='/painel/eventos-automaticos'>
                <Button variant="padrao" className="botao-cadastrar">
                    <FontAwesomeIcon className="icone" icon={["fas", "list"]} />
                    <span>Eventos Automáticos</span>
                </Button>
            </Link>
            <DebounceInput 
                placeholder="" 
                debounceTimeout={400}
                element={Form.Control} 
                value={parametros.texto}
                onChange={(e) =>{
                    alterarParametros({...parametros, texto: e.target.value, pagina: 1})
                }}
            />
            <div className="campo-flags">
                <ListaCheckbox 
                    itens={[
                        {id: 'S', label: 'Ativos'},
                        {id: 'N', label: 'Inativos'}
                    ]}
                    value={parametros.ativo}
                    label='ativo-inativo'
                    onChange={(ids) => {
                        parametros.ativo = ids;
                        alterarParametros({...parametros, pagina: 1});
                    }}
                />
            </div>
            {!mostrarMais && <Button 
                variant="padrao"
                onClick={() => expandirParametros(true)}
            >
                <FontAwesomeIcon className="icone" icon={["fas", "search-plus"]} />
            </Button>}
        </div>

        {mostrarMais && 
            <Row className='mt-3'>
                <Col md='9'>
                    <ComboResponsavelConsulta 
                        value={parametros.responsaveis}
                        onChange={responsaveisArray => {
                            parametros.responsaveis = responsaveisArray;
                            alterarParametros({...parametros});
                        }}
                    />
                </Col>
                <Col md='3'>
                    <Form.Group>
                        <Form.Label>Concluido</Form.Label>
                        <div className="campo-flags">
                            <ListaCheckbox 
                                itens={[
                                    {id: 'S', label: 'Sim'},
                                    {id: 'N', label: 'Não'}
                                ]}
                                value={parametros.concluido}
                                label='tipo-pessoa'
                                onChange={(ids) => {
                                    parametros.concluido = ids;
                                    alterarParametros({...parametros, pagina: 1});
                                }}
                            />
                        </div>
                    </Form.Group>
                </Col>
            </Row>
        }
    </div>

}
