import React from 'react';
import styles from './index.module.scss';

// componentes
import { useSelector } from 'react-redux';
import Moment from 'react-moment';
import BotaoTabela from './../../../Componentes/BotaoTabela';
import { mascaraCpf, mascaraCnpj } from './../../../../Funcoes';

// elemento registro
function Registro({
    dados, 
    parametros, 
    ...props
}){
    // console.log(dados);
    let permissao = useSelector(state => state.login.permissao);

    // ambos
    // 'Codigo', 'F/J', 'Nome', 'UF', 'Cidade', 'CPF/CNPJ', 'Telefone'

    // pessoa fisica
    // 'Codigo', 'Nome', 'Nasc.', 'UF', 'Cidade', 'CPF', 'Telefone'

    // pessoa juridica
    // 'Codigo', 'Nome', 'Nome Fantasia', 'UF', 'Cidade', 'CNPJ', 'Telefone'

    return <tr 
        onClick={props.onClickTr}
        className={`clicavel`}
    >
        <td className="estreito text-center font-weight-bold">{dados.id}</td>
        {(parametros.tipo_pessoa.includes('J') && parametros.tipo_pessoa.includes('F')) &&
            <td className="estreito text-center"><span className={styles.labelTipoPessoa}>{dados.tipo_pessoa}</span></td>
        }
        <td>{dados.nome}</td>
        {(parametros.tipo_pessoa.includes('J') && !parametros.tipo_pessoa.includes('F')) &&
            <td>{dados.nome_fantasia}</td>
        }
        {(!parametros.tipo_pessoa.includes('J') && parametros.tipo_pessoa.includes('F')) &&
            <td>{dados.data_nascimento && <Moment format="DD/MM/YYYY">{dados.data_nascimento}</Moment>}</td>
        }
        <td>{dados.estado}</td>
        <td>{dados.cidade}</td>

        <td className='estreito text-center text-nowrap'>
            { dados.tipo_pessoa === 'F' ?
                mascaraCpf(dados.cpf)
                :
                mascaraCnpj(dados.cnpj)
            }
        </td>

        <td className="text-nowrap estreito">

            { permissao.clienteEnviarEmail === 'S' &&
                <BotaoTabela 
                    onClick={props.clicouEnviarWhatsapp}
                    icone={["fab", "whatsapp-square"]}
                >Escrever Whatsapp</BotaoTabela>
            }
            
            { permissao.clienteEnviarEmail === 'S' &&
                <BotaoTabela 
                    onClick={props.clicouEnviarSms}
                    icone={["fas", "sms"]}
                >Escrever Sms</BotaoTabela>
            }

            { permissao.clienteEnviarEmail === 'S' &&
                <BotaoTabela 
                    onClick={props.clicouEnviarEmail}
                    icone={["fas", "envelope"]}
                >Escrever Email</BotaoTabela>
            }

            { (parseInt(permissao.clientes) > 1) &&
                <BotaoTabela 
                    onClick={props.clicouEditar}
                    icone={["fas", "pencil-alt"]}
                >Editar</BotaoTabela>
            }

            {/* {(dados.ativo === 'S')? 
                <BotaoTabela 
                    onClick={props.clicouInativar}
                    icone={["fas", "toggle-on"]}
                    color="#219221"
                >Ativo</BotaoTabela>
                : 
                <BotaoTabela 
                    onClick={props.clicouAtivar}
                    icone={["fas", "toggle-off"]}
                >Inativo</BotaoTabela>
            } */}
            
        </td>
    </tr>
}

export default Registro;