import React, { useState, useEffect, useDebugValue } from 'react';
import axios from 'axios';

// componentes
import { ConfiguracaoSistemaComponent } from './components';
import { Row, Col, Card } from 'react-bootstrap';

// componentes de configurações
import ConfiguraEmailSeguroAventuraCancelamento from './ConfiguraEmailSeguroAventuraCancelamento';
import ConfiguraEmailSeguroAventuraResponsavelCancelamento from './ConfiguraEmailSeguroAventuraResponsavelCancelamento';

// contexto
import { ConfiguracaoSistemaContexto } from './ConfiguracaoSistemaContexto';

export function estiloEvento(coresEventos, codigo){
    let estilo = {
        background: '',
        color: ''
    };
    switch(codigo){
        case '1': 
            estilo.background = coresEventos['tipo-direito'].fundo;
            estilo.color = coresEventos['tipo-direito'].texto;
        break;
        case '2': 
            estilo.background = coresEventos['tipo-seguro'].fundo;
            estilo.color = coresEventos['tipo-seguro'].texto;
        break;
        case '3': 
            estilo.background = coresEventos['tipo-consultoria'].fundo;
            estilo.color = coresEventos['tipo-consultoria'].texto;
        break;
        case '4': 
            estilo.background = coresEventos['tipo-avaliacao'].fundo;
            estilo.color = coresEventos['tipo-avaliacao'].texto;
        break;
        case '5': 
            estilo.background = coresEventos['tipo-imoveis'].fundo;
            estilo.color = coresEventos['tipo-imoveis'].texto;
        break;
    }
    return estilo;
}

// configurações
export default function ConfiguracaoSistema(){

    // estados
    let [chaves, alterarChaves] = useState();
    let [carregando, alterarCarregando] = useState(true);

    // consulta as configurações do sistema
    async function consultarConfiguracoes(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/configuracaoSistema/padrao`);
            alterarChaves(data.chaves);
            alterarCarregando(false);

        }catch({response}){}
    }

    // inicializa
    useEffect(() => {
        consultarConfiguracoes();
    }, []);

    // se finalizou o carregamento
    if(carregando){
        return null;
    }

    // carrega os dados de configuração
    return <ConfiguracaoSistemaContexto.Provider value={{chaves, alterarChaves}}>
        <ConfiguracaoSistemaComponent>
            <Row className='justify-content-md-center'>
                <Col md={9}>
                    <Card
                        className="border-0 shadow-sm"
                    >
                        <Card.Body>
                            <ConfiguraEmailSeguroAventuraCancelamento />
                            <ConfiguraEmailSeguroAventuraResponsavelCancelamento />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </ConfiguracaoSistemaComponent>
    </ConfiguracaoSistemaContexto.Provider>
}
