import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ConsultarContexto } from './../../ConsultarContexto';

// exclui evento
export default function BotaoExcluir({
    id,
    className
}){

    // estados
    const [confirmar, alterarConfirmar] = useState(false);
    const { parametros, alterarParametros } = useContext(ConsultarContexto);
    let [salvando, alterarSalvando] = useState(false);


    // se confirma exclui
    async function excluir(){

        // mostra carregamento
        alterarSalvando(true);

        try{

            // faz a requisição
            await axios.delete(`/seguroAventura/${id}`);
            alterarConfirmar(false);
            alterarParametros({...parametros});

        }catch({response}){

            // verifica se retornou erro
            if(response && response.data) {

                toast(({closeToast }) => <>
                    <div className="toast-header">
                        <strong className="mr-auto">Houve um problema ao excluir</strong>
                        <button 
                            onClick={closeToast} 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>                        
                    </div>
                    <div className="toast-body">
                        {response.data.boleto}
                    </div>
                </>);

            }

        }
        finally{

            // esconde carregamento
            alterarSalvando(false);
            
        }


    }

    return <>
        <Button
            className={["rounded-pill p-1", className].join(" ")}
            variant="danger"
            onClick={e => {
                alterarConfirmar(true);
            }}
        >
            <FontAwesomeIcon icon={faTrash} size="xs" />
        </Button>

        <Modal 
            show={confirmar} 
            onExited={() => {}}
            onHide={() => {}}
            size={'sm'}
        >
            <Modal.Header className='border-0'>
                <Modal.Title>
                    Excluir seguro aventura!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Tem certeza que quer excluir o evento?</p>
            </Modal.Body>
            <Modal.Footer  className='border-0'>
                <Button
                    variant='padrao'
                    onClick={() => {alterarConfirmar(false)}}
                    disabled={salvando}
                >
                    <span>Voltar</span>
                </Button>
                <Button
                    onClick={excluir}
                    variant='success'
                    disabled={salvando}
                >
                    {salvando ? 
                        <>
                            <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                            <span>Excluindo</span>
                        </> : 
                        <span>Sim, deletar!</span>
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    </>

}