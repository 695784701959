import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import { useHistory, useParams } from "react-router-dom";
import { Card, Form, Row, Col, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from "react-device-detect";
import { toast } from 'react-toastify';
import MaskedInput from 'react-maskedinput';
import useIsMounted from 'ismounted';
import ComboModeloSms from '../../Componentes/ComboModeloSms';
import TextoSms from './../../ModelosSms/Cadastrar/TextoSms';

// componente
export default function EnviarWhatsapp({
    aniversariante='N'
}){

    // estados, 
    const isMounted = useIsMounted();
    let history = useHistory();
    let { idade, id, idcliente } = useParams();
    let [dados, alterarDados] = useState({
        id: id || null,
        id_cliente: idcliente || null,
        mensagem: '',
        celular: '',
        data_agendado: null,
        tipo_aniversariante: aniversariante,
        idade_referencia: idade || null,
        flashsms: 'N'
    });
    let [dadosCliente, alterarDadosCliente] = useState(null);
    let [carregando, alterarCarregando] = useState(true);
    let [salvando, alterarSalvando] = useState(false);
    let [erro, alterarErro] = useState(null);

    // referências
    let telCelInput = React.createRef();

    // inicializa
    useEffect(() => {

        // verifica se é edição ou cadastro
        if(idcliente){

            // consulta os dados do cliente
            axios.get(`/clientes/${dados.id_cliente}`)
            .then(({data, ...retorno}) => {
                alterarDados({
                    ...dados,
                    celular: `55${data.dados.telefone_celular}`
                });
                alterarDadosCliente(data.dados);
            })
            .catch(e => {})
            .then(() => {
                alterarCarregando(false);
            });

        }


    }, []);

    // faz o envio
    function enviar(){
        return console.log(dados)
        // envia
        alterarSalvando(true);

        try {

            // if(isMobile) {
            //     
            // } else {
            //    
            // }   

        } catch(e) {

            console.log(e)

        } finally {

            if (isMounted.current) {
                alterarSalvando(false);
            }

        }   

    }


    // carregar modelo de whatsapp com as informaões do cliente
    function carregarModelo(idModelo){

        // faz a requisição para carregar o modelo com os dados do cliente
        axios.get(`/modelosSms/${idModelo}/convertido/${dados.id_cliente}`)
        .then(({data, ...retorno}) => {
            dados.mensagem = data.mensagem;
            alterarDados({ ...dados });
        })
    }

    // se estiver carregando
    if(carregando){
        return <>Carregando ...</>
    }

    return <>

        <Row>
            <Col lg={8}>
                <Card className={'border-0 shadow-sm'}>
                    <Card.Header className={'bg-white border-0'}>
                        <Card.Title as='h4'>Enviar Whatsapp</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Row>
                            <Col lg={8}>
                                <Form.Label>Nome</Form.Label>
                                <p>{dadosCliente.nome}</p>
                            </Col>
                            <Col lg={4}>
                                <Form.Label>Celular</Form.Label>
                                <MaskedInput 
                                    className="form-control"
                                    mask="+11 (11) 11111-1111"
                                    value={dados.celular}
                                    ref={telCelInput}
                                    onChange={(e) => {
                                        alterarDados({...dados, celular: telCelInput.current.mask.getRawValue().split('_').join('')});
                                    }}
                                />
                            </Col>
                        </Form.Row>

                        <Form.Group>
                            <Form.Label>Mensagem</Form.Label>
                            <TextoSms 
                                value={dados.mensagem}
                                onChange={e => {
                                    dados.mensagem = e.target.value;
                                    alterarDados({...dados});
                                }}
                                maxLength={200}
                            />
                        </Form.Group>
                        <p className={'text-right'}>
                            <span>Caracteres </span>
                            <Badge
                                variant={dados.mensagem.length > 160 ? 'danger' : 'info'}
                            >
                                {dados.mensagem.length}
                            </Badge>
                        </p>
                    </Card.Body>
                </Card>
                <Form.Group className="d-flex justify-content-around my-4">
                    <Button variant="danger" onClick={() => {
                        history.goBack();
                    }}>
                        <span>Cancelar</span>
                    </Button>
                    <Button 
                        variant="success" 
                        disabled={salvando} 
                        // onClick={enviar}
                        onClick={() => isMobile 
                            ? `whatsapp://send?phone=${encodeURIComponent(dados.celular)}&text=${encodeURIComponent(dados.mensagem)}` 
                            : window.open(`https://api.whatsapp.com/send?phone=${encodeURIComponent(dados.celular)}&text=${encodeURIComponent(dados.mensagem)}`)
                        }
                    >
                        {salvando ? 
                            <>
                                <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                <span>Enviando</span>
                            </> : 
                            <span>Enviar whatsapp</span>
                        }
                    </Button>
                </Form.Group>
            </Col>
            <Col lg={4}>
                <Card className={'border-0 shadow-sm'}>
                    <Card.Body>
                        { dados.mensagem === '' && 
                            <ComboModeloSms 
                                alterou={carregarModelo}
                                tipo={['3']}
                                placeholder={'Escolher modelo de whatsapp'}
                            />
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>

    

}