import React from 'react';

// componentes
import { Form } from 'react-bootstrap';

// componente
export default function TextoSms({
    value,
    onChange,
    maxLength=160
}){

    return <Form.Control 
        as='textarea'
        value={value}
        onChange={e => {
            console.dir(e.target);
            // limpa texto
            let textoLimpo = e.target.value;

            // substitui alguma letra especifico
            textoLimpo = textoLimpo.replace('ã', 'a');
            textoLimpo = textoLimpo.replace('ç', 'c');
            textoLimpo = textoLimpo.replace('é', 'e');
            textoLimpo = textoLimpo.replace('í', 'i');

            // se o texto estiver conforme padrão, altera
            if(/^[A-Za-z0-9 .,-/?$:_\n\[\]]+$/.test(textoLimpo)){
                value = textoLimpo;
            }else if(textoLimpo === ''){
                value = '';
            }

            e.target.value = value;

            // alterarDados({...dados});
            onChange(e);
        }}
        maxLength={maxLength}
    />

}