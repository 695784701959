import React, { useState } from 'react';
import styles from './index.module.scss';
import moment from 'moment';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Button, Tooltip, ButtonToolbar, OverlayTrigger, Badge } from 'react-bootstrap';

function Evento({
    dados,
    modo='cadastro', // cadastro, automatico
    excluir,
    alterar
}){

    return <Card  className={[styles.evento, 'shodow-sm border-0', (modo === 'automatico') && 'bg-light'].join(' ')}>
        <Card.Body>
            { modo === 'cadastro' &&
                <>
                    { dados.concluido === 'N' ?
                        <small>{moment(dados.data_programada).format('DD/MM/YYYY')}</small>
                    :
                        <>
                            <small className='mr-1'>{moment(dados.data_concluido).format('DD/MM/YYYY')}</small>
                            <Badge
                                variant='success'
                            >
                                Concluido
                            </Badge>
                        </>
                    }
                    <hr className='mt-1' />
                </>
            }
            <p className='mb-0'>{dados.descricao}</p>


            <div className={[styles.opcoes, 'p-1'].join(' ')}>

                { modo === 'cadastro' &&
                    <ButtonToolbar>
                        <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip>Editar</Tooltip>}
                        >
                            <button
                                onClick={alterar}
                                className='rounded-circle'
                            >
                                <FontAwesomeIcon className="icone" icon={["fas", 'pencil-alt']} />
                            </button>
                        </OverlayTrigger>
                    </ButtonToolbar>
                }

                { (modo === 'automatico' || dados.id === null) &&
                    <ButtonToolbar>
                        <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip>Excluir</Tooltip>}
                        >
                            <button
                                onClick={excluir}
                                className='rounded-circle'
                            >
                                <FontAwesomeIcon className="icone" icon={["fas", 'trash']} />
                            </button>
                        </OverlayTrigger>
                    </ButtonToolbar>
                }

            </div>
        </Card.Body>
    </Card>
}

export default Evento;