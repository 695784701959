import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import axios from 'axios';

// componentes
import OptionSelect from './OptionSelect';

// combo para consulta de responsaveis
export default function ComboResponsavelConsulta({
    onChange,
    placeholder='Responsáveis'
}){

    // estados
    let [consulta, alterarConsulta] = useState([]);
    let [responsaveis, alterarResponsaveis] = useState([]);

    // inicializa com dados pre definidos
    useEffect(() => {
        consultarResponsaveis('');
    }, []);

    // consultar responsavels
    async function consultarResponsaveis(inputValue, callback = null){

        try{

            // faz a requisição
            let { data } = await axios.get(`/usuarios/responsaveis`, {
                params: {
                    texto: inputValue,
                    registrosPorPagina: 5,
                }
            });

            // filtra registros, desabilita quem já está na lista
            let registros = data.registros.map(responsavel => {
                // verifica se já está adicionado
                if(responsaveis.find(responsavelFind => {
                    if(responsavelFind.dados.id === responsavel.dados.id){
                        return true;
                    }
                    return false;
                })){
                    responsavel.isDisabled = true;
                }
                return responsavel;
            });
            
            // altera os dados
            alterarConsulta(registros);
            if(callback !== null){
                callback(registros);
            }

        }catch({response}){}


    }

    // ao alterar grupos, retorna ids
    useEffect(() => {
        onChange(responsaveis.map(r => r.dados.id));
    }, [responsaveis]);

    // retorno
    return <AsyncSelect 
        isMulti
        isClearable
        loadOptions={consultarResponsaveis}
        defaultOptions={consulta}
        options={responsaveis}
        components={{
            Option: OptionSelect
        }}
        value={responsaveis.map(opcao => {
            return {label: opcao.cliente.nome, value: opcao.dados.id}
        })}
        onChange={(evento, validar) => {

            // verifica ações
            if(validar.action === 'pop-value'){
                responsaveis.pop();
                alterarResponsaveis([...responsaveis]);
            }else if(validar.action === 'remove-value'){
                alterarResponsaveis(responsaveis.filter((responsavel, a) => {
                    if(responsavel.dados.id === validar.removedValue.value){
                        return false;
                    }
                    return true
                }));
            }else if(validar.action === 'clear'){
                alterarResponsaveis([]);
            }else if(validar.action === 'select-option'){
                alterarResponsaveis([...responsaveis, validar.option]);
            }
        }}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Digite algo para fazer a consulta'}
        placeholder={placeholder}
    />
}
