import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, OverlayTrigger, ButtonToolbar, Tooltip  } from 'react-bootstrap';
import Moment from 'react-moment';
import Carregador from './../../Carregador';
import useIsMounted from 'ismounted';

// icones
import IconeDoc from './icones/doc.png';

function Arquivo({
    arquivo,
    excluido,
    rotaExcluir='anexos',
    rotaBaixar='anexos',
    abrir,
    size='md',
    habilitarExcluir=true,
    login
}){
    
    // variaveis
    const isMounted = useIsMounted();
    let [executando, alterarExecutando] = useState(false);
    let [confirmarExclusao, mostrarConfirmarExclusao] = useState(false);

    // faz a exclusão do arquivo
    async function excluir(){
        alterarExecutando(true);
        
        // faz a requisição
        try{
            let retorno = await axios.delete(`/${rotaExcluir}/${arquivo.id}`);

            // excluido
            excluido(arquivo);
            
        }catch({response}){
            
            // com erro
            if(response){

                // se for erro comum
                if(response.status === 400){
                    console.log(response.data);
                }

            }

        }finally{
            if (isMounted.current) {
                alterarExecutando(false);
            }
        }

    }

    // 
    return <div className={[styles.arquivo, (size === 'sm') && styles.small , 'p-2 rounded'].join(' ')}>

        { arquivo.nome_temporario.split('.').pop() === 'jpg' ?
            <img src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/${rotaBaixar}/${arquivo.nome_temporario}`} className={[styles.icone, 'mr-2'].join(' ')} />
            :
            <img src={IconeDoc} className={[styles.icone, 'mr-2'].join(' ')} />
        }

        <div className={styles.descricao}>
            <div title={arquivo.nome} className={[styles.nome, 'text-truncate'].join(' ')}>{arquivo.nome}</div>
            <div><small className='text-secondary'><Moment format='DD/MM/YYYY'>{arquivo.data_cadastro}</Moment></small></div>
            <div className={[styles.opcoesArquivo, 'p-2'].join(' ')}>

                {/* <button
                    className={[styles.opcao, 'rounded ml-1'].join(' ')}
                    onClick={abrir}
                >
                    <FontAwesomeIcon className={styles.iconeOpcao} icon={["fas", 'download']} />
                </button> */}
                <OverlayTrigger
                    placement='top'
                    overlay={<Tooltip>Abrir Arquivo</Tooltip>}
                >
                    <a 
                        // href={`${process.env.REACT_APP_URL_PHP}/arquivos/anexos/${arquivo.nome_temporario}`} 
                        href={`${process.env.REACT_APP_URL_ARQUIVOS}/download.php/${encodeURIComponent(arquivo.nome)}?p=${rotaBaixar}&f=${encodeURIComponent(arquivo.nome_temporario)}&n=${encodeURIComponent(arquivo.nome)}`} 
                        target="_blank" 
                        className={[styles.opcao, 'rounded ml-1 pb-1 px-1'].join(' ')}
                    >
                        <FontAwesomeIcon className={styles.iconeOpcao} icon={["fas", 'download']} />
                    </a>
                </OverlayTrigger>
                {/* <button
                    className={[styles.opcao, 'rounded ml-1'].join(' ')}
                >
                    <FontAwesomeIcon className={styles.iconeOpcao} icon={["fas", 'pencil-alt']} />
                </button> */}

                {/* <button
                    className={[styles.opcao, 'rounded ml-1'].join(' ')}
                    onClick={() => {mostrarConfirmarExclusao(true)}}
                >
                    <FontAwesomeIcon className={styles.iconeOpcao} icon={["fas", 'trash']} />
                </button> */}

                { confirmarExclusao && 
                    <>
                        <Button 
                            variant='danger' 
                            size='sm' 
                            className={[styles.opcaoExclusao, 'px-2 py-0 mr-1'].join(' ')}
                            onClick={() => {mostrarConfirmarExclusao(false)}}
                            disabled={executando}
                        >
                            <FontAwesomeIcon className={styles.iconeOpcaoExcluir} icon={["fas", 'times']} />
                            <span>Cancelar</span>
                        </Button>
                        <Button 
                            variant='success' 
                            size='sm' 
                            className={[styles.opcaoExclusao, 'px-2 py-0'].join(' ')}
                            onClick={excluir}
                            disabled={executando}
                        >
                            <FontAwesomeIcon className={styles.iconeOpcaoExcluir} icon={["fas", 'check']} />
                            <span>Confirmar</span>
                        </Button>
                    </>
                }

                {/* EXCLUIR */}
                { (habilitarExcluir && !confirmarExclusao) &&
                    <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Excluir Arquivo</Tooltip>}
                    >
                        <button
                            className={[styles.opcao, 'rounded ml-1'].join(' ')}
                            onClick={() => {mostrarConfirmarExclusao(true)}}
                        >
                            <FontAwesomeIcon className={styles.iconeOpcao} icon={["fas", 'trash']} />
                        </button>
                    </OverlayTrigger>
                }
                
            </div>
        </div>
        { executando && 
            <Carregador 
                className='rounded bg-light text-body'
                small={true}
            />
        }
    </div>
}

// function Arquivo.Upload(){

// }

export function ArquivoUpload({
    upload,
    emEspera,
    size='md'
}){

    return <div className={[styles.arquivo, styles.salvando, (size === 'sm') && styles.small , 'p-2 rounded shadow-sm'].join(' ')}>
        { !emEspera && 
            <>
                { upload.erro === null ?
                    <div className={[styles.barra, 'rounded'].join(' ')} style={{width: `${upload.progresso || 0}%`}}><span>{upload.progresso || 0}%</span></div>
                    :
                    <div className={[styles.barra, styles.erro, 'rounded'].join(' ')} style={{width: `${upload.progresso || 0}%`}}><span className={'text-light'}>Erro!</span></div>
                }
                
            </>
            
        }
        <img src={IconeDoc} className={[styles.icone, 'mr-2'].join(' ')} />
        <div className={styles.descricao}>
            <div className={[styles.nome, 'text-truncate'].join(' ')} title={upload.arquivo.name}>{upload.arquivo.name}</div>
            {!emEspera && 
                <div>
                    <small className='text-secondary'>{upload.erro === null ? 'Salvando arquivo...' : ''}</small>
                </div>
            }
        </div>
        
    </div>
}

// importa states
let mapStateToProps = function(state){
    let estado = {
        login: state.login
    };
    return estado;
};

// retorna página de consulta
export default connect(mapStateToProps)(Arquivo);