import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import moment from 'moment';

// componentes
import { Form, Row, Col } from 'react-bootstrap';
import InputHora from './../../../Componentes/InputHora';
import Flatpick from 'react-flatpickr';
import { Portuguese } from "flatpickr/dist/l10n/pt";

// componente
export default function ParametrosSms({
    dados,
    alterarDados
}){

    // componente
    return <>

        <Form.Group>
            <Form.Check 
                type="switch"
                id="agendar-switch"
                label="Agendar essa mensagem?"
                value={dados.data_agendado === null ? false : true}
                onChange={(e) => {

                    if(e.target.checked){
                        dados.data_agendado = moment().format('YYYY-MM-DD HH:mm:DD');
                    }else{
                        dados.data_agendado = null;
                    }
                    alterarDados({...dados});
                }}
            />
        </Form.Group>

        { dados.data_agendado !== null &&
            <Form.Row className={'mb-3'}>
                <Col>
                    <Form.Label>Data</Form.Label>
                    <Flatpick 
                        className={["form-control", styles.campoData].join(' ')} 
                        value={dados.data_agendado.split(' ')[0]}
                        options={{
                            dateFormat: "Y-m-d",
                            altFormat: "d/m/Y",
                            altInput: true,
                            locale: Portuguese,
                            // allowInput: true,
                            onChange: (date, dateStr, instance) => {
                                if(dateStr === '') dateStr = null;
                                dados.data_agendado = `${dateStr} ${dados.data_agendado.split(' ')[1]}`;
                                alterarDados({...dados});  
                            }
                        }}
                    />
                </Col>
                <Col>
                    <Form.Label>Hora</Form.Label>
                    <InputHora 
                        value={dados.data_agendado.split(' ')[1]}
                        onChange={valor => {
                            dados.data_agendado = `${dados.data_agendado.split(' ')[0]} ${valor}`;
                            alterarDados({...dados});  
                        }}
                        aceitarNull={false}
                    />
                </Col>

            </Form.Row>
        }

        <Form.Group>
            <Form.Check 
                type="switch"
                id="flashsms-switch"
                label="Mensagem do tipo flashsms?"
                value={dados.flashsms === 'S' ? true : false}
                onChange={(e) => {

                    if(e.target.checked){
                        dados.flashsms = 'S';
                    }else{
                        dados.flashsms = 'N';
                    }
                    alterarDados({...dados});
                }}
            />
        </Form.Group>
    
    </>
}