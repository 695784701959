import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './PainelNavbar.scss';
import styles from './index.module.scss';
import imagemVazia from './imagem.png';
import moment from 'moment';
import axios from 'axios';

// componentes
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavDropdown, ButtonToolbar, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import ModalInformar from './../Dispositivos/ModalInformar';

// navbar
export default function PainelNavbar({
    numero=8
}){

    // estados
    const { login, painel } = useSelector(state => state);
    const dispatch = useDispatch();
    let [hora, alterarHora] = useState(null);
    let [habilitarBatida, alterarHabilitarBatida] = useState(false);
    let [escolherDispositivo, alterarEscolherDispositivo] = useState(false);
    let [batendoPonto, alterarBatendoPonto] = useState(false);

    // clicou para fazer logoff
    function deslogar(e){
        
        // desloga
        dispatch({
            type: 'FAZER_LOGGOF',
            reducer: 'login'
        });
    }

    // verde: ponto batido, azul: neutro, precisa ser batido
    // ajusta tempo
    useEffect(() => {

        // altera hora
        let intervalo = setInterval(() => {
            hora = new Date();
            alterarHora(hora);

            // verifica se é para habilitar ou desabilitar o ponto
            if(painel.ponto.ultima_batida !== null){

                // calcula as horas
                let horas = moment(hora);
                let horaBatida = moment(painel.ponto.ultima_batida.data_batida);
                let diferencaHoras = horas.diff(horaBatida);

                // verifica se foi última batida a 20 minutos
                let min20 = 20 * 60 * 1000;

                // pega o tempo em ml
                let tempoMl = diferencaHoras.valueOf();
                if(tempoMl >= 0 && tempoMl <= min20){
                    alterarHabilitarBatida(false);
                }else{
                    alterarHabilitarBatida(true);
                }

            }else{
                alterarHabilitarBatida(true);
            }

        }, 1000);

        return () => {
            clearInterval(intervalo);
        }

    }, [painel.ponto]);

    // bate o ponto
    async function baterPonto(){
        alterarHabilitarBatida(false);
        alterarBatendoPonto(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/ponto/bater`);
            alterarBatendoPonto(false);
            
            // atualiza dados do ponto
            dispatch({
                type: 'painelReducer/ATUALIZARPONTO',
                ponto: data.ponto
            });

        }catch({response}){}
    }

    return <>
        { escolherDispositivo &&
            <ModalInformar 
                fechou={() => {
                    alterarEscolherDispositivo(false);
                }}
            />
        }
        <nav className="painel-navbar navbar navbar-expand-lg ">

            <div className="container-fluid">
                {/* <p className="navbar-brand"> Usuários / Cadastrar / </p> */}
                {/* <a className={[styles.navbarBrand, 'navbar-brand'].join(' ')} href="/">{login.dadosUsuario.login}</a> */}
                
                <div className="collapse navbar-collapse justify-content-end">
                    <ul className="nav navbar-nav mr-auto">

                        <ItemMenuTipo1
                            link={'/painel'}
                            icone={["fas", "home"]}
                            label={'Inicio'}
                        />
                        {/* <ItemMenuTipo1
                            link={'/painel/historico'}
                            icone={["fas", "user-clock"]}
                            label={'Histórico Usuários'}
                        /> */}
                        <ItemMenuTipo1
                            link={'/painel/clientes-mapa'}
                            icone={["fas", "map-marked-alt"]}
                            label={'Mapa dos Clientes'}
                        />
                        <ItemMenuTipo1
                            link={'/painel/relatorios'}
                            icone={["fas", "chart-line"]}
                            label={'Relatórios'}
                        />
                        <ItemMenuTipo1
                            link={'/painel/meu-ponto'}
                            icone={["fas", "list-alt"]}
                            label={'Ponto'}
                        />
                        <ItemMenuTipo1
                            link={'/painel/historico'}
                            icone={["fas", "clock"]}
                            label={'Histórico'}
                        />
                        <ItemMenuTipo1
                            link={'/painel/arquivos-whatsapp'}
                            icone={["fab", "whatsapp-square"]}
                            label={'Arquivo Whatsapp'}
                        />


                        
                        {/* <li className="nav-item">
                            <Link className="nav-link" data-toggle="dropdown" to="/">
                                <FontAwesomeIcon className="icone" icon={["fas", "home"]}></FontAwesomeIcon>
                                <span className="d-lg-none">Inicio</span>
                            </Link>
                        </li> */}
                        
                        {/* <li className="dropdown nav-item show">
                            <NavDropdown className="nav-link nav-link-dropdown" title={(
                                <span className="">
                                    <FontAwesomeIcon className="icone" icon={["fas", "home"]}></FontAwesomeIcon>
                                    <b className="caret" />
                                    <span className="notification">{numero}</span>
                                </span>
                            )} >
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                            </NavDropdown>
                        </li> */}

                    </ul>
                    <ul className="navbar-nav ml-auto">
                        {/* <li className="nav-item">
                            <a className="nav-link" href="#pablo">
                                <span className="no-icon">Account</span>
                            </a>
                        </li> */}

                        { painel.dispositivo &&
                            <li className={[styles.link, 'nav-item'].join(' ')}>
                                <ButtonToolbar>
                                    <OverlayTrigger
                                        placement={'bottom'}
                                        overlay={
                                            <Tooltip>
                                                {painel.dispositivo.descricao}
                                            </Tooltip>
                                        }
                                    >
                                        <Button 
                                            className={[styles.linkButton,'nav-link'].join(' ')}
                                            onClick={() => {
                                                console.log('bater ponto')
                                            }}
                                        >
                                            <FontAwesomeIcon className="icone" icon={["fas", "desktop"]}></FontAwesomeIcon>
                                        </Button> 
                                    </OverlayTrigger>
                                </ButtonToolbar>
                            </li>
                        }

                        { (painel.dispositivo === null && login.permissao.habilitarTemporariamenteDefinirDispositivo === 'S') &&
                            <li className={[styles.link, 'nav-item'].join(' ')}>
                                <ButtonToolbar>
                                    <OverlayTrigger
                                        placement={'bottom'}
                                        overlay={
                                            <Tooltip>
                                                Informar dispositivo
                                            </Tooltip>
                                        }
                                    >
                                        <Button 
                                            className={[styles.linkButton,'nav-link'].join(' ')}
                                            onClick={() => {
                                                alterarEscolherDispositivo(true);
                                            }}
                                        >
                                            <FontAwesomeIcon className="icone" icon={["fas", "desktop"]}></FontAwesomeIcon>
                                        </Button> 
                                    </OverlayTrigger>
                                </ButtonToolbar>
                            </li>
                        }

                        { ( painel.ponto && painel.ponto.habilitar_ponto ) &&
                            <li className={[styles.link, 'nav-item', habilitarBatida && styles.fazerBatida].join(' ')}>
                                <ButtonToolbar>
                                    <OverlayTrigger
                                        placement={'bottom'}
                                        overlay={
                                            <Tooltip>
                                                <p className='mb-0'>Bater ponto</p> 
                                                <span>{hora && moment(hora).format('HH:mm:ss')}</span>
                                            </Tooltip>
                                        }
                                    >
                                        <Button 
                                            className={[styles.linkButton,'nav-link', styles.botaoPonto].join(' ')}
                                            disabled={ batendoPonto === true }
                                            onClick={() => {

                                                // faz a batida do ponto se for permitido
                                                if(habilitarBatida && !batendoPonto){
                                                    baterPonto();
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon className="icone" icon={["fas", "concierge-bell"]}></FontAwesomeIcon>
                                        </Button> 
                                    </OverlayTrigger>
                                </ButtonToolbar>
                            </li>
                        }

                        <div className={[styles.navbarBrand, 'pl-2'].join(' ')}>
                            <div className={styles.info}>
                                { login.dadosUsuario.tipo === '2' && 
                                    <span className={styles.cargo}>{login.dadosCliente.profissao}</span>
                                }
                                { login.dadosUsuario.tipo === '1' ?
                                    <span className={styles.nome}>{login.dadosUsuario.login}</span>
                                    :
                                    <span className={styles.nome}>{login.dadosCliente.nome}</span>
                                }
                            </div>
                            { login.dadosCliente.foto ?
                                <img src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/fotos/${login.dadosCliente.foto}`} className={styles.foto} />
                                :
                                <FontAwesomeIcon className={styles.iconeUsuario} icon={["fas", "user-circle"]}></FontAwesomeIcon>
                            }

                            
                        </div>
                        <li className="nav-item">
                            <button className={[styles.botaoSair, ""].join('')} onClick={deslogar}>
                                <span className="no-icon">Sair</span>
                                <FontAwesomeIcon className={styles.icone} icon={["fas", "lock"]}></FontAwesomeIcon>
                            </button>
                        </li>
                        <ItemMenuTipo1
                            link={'/painel/configuracoes'}
                            icone={["fas", "cog"]}
                            label={'Configurações'}
                        />
                    </ul>
                </div>
            </div>
            
        </nav>
    </>
}

function ItemMenuTipo1({
    link,
    label,
    icone,
}){

    return <li className={[styles.link, 'nav-item'].join(' ')}>
        <ButtonToolbar>
            <OverlayTrigger
                placement={'bottom'}
                overlay={
                    <Tooltip>{label}</Tooltip>
                }
            >
                <Link className={[styles.navLink, 'nav-link'].join(' ')} data-toggle="dropdown" to={link}>
                    <FontAwesomeIcon className="icone" icon={icone}></FontAwesomeIcon>
                </Link>
            </OverlayTrigger>
        </ButtonToolbar>
    </li>

}

function ItemMenuTipo2({
    onClick,
    label,
    icone,
    className=''
}){

    return <li className={[styles.link, 'nav-item'].join(' ')}>
        <ButtonToolbar>
            <OverlayTrigger
                placement={'bottom'}
                overlay={
                    <Tooltip>{label}</Tooltip>
                }
            >
                <Button className={[styles.linkButton,'nav-link', className].join(' ')} onClick={onClick}>
                    <FontAwesomeIcon className="icone" icon={icone}></FontAwesomeIcon>
                </Button> 
            </OverlayTrigger>
        </ButtonToolbar>
    </li>

}
