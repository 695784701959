import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

// componentes
import ConsultaProximidade from './ConsultaProximidade';
import MapaGoogle from './MapaGoogle';
import ClienteBuscar from './ClienteBuscar';
import ClienteFocado from './ClienteFocado';

// contexto
export const ClientesMapaContexto = React.createContext(null);

// https://spectrum.chat/react-google-maps/general/map-fitbounds-automatically~bf9894eb-31aa-4d00-bbcf-66a70c5680dd
function ClientesMapa({
    ...props
}){    

    // inicializa contexto
    const [valor, alterarValor] = useState(1234);
    const [cliente, alterarCliente] = useState(null);
    const [clienteSelecionado, alterarClienteSelecionado] = useState(null);
    const [clienteSelecinadoEventos, alterarClienteSelecinadoEventos] = useState(null);
    const [cordenadaCentral, alterarCordenadaCentral] = useState({
        lat: -27.591684,
        lng: -48.551642
    });
    const [filtros, alterarFiltros] = useState({
        grupos: []
    });
    const [zoom, alterarZoom] = useState(20);
    const [clientesProximidade, alterarClientesProximidade] = useState([]);
    const [consultando, alterarConsultando] = useState(true);
    const [chaveApi, alterarChaveApi] = useState(null);

    // função feita para resgatar chave da api da google do cliente
    async function consultarChaveMapaGoogle() {

        try {

            // consulta a chave
            const { data } = await axios.get(`/configuracaoSistema/chaveApiGoogle`);
            
            // altera estado com valor
            alterarChaveApi(data.valor);

        } catch(e){
            console.log(e);
        } finally {

            // esconde carregamento
            alterarConsultando(false);

        }

    }

    useEffect(() => {
        consultarChaveMapaGoogle();
    }, [])

    useEffect(() => {
        // alterarClienteSelecionado(null);
        alterarClientesProximidade([]);
        // alterarCliente(null);
    }, [filtros]);
    
    // se estiver carregando retorna icone de carregamento
    if(consultando || !chaveApi) return <div 
        className='w-100 d-flex align-items-center justify-content-center'
        style={{ height: '100vh' }}
    >
        <FontAwesomeIcon 
            pulse
            icon={faSpinner} 
            style={{
                width: 100,
                height: 100
            }}                         
        />
    </div>

    return <ClientesMapaContexto.Provider
        value={{
            valor, alterarValor,
            cliente, alterarCliente,
            cordenadaCentral, alterarCordenadaCentral,
            zoom, alterarZoom,
            clientesProximidade, alterarClientesProximidade,
            clienteSelecionado, alterarClienteSelecionado,
            clienteSelecinadoEventos, alterarClienteSelecinadoEventos,
            filtros, alterarFiltros
        }}
    >

        <MapaGoogle
            chaveApi={chaveApi}
        />
        
        <ClienteBuscar />
        {cliente !== null &&
            <ClienteFocado 
                key={cliente}
                chaveApi={chaveApi} 
            />
        }
    
        <ConsultaProximidade 
            chaveApi={chaveApi}
        />
    </ClientesMapaContexto.Provider>

}

// pega as propriedades do painel
const mapStateToProps = (state) => {
    const estado = {
        painel: state.painel,
        login: state.login
    };
    return estado
}

export default connect(mapStateToProps)(ClientesMapa);
