import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Portuguese } from "flatpickr/dist/l10n/pt";
import Flatpick from 'react-flatpickr';
import moment from 'moment';
import axios from 'axios';
import 'moment/locale/pt-br';

// componentes
import { FechamentoComponent } from './components';
import { Card, Col, Row, Badge, Button, Form } from 'react-bootstrap';
import Carregador from './../../Componentes/Carregador';
import InputData from '../../Componentes/InputData';
import Registro from './Registro';
import ModalConfirmar from './../../Componentes/ModalConfirmar';
import { DebounceInput } from 'react-debounce-input';
import ModalEditar from './ModallEditar';
import PaginacaoComponente from '../../Componentes/Paginacao';

// consulta
export default function Fechamento() {
    
    // estados
    const { dataMes } = useParams();
    const [parametros, alterarParametros] = useState({
        data: dataMes ?? null,
        pagina: 1,
        registrosPorPagina: 100,
        pesquisa: ''
    });
    const [carregando, mostrarCarregando] = useState(false);
    const [consulta, alterarConsulta] = useState([]);
    const [confirmarFechamento, alterarConfirmarFechamento] = useState(null);
    const [fazendoFechamento, alterarFazendoFechamento] = useState(false);
    const [fazendoRelatorioPlano, alterarFazendoRelatorioPlano] = useState(false);
    const [fazendoFechamentoMassa, alterarFazendoFechamentoMassa] = useState(false);
    const [fazendoRelatorioParcial, alterarFazendoRelatorioParcial] = useState(false);
    const [fazendoRelatorioMensal, alterarFazendoRelatorioMensal] = useState(false);
    const [clicouDesabilitarTodosFechamento, alterarClicouDesabilitarTodosFechamento] = useState(true);
    const [clicouDesabiltiarTodosBoleto, alterarClicouDesabilitarTodosBoleto] = useState(true);
    const [modalEdicao, alterarModalEdicao] = useState({
        mostrar: false,
        registro: null
    });
    const [confirmarFechamentoMassa, alterarConfirmarFechamentoMassa] = useState(null);
    const [valorMinimo, alterarValorMinimo] = useState("9.90");
    const [dataValidadeBoleto, alterarDataValidadeBoleto] = useState(dataMes ? moment(dataMes).add(1, 'M').format('YYYY-MM-12') : '');
    const [totalRegistros, alterarTotalRegistros] = useState(0);

    let dataValidadeBoletoRef = React.createRef();

    // inicializa
    useEffect(() => {
        consultarRegistros();
    }, [parametros]);

    // consulta registros
    async function consultarRegistros() {

        // mostra carregamento
        mostrarCarregando(true);

        try {

            // faz a requisição
            const { data } = await axios.get('/seguroAventura/fechamentos', { params: parametros });

            // altera registros 
            alterarConsulta(data.registros.map((m) => {
                return {
                    ...m,
                    selecionado: m.cliente.clienteDesabilitado === "N" ? true : false,
                    selecionadoBoleto: m.cliente.clienteDesabilitado === "S" || (
                        m.dados.possuiBoletos === "S" &&
                        m.dados.possuiBoletosEmAberto === "N"
                        && m.dados.possuiEventos === "S"
                    )
                        || !m.cliente.idAsaas ? false : true
                }
            }));

            // resgata total de registros
            alterarTotalRegistros(parseInt(data.totalRegistros));


        } catch (e) {
            console.log(e);
        } finally {

            // esconde carregamento
            mostrarCarregando(false);

        }
    }

    // faz fechamento de seguro
    async function fazerFechamento() {

        // faz o carregamento
        alterarFazendoFechamento(true)

        try {

            // faz a requisição
            await axios.post(`/seguroAventura/fechamentoCartaOferta`, {
                dados: {
                    cartaOferta: confirmarFechamento.dados.cartaOferta,
                    data: parametros.data,
                    simular: "N",
                    valorMinimo: valorMinimo ? valorMinimo.replace(',', '.') : null,
                    seguroValorDiariaEditado: confirmarFechamento.cliente.seguroValorDiariaEditado ? parseFloat(confirmarFechamento.cliente.seguroValorDiariaEditado) : null,
                    idCliente: confirmarFechamento.cliente.id,
                    dataVencimento: dataValidadeBoleto === '' ? null : dataValidadeBoleto,
                    fazFechamento: confirmarFechamento.selecionado,
                    geraBoleto: confirmarFechamento.selecionadoBoleto,
                    dataVencimento: dataValidadeBoleto === '' ? null : dataValidadeBoleto
                }
            });

            alterarConfirmarFechamento(null);
            consultarRegistros();

        } catch (e) {

            // se veio retorno
            if (e.response && e.response.data) {

                // resgata primeiro erro
                const erro = Object.entries(e.response.data)[0];

                // resgata chave e valor do erro
                const [chave, valor] = erro;

                toast(({ closeToast }) => <>
                    <div className="toast-header bg-danger text-white">
                        <strong className="mr-auto">{chave.charAt(0).toUpperCase() + chave.slice(1)}</strong>
                        <button
                            onClick={closeToast}
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="toast-body  bg-danger text-white">
                        {valor}
                    </div>
                </>, { autoClose: 10000 });
            }

        } finally {
            alterarFazendoFechamento(false);
        }
    }

    // faz fechamento de seguro
    async function fazerFechamentoMassa() {

        // verifica se está pesquisando
        if (parametros.pesquisa.length > 0) {

            return toast(({ closeToast }) => <>
                <div className="toast-header bg-warning text-white">
                    <strong className="mr-auto">Aviso</strong>
                    <button
                        onClick={closeToast}
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body  bg-warning text-white">
                    Limpe a pesquisa para o funcionamento do fechamento em massa.
                </div>
            </>, { autoClose: 10000 })

        }

        // mostra carregamento 
        alterarFazendoFechamentoMassa(true);

        // prepara desselecionados
        let desselecionados = [];
        let desselecionadosBoleto = [];

        // percorre registros
        consulta.map(c => {

            // se registro estiver desselecionado
            if (!c.selecionado) {

                // adiciona id ao array
                desselecionados.push(c.dados.id);

            }

            // se registro estiver desselecionado para boleto
            if (!c.selecionadoBoleto) {

                // adiciona id ao array
                desselecionadosBoleto.push(c.dados.id);

            }

        })

        // cria parametros para requisição de fechamento em massa
        const parametrosRequisicao = {
            pagina: parametros.pagina,
            pesquisa: parametros.pesquisa,
            registrosPorPagina: parametros.registrosPorPagina,
            data: parametros.data,
            valorMinimo: valorMinimo ? valorMinimo.replace(',', '.') : null,
            desselecionados,
            desselecionadosBoleto,
            dataVencimento: dataValidadeBoleto === '' ? null : dataValidadeBoleto
        }

        try {

            // faz a requisição
            await axios.post(`/seguroAventura/fechamentoCartaOferta/massa`, parametrosRequisicao);

            alterarConfirmarFechamentoMassa(null);
            consultarRegistros();

        } catch (e) {
            console.log(e);

            if (e.response && e.response.data) {
                e.response.data.map(retorno => {

                    toast(({ closeToast }) => <>
                        <div className="toast-header bg-danger text-white">
                            <strong className="mr-auto">{retorno.nome}</strong>
                            <button
                                onClick={closeToast}
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="toast-body  bg-danger text-white">
                            {retorno.descricao}
                        </div>
                    </>, { autoClose: 10000 });
                })
            }

        } finally {
            alterarFazendoFechamentoMassa(false);
        }
    }

    // altera dados do registro no banco
    async function alterarRegistroCliente(registro) {

        // mostra carregamento
        mostrarCarregando(true);

        let dadosUsuario = registro.dadosUsuario || {
            id: null,
            login: '',
            senha: '',
            confirmar_senha: ''
        }

        try {

            // altera dados do fechamento
            await axios.post('/clientes', {
                dados: registro.dados,
                grupos: registro.grupos,
                modoUsuario: registro.dadosUsuario ? true : false,
                dadosUsuario
            });

            // esconde carregamento
            mostrarCarregando(false);

            // consulta novamente os registros
            consultarRegistros();

        } catch (e) {

            console.log(e)

        } finally {

            // esconde carregamento
            mostrarCarregando(false);

        }

    }

    // faz relatório parcial para gerar xls
    async function fazerRelatorioParcial() {

        // mostra carregamento
        alterarFazendoRelatorioParcial(true);

        try {

            // faz a requisição
            const { data } = await axios.get('/seguroAventuraFechamento/relatorioParcial', {
                params: {
                    data: dataMes.toString()
                }
            });

            // faz o download
            window.open(
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=anexosEmail&f=${encodeURIComponent(data.temporario)}&n=${encodeURIComponent(data.nome)}`,
                '_blank'
            );

        } catch (e) {
            console.log(e);
        } finally {
            // esconde carregamento
            alterarFazendoRelatorioParcial(false);
        }

    }

    // faz relatório mensal para gerar xls
    async function fazerRelatorioMensal() {

        // mostra carregamento
        alterarFazendoRelatorioMensal(true);

        try {

            // faz a requisição
            const { data } = await axios.get('/seguroAventura/gerarFaturamentoMensal', {
                params: {
                    data: dataMes.toString()
                }
            });

            if (data.length > 0) {

                toast(({ closeToast }) => <>
                    <div className="toast-header bg-success text-white">
                        <strong className="mr-auto">Sucesso</strong>
                        <button
                            onClick={closeToast}
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="toast-body  bg-success text-white">
                        Verifique sua caixa de entrada em seu e-mail
                    </div>
                </>, { autoClose: 10000 });

            }

        } catch (e) {
            console.log(e);
        } finally {
            // esconde carregamento
            alterarFazendoRelatorioMensal(false);
        }

    }

    // faz relatório mensal por plano para gerar xls
    async function fazerRelatorioPlano() {

        // mostra carregamento
        alterarFazendoRelatorioPlano(true);

        try {

            // faz a requisição
            const { data } = await axios.get('/seguroAventura/gerarRelatorioMensalPorPlano', {
                params: {
                    data: dataMes.toString()
                }
            });

            if (data.length > 0) {

                toast(({ closeToast }) => <>
                    <div className="toast-header bg-success text-white">
                        <strong className="mr-auto">Sucesso</strong>
                        <button
                            onClick={closeToast}
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="toast-body  bg-success text-white">
                        Verifique sua caixa de entrada em seu e-mail
                    </div>
                </>, { autoClose: 10000 });

            }

        } catch (e) {
            console.log(e);
        } finally {
            // esconde carregamento
            alterarFazendoRelatorioPlano(false);
        }

    }

    // retorna
    return <FechamentoComponent>

        {confirmarFechamento !== null &&
            <ModalConfirmar
                mostrar={confirmarFechamento !== null}
                texto={`Deseja realmente fazer o fechamento da carta oferta ${confirmarFechamento.dados.cartaOferta} ?`}
                clicouCancelar={() => {
                    alterarConfirmarFechamento(null);
                }}
                clicouConfirmar={fazerFechamento}
                textoCarregando={'Fazendo fechamento, aguarde!'}
                carregando={fazendoFechamento}
                textoBotaoConfirmar={''}
            />
        }

        {confirmarFechamentoMassa !== null &&
            <ModalConfirmar
                mostrar={confirmarFechamentoMassa !== null}
                texto={`Deseja realmente fazer o fechamento em massa?`}
                clicouCancelar={() => {
                    alterarConfirmarFechamentoMassa(null);
                }}
                clicouConfirmar={fazerFechamentoMassa}
                textoCarregando={'Fazendo fechamento em massa, aguarde!'}
                carregando={fazendoFechamentoMassa}
                textoBotaoConfirmar={''}
            />
        }

        {modalEdicao.mostrar &&
            <ModalEditar
                clicouCancelar={() => alterarModalEdicao({ mostrar: false })}
                clicouConfirmar={async (registroAlterado) => {

                    // esconde modal
                    alterarModalEdicao({ mostrar: false })

                    // altera fechamento no banco
                    await alterarRegistroCliente(registroAlterado);

                }}
                registro={modalEdicao.registro}
                valorMinimo={valorMinimo}
            />
        }

        <Row>
            <Col>
                <Card className="painel-consulta border-0 shadow-sm">

                    <Card.Header className="d-flex">
                        <h4 className="card-title font-weight-bolder flex-grow-1">
                            Fechamento - {moment(parametros.data).format('MM/YYYY')}
                        </h4>
                        <div>
                            <Button
                                className='mr-2'
                                variant='padrao'
                                size='sm'
                                onClick={() => fazerRelatorioPlano()}
                            >
                                {(fazendoRelatorioPlano) ? <>
                                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                    <span>Carregando</span>
                                </> : 'Relatório por plano'}
                            </Button>
                            <Button
                                className='mr-2'
                                variant='padrao'
                                size='sm'
                                onClick={() => fazerRelatorioParcial()}
                            >
                                {fazendoRelatorioParcial ? '' : 'Relatório parcial'}
                                {fazendoRelatorioParcial && <>
                                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                    <span>Carregando</span>
                                </>}
                            </Button>
                            <Button
                                className='mr-2'
                                variant='padrao'
                                size='sm'
                                onClick={() => fazerRelatorioMensal()}
                            >
                                {fazendoRelatorioMensal ? '' : 'Gerar faturamento mensal'}
                                {fazendoRelatorioMensal && <>
                                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                    <span>Carregando</span>
                                </>}
                            </Button>
                            <Button
                                className='mr-2'
                                variant='padrao'
                                size='sm'
                                onClick={() => {
                                    alterarConfirmarFechamentoMassa(true);
                                }}
                            >
                                Fechamento em massa!
                            </Button>
                        </div>
                    </Card.Header>

                    <div className="d-flex flex-row align-items-center justify-content-between" style={{ padding: '12px 20px' }}>
                        <div className="d-flex flex-row align-items-center">
                            <span style={{ flex: 1 }}>
                                Valor mínimo
                            </span>
                            <DebounceInput
                                placeholder="Ex: 9,90"
                                debounceTimeout={400}
                                element={Form.Control}
                                value={valorMinimo ?? ''}
                                onChange={(e) => {
                                    alterarValorMinimo(e.target.value === '' ? null : e.target.value);
                                }}
                                style={{ marginLeft: 10, width: "auto", height: 40 }}
                            />
                        </div>
                        <div className="d-flex flex-row align-items-center">
                            <span style={{ flex: 1 }}>Validade do boleto</span>
                            <div className="d-flex align-itens-center">
                                <Flatpick
                                    ref={dataValidadeBoletoRef}
                                    value={dataValidadeBoleto}
                                    options={{
                                        dateFormat: "Y-m-d",
                                        altFormat: "d/m/Y",
                                        altInput: false,
                                        allowInput: false,
                                        disableMobile: true,
                                        locale: Portuguese,
                                        onChange: (selectedDates, dateStr, instance) => {
                                            if (dateStr === '') dateStr = null;
                                            alterarDataValidadeBoleto(dateStr);
                                        }
                                    }}
                                    render={
                                        ({ defaultValue, value, onChange, ...props }, ref) => {
                                            return <input onChange={(selectedDates, dateStr, instance) => alterarDataValidadeBoleto(dateStr)} value={dataValidadeBoleto} style={{ visibility: 'hidden', position: 'absolute' }} {...props} defaultValue={defaultValue} ref={ref} />
                                        }
                                    }

                                />
                                <InputData
                                    data={dataValidadeBoleto}
                                    onChange={(data) => {
                                        alterarDataValidadeBoleto(data);
                                    }}
                                    size="sm"
                                    style={{ marginLeft: 10, minWidth: 210, height: 40 }}
                                />
                                <FontAwesomeIcon
                                    style={{
                                        cursor: 'pointer',
                                        alignSelf: 'center',
                                        margin: '0px 8px',
                                        width: 25,
                                        height: 25
                                    }}
                                    icon={["fas", 'calendar-day']}
                                    onClick={() => {
                                        dataValidadeBoletoRef.current.flatpickr.open()
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-row align-items-center justify-content-center" style={{ padding: '12px 20px' }}>
                        <DebounceInput
                            placeholder="Pesquise pelo nome do cliente ou pelo seu código referência"
                            debounceTimeout={400}
                            element={Form.Control}
                            value={parametros.pesquisa ?? ''}
                            onChange={(e) => alterarParametros({ ...parametros, pesquisa: e.target.value, pagina: 1 })}
                            style={{ flex: 1, height: 40 }}
                        />
                    </div>

                    <Card.Body>
                        <div className="legenda mb-1">
                            <span><Badge variant="info">n<sup>o</sup></Badge> Participantes</span>
                            <span><Badge variant="primary">n<sup>o</sup></Badge> Diarias</span>
                            <span><Badge variant="success">R$</Badge> Diarias x Valor</span>
                        </div>

                        <table className="table table-hover tabela border">
                            <thead>
                                <tr>
                                    <th className='text-center'>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <Form.Check
                                                type={'checkbox'}
                                                inline
                                                checked={clicouDesabilitarTodosFechamento}
                                                id={'1'}
                                                label="Fechamento"
                                                onChange={(e) => {
                                                    mostrarCarregando(true);
                                                    let novaConsulta = [...consulta];
                                                    novaConsulta.map(nc => {
                                                        nc.selecionado = !clicouDesabilitarTodosFechamento
                                                    })
                                                    alterarConsulta(novaConsulta);
                                                    alterarClicouDesabilitarTodosFechamento(!clicouDesabilitarTodosFechamento);
                                                    mostrarCarregando(false);
                                                }}
                                                style={{ marginBottom: -2.5 }}
                                            />
                                        </div>
                                    </th>
                                    <th className='text-center'>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <Form.Check
                                                type={'checkbox'}
                                                inline
                                                checked={clicouDesabiltiarTodosBoleto}
                                                label="Boleto"
                                                id={'2'}
                                                onChange={(e) => {
                                                    mostrarCarregando(true);
                                                    let novaConsulta = [...consulta];
                                                    novaConsulta.map(nc => {
                                                        nc.selecionadoBoleto = !clicouDesabiltiarTodosBoleto
                                                    })
                                                    alterarConsulta(novaConsulta);
                                                    alterarClicouDesabilitarTodosBoleto(!clicouDesabiltiarTodosBoleto);
                                                    mostrarCarregando(false);
                                                }}
                                                style={{ marginBottom: -2.5 }}
                                            />
                                        </div>
                                    </th>
                                    <th className='text-center'>Código Referência</th>
                                    <th>Cliente</th>
                                    <th className="text-right">Status</th>
                                    <th className="text-right">Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {consulta.map((registro, indice) =>
                                    <Registro
                                        indice={indice}
                                        key={registro.dados.cartaOferta + indice.toString()}
                                        dados={registro.dados}
                                        diarias={registro.diarias}
                                        cliente={registro.cliente}
                                        fechado={registro.fechado}
                                        selecionado={registro.selecionado}
                                        selecionadoBoleto={registro.selecionadoBoleto}
                                        parametros={parametros}
                                        onClickTr={() => console.log('clicou TR')}
                                        clicouFechar={() => {
                                            alterarConfirmarFechamento(registro);
                                        }}
                                        clicouEditar={() => {
                                            alterarModalEdicao({
                                                mostrar: true,
                                                registro
                                            });
                                        }}
                                        clicouCheckBox={(dados) => {
                                            let novaConsulta = [...consulta];
                                            novaConsulta[dados.indice].selecionado = !novaConsulta[dados.indice].selecionado;
                                            alterarConsulta(novaConsulta);
                                        }}
                                        clicouCheckBoxBoleto={(dados) => {
                                            let novaConsulta = [...consulta];
                                            novaConsulta[dados.indice].selecionadoBoleto = !novaConsulta[dados.indice].selecionadoBoleto;
                                            alterarConsulta(novaConsulta);
                                        }}
                                        dataMes={dataMes}
                                        valorMinimo={valorMinimo}
                                    />
                                )}
                            </tbody>
                        </table>
                        <PaginacaoComponente
                            pagina={parametros.pagina}
                            registrosPorPagina={parametros.registrosPorPagina}
                            totalRegistros={totalRegistros}
                            alterouPagina={(pagina) => alterarParametros({ ...parametros, pagina })}
                        />
                        {carregando &&
                            <Carregador className="rounded-bottom" />
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </FechamentoComponent>

}
