import React, {
    useState,
    useEffect
} from 'react';
import {
    Form,
    Card,
    Row,
    Col,
    Button,
    Alert,
    ButtonToolbar,
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap';
import {
    useHistory,
    useParams,
    useLocation,
    Prompt
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import MaskedInput from 'react-maskedinput';
import axios from 'axios';
import moment from 'moment';

import { intersectObject } from './../../../Funcoes';
import ColorPicker from './../../Componentes/ColorPicker';
import ComboResponsavel from './../../Componentes/ComboResponsavel';
import TipoPessoa from './TipoPessoa';
import Estados from './Estados';
import Abas from './Abas';
import Foto from './Foto';
import Anexos from './../../Componentes/Anexos';
import InputData from './../../Componentes/InputData';
import ComboGrupos from './ComboGrupos';
import Eventos from './../../Componentes/Eventos';
import FichasConsultar from './../Fichas/Consultar';
import Apolices from './../Apolices';
import SmsConsultar from './../../Sms/Consultar';
import ObservacaoLogin from './ObservacaoLogin';
import CamposSeguroAventura from './CamposSeguroAventura';
import styles from './index.module.scss';

// componente
export default function ClienteCadastroPagina({
    abaAbrir = 'cliente',
    usuario = false
}) {

    // estados
    const { id } = useParams();
    const location = useLocation();
    const history = useHistory();
    const login = useSelector((state) => state.login);
    const [modo, alterarModo] = useState('cadastro'); // cadastro, edicao
    const [carregando, alterarCarregando] = useState(true);
    const [dados, alterarDados] = useState({
        id: id || null,
        id_asaas: false,
        id_plano: null,
        tipo_cliente: '3',
        id_responsavel: login.dadosUsuario.id,
        dados_participante_obrigatorio: "N",
        tipo_pessoa: 'F', // F, J
        pre_cliente: 'S',
        exibir_termos: 'N', // S, N
        foto: null,
        data_vigencia_apolice: moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        nome: '',
        nome_pai: '',
        nome_mae: '',
        estado_civil: null,
        diarias_evento_checkin: null,
        profissao: '',
        data_nascimento: null,
        nome_fantasia: '',
        cpf: '',
        rg: '',
        rg_data_emissao: null,
        cnh: '',
        cnh_data_emissao: null,
        cnpj: '',
        responsavel_juridico_nome: '',
        responsavel_juridico_telefone: '',
        responsavel_juridico_email: '',
        telefone_residencial: '',
        telefone_comercial: '',
        telefone_celular: '',
        email: '',
        site: '',
        cep: '',
        cidade: '',
        estado: '',
        endereco: '',
        numero: '',
        bairro: '',
        complemento: '',
        id_recomendacao: null,
        descricao_indicacao: '',
        observacao: '',
        nome_assinatura: '',
        cargo_assinatura: '',
        email_assinatura: '',
        celular_assinatura: '',
        observacao_grupo: null,
        sexo: 'M',
        cor: null,
        carta_oferta: null,
        numero_apolice: null,
        numero_contrato: null,
        link_termo_responsabilidade: null,
        seguro_link_xls: null,
        seguro_link_xls_campos: null,
        seguro_evento_nome: null,
        seguro_evento_descricao: null,
        seguro_evento_local: null,
        seguro_id_modelo_email_participante: null,
        seguro_valor_diaria: '0.0',
        seguro_valor_morte_acidental: '0.0',
        seguro_valor_invalidez: '0.0',
        seguro_valor_dmho: '0.0',
        seguro_habilitar_comprovante_participante: "N",
        seguro_habilitar_comprovante_participante_individual: "N",
        seguro_evento_finalizado_permite_cliente_novo: "N",
        bloquear_envio_emails_seguro_aventura: "N",
        plano_adicional_deslocamento: "N",
        seguro_aventura_token_checkin: null
    });
    const [dadosContratoAutentique, alterarDadosContratoAutentique] = useState(null);
    const [dadosUsuario, alterarDadosUsuario] = useState({
        id: null,
        login: '',
        senha: '',
        confirmar_senha: ''
    });
    const [dadosOriginais, alterarDadosOriginais] = useState(JSON.stringify(dados));
    const [grupos, alterarGrupos] = useState([]);
    const [salvando, alterarSalvando] = useState(false);
    const [erro, alterarErro] = useState(null);
    const [erros, alterarErros] = useState({});
    const [aba, alterarAba] = useState(abaAbrir); // cliente, arquivos, fichas
    const [foto, alterarFoto] = useState({
        imagem: dados.foto,
        // caminhoBase:`${process.env.PUBLIC_URL}/php/arquivos/eventos/`,
        arquivo: null,
        onChange: (arquivo) => {
            alterarFoto({ ...foto, arquivo });
        },
        // progressoUpload: null
        progresso: null
    });
    const [salvandoAnexos, alterarSalvandoAnexos] = useState(false);
    const [subindoTermoResponsabilidade, alterarSubindoTermoResponsabilidade] = useState(false);
    const [baixandoTermoResponsabilidade, alterarBaixandoTermoResponsabilidade] = useState(false);
    const [validarCpf, alterarValidarCpf] = useState(null);
    const [validarCnpj, alterarValidarCnpj] = useState(null);
    const [validandoCpfCnpj, alterarValidandoCpfCnpj] = useState(false);
    const [modoUsuario, alterarModoUsuario] = useState(usuario);
    const [salvandoEventos, alterarSalvandoEventos] = useState(false);
    const [gerandoApolice, alterarGerandoApolice] = useState(false);
    const [gerandoContrato, alterarGerandoContrato] = useState(false);
    const [clicouSalvar, alterarClicouSalvar] = useState(false);
    const [integracaoAsaas, alterarIntegracaoAsaas] = useState(false);
    const [carregamentoToken, alterarCarregamentoToken] = useState(false);
    const [carregamentoExportacao, alterarCarregamentoExportacao] = useState(false);
    const [assinatura, alterarAssinatura] = useState(null);

    // referencias
    const cepInput = React.createRef();
    const cpfInput = React.createRef();
    const cnpjInput = React.createRef();
    const telResInput = React.createRef();
    const telComInput = React.createRef();
    const telCelInput = React.createRef();
    const telJurInput = React.createRef();
    const telAssInput = React.createRef();
    
    // monta componente
    useEffect(() => {

        // define se é cadastro ou edição
        if (dados.id) {

            // altera o modo
            alterarModo('edicao');

            // faz a consulta dos dados
            consultarId();


        } else {
            alterarCarregando(false);
        }

    }, []);

    // ao finaliza o cadastro de todos os itens do formulário
    useEffect(() => {

        // ao finalizar o cadastro mostra toast de salvo
        if (clicouSalvar && !salvando && !salvandoAnexos && !salvandoEventos) {
            toast(({ closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Cliente salvo com sucesso!</strong>
                    <button
                        onClick={closeToast}
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    {dados.nome}
                </div>
            </>);
            // history.push('/painel/clientes');
            alterarAssinatura(JSON.stringify({
                dados,
                grupos,
                dadosUsuario
            }));
        }

    }, [salvando, salvandoAnexos, salvandoEventos]);

    // cria assinatura assim que finaliza carregamento para diferenciar um registro alterado
    useEffect(() => {
        if (!carregando) {

            // cria assinatura
            alterarAssinatura(JSON.stringify({
                dados,
                grupos,
                dadosUsuario
            }));

        }
    }, [carregando]);

    // consulta id do cliente
    async function consultarId() {

        try {

            // faz a requisição
            const { data } = await axios.get(`/clientes/${dados.id}`);

            // aplica os dados
            alterarFoto({ ...foto, imagem: data.dados.foto });
            let dadosNovos = intersectObject(dados, data.dados);

            // se não tiver data, adiciona
            if (!dadosNovos.data_vigencia_apolice) dadosNovos.data_vigencia_apolice = dados.data_vigencia_apolice;

            alterarDados(dadosNovos);

            // alterarDadosOriginais(JSON.stringify(dadosNovos));
            alterarGrupos(data.grupos);

            // altera dados do contrato do cliente
            alterarDadosContratoAutentique(data.dadosContratoAutentique);

            // se for cliente vinculado a um usuário
            if (data.dadosUsuario) {
                alterarDadosUsuario({ ...dadosUsuario, ...data.dadosUsuario });
                alterarModoUsuario(true);
            }

            // altera a assinatura para verificar se está atualizado
            alterarAssinatura(JSON.stringify({
                dados: dadosNovos,
                grupos: data.grupos,
                dadosUsuario: { ...dadosUsuario, ...data.dadosUsuario }
            }))

            // altera integração
            alterarIntegracaoAsaas(data.dados.id_asaas ? true : false);

            // finaliza carregamento
            alterarCarregando(false);

        } catch (e) {
            console.log(e);
        }

    }

    // faz a requisição para gerar uma hash para o número de diárias
    async function gerarTokenDiarias(numeroDiarias) {

        // mostra carregamento token
        alterarCarregamentoToken(true);

        try {

            // resgata id do cliente
            const idCliente = dados.id;

            // faz a consulta passando token do usuário
            const { data } = await axios.post(
                `/clientes/gerarTokenDiarias/${idCliente}`,
                {
                    numeroDiarias
                },
                {
                    headers: {
                        tokenusuario: login.dadosUsuario.token
                    }
                }
            );

            // altera o estado
            alterarDados({
                ...dados,
                diarias_evento_checkin: data.diariasEventoCheckin
            });

            // resgata objeto para alterar
            const assinaturaAlterar = JSON.parse(assinatura);

            // altera a assinatura para verificar se está atualizado
            alterarAssinatura(JSON.stringify({
                grupos: assinaturaAlterar.grupos,
                dadosUsuario: assinaturaAlterar.dadosUsuario,
                dados: {
                    ...assinaturaAlterar.dados,
                    diarias_evento_checkin: data.diariasEventoCheckin
                }
            }))

        } catch (e) {

            console.log(e);

            // verifica se possui retorno de erro
            if (e.response && e.response.data) {

                const erros = e.response.data;

                // resgata as chaves do objeto e percorre
                Object.keys(erros).map((erro) => {

                    return toast(({ closeToast }) => <>
                        <div className="toast-header bg-danger text-white">
                            <strong className="mr-auto">Erro ao adicionar diárias ao evento</strong>
                            <button
                                onClick={closeToast}
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="toast-body bg-danger text-light">
                            {erros[erro]}
                        </div>
                    </>, { autoClose: 5000 })

                });
            }

        } finally {
            // esconde carregamento
            alterarCarregamentoToken(false);
        }

    }

    // valida cpf
    async function validarCampoCpf() {
        alterarErros({});
        alterarValidarCpf(null);

        // valida cpf
        if (dados.cpf.length === 11) {
            alterarValidandoCpfCnpj(true);

            try {

                // faz a requisição
                let { data } = await axios.post('/clientes/validarCpf', dados);
                alterarValidarCpf(true);

            } catch ({ response }) {

                if (response) {

                    if (response.status === 400) {
                        alterarErros(response.data);
                        alterarValidarCpf(false);
                    }
                }

            } finally {
                alterarValidandoCpfCnpj(false);
            }

        }
    }

    // valida cnpj
    async function validarCampoCnpj() {
        alterarErro(null);
        alterarValidarCnpj(null);

        // valida cnpj
        if (dados.cnpj.length === 14) {
            alterarValidandoCpfCnpj(true);

            // faz a requisição
            try {
                let { data } = await axios.post('/clientes/validarCnpj', dados);
                alterarValidarCnpj(true);
            } catch ({ response }) {

                if (response) {

                    // se tiver resposta
                    if (response.status === 400) {
                        alterarErros(response.data);
                        alterarValidarCnpj(false);
                    }
                }

            } finally {
                alterarValidandoCpfCnpj(false);
            }
        }
    }

    // salva os dados
    async function salvar() {
        alterarErros({});
        alterarErro(null);
        alterarSalvando(true);
        alterarClicouSalvar(true);

        // faz a requisição
        let idNovo = null;
        try {

            // faz a requisição
            let { data } = await axios.post(`/clientes`, {
                modoUsuario,
                dados,
                grupos,
                dadosUsuario
            });

            // salva
            // altera o id se for cadastro
            if (modo === 'cadastro') {
                dados.id = data.id
                alterarModo('edicao');
            }

            // se for cadastro de usuário
            if (modoUsuario && dadosUsuario.id === null) {
                alterarDadosUsuario({ ...dadosUsuario, id: data.idUsuario });
            }

            // salva foto/arquivos
            let promessas = [];

            // salva imagem do usuário
            if (foto.arquivo !== null) {
                let formData = new FormData();
                formData.append('imagem', foto.arquivo);
                formData.append('id', dados.id);

                // verifica o progresso da imagem do card
                function progressoFoto(progressEvent) {
                    let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    if (progress === 100) {
                        progress = null;
                    }
                    alterarFoto({ ...foto, progresso: progress });
                }

                // promessa de salvar a foto
                let promessaSalvarFoto = axios.post('/clientes/foto', formData, {
                    onUploadProgress: progressoFoto,
                    headers: { 'Content-type': 'multipart/form-data' },
                })
                    .then(({ data }) => {
                        dados.foto = data.foto;
                        alterarFoto({ ...foto, arquivo: null });
                    });

                // incrementa promessas
                promessas.push(promessaSalvarFoto);
            }

            // aguarda salvar arquivos
            let aguardarPromessas = await Promise.all(promessas);

            // finalizado
            alterarDados({ ...dados });

            // consulta novamente
            consultarId();

        } catch ({ response }) {

            if (response) {

                // erros no formulário
                if (response.status === 400) {
                    alterarErros(response.data);
                    alterarClicouSalvar(false);
                }

            }

        } finally {

            // finalizado
            alterarSalvando(false);
        }



    }

    // realiza o download do arquivo
    async function realizarDownloadTermo() {

        // carrega
        alterarBaixandoTermoResponsabilidade(true);

        try {

            const { data } = await axios.get('/clientes/baixarArquivoTermo', {
                params: {
                    linkTermoResponsabilidade: dados.link_termo_responsabilidade,
                    id: dados.id
                },
                responseType: 'blob'
            });

            // resgata o retorno e cria uma url
            const href = URL.createObjectURL(data);

            // cria um elemento a
            const link = document.createElement('a');

            // atribui o link
            link.href = href;

            // altera o atributo e nome do arquivo
            link.setAttribute('download', `TermoDeResponsabilidade.pdf`);

            // adiciona tag a ao body
            document.body.appendChild(link);

            // faz o clique na tag a para realizar o download
            link.click();

            // retira a tag a do body
            document.body.removeChild(link);

            // retira link
            URL.revokeObjectURL(href);


        } catch (e) {
            console.error(e);
        } finally {
            // desativa carregamento
            alterarBaixandoTermoResponsabilidade(false);
        }

    }

    // realiza o upload do arquivo
    async function realizarUploadTermo(arquivoEvento = null) {

        // resgata valor do input        
        const arquivo = arquivoEvento.target.files[0];

        // sem arquivos não executa
        if (
            !arquivo ||
            ![
                'application/pdf'
            ].includes(arquivo.type)
        ) return;

        // ativa carregamento
        alterarSubindoTermoResponsabilidade(true);

        // prepara para enviar
        let salvarPdf = new FormData();
        salvarPdf.append('arquivo', arquivo);
        salvarPdf.append('id', dados.id);

        try {

            // faz a requisição para o upload
            const { data } = await axios.post('/clientes/subirArquivoTermo', salvarPdf, {
                headers: {
                    'Content-type': 'multipart/form-data',
                    tokenusuario: login.dadosUsuario.token
                }
            });

            // altera dados
            alterarDados({
                ...dados,
                link_termo_responsabilidade: data
            });

        } catch (e) {
            console.error(e);
        } finally {
            alterarSubindoTermoResponsabilidade(false);
        }

    }

    // consulta apolice do cliente e realiza o download
    async function downloadApolice() {

        // ativa carregamento
        alterarGerandoApolice(true);

        try {

            // realiza a requisição para baixar apólice
            const { data } = await axios.get(`/clientes/pdfArquivoApolice/${dados.id}`);

            // faz o download
            window.open(
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=anexosEmail&f=${encodeURIComponent(data.temporario)}&n=${encodeURIComponent(data.nome)}`,
                '_blank'
            );

        } catch (e) {

            console.log(e);

            // verifica se possui retorno de erro
            if (e.response && e.response.data) {

                const erros = e.response.data;

                // resgata as chaves do objeto e percorre
                Object.keys(erros).map((erro) => {

                    return toast(({ closeToast }) => <>
                        <div className="toast-header bg-danger text-white">
                            <strong className="mr-auto">Erro ao emitir apólice</strong>
                            <button
                                onClick={closeToast}
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="toast-body bg-danger text-light">
                            {erros[erro]}
                        </div>
                    </>, { autoClose: 5000 })

                });
            }

        } finally {

            // desativa carregamento
            alterarGerandoApolice(false);

        }

    }

    // gera o contrato do cliente e realiza o download
    async function emissaoContrato() {

        // ativa carregamento
        alterarGerandoContrato(true);

        try {

            // realiza a requisição para gerar o contrato
            await axios.post(`/clientes/emissaoContrato/${dados.id}`);

            // mostra toast
            toast(({ closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Enviado!</strong>
                    <button
                        onClick={closeToast}
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Solicitação de assinatura enviado ao cliente!
                </div>
            </>, { autoClose: 5000 })

            // consulta o cliente novamente
            consultarId();

        } catch (e) {

            console.log(e);

            // verifica se possui retorno de erro
            if (e.response && e.response.data) {

                const erros = e.response.data;

                // resgata as chaves do objeto e percorre
                Object.keys(erros).map((erro) => {

                    return toast(({ closeToast }) => <>
                        <div className="toast-header bg-danger text-white">
                            <strong className="mr-auto">Erro ao emitir contrato</strong>
                            <button
                                onClick={closeToast}
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="toast-body bg-danger text-light">
                            {erros[erro]}
                        </div>
                    </>, { autoClose: 5000 })

                });
            }

        } finally {

            // desativa carregamento
            alterarGerandoContrato(false);

        }

    }

    // gera o arquivo xls do cliente
    async function gerarXlsCliente() {

        // caso já esteja gerando, para a execução
        if(carregamentoExportacao) return;

        // ativa o carregamento
        alterarCarregamentoExportacao(true);

        try {

            // realiza a requisiçao para exportar os dados do cliente
            const { data } = await axios.get(`/clientes/exportarCliente/${dados.id}`);

            // abre arquivo na url
            window.open(data.caminho, '_blank');

        } catch(e) {
            console.error(e);
        } finally {

            // desativa o carregamento
            alterarCarregamentoExportacao(false);

        }

    }

    // ao alterar registro
    function eventoAlterarDados(e) {
        dados[e.target.name] = e.target.value;
        alterarDados({ ...dados });
    }

    // toast para quando realizar a ação de copiar um texto
    function toastCopiarTexto(titulo, mensagem, cor = 'light') {
        toast(({ closeToast }) => <>
            <div className={`toast-header bg-${cor} text-light`}>
                <strong className="mr-auto">{titulo}</strong>
                <button
                    onClick={closeToast}
                    className="ml-2 mb-1 close btn-outline-light outline-0"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className={`toast-body bg-${cor} text-light`}>
                {mensagem}
            </div>
        </>, { autoClose: 5000 });
    }

    // busca cep
    function buscarCep(cepBuscar) {
        let buscarCepCon = axios.create({ headers: null });

        // se foi totalmente preenchido
        if (cepBuscar.length === 8) {

            // faz a busca
            buscarCepCon.get("https://viacep.com.br/ws/" + cepBuscar + "/json/", {
                responseType: 'json'
            }).then(retorno => {

                alterarDados({
                    ...dados,
                    'cidade': retorno.data.localidade,
                    'estado': retorno.data.uf,
                    'endereco': retorno.data.logradouro,
                    'bairro': retorno.data.bairro,
                    'cep': cepBuscar
                });
            });

        }
    }

    // verifica se está sendo salvo algum elemento para bloquear botão de salvar
    function salvandoElementos() {
        return (
            !dados.id &&
            (
                salvandoAnexos ||
                salvandoEventos
            )
        );
    }

    // se estiver carregando
    if (carregando) {
        return <div>Carregando...</div>
    }

    // cadastro
    return <div>

        {/* impede a saída se possuir itens a salvar */}
        <Prompt
            when={assinatura !== JSON.stringify({ dados, grupos, dadosUsuario })}
            message={'Deseja sair dessa tela sem salvar as alterações?'}
        />

        <Row hidden={aba !== 'cliente'}>
            <Col md='8'>
                <Abas
                    value={aba}
                    onChange={(novaAba) => alterarAba(novaAba)}
                    desabilitarFichas={modo === 'cadastro'}
                    modo={modo}
                    modoUsuario={modoUsuario}
                    permissao={login.permissao}
                />

                <Card className='mb-4 border-0 shadow-sm'>
                    <Card.Header className={[styles.cabecalho, 'bg-white'].join(' ')}>
                        <Card.Title as='h4' className='mb-0'>{modo === 'cadastro' ? 'Cadastrar' : 'Editar'} {modoUsuario ? 'Usuário' : 'Cliente'}{dados.id && <i><small> - {dados.id}</small></i>}</Card.Title>
                        <div className={styles.opcoes}>
                            {dados.id !== null &&
                                <>
                                    <ButtonToolbar
                                        className='ml-2'
                                    >
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip>Enviar email</Tooltip>}
                                        >
                                            <Button
                                                variant='padrao'
                                                size='sm'
                                                onClick={() => {
                                                    history.push(`/painel/clientes/enviar-email/${dados.id}`)
                                                }}
                                                className='rounded-circle'
                                            >
                                                <FontAwesomeIcon className="icone" icon={["fas", 'envelope']} />
                                            </Button>
                                        </OverlayTrigger>
                                    </ButtonToolbar>

                                    <ButtonToolbar
                                        className='ml-2'
                                    >
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip>Enviar whatsapp</Tooltip>}
                                        >
                                            <Button
                                                variant='padrao'
                                                size='sm'
                                                onClick={() => {
                                                    history.push(`/painel/clientes/enviar-whatsapp/${dados.id}`);
                                                }}
                                                className='rounded-circle'
                                            >
                                                <FontAwesomeIcon className="icone" icon={["fab", "whatsapp-square"]} />
                                            </Button>
                                        </OverlayTrigger>
                                    </ButtonToolbar>

                                    <ButtonToolbar
                                        className='ml-2'
                                    >
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip>Enviar sms</Tooltip>}
                                        >
                                            <Button
                                                variant='padrao'
                                                size='sm'
                                                onClick={() => {
                                                    history.push(`/painel/clientes/enviar-sms/${dados.id}`);
                                                }}
                                                className='rounded-circle'
                                            >
                                                <FontAwesomeIcon className="icone" icon={["fas", 'sms']} />
                                            </Button>
                                        </OverlayTrigger>
                                    </ButtonToolbar>

                                    <ButtonToolbar
                                        className='ml-2'
                                    >
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip>Exportar cadastro</Tooltip>}
                                        >
                                            <Button
                                                variant='padrao'
                                                size='sm'
                                                onClick={gerarXlsCliente}
                                                className='rounded-circle'
                                            >
                                                <FontAwesomeIcon
                                                    className="icone"
                                                    pulse={carregamentoExportacao}
                                                    color={carregamentoExportacao ? 'gray' : 'green'}
                                                    icon={["fas", carregamentoExportacao ? 'spinner' : 'file-excel']}
                                                />
                                            </Button>
                                        </OverlayTrigger>
                                    </ButtonToolbar>
                                </>
                            }

                            {(!modoUsuario && login.permissao.habilitarCadastroLoginCliente === 'S') &&

                                <ButtonToolbar
                                    className='ml-2'
                                >
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={<Tooltip>Virar Usuário</Tooltip>}
                                    >
                                        <Button
                                            variant='padrao'
                                            size='sm'
                                            onClick={() => {
                                                alterarModoUsuario(true);
                                            }}
                                            className='rounded-circle'
                                        >
                                            <FontAwesomeIcon className="icone" icon={["fas", 'user-tie']} />
                                        </Button>
                                    </OverlayTrigger>
                                </ButtonToolbar>
                            }
                        </div>
                    </Card.Header>
                    <Card.Body className='text-center'>
                        <Form.Group className={[styles.responsavelForm, 'text-left'].join(' ')}>
                            <Form.Label>Responsável</Form.Label>
                            <Row>
                                <Col>
                                    <ComboResponsavel
                                        id={dados.id_responsavel}
                                        alterou={(id) => {
                                            dados.id_responsavel = id;
                                            alterarDados({ ...dados });
                                        }}
                                    />
                                </Col>
                                <Col xs={1}>
                                    <ColorPicker
                                        value={dados.cor}
                                        onChange={cor => {
                                            dados.cor = cor;
                                            alterarDados({ ...dados });
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Form.Group className={styles.preCliente}>
                                <Form.Check
                                    inline
                                    label="Integrado em ASAAS"
                                    type={'checkbox'}
                                    checked={integracaoAsaas}
                                    disabled
                                    id={`checkbox-pre-cliente`}
                                />
                                <Form.Check
                                    inline
                                    label="Pré Cliente"
                                    type={'checkbox'}
                                    checked={(dados.pre_cliente === 'S') ? true : false}
                                    id={`checkbox-pre-cliente`}
                                    onChange={(e) => {
                                        alterarDados({
                                            ...dados,
                                            pre_cliente: e.target.checked ? 'S' : 'N'
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Form.Group>

                        <Form.Group className="m-0 d-flex justify-content-around">
                            {[
                                { id: '1', descricao: 'Colaborador' },
                                { id: '2', descricao: 'Produtor' },
                                { id: '3', descricao: 'Cliente' },
                                { id: '4', descricao: 'Fornecedor' },
                                { id: '5', descricao: 'Parceiro' },
                            ].map(tiposCliente =>
                                <Form.Check
                                    key={tiposCliente.id}
                                    inline
                                    label={tiposCliente.descricao}
                                    type={'radio'}
                                    checked={(tiposCliente.id === dados.tipo_cliente) ? true : false}
                                    id={`custom-inline-tipo-cliente-${tiposCliente.id}`}
                                    name='tipo_cliente'
                                    onChange={e => {
                                        dados.tipo_cliente = tiposCliente.id;
                                        alterarDados({ ...dados });
                                    }}
                                />
                            )}
                        </Form.Group>
                    </Card.Body>
                </Card>

                {modoUsuario &&
                    <Card className='mb-4 border-0 shadow-sm'>
                        <Card.Header className={[styles.cabecalho, 'bg-white'].join(' ')}>
                            <Card.Title as='h6' className='mb-0'>Dados do usuário</Card.Title>
                        </Card.Header>
                        <Card.Body>

                            <Form.Row>
                                <Col md='5'>
                                    <Form.Label>Login</Form.Label>
                                    <Form.Control
                                        value={dadosUsuario.login}
                                        onChange={e => {
                                            alterarDadosUsuario({ ...dadosUsuario, login: e.target.value });
                                        }}
                                        isInvalid={erros.login ? true : false}
                                    />
                                    {erros.login &&
                                        <Form.Control.Feedback type="invalid">{erros.login}</Form.Control.Feedback>
                                    }
                                </Col>
                            </Form.Row>

                            <Form.Row className='my-3'>
                                <Col md='6'>
                                    <Form.Label>Senha</Form.Label>
                                    <Form.Control
                                        value={dadosUsuario.senha}
                                        onChange={e => {
                                            alterarDadosUsuario({ ...dadosUsuario, senha: e.target.value });
                                        }}
                                        type='password'
                                        isInvalid={erros.senha ? true : false}
                                    />
                                    {erros.senha &&
                                        <Form.Control.Feedback type="invalid">{erros.senha}</Form.Control.Feedback>
                                    }
                                </Col>
                                <Col md='6'>
                                    <Form.Label>Confirmar Senha</Form.Label>
                                    <Form.Control
                                        value={dadosUsuario.confirmar_senha}
                                        onChange={e => {
                                            alterarDadosUsuario({ ...dadosUsuario, confirmar_senha: e.target.value });
                                        }}
                                        type='password'
                                        isValid={dadosUsuario.confirmar_senha.length > 0 && dadosUsuario.senha === dadosUsuario.confirmar_senha}
                                        isInvalid={erros.confirmar_senha ? true : false}
                                    />
                                    {(erros.confirmar_senha) &&
                                        <Form.Control.Feedback type="invalid">{erros.confirmar_senha}</Form.Control.Feedback>
                                    }
                                </Col>
                            </Form.Row>

                            <hr />

                            <Form.Row>
                                <Col md='7'>
                                    <Form.Label title='Substitui campo nome'>Nome Assinatura</Form.Label>
                                    <Form.Control
                                        value={dados.nome_assinatura}
                                        name='nome_assinatura'
                                        onChange={eventoAlterarDados}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label title='Substitui campo profissão'>Cargo Assinatura</Form.Label>
                                    <Form.Control
                                        value={dados.cargo_assinatura}
                                        name='cargo_assinatura'
                                        onChange={eventoAlterarDados}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row className='mt-3'>
                                <Col md='8'>
                                    <Form.Label title='Substitui campo email'>Email Assinatura</Form.Label>
                                    <Form.Control
                                        value={dados.email_assinatura}
                                        name='email_assinatura'
                                        onChange={eventoAlterarDados}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label title='Substitui campo telefone celular'>Celular Assinatura</Form.Label>
                                    <MaskedInput
                                        className="form-control"
                                        mask="(11) 11111-1111"
                                        value={dados.celular_assinatura}
                                        ref={telAssInput}
                                        onChange={(e) => {
                                            alterarDados({ ...dados, celular_assinatura: telAssInput.current.mask.getRawValue().split('_').join('') });
                                        }}
                                    />
                                </Col>
                            </Form.Row>
                        </Card.Body>
                    </Card>
                }

                <Card className='border-0 shadow-sm'>
                    <Card.Header className={[styles.cabecalho, 'bg-white'].join(' ')}>
                        <Card.Title as='h6' className='mb-0'>Dados</Card.Title>
                        <div className={styles.tipoCliente}>
                            <TipoPessoa
                                value={dados.tipo_pessoa}
                                name='tipo_pessoa'
                                onChange={eventoAlterarDados}
                            />
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Form.Row>
                            <Col>
                                <Form.Label>{dados.tipo_pessoa === 'F' ? 'Nome' : 'Razão Social'}</Form.Label>
                                <Form.Control
                                    value={dados.nome}
                                    name='nome'
                                    onChange={eventoAlterarDados}
                                />
                            </Col>
                            {dados.tipo_pessoa === 'F' &&
                                <Col md='4'>
                                    <Form.Label>Data Nascimento</Form.Label>
                                    <InputData
                                        data={dados.data_nascimento}
                                        onChange={(data) => {
                                            dados.data_nascimento = data;
                                            alterarDados({ ...dados });
                                        }}
                                    />
                                </Col>
                            }
                        </Form.Row>

                        {/* sexo */}
                        {dados.tipo_pessoa === 'F' &&
                            <div
                                className={'text-right mt-1'}
                            >
                                <Form.Group>
                                    <Form.Check
                                        inline
                                        label={`Masculino`}
                                        type={'radio'}
                                        name={`sexo`}
                                        id={`radio-sexo-cliente-m`}
                                        checked={dados.sexo === 'M' ? true : false}
                                        onChange={e => {
                                            dados.sexo = e.target.checked ? 'M' : 'F';
                                            alterarDados({ ...dados });
                                        }}
                                    />
                                    <Form.Check
                                        inline
                                        label={`Feminino`}
                                        type={'radio'}
                                        name={`sexo`}
                                        id={`radio-sexo-cliente-f`}
                                        checked={dados.sexo === 'F' ? true : false}
                                        onChange={e => {
                                            dados.sexo = e.target.checked ? 'F' : 'M';
                                            alterarDados({ ...dados });
                                        }}
                                    />
                                </Form.Group>
                            </div>
                        }

                        {dados.tipo_pessoa === 'J' &&
                            <>
                                <Form.Row className='mt-3'>
                                    <Col md='7'>
                                        <Form.Label>Nome Fantasia</Form.Label>
                                        <Form.Control
                                            value={dados.nome_fantasia}
                                            name='nome_fantasia'
                                            onChange={eventoAlterarDados}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label>
                                            <span>CNPJ </span>
                                            {validandoCpfCnpj &&
                                                <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                            }
                                        </Form.Label>
                                        <MaskedInput
                                            className={[
                                                "form-control",
                                                (erros.cnpj) && 'is-invalid',
                                                (validarCnpj) && 'is-valid'
                                            ].join(' ')}
                                            mask="11.111.111/1111-11"
                                            value={dados.cnpj}
                                            ref={cnpjInput}
                                            onChange={(e) => {
                                                dados.cnpj = cnpjInput.current.mask.getRawValue().split('_').join('');
                                                alterarDados({ ...dados });
                                                validarCampoCnpj();
                                            }}
                                        />
                                        {(erros.cnpj) &&
                                            <Form.Control.Feedback type="invalid">{erros.cnpj}</Form.Control.Feedback>
                                        }
                                    </Col>
                                </Form.Row>
                                <Form.Group className='mt-3'>
                                    <Form.Label>Nome Responsável</Form.Label>
                                    <Form.Control
                                        value={dados.responsavel_juridico_nome}
                                        name='responsavel_juridico_nome'
                                        onChange={eventoAlterarDados}
                                    />
                                </Form.Group>
                                <Form.Row className='mb-3'>
                                    <Col md='8'>
                                        <Form.Label>Email Responsável</Form.Label>
                                        <Form.Control
                                            value={dados.responsavel_juridico_email}
                                            name='responsavel_juridico_email'
                                            onChange={eventoAlterarDados}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label>Telefone Responsável</Form.Label>
                                        <MaskedInput
                                            className="form-control"
                                            mask="(11) 11111-1111"
                                            value={dados.responsavel_juridico_telefone}
                                            ref={telJurInput}
                                            onChange={(e) => {
                                                alterarDados({ ...dados, responsavel_juridico_telefone: telJurInput.current.mask.getRawValue().split('_').join('') });
                                            }}
                                        />
                                    </Col>
                                </Form.Row>
                            </>
                        }

                        {dados.tipo_pessoa === 'F' &&
                            <>
                                <Form.Row className='mt-3'>
                                    <Col>
                                        <Form.Label>Estado Civil</Form.Label>
                                        <Form.Control
                                            as='select'
                                            value={dados.estado_civil || ''}
                                            onChange={e => {
                                                dados.estado_civil = e.target.value;
                                                alterarDados({ ...dados });
                                            }}
                                        >
                                            <option value=''>Escolher...</option>
                                            <option value="1">Casado</option>
                                            <option value="2">Solteiro</option>
                                            <option value="3">Separado Judicialmente</option>
                                            <option value="4">Divorciado</option>
                                            <option value="5">Viúvo</option>
                                            <option value="6">União Estável</option>
                                        </Form.Control>
                                    </Col>
                                    <Col md='8'>
                                        <Form.Group>
                                            <Form.Label>Profissão</Form.Label>
                                            <Form.Control
                                                value={dados.profissao}
                                                name='profissao'
                                                onChange={eventoAlterarDados}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row >
                                    <Col md='6'>
                                        <Form.Label>Nome do pai</Form.Label>
                                        <Form.Control
                                            value={dados.nome_pai}
                                            name='nome_pai'
                                            onChange={eventoAlterarDados}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label>Nome da mãe</Form.Label>
                                        <Form.Control
                                            value={dados.nome_mae}
                                            name='nome_mae'
                                            onChange={eventoAlterarDados}
                                        />
                                    </Col>
                                </Form.Row>
                                <Form.Group className='mt-3'>
                                    <Form.Label>
                                        <span>CPF </span>
                                        {validandoCpfCnpj &&
                                            <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                        }
                                    </Form.Label>
                                    <MaskedInput
                                        className={[
                                            "form-control",
                                            (erros.cpf) && 'is-invalid',
                                            (validarCpf) && 'is-valid'
                                        ].join(' ')}
                                        mask="111.111.111-11"
                                        value={dados.cpf}
                                        ref={cpfInput}
                                        onChange={(e) => {
                                            dados.cpf = cpfInput.current.mask.getRawValue().split('_').join('');
                                            alterarDados({ ...dados });
                                            validarCampoCpf();
                                        }}
                                    />


                                    {(erros.cpf) &&
                                        <Form.Control.Feedback type="invalid">{erros.cpf}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Row >
                                    <Col md='7'>
                                        <Form.Label>RG</Form.Label>
                                        <Form.Control
                                            value={dados.rg}
                                            name='rg'
                                            onChange={eventoAlterarDados}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label>Data Emissao do RG</Form.Label>
                                        <InputData
                                            data={dados.rg_data_emissao}
                                            onChange={(data) => {
                                                dados.rg_data_emissao = data;
                                                alterarDados({ ...dados });
                                            }}
                                        />
                                    </Col>
                                </Form.Row>

                                <Form.Row className='mt-3 mb-3'>
                                    <Col md='7'>
                                        <Form.Label>CNH</Form.Label>
                                        <Form.Control
                                            value={dados.cnh}
                                            name='cnh'
                                            onChange={eventoAlterarDados}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label>Data Emissao da CNH</Form.Label>
                                        <InputData
                                            data={dados.cnh_data_emissao}
                                            onChange={(data) => {
                                                dados.cnh_data_emissao = data;
                                                alterarDados({ ...dados });
                                            }}
                                        />
                                    </Col>
                                </Form.Row>
                            </>
                        }

                        <Form.Row className='mb-3'>
                            <Col>
                                <Form.Label>Telefone Residencial</Form.Label>
                                <MaskedInput
                                    className="form-control"
                                    mask="(11) 11111-1111"
                                    value={dados.telefone_residencial}
                                    ref={telResInput}
                                    onChange={(e) => {
                                        alterarDados({ ...dados, telefone_residencial: telResInput.current.mask.getRawValue().split('_').join('') });
                                    }}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Telefone Comercial</Form.Label>
                                <MaskedInput
                                    className="form-control"
                                    mask="(11) 11111-1111"
                                    value={dados.telefone_comercial}
                                    ref={telComInput}
                                    onChange={(e) => {
                                        alterarDados({ ...dados, telefone_comercial: telComInput.current.mask.getRawValue().split('_').join('') });
                                    }}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Telefone Celular</Form.Label>
                                <MaskedInput
                                    className="form-control"
                                    mask="(11) 11111-1111"
                                    value={dados.telefone_celular}
                                    ref={telCelInput}
                                    onChange={(e) => {
                                        alterarDados({ ...dados, telefone_celular: telCelInput.current.mask.getRawValue().split('_').join('') });
                                    }}
                                />
                            </Col>
                        </Form.Row>

                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                value={dados.email}
                                name='email'
                                onChange={eventoAlterarDados}
                            />
                        </Form.Group>

                        {dados.tipo_pessoa === 'J' &&
                            <Form.Group>
                                <Form.Label>Site</Form.Label>
                                <Form.Control
                                    value={dados.site}
                                    name='site'
                                    onChange={eventoAlterarDados}
                                />
                            </Form.Group>
                        }



                    </Card.Body>
                </Card>

                <Card className='border-0 shadow-sm mt-4'>
                    <Card.Body>

                        <Form.Row>
                            <Col md='3'>
                                <Form.Label>Cep</Form.Label>
                                <MaskedInput
                                    className={['form-control'].join(' ')}
                                    mask="11111-111"
                                    value={dados.cep}
                                    ref={cepInput}
                                    onChange={(e) => {
                                        let cepNovo = cepInput.current.mask.getRawValue().split('_').join('');
                                        buscarCep(cepNovo);
                                        alterarDados({ ...dados, cep: cepNovo });

                                    }}
                                />
                            </Col>
                            <Col md='7'>
                                <Form.Label>Cidade</Form.Label>
                                <Form.Control
                                    value={dados.cidade}
                                    name='cidade'
                                    onChange={eventoAlterarDados}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Estado</Form.Label>
                                <Estados
                                    value={dados.estado}
                                    name='estado'
                                    onChange={eventoAlterarDados}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row className='mt-3'>
                            <Col md='9'>
                                <Form.Label>Endereço</Form.Label>
                                <Form.Control
                                    value={dados.endereco}
                                    name='endereco'
                                    onChange={eventoAlterarDados}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Numero</Form.Label>
                                <Form.Control
                                    value={dados.numero}
                                    name='numero'
                                    onChange={eventoAlterarDados}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row className='mt-3'>
                            <Col md='7'>
                                <Form.Label>Bairro</Form.Label>
                                <Form.Control
                                    value={dados.bairro}
                                    name='bairro'
                                    onChange={eventoAlterarDados}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Complemento</Form.Label>
                                <Form.Control
                                    value={dados.complemento}
                                    name='complemento'
                                    onChange={eventoAlterarDados}
                                />
                            </Col>
                        </Form.Row>

                    </Card.Body>
                </Card>

                {/* { aba === 'cliente' && 
                    // <Card className='border-0 shadow-sm mt-4'>
                    //     <Card.Body>
                    //         <Anexos 
                    //             tabela={'clientes'}
                    //             id={dados.id}
                    //         />
                    //     </Card.Body>
                    // </Card>
                } */}

                {(parseInt(login.permissao.clientes) > 2) &&
                    <SalvarFormulario
                        salvando={salvando || salvandoElementos()}
                        cancelar={() => {
                            history.push('/painel/clientes');
                        }}
                        onClick={() => salvar(dados)}
                    />
                }

                {(salvandoAnexos && !dados.id) &&
                    <p>Salvando anexos, aguarde!</p>
                }

                {(salvandoEventos && !dados.id) &&
                    <p>Salvando eventos, aguarde!</p>
                }

                {Object.keys(erros).length > 0 &&
                    <Alert variant='danger'>
                        Falha ao salvar! Confira se todos os campos estão corretos!
                    </Alert>
                }
            </Col>
            <Col md='4'>

                <Card className='border-0 shadow-sm'>
                    <Card.Body>
                        {/* <div className={styles.imagemCliente}>
                            <img src={imagemVazia} className='rounded' />
                        </div> */}
                        <Foto
                            {...foto}
                        />

                    </Card.Body>
                </Card>

                <Card className='border-0 shadow-sm mt-4'>
                    <Card.Body>
                        <Form.Group>
                            <Form.Label>Fonte</Form.Label>
                            <Form.Control
                                value={dados.id_recomendacao || ''}
                                name='id_recomendacao'
                                onChange={(e) => {
                                    dados.id_recomendacao = e.target.value;
                                    if (e.target.value === '') {
                                        dados.id_recomendacao = null;
                                    }
                                    alterarDados({ ...dados });
                                }}
                                as='select'
                            >
                                <option value=''></option>
                                <option value='1'>Por recomendação</option>
                                <option value='2'>Google</option>
                                <option value='3'>Facebook</option>
                                <option value='4'>Outra fonte na internet</option>
                                <option value='5'>Site</option>
                                <option value='6'>Outros</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Descrição Indicação</Form.Label>
                            <Form.Control
                                value={dados.descricao_indicacao}
                                name='descricao_indicacao'
                                onChange={eventoAlterarDados}
                                as='textarea'
                            />
                        </Form.Group>

                    </Card.Body>
                </Card>

                <Card className='border-0 shadow-sm mt-4'>
                    <Card.Body>

                        <Form.Group>
                            <Form.Label>Observação</Form.Label>
                            <Form.Control
                                value={dados.observacao}
                                name='observacao'
                                onChange={eventoAlterarDados}
                                as='textarea'
                                rows='5'
                            />
                        </Form.Group>

                    </Card.Body>
                </Card>

                <Card className='border-0 shadow-sm mt-4'>
                    <Card.Body>
                        <ComboGrupos
                            grupos={grupos}
                            onChange={(arrayGrupos) => {

                                // percorre grupos e verifica se o grupo ativo já está inserido no array
                                const possuiGrupoAtivo = grupos.some(grupo => grupo.descricao === "Seguro Aventura Ativo");

                                // percorre combo grupos e verifica se o grupo ativo foi selecionado
                                const foiIncluidoGrupoAtivo = arrayGrupos.some(ag => ag.descricao === "Seguro Aventura Ativo");

                                // se não estava no array mas foi incluido
                                if(!possuiGrupoAtivo && foiIncluidoGrupoAtivo) {

                                    // caso grupo ativo for incluido, ativa o envio do comprovante individual para os participantes
                                    alterarDados({
                                        ...dados,
                                        seguro_habilitar_comprovante_participante_individual: 'S'
                                    });
                                }

                                // atualiza grupos
                                alterarGrupos(arrayGrupos);
                            }}
                        />
                    </Card.Body>
                </Card>

                <ObservacaoLogin
                    dados={dados}
                    alterarDados={alterarDados}
                    grupos={grupos}
                />

                <CamposSeguroAventura
                    dados={dados}
                    grupos={grupos}
                    emissaoContrato={emissaoContrato}
                    gerandoContrato={gerandoContrato}
                    alterarDados={alterarDados}
                    gerandoApolice={gerandoApolice}
                    downloadApolice={downloadApolice}
                    carregamentoToken={carregamentoToken}
                    dadosContratoAutentique={dadosContratoAutentique}
                    gerarTokenDiarias={(diarias) => gerarTokenDiarias(diarias)}
                    toastCopiarTexto={(titulo, mensagem, cor) => toastCopiarTexto(titulo, mensagem, cor)}
                    realizarDownloadTermo={realizarDownloadTermo}
                    realizarUploadTermo={realizarUploadTermo}
                    subindoTermoResponsabilidade={subindoTermoResponsabilidade}
                    baixandoTermoResponsabilidade={baixandoTermoResponsabilidade}
                />
            </Col>
        </Row>

        <div hidden={aba !== 'anexos'}>
            <Abas
                value={aba}
                onChange={(novaAba) => alterarAba(novaAba)}
                modo={modo}
                modoUsuario={modoUsuario}
                permissao={login.permissao}
            />

            {(parseInt(login.permissao.clienteAnexos) > 1) &&
                <Card className='border-0 shadow-sm'>
                    <Card.Header className='bg-white'>
                        <Card.Title as='h4' className='mb-0'>Anexos do cliente</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Anexos
                            habilitarCadastro={parseInt(login.permissao.clienteAnexos) > 2}
                            habilitarExcluir={parseInt(login.permissao.clienteAnexos) > 3}
                            tabela={'clientes'}
                            id={dados.id}
                            salvando={(flag) => {
                                alterarSalvandoAnexos(flag);
                            }}
                        />
                    </Card.Body>
                </Card>
            }
        </div>

        {aba === 'fichas' &&
            <>
                <Abas
                    value={aba}
                    onChange={(novaAba) => alterarAba(novaAba)}
                    modo={modo}
                    modoUsuario={modoUsuario}
                    permissao={login.permissao}
                />

                {/* instancia componente para consulta de fichas */}
                <FichasConsultar
                    idCliente={dados.id}
                />

            </>
        }

        {aba === 'apolices' &&
            <>
                <Abas
                    value={aba}
                    onChange={(novaAba) => alterarAba(novaAba)}
                    modo={modo}
                    modoUsuario={modoUsuario}
                    permissao={login.permissao}
                />

                {/* instancia componente para consulta de fichas */}
                <Apolices
                    idCliente={dados.id}
                />

            </>
        }

        {aba === 'sms' &&
            <>
                <Abas
                    value={aba}
                    onChange={(novaAba) => alterarAba(novaAba)}
                    modo={modo}
                    modoUsuario={modoUsuario}
                    permissao={login.permissao}
                />

                {/* instancia componente para consulta de fichas */}
                <SmsConsultar
                    idCliente={dados.id}
                />

            </>
        }

        <div hidden={aba !== 'eventos'}>
            <Abas
                value={aba}
                onChange={(novaAba) => alterarAba(novaAba)}
                modo={modo}
                modoUsuario={modoUsuario}
                permissao={login.permissao}
            />
            <Eventos
                alvo={7}
                id={dados.id}
                preCliente={dados.pre_cliente}
                idResponsavel={dados.id_responsavel}
                alterarSalvandoEventos={alterarSalvandoEventos}
            />
        </div>

    </div>
}


function SalvarFormulario({
    salvando,
    cancelar,
    onClick
}) {
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando} onClick={onClick}>
            {salvando ?
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> :
                <span>Salvar</span>
            }
        </Button>
    </Form.Group>
}
