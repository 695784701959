import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import { useHistory } from 'react-router-dom';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carregador from './../../Componentes/Carregador';
import PaginacaoComponente from './../../Componentes/Paginacao';
import Parametros from './Parametros';
import Registro from './Registro';

// componente
export default function Consultar(){

    // variaveis
    const history = useHistory();
    let [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10,
        ativo: ['S'],
        texto: ''
    });
    let [totalRegistros, atualizarTotalRegistros] = useState(0);
    let [carregando, mostrarCarregando] = useState(false);
    let [consulta, alterarConsulta] = useState([]);

    // inicializa
    useEffect(() => {

        // carregando
        mostrarCarregando(true);

        // faz a requisição
        axios.get('modelosSms', {
            params: parametros
        })
        .then(({data, ...retorno}) => {
            alterarConsulta(data.registros);
            atualizarTotalRegistros(parseInt(data.totalRegistros));
        })
        .catch(() => {})
        .then(() => {
            mostrarCarregando(false);
        });

    }, [parametros]);

    return <Row>
        <Col>
            <Card className="painel-consulta border-0 shadow-sm" >
                <div className="card-header">
                    <h4 className="card-title font-weight-bolder">Modelos de SMS</h4>
                    <Parametros parametros={parametros} alterarParametros={alterarParametros} />
                </div>
                <Card.Body>
                    
                    <table className="table table-hover tabela">
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Descrição</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {consulta.map((registro, a) => 
                                <Registro 
                                    key={a} 
                                    dados={registro}
                                    parametros={parametros}
                                    onClickTr={() => console.log('clicou TR')}
                                    clicouEditar={() => {
                                        history.push(`/painel/modelos-sms/alterar/${registro.id}`)
                                    }}
                                />
                            )}
                        </tbody>
                    </table>

                    <PaginacaoComponente
                        pagina={parametros.pagina}
                        registrosPorPagina={parametros.registrosPorPagina}
                        totalRegistros={totalRegistros}
                        alterouPagina={(pagina) => {
                            alterarParametros({...parametros, pagina: pagina});
                        }}
                    />
                    {carregando && 
                        <Carregador className="rounded-bottom" />
                    }
                </Card.Body>
            </Card>
        </Col>
    </Row>
}