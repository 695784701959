import React from 'react';
import styles from './index.module.scss';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons';

// componente
function Abas({
    value,
    onChange,
}){
    

    return <>
        <div className={[styles.abas, 'mb-2'].join(' ')}>

            <div className={styles.abasCliente}>

                
                {/* <BotaoAba 
                    ativo={value === 'geral'}
                    onClick={() => {onChange('geral')}}
                    icone={["fas", 'chart-line']}
                >
                    Geral
                </BotaoAba> */}

                <BotaoAba 
                    ativo={value === 'funil'}
                    onClick={() => {onChange('funil')}}
                    icone={["fas", 'funnel-dollar']}
                >
                    Funil de Vendas
                </BotaoAba>
                
                <BotaoAba 
                    ativo={value === 'vigente'}
                    onClick={() => {onChange('vigente')}}
                    icone={faStopwatch}
                >
                    Vigente
                </BotaoAba>

                <BotaoAba 
                    ativo={value === 'parcelas'}
                    onClick={() => {onChange('parcelas')}}
                    icone={["fas", 'money-check-alt']}
                >
                    Parcelas
                </BotaoAba>

                


            </div>
        </div>
    </>
}

function BotaoAba({
    ativo,
    disabled,
    onClick,
    icone,
    children
}){


    return <button 
        size='sm'
        variant="padrao"
        className={[styles.aba, ativo && styles.ativo, 'mr-1'].join(' ')}
        disabled={disabled}
        onClick={onClick}
    >
        <FontAwesomeIcon className={[styles.icone, 'mr-1'].join(' ')} icon={icone} />
        <span>{children}</span>
    </button>
}

export default Abas;