import React from 'react';

import { Form, Row, Col } from 'react-bootstrap';

function FormularioReferenciasPessoais({
    dados,
    alterarDados,
    funcaoAlterarDados,
    numeroReferencia
}){

    return <>
    
    
        <Form.Row>
            <Form.Group as={Col} lg='12' >
                <Form.Label>{numeroReferencia}. Nome Referencia</Form.Label>
                <Form.Control
                    value={dados[`preferencias_${numeroReferencia}_nome_referencia`]}
                    name={`preferencias_${numeroReferencia}_nome_referencia`}
                    onChange={funcaoAlterarDados}
                    maxLength='200'
                />
            </Form.Group>
        </Form.Row>

    
        <Form.Row>
            <Form.Group as={Col} lg='8' >
                <Form.Label>{numeroReferencia}. Telefone Referencia</Form.Label>
                <Form.Control
                    value={dados[`preferencias_${numeroReferencia}_telefone_referencia`]}
                    name={`preferencias_${numeroReferencia}_telefone_referencia`}
                    onChange={funcaoAlterarDados}
                    maxLength='100'
                />
            </Form.Group>
            <Form.Group as={Col} >
                <Form.Label>{numeroReferencia}. Parentesco</Form.Label>
                <Form.Control
                    value={dados[`preferencias_${numeroReferencia}_grau_parentesco`]}
                    name={`preferencias_${numeroReferencia}_grau_parentesco`}
                    onChange={funcaoAlterarDados}
                    maxLength='10'
                />
            </Form.Group>
        </Form.Row>
    </>
}


export default FormularioReferenciasPessoais;