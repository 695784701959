import styled from 'styled-components';

export const InfoComponent = styled.div.attrs({
    className: ``
})`

    .imagem {

        img {    
            height: 86px;
            max-width: 70px;
            object-fit: contain;
        }
    }

    .cidade {
        font-size: 0.8rem;
        color: grey;
    }

    .tipo-pessoa {
        vertical-align: text-bottom;
    }

`;