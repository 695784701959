import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ConfiguracaoPropostaComponent } from './components';
import ComboCliente from './../../../Componentes/ComboCliente';
import { Form } from 'react-bootstrap';

export default function ConfiguracaoProposta(){

    // checkbox para habilitar
    // cliente para enviar
    // salvar ->
    const [carregando, alterarCarregando] = useState(true);
    const [salvando, alterarSalvando] = useState(false);
    const [idSeguradora, alterarIdSeguradora] = useState(null);
    const [habilitarEmail, alterarHabilitarEmail] = useState('N');

    // consultar configuração
    async function consultarConfiguracao(){
        
        try{

            // faz a requisição
            let { data } = await axios.get(`/configuracaoSistema/seguradoraPropostaSeguroAcidentesPessoais`);
            alterarIdSeguradora(data.valor)
            

        }catch({response}){
            return;
        }

        try{

            // faz a requisição
            let { data } = await axios.get(`/configuracaoSistema/habilitarEmailseguradoraPropostaSeguroAcidentesPessoais`);
            alterarHabilitarEmail(data.valor);

        }catch({response}){
            return;
        }

        alterarCarregando(false);
    }

    // alterar id seguradora
    async function salvarIdSeguradora(idSalvar){
        alterarSalvando(true);
        try{

            // faz a requisição
            let { data } = await axios.put(`/configuracaoSistema/seguradoraPropostaSeguroAcidentesPessoais`, {
                valor: idSalvar
            });
            alterarIdSeguradora(idSalvar);
        }catch({response}){}
        finally{
            alterarSalvando(false);
        }
    }

    // alterar habilitar email para envio
    async function salvarHabilitarEmailSeguradora(habilitado){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.put(`/configuracaoSistema/habilitarEmailseguradoraPropostaSeguroAcidentesPessoais`, {
                valor: habilitado
            });
            alterarHabilitarEmail(habilitado);

        }catch({response}){}
        finally{
            alterarSalvando(false);
        }
    }


    // inicializar
    useEffect(() => {
        consultarConfiguracao();
    }, []);

    if(carregando){
        return <ConfiguracaoPropostaComponent>
            <FontAwesomeIcon icon={faSpinner} pulse />
        </ConfiguracaoPropostaComponent>
    }

    return <>
        <ConfiguracaoPropostaComponent>
            {salvando && 
                <FontAwesomeIcon className='carregando' icon={faSpinner} pulse />
            }
            <ComboCliente 
                size="sm"
                id={idSeguradora}
                alterou={novoId => {
                    salvarIdSeguradora(novoId);
                }}
            />
            <Form.Check 
                custom
                type='checkbox'
                label="Habilitar email"
                size="sm"
                id='checkbox-habilitar-email-seguradora'
                className="habilitar-email-seguradora"
                checked={habilitarEmail === 'S'}
                onChange={e => {
                    salvarHabilitarEmailSeguradora(e.target.checked ? 'S' : 'N');
                }}
                disabled={salvando}
            />
        </ConfiguracaoPropostaComponent>
    
    </>


}