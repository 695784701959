import React, { useState, useEffect } from 'react';

// componentes
import { Form, Row, Col } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import ComboCliente from '../../../Componentes/ComboCliente';

export default function Filtros({
    parametros,
    alterarParametros
}){


    return <>
        <Row>
            <Col>
                <DebounceInput 
                    placeholder="Buscar por evento" 
                    debounceTimeout={400}
                    element={Form.Control} 
                    value={parametros.codigo}
                    onChange={(e) =>{
                        alterarParametros({...parametros, codigo: e.target.value, pagina: 1})
                    }}
                />
            </Col>
            <Col>
                <DebounceInput 
                    placeholder="Buscar por participante" 
                    debounceTimeout={400}
                    element={Form.Control} 
                    value={parametros.participante}
                    onChange={(e) =>{
                        alterarParametros({...parametros, participante: e.target.value, pagina: 1})
                    }}
                />
            </Col>
            <Col>
                <DebounceInput 
                    placeholder="Buscar por carta" 
                    debounceTimeout={400}
                    element={Form.Control} 
                    value={parametros.carta}
                    onChange={(e) =>{
                        alterarParametros({ ...parametros, carta: e.target.value, pagina: 1 })
                    }}
                />
            </Col>
            <Col>
                <Form.Group className='mb-0'>                    
                    <ComboCliente 
                        id={null} // id do cliente
                        alterou={(novoId) => {                            
                            alterarParametros({ ...parametros, idCliente: novoId, pagina: 1 })
                        }}
                    />
                </Form.Group>
            </Col>

        </Row>
    </>

}