import React, { 
    useState, 
    useEffect 
} from 'react';
import { 
    Row, 
    Col, 
    Container
} from 'react-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

import styles from './index.module.scss';
import logoTipoAventura from '../../../assets/rocaSeguroAventura.png';

export default function AutenticidadeContratacao() {

    // estados
    const { token } = useParams();
    const [carregando, alterarCarregando] = useState(false);
    const [dadosEvento, alterarDadosEvento] = useState(null);  
    const [dataCadastroPrimeiroParticipante, alterarDataCadastroPrimeiroParticipante] = useState(new Date());
    
    // faz a consulta enviando o token do evento
    async function consultarToken() {

        // ativa o carregamento
        alterarCarregando(true);

        try {

            // faz a requisição
            const { data } = await axios.get(`/seguroAventura/token/${token}`);
            alterarDadosEvento(data.dados);
            
            // caso evento possua participantes
            if(data.participantes.length > 0) {

                // resgata menor data de cadastro entre os participantes
                alterarDataCadastroPrimeiroParticipante(verificaDataParticipantes(data.participantes));
            }

        } catch({response}) {

            if(response?.status && response.status === 400){
                window.alert('Token não encontrado');
            }

        } finally {

            // desativa o carregamento
            alterarCarregando(false);
        }

    }

    // percorre e verifica menor data de cadastro entre os participantes
    function verificaDataParticipantes(participantes) {

        let dataCadastro = null;
        
        // percorre participantes
        for(let index = 0; index < participantes.length; index++) {

            // resgata participante pelo index
            const participante = participantes[index].dados;
            
            // resgata sua data de cadastro
            const dataCadastroParticipante = participante.dataCadastro;

            // caso ainda não possuir para verificar
            if(!dataCadastro) {
                dataCadastro = dataCadastroParticipante;

                // pula para o próximo
                continue;
            }
            
            // caso a data anterior seja maior que a atual
            if(new Date(dataCadastro) > new Date(participante.dataCadastro)) {

                // atribui a menor data
                dataCadastro = participante.dataCadastro;
            }

        };

        return new Date(dataCadastro);
    }
    
    // inicializa
    useEffect(() => {

        // se possui codigo, faz a requisição
        if(token) consultarToken();

    }, []);

    // se estiver carregando
    if (carregando || !dadosEvento) {
        return <div className="w-full h-full text-center">
            <FontAwesomeIcon 
                icon={faSpinner} 
                pulse 
            /> 
            Aguarde!
        </div>
    }

    // constroi a url para o usuário ir até o pdf
    const urlPdfAutenticidade = window.location.origin + '/api/seguroAventura/pdfComprovanteContratacao/autenticidade/' + token;
    
    // retorna componente
    return (
        <Container className='p-5'>
            <Col 
                lg={12} 
                className={styles.containerImg}
            >
                <div className="formulario">
                    <div className="logo text-center mb-4">
                        <img 
                            src={logoTipoAventura} 
                            className="w-50 logo-roca"
                        />
                    </div>
                </div>
            </Col>
            <Row className='mb-4'>
                <Col className={[styles.containerPadrao, styles.containerTitulo]}>
                    <span>Comprovante de Contratação</span>
                    <span>Verificação de Autenticidade Eletrônica</span>
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col className={styles.containerPadrao}>
                    <h5>Emissão</h5>
                    <span>GMT {moment(dataCadastroPrimeiroParticipante).format(`ZZ [Brasília em] DD MMM YYYY [às] HH:mm:ss`)}</span>
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col className={styles.containerPadrao}>
                    <h5>Período de Vigência</h5>
                    <span>{`DE: ${moment(dadosEvento.dataInicio).format('DD/MM/YYYY')} às ${dadosEvento?.horaInicio} ATÉ: ${moment(dadosEvento.dataFinal).format('DD/MM/YYYY')} às ${dadosEvento?.horaFinal}`}</span>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col className={styles.containerPadrao}>
                    <a 
                        target="_blank"
                        href={urlPdfAutenticidade}
                        className={styles.link} 
                        rel="noreferrer"
                    >
                        Acessar Via Original
                    </a>
                </Col>
            </Row>
        </Container>
    )

}