import styled from 'styled-components';


export const ConsultarComponent = styled.div.attrs({
    className: ``
})`

    .card-abertos {
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 3px black;
            background-color: #ffc107 !important;
        }
    }

    .card-andamento {
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 3px black;
            background-color: #28a745 !important;
        }
    }

    .card-finalizados {
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 3px black;
            background-color: #007bff !important;
        }
    }    

    .card-cancelados {
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 3px black;
            background-color: #dc3545 !important;
        }
    } 

    .card-diarias {
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 3px black;
            background-color: black !important;
        }
    }

    .card-erros {
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 2px #404040;
            background-color: #fbbe05 !important;
        }
    }

    .card-evento {
        cursor: pointer;

        &:hover {
            background: #e6e6e6;
            box-shadow: 0 0 3px black;
        }
    }

    .card-selecionado {
        border: 1px solid black;
        box-shadow: 0px 0px 12px 0px black;
    }

    .registros-eventos {
        display: grid;
        gap: 0.5rem;
        grid-template-columns: 1fr 1fr 1fr;

        .titulo-evento {
            font-size: 0.8rem;
        }

        .evento-descricao {
            font-size: 0.8rem
        }

        .opcoes {

            button {
                height: auto;
                display: flex;
            }
        }
    }

`