import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// importa componente
import useIsMounted from 'ismounted';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConteudoEmail from './../ConteudoEmail';
import ContentEditable from "react-contenteditable";
import Tags from './../../Componentes/Tags';

// componente
export default function Cadastrar({
    // modoLeitura='modelo' // modelo, envio
}){

    // carregar template base
    const isMounted = useIsMounted();
    let history = useHistory();
    let {id} = useParams();
    let login = useSelector(state => state.login);
    let [html, alterarHtml] = useState('');
    let [htmlDestaque, alterarHtmlDestaque] = useState('');
    let [modo, alterarModo] = useState('cadastro'); // cadastro, edicao
    let [carregando, alterarCarregando] = useState(true);
    let [dados, alterarDados] = useState({
        id: id || null,
        descricao: null,
        titulo: null,
        conteudo: null,
        destaque: null,
        conteudo_corpo: null,
        padrao_novo: 'S',
        id_imagem: null,
        tipo: '1'
    });
    let [padraoAntigo, alterarPadraoAntigo] = useState(false);
    let [salvando, alterarSalvando] = useState(false);


    // tera outro componente para envio de email

    // opção para pré visualização deverá ser carregada a partir do backend
    
    // não terá rodapé no front (somente pré visualização e em caso de reenvio)

    // verificar todas as funções de envio de email para unificar, detaque, conteudo, cabeçalho e rodapé(assinatura)

    // inicializa componente
    useEffect(() => {

        // verifica se é edição
        if(dados.id){

            // altera o modo
            alterarModo('edicao');

            // carrega os dados do modelo de email
            axios.get(`/modelosEmail/${dados.id}`)
            .then(({data, ...retorno}) => {
                alterarDados(data.dados);
            })
            .catch(() => {})
            .then(() => {
                alterarCarregando(false);
            })

        }else{
            alterarCarregando(false);
        }

    }, []);

    // salva
    function salvar(){
        alterarSalvando(true);

        // salva
        axios.post(`modelosEmail`, dados)
        .then(({data, ...retorno}) => {
            history.push('/painel/modelos-email');
        })
        .catch(() => {})
        .then(() => {
            if(isMounted.current){
                alterarSalvando(false);
            }
        });

    }

    // carregando
    if(carregando){
        return <div>Carregando...</div>
    }

    return <>
        <Row>
            <Col md='8'>
                <Card className='border-0 shadow-sm'>
                    <Card.Header className='bg-white border-0 mb-0 pb-0'>
                        <Card.Title>Cadastro Modelo de Email</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Row>
                            <Col lg={8}>
                                <Form.Group>
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control 
                                        value={dados.descricao || ''}
                                        onChange={e => {
                                            dados.descricao = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group>
                                    <Form.Label>Tipo</Form.Label>
                                    <Form.Control 
                                        as="select"
                                        value={dados.tipo || ''}
                                        onChange={e => {
                                            dados.tipo = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                    >
                                        <option value={1}>Clientes</option>
                                        {/* <option value={2}>Parcelas</option> */}
                                        <option value={3}>Seguro Aventura</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        {/* { dados.padrao_novo === 'N' &&
                            <Button
                                variant={padraoAntigo ? 'success': 'padrao'}
                                onClick={() => {
                                    alterarPadraoAntigo(!padraoAntigo);
                                }}
                            >
                                Visualizar Padrão Antigo
                            </Button>
                        } */}
                    </Card.Body>
                </Card>
            </Col>
            <Col md='4'></Col>
        </Row>
        <Row>
            <Col md='8'>

                <Card className='border-0 shadow-sm mt-4'>
                    <Card.Body
                        className={padraoAntigo && 'p-0'}
                        style={{
                            overflowX: padraoAntigo ? 'auto' : null
                        }}
                    >
                        { padraoAntigo ?
                            <>  
                                <div className='p-2' style={{background: '#f7f7f7'}}>
                                    <Form.Control value={dados.titulo} disabled={true} />
                                </div>
                                <ContentEditable 
                                    html={dados.conteudo}
                                    disabled={true}
                                />
                            </>
                            :
                            <ConteudoEmail 
                                value={{
                                    id_imagem: dados.id_imagem,
                                    titulo: dados.titulo,
                                    destaque: dados.destaque,
                                    conteudo_corpo: dados.conteudo_corpo
                                }}
                                onChange={dadosNovos => {
                                    Object.assign(dados, dadosNovos);
                                    alterarDados({...dados});
                                }}
                            />
                        }
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className='border-0 shadow-sm mt-4'>
                    <Card.Header className='mb-0 border-0 bg-white pb-0'>
                        <Card.Title className='mb-0' as='h5'>Tags</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Tags 
                            tipo={dados.tipo}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col md='8'>
                <SalvarFormulario 
                    salvando={salvando}
                    onClick={() => salvar()}
                    cancelar={() => {
                        history.push('/painel/modelos-email');
                    }}
                />
            </Col>
        </Row>
    </>
}


function SalvarFormulario({
    salvando,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <span>Salvar</span>
            }
        </Button>
    </Form.Group>
}