import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import moment from 'moment';
import { ResponsiveContainer, PieChart, Pie, BarChart, Sector, Bar, Cell, LabelList, Brush, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Badge, Form } from 'react-bootstrap';
import { capitalizar } from './../../../../Funcoes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

// componentes

// componente
export default function GraficoSeguradorasFunil({
    registros
}){

    // estados
    const [carregando, alterarCarregando] = useState(true);
    const [dados, alterarDados] = useState([]);

    // exemplo de retorno
    // let [dados, alterarDados] = useState([
    //     {name: 'LIBERTY SEGUROS S/A', value: 100, cor: getRandomColor(), show: true},
    //     {name: 'AFFINITY SEGUROS', value: 70, cor: getRandomColor(), show: true }
    // ]);

    // inicializa
    useEffect(() => {
        console.log(registros);
        // altera o retorno para incluir novos dados
        alterarDados(registros.map(registro => {
            return {
                value: parseInt(registro.total),
                name: registro.dados.nome,
                cor: registro.dados.cor || '#00FF00',
                show: true
            };
        }));

    }, [registros]);

    // função temporária para cores
    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    // tooltip de quando passa o mouse sobre a celula
    const CustomTooltip = ({ active, payload, label, ...props }) => {
        if (active) {
            let nome = capitalizar(payload[0].name);
            return <div className={[styles.tooltip, 'p-2 rounded shadow-sm bg-light text-secondary text-center'].join(' ')}>
                <p className="m-0 mr-2">{nome} <Badge variant='secondary'>{payload[0].value}</Badge></p>
            </div>
        }
      
        return null;
    };

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, index,
        }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        
        return (
            <text stroke="black" strokeWidth="0.3" x={x} y={y} fill="white" fontWeight={'bold'} textAnchor={'middle'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return <>
        <ResponsiveContainer width='100%' height={300}>
            <PieChart >
                <Pie
                    data={dados.filter(dado => dado.show)}
                    // cx={120}
                    // cy={200}
                    innerRadius={60}
                    outerRadius={120}
                    fill="#8884d8"
                    paddingAngle={2}
                    dataKey="value"

                    labelLine={false}
                    label={renderCustomizedLabel}
                    isAnimationActive={false}
                >
                    {dados.filter(dado => dado.show).map((entry, index) => 
                        <Cell key={`cell-${index}`} fill={entry.cor} ></Cell>
                    )}
                </Pie>
                <Tooltip content={CustomTooltip} />
            </PieChart>
        </ResponsiveContainer>
        <div>
            {dados.map(dado => 
                <Form.Check 
                    key={dado.name}
                    // type={'switch'}
                    id={`switch-grafico-${dado.name}`}
                    label={<><Badge className={styles.legendaPizza} style={{background: dado.cor}}>{dado.value}</Badge> {capitalizar(dado.name)}</>}
                    checked={dado.show}
                    onChange={e => {
                        dado.show = e.target.checked;
                        alterarDados([...dados]);
                    }}
                    size='sm'
                />    
            )}
        </div>
    </>
    

    /*
    return <>
        <ResponsiveContainer width='100%' height={dados.length * 20}>
            <BarChart 
                // data={dados.map(dado => { return {[dado.name]: dado.value}; })} 
                data={dados} 
                margin={{top: 5, right: 30, left: 20, bottom: 5}} layout="vertical">
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis type="number"/>
                <YAxis dataKey="name" type="category" width={270} hide />
                <Tooltip content={CustomTooltip} />
                
                <Bar dataKey="value" fill="rgb(51, 217, 51)">
                    {dados.map(dado => 
                        <Cell key={`cell-${dado.name}`} fill={dado.cor} ></Cell>
                    )}
                </Bar>
                
            </BarChart>
        </ResponsiveContainer>
        <div>
            {dados.map(dado => 
                <Form.Check 
                    key={dado.name}
                    // type={'switch'}
                    id={`switch-grafico-${dado.name}`}
                    label={<><Badge className={styles.legendaPizza} style={{background: dado.cor}}>{dado.value}</Badge> {capitalizar(dado.name)}</>}
                    checked={dado.show}
                    onChange={e => {
                        dado.show = e.target.checked;
                        alterarDados([...dados]);
                    }}
                    size='sm'
                />    
            )}
        </div>
    </>
    */

    /*
    return <>
        <ResponsiveContainer width='100%' height={dados.length * 30}>
            <BarChart 
                // data={dados.map(dado => { return {[dado.name]: dado.value}; })} 
                data={dados} 
                margin={{top: 5, right: 30, left: 20, bottom: 5}} layout="vertical">
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis type="number"/>
                <YAxis dataKey="name" type="category" width={270} />
                <Tooltip content={CustomTooltip} />
                
                <Bar dataKey="value" fill="rgb(51, 217, 51)">
                    {dados.map(dado => 
                        <Cell key={`cell-${dado.name}`} fill={dado.cor} ></Cell>
                    )}
                </Bar>
                
            </BarChart>
        </ResponsiveContainer>
        <div className='text-center'>
            {dados.map(dado => 
                <Badge key={dado.name} className={[styles.legendaPizza, 'm-1 p-1'].join(' ')} style={{background: dado.cor}}>
                    <FontAwesomeIcon icon={faCheck} size='xs' />
                </Badge>
            )}
        </div>
    </>
    */
    

}