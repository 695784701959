import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Container, Button, Form } from 'react-bootstrap';

// mostra modal para uma simples confirmação de sim ou não
export default function ModalExcluir({
    registro,
    excluido,
    fechou
}){

    // estados
    const [mostrar, alterarMostrar] = useState(true);
    const [salvando, alterarSalvando] = useState(false);

    // faz a exclusão
    async function excluir(){

        // exclui
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/modelosEmail`, {
                dados: {
                    id: registro.id,
                    ativo: 'N'
                }
            });
            alterarMostrar(false);
            excluido();

        }catch({response}){}
    }

    return <Modal 
    show={mostrar} 
    onExited={fechou}
    onHide={() => {}}
    size={'md'}
>
    <Modal.Header className='border-0'>
        <Modal.Title>
            Excluir
        </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <Container>
            Deseja excluir esse modelo de email?
        </Container>
    </Modal.Body>
    <Modal.Footer  className='border-0'>
        <Button
            variant='padrao'
            onClick={() => {alterarMostrar(false)}}
            disabled={salvando}
        >
            <span>Desistir</span>
        </Button>
        <Button
            onClick={excluir}
            variant='danger'
            disabled={salvando}
        >
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Excluindo</span>
                </> : 
                <span>Excluir</span>
            }
        </Button>
    </Modal.Footer>
</Modal>

}
