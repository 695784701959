import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carregador from './../../Componentes/Carregador';
import PaginacaoComponente from './../../Componentes/Paginacao';
import Parametros from './Parametros';
import Registro from './Registro';

export default function Consultar(){

    // estados
    let history = useHistory();
    let login = useSelector(state => state.login);
    let [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10,
        ativo: ['S'],
        texto: '',
        estados: [],
        dataProgramadoInicio: null,
        dataProgramadoFim: null
    });
    let [totalRegistros, atualizarTotalRegistros] = useState(0);
    let [carregando, mostrarCarregando] = useState(false);
    let [consulta, alterarConsulta] = useState([]);

    // inicia
    useEffect(() => {
        mostrarCarregando(true);

        axios.get('/emailsEnviado', {
            params: parametros
        })
        .then(({data, ...retorno}) => {
            alterarConsulta(data.registros);
            atualizarTotalRegistros(parseInt(data.totalRegistros));
        })
        .catch(() => {})
        .then(() => {
            mostrarCarregando(false);
        });

    }, [parametros]);

    return <Row>
        <Col>
            <Card className="painel-consulta border-0 shadow-sm" >
                <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                        <h4 className="card-title font-weight-bolder">Caixa de Saída de emails</h4>
                        <Button 
                            variant='padrao' 
                            size='sm'
                            onClick={() => {
                                history.push('/painel/modelos-email');
                            }}
                        >
                            <FontAwesomeIcon className="icone" icon={["far", 'envelope']} />
                            <span>Modelos de email</span>
                        </Button>
                    </div>
                    <Parametros parametros={parametros} alterarParametros={alterarParametros} />
                </div>
                <Card.Body>
                    
                    <table className="table table-hover tabela">
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Título</th>
                                <th>Para</th>
                                <th>Data</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {consulta.map((registro, a) => 
                                <Registro 
                                    key={registro.id}
                                    dados={registro}
                                    clicouAbrir={() => {
                                        history.push(`/painel/clientes/abrir-email/${registro.id}`);
                                    }}
                                />
                            )}
                        </tbody>
                    </table>
                    <PaginacaoComponente
                        pagina={parametros.pagina}
                        registrosPorPagina={parametros.registrosPorPagina}
                        totalRegistros={totalRegistros}
                        alterouPagina={(pagina) => {
                            alterarParametros({...parametros, pagina: pagina});
                        }}
                    />
                    {carregando && 
                        <Carregador className="rounded-bottom" />
                    }
                </Card.Body>
            </Card>
        </Col>
    </Row>
}
