import React from 'react';
import styles from './index.module.scss';

// componente options
function OptionSelect({
    data, 
    ...props
}) {

    // propriedades importantes
    const { 
        innerProps, 
        innerRef, 
        isFocused 
    } = props;
    
    // retorna componente
    return (
        <div 
            tabIndex={'-1'} 
            ref={innerRef} 
            className={[styles.option, isFocused && styles.focado].join(' ')}
            {...innerProps} 
        >
            <div className={styles.dadosPlano}>
                <div>
                    <p>{data.dados.nome} {data.dados.valor ? `(R$ ${(data.dados.valor).replace('.', ',')})` : ''}</p>
                </div>
            </div>
        </div>
    )
    
}

// opção
export default OptionSelect;