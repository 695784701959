import React, { useState } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';

import InputDataCombo from '../../../Componentes/InputDataCombo';

// cria os parametros
function Parametros({
    parametros, 
    alterarParametros
}){

    const [mostrarMais, expandirParametros] = useState(false); 
    const estadosEnvio = [
        {value: '1', label: 'Com erro'},
        {value: '2', label: 'Enviado'},
    ]

    // parametros
    return <div className="parametros">
        <div className="parametros-inicial">
            <DebounceInput 
                placeholder="Pesquisa" 
                debounceTimeout={400}
                element={Form.Control} 
                value={parametros.texto}
                onChange={(e) =>{
                    alterarParametros({...parametros, texto: e.target.value, pagina: 1})
                }}
            />
            <Button 
                variant="padrao"
                onClick={() => expandirParametros(!mostrarMais)}
            >
                <FontAwesomeIcon className="icone" icon={!mostrarMais ? ["fas", "search-plus"] : ["fas", "search-minus"]} />
            </Button>
        </div>

        {mostrarMais && 
            <Row className='mt-3'>
                <Col md='6'>
                    <Form.Group>
                        <Form.Label>Estado</Form.Label>
                        <Select
                            value={estadosEnvio.filter(estado => {
                                if(parametros.statusOperadora && parametros.statusOperadora.includes(estado.value)){
                                    return true;
                                }
                                return false;
                            })}
                            options={estadosEnvio}
                            isMulti={true}
                            placeholder=''
                            onChange={(e) => {
                                e = e || []; // mantem vazio se for null

                                // altera os filtros selecionados
                                parametros.statusOperadora = e.map((option) => option.value);
                                alterarParametros({...parametros});
                            }}
                        />
                    </Form.Group>
                </Col>
                <Col md='6'>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Envio</Form.Label>
                                <Row>
                                    <Col>
                                        <InputDataCombo 
                                            placeholder="A partir de"
                                            data={parametros.dataProgramadoInicio}
                                            onChange={(novaData) => {
                                                alterarParametros({...parametros, dataProgramadoInicio: novaData, pagina: 1})
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <InputDataCombo 
                                            placeholder="Até"
                                            data={parametros.dataProgramadoFim}
                                            onChange={(novaData) => {
                                                alterarParametros({...parametros, dataProgramadoFim: novaData, pagina: 1})
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                
            </Row>
        }
    </div>

}

export default Parametros;