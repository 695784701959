import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// componentes
import { Card, Col, Row } from 'react-bootstrap';
import Carregador from './../../Componentes/Carregador';
import PaginacaoComponente from './../../Componentes/Paginacao';
import Parametros from './Parametros';
import Registro from './Registro';

// consulta
export default function UsuariosConsultar(){

    // parametros de consulta
    const { login } = useSelector(state => state);
    const history = useHistory();
    const [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10,
        status: ['A'],
        texto: ''
    });
    const [totalRegistros, atualizarTotalRegistros] = useState(0);
    const [carregando, mostrarCarregando] = useState(false);
    const [consulta, alterarConsulta] = useState([]);

    // consulta os registros
    async function consultarRegistros(){
        mostrarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/usuarios`, { params: parametros });
            alterarConsulta(data.registros);
            atualizarTotalRegistros(parseInt(data.totalRegistros));

        }catch({response}){}
        finally{
            mostrarCarregando(false);
        }

    }

    // inicia
    useEffect(() => {
        consultarRegistros();
    }, [parametros]);

    return <Row>
        <Col>
            <Card className="painel-consulta border-0 shadow-sm" >
                <Card.Header>
                    <Card.Title as='h4'>Usuarios</Card.Title>
                    <Parametros parametros={parametros} alterarParametros={alterarParametros}/>
                </Card.Header>
                <Card.Body>
                <table className="table table-hover tabela">
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Login</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {consulta.map((registro, a) => 
                                <Registro 
                                    permissao={login.permissao}
                                    key={a} 
                                    dados={registro.dados}
                                    parametros={parametros}
                                    onClickTr={() => console.log('clicou TR')}
                                    clicouEditar={() => {
                                        if(registro.dados.id_cliente){
                                            history.push(`/painel/usuarios/alterar/${registro.dados.id_cliente}`);
                                        }
                                    }}
                                    clicouPermissoes={() => {
                                        history.push(`/painel/usuarios/permissoes/${registro.dados.id}`);
                                    }}
                                    clicouPonto={() => {
                                        history.push(`/painel/ponto/${registro.dados.id}`);
                                    }}
                                    clicouTransferir={() => {
                                        history.push(`/painel/usuarios/transferir/${registro.dados.id}`);
                                    }}
                                    alterouStatus={(statusNovo) => {
                                        registro.dados.status = statusNovo;
                                        alterarConsulta([...consulta]);
                                        // ativarOuInativar(registro);
                                    }}
                                    clicouLiberarIp={() => {
                                        history.push(`/painel/usuarios/ip/${registro.dados.id}`);
                                    }}
                                />
                            )}
                        </tbody>
                    </table>
                    <PaginacaoComponente
                        pagina={parametros.pagina}
                        registrosPorPagina={parametros.registrosPorPagina}
                        totalRegistros={totalRegistros}
                        alterouPagina={(pagina) => {
                            alterarParametros({...parametros, pagina: pagina});
                        }}
                    />
                    {carregando && 
                        <Carregador className="rounded-bottom" />
                    }
                </Card.Body>
            </Card>
        </Col>
    </Row>
}
