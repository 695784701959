import React from 'react';

// import componentes
import { Form, Row, Col } from 'react-bootstrap';

function FormularioImobiliaria({
    dados,
    alterarDados,
    funcaoAlterarDados,
    modoLeitura=false
}){


    return <>
        <Form.Group as={Row} >
            <Form.Label column lg='3'>Imobiliária</Form.Label>
            <Col lg='9'>
                <Form.Control
                    value={dados.imobiliaria}
                    name='imobiliaria'
                    onChange={funcaoAlterarDados}
                    maxLength='200'
                    readOnly={modoLeitura}
                />
            </Col>
        </Form.Group>
        
        {/* mascara */}
        <Form.Group as={Row} >
            <Form.Label column lg='3'>CNPJ</Form.Label>
            <Col lg='9'>
                <Form.Control
                    value={dados.cnpj}
                    name='cnpj'
                    onChange={funcaoAlterarDados}
                    maxLength='30'
                    readOnly={modoLeitura}
                />
            </Col>
        </Form.Group>
        
        <Form.Group as={Row} >
            <Form.Label column lg='5'>E-mail de Acompanhamento</Form.Label>
            <Col lg='7'>
                <Form.Control
                    value={dados.email_acompanhamento}
                    name='email_acompanhamento'
                    onChange={funcaoAlterarDados}
                    maxLength='100'
                    readOnly={modoLeitura}
                />
            </Col>
        </Form.Group>
        
    </>
}

export default FormularioImobiliaria;