import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Badge, Button, Card, Col, Container, Row } from 'react-bootstrap';

export default function Concluido(){

    // estados
    const history = useHistory();
    const { token } = useParams();
    const [carregando, alterarCarregando] = useState(true);
    const [dados, alterarDados] = useState(null);

    // faz a consulta
    async function consultarToken(){

        // consulta o token
        try{

            let { data } = await axios.get(`/seguroAventura/token/${token}`);
            alterarDados(data.dados);
            alterarCarregando(false);

        }catch({response}){}

    }

    // inicializa
    useEffect(() => {
        consultarToken();
    }, []);

    // carregando
    if(carregando){
        return <div className="text-center">
            <FontAwesomeIcon icon={faSpinner} pulse /> Aguarde!
        </div>
    }
    
    return <>
        <Container className="py-5">
            <Row>
                <Col lg={{span: 6, offset: 3}}>
                    <Card className="border-0 bg-light text-center">
                        <Card.Body>
                            <p>Cadastro conclúido!</p>
                            <p>Guarde seu código <Badge variant="success"><b>{dados.codigo}</b></Badge> para editar em outro momento!</p>
                            
                            <Button
                                variant="padrao"
                                onClick={() => {
                                    history.push(`/formularios/propostaSeguro`);
                                }}
                            >
                                Voltar!
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>

}