import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import AsyncSelect from 'react-select/lib/Async';
import OptionSelect from './OptionSelect';
import { Form } from 'react-bootstrap';

// componente
export default function ComboModeloSms({
    alterou,
    tipo=['1'],
    placeholder='Escolher modelo de sms'
}){

    // estados
    const [consulta, alterarConsulta] = useState([]);
    const [carregando, alterarCarregando] = useState(false);
    const [dados, alterarDados] = useState(null);

    // inicializa
    useEffect(() => {

        // carregar alguns
        consultar('');

    }, []);

    // consulta os modelos de sms
    function consultar(inputValue, callback = null){

        // consulta os modelos
        axios.get('/modelosSms', {
            params: {
                paigna: 1,
                registrosPorPagina: 5,
                tipo: tipo
            }
        })
        .then(({data, ...retorno}) => {
            alterarConsulta(data.registros);
            if(callback !== null){
                callback(data.registros)
            }
        });
        

    }

    // componente
    return <>
        <AsyncSelect 
            isDisabled={carregando}
            isClearable
            loadOptions={consultar} // ao alterar, carrega
            defaultOptions={consulta}       // lista pré carregada
            value={dados && {
                label: dados.descricao
            }}  
            onChange={(e) => {

                if(e === null){
                    alterarDados(null);
                    alterou(null);
                }else{
                    alterarDados({...e});
                    alterou(e.id);
                }
            }}
            components={{
                Option: OptionSelect
            }}
            noOptionsMessage={() => 'Digite algo para fazer a consulta'}
            loadingMessage={() => 'Carregando...'}
            placeholder={placeholder}
        />
    </>

}