import React, {useState, useEffect} from 'react';
import styles from './index.module.scss';

// componentes
import BotaoTabela from './../../../Componentes/BotaoTabela';

// elemento registro
function Registro({
    dados, 
    clicouEditar
}){

    // D: dias, S: semanas, M: meses, A: anos
    let periodo = {
        'D': 'Dia(S)',
        'S': 'Semana(s)',
        'M': 'Mes(es)',
        'A': 'Ano(s)'
    }

    return <tr>
        <td className="estreito text-center font-weight-bold">{dados.id}</td>
        <td>{dados.descricao}</td>
        <td>{dados.quantidade} {periodo[dados.periodo]}</td>
        <td className="text-nowrap estreito">
            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >Editar</BotaoTabela>
        </td>
    </tr>
}

export default Registro;