import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import axios from 'axios';

// importa contexto
import { FichaCadastrarContexto } from './../FichaCadastrarContexto';

// componentes
import { ContainerComponent } from './components';
import { Card, ButtonToolbar, OverlayTrigger, Button, Tooltip, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faHandsHelping, faPlus, faFileImport, faMarker, faCompressAlt, faAngleRight, faAngleDown, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Custos, { CustosTotais, FormaPagamento } from './../CobrancaFicha/Custos';
import Parcelas, { Parcela, ajustarLimiteParcelas, ajustarValorParcelas, ajustarVencimentoParcelas, calcularComissao, calcularValorLiquido } from './../CobrancaFicha/Parcelas';
import Cancelamento from './../CobrancaFicha/Cancelamento';
import AnexosFicha from './../AnexosFicha';
import Vigencia from './../CobrancaFicha/Vigencia';


// componente
export default function Proposta({

}){

    // estados
    const { 
        dados, alterarDados, erros, salvando,
        dadosProposta, alterarDadosProposta,
        dadosPropostaParcelas, alterarDadosPropostaParcelas,
        anexosProposta, alterarAnexosProposta,
        dadosEmissao, dadosVigencia, alterarDadosVigencia
    } = useContext(FichaCadastrarContexto);
    const [desfazerExclusaoDisponivel, alterarDesfazerExclusaoDisponivel] = useState(false);
    const [minimizado, alterarMinimizado] = useState(true);
    const [valorBruto, alterarValorBruto] = useState('0.00');
    const [verificando, alterarVerificando] = useState(false);

    // inclui emissão zerada
    async function incluirProposta(){

        let dadosInicial = {
            id: null,
            id_cliente_ficha: dados.id,
            id_seguradora: null,
            valor_impostos: '0.0',
            forma_pagamento: '1',
            comissao: '0.0',
            data_proposta: moment().format('YYYY-MM-DD'),
            juros: '0.0',
            adicional_fracionamento: '0.0',
            cancelado: 'N',
            data_cancelado: null,
            motivo_cancelado: null
        };
        let parcelasInicial = [];
        let anexosInicial = [];

        // se for uma ficha de edição, verifica se já possui uma proposta incluida
        if(dados.id !== null){
            alterarVerificando(true);
            
            try{

                // faz a requisição
                let { data } = await axios.get(`/fichas/${dados.id}`);
                if(data.proposta !== null){
                    dadosInicial = data.proposta;
                    parcelasInicial = data.propostaParcelas;
                    anexosInicial = data.anexosProposta;
                }
                alterarVerificando(false);

            }catch({response}){}

        }

        // altera
        alterarDadosProposta(dadosInicial);
        alterarDadosPropostaParcelas(parcelasInicial)
        alterarAnexosProposta(anexosInicial);
        alterarMinimizado(false);


    }

    // exclui a emissão
    async function excluirProposta(){
        alterarDadosProposta(null);
        alterarDadosPropostaParcelas([]);
        alterarAnexosProposta([]);
        alterarMinimizado(true);
    }

    // ajusta quantidade de parcelas
    async function alterarQuantidadeParcelas(novoLimite){
        let novoArrayParcelas = ajustarLimiteParcelas('proposta', dadosPropostaParcelas, novoLimite, valorBruto, dadosProposta);
        alterarDadosPropostaParcelas(novoArrayParcelas);
    }

    // recalcula o valor das parecelas
    async function recalcularValorParcelas(){
        let novoArrayParcelas = ajustarValorParcelas('proposta', dadosPropostaParcelas, valorBruto, dadosProposta);
        alterarDadosPropostaParcelas(novoArrayParcelas);
    }

    // após alterar o valor da parcela, ajusta o bruto
    async function atualizarValorBruto(){
        let novoValorBruto = 0.0;
        let novoValorImpostos = 0.0;
        dadosPropostaParcelas.forEach(parcelaRegistro => {
            novoValorBruto += parseFloat(parcelaRegistro.dados.valor);
            novoValorImpostos += parseFloat(parcelaRegistro.dados.valor_juros);
        });
        dadosProposta.valor_impostos = novoValorImpostos.toFixed(2);
        alterarDadosProposta({...dadosProposta});
        alterarValorBruto(novoValorBruto.toFixed(2));
        recalcularValorParcelas();
    }

    // atualiza o vencimento das próximas parcelas a partir da parcela alterada
    async function ajustarVencimentoParcelasPosterior(parcelaVerificar){
        let novoArrayParcelas = ajustarVencimentoParcelas('proposta', dadosPropostaParcelas, parcelaVerificar);
        alterarDadosPropostaParcelas(novoArrayParcelas);
    }

    // recalcular valor das parcelas
    useEffect(() => {
        if(dadosProposta !== null){
            let novoArrayParcelas = ajustarValorParcelas('proposta', dadosPropostaParcelas, valorBruto, dadosProposta);
            alterarDadosPropostaParcelas(novoArrayParcelas);
        }
    }, [valorBruto, dadosProposta && dadosProposta.valor_impostos]);

    // se foi inserido uma proposta nova, insere uma parcela
    useEffect(() => {
        if(dadosProposta !== null && dadosPropostaParcelas.length == 0){
            alterarQuantidadeParcelas(1);
        }
    }, [dadosProposta, dadosPropostaParcelas]);


    // carregando a primeira vez, calcula o valor bruto somando os totais
    useEffect(() => {
        let valorBrutoAtualizar = 0.0;
        dadosPropostaParcelas.forEach(parcela => {
            valorBrutoAtualizar += parseFloat(parcela.dados.valor);
        });
        alterarValorBruto(valorBrutoAtualizar.toFixed(2));
    }, []);

    return <>
        <ContainerComponent
            minimizado={minimizado}
        >
            <div className={'mr-2'}>
                { dadosProposta !== null && 
                    <>
                        <div className="mb-2">
                            <ButtonToolbar>
                                <OverlayTrigger
                                    placement='top'
                                    overlay={<Tooltip>Excluir Proposta</Tooltip>}
                                >
                                    <Button
                                        variant='padrao'
                                        size='sm'
                                        onClick={excluirProposta}
                                        className='rounded-circle'
                                        disabled={dadosEmissao !== null}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </OverlayTrigger>
                            </ButtonToolbar>
                        </div>
                    </>
                }

                { dadosProposta == null && 
                    <ButtonToolbar>
                        <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip>Incluir Proposta</Tooltip>}
                        >
                            <Button
                                variant='padrao'
                                size='sm'
                                onClick={incluirProposta}
                                className='rounded-circle'
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </OverlayTrigger>
                    </ButtonToolbar>
                }

            </div>

            <div className="flex-grow-1 proposta">
                <p className="m-0 text-secondary mb-1">
                    <FontAwesomeIcon icon={faMarker} /> 
                    <span className="mx-2">Proposta</span>
                    {dadosProposta !== null &&
                        <>
                            { minimizado ? 
                                <FontAwesomeIcon className="minimizar" icon={faAngleRight} onClick={() => {alterarMinimizado(false)}} />
                                :
                                <FontAwesomeIcon className="minimizar" icon={faAngleDown} onClick={() => {alterarMinimizado(true)}} />
                            }
                        </>
                    }

                    { (verificando) &&
                        <small>
                            <FontAwesomeIcon icon={faSpinner} pulse /> Verificando
                        </small>
                    }
                    
                </p>
                { (dadosProposta !== null && !minimizado) && 
                    <>

                        <Row>
                            <Col lg={9}>
                                <Row>
                                    <Col lg={9}>
                                        <Card className='border-0 shadow-sm mb-3'>
                                            <Card.Body>
                                                <Custos 
                                                    dados={dadosProposta}
                                                    alterarDados={alterarDadosProposta}
                                                    quantidadeParcelas={dadosPropostaParcelas.length}
                                                    alterarQuantidadeParcelas={alterarQuantidadeParcelas}
                                                    valorBruto={valorBruto} 
                                                    alterarValorBruto={alterarValorBruto}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={3}>
                                        <Card className='border-0 shadow-sm mb-3'>
                                            <Card.Body>
                                                <CustosTotais 
                                                    comissao={calcularComissao('emissao', valorBruto, dadosProposta)}
                                                    liquido={calcularValorLiquido('emissao', valorBruto, dadosProposta)}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>

                                <Card className='border-0 shadow-sm mb-3'>
                                    <Card.Body>
                                        <Parcelas
                                            modo="proposta"
                                        >
                                            {dadosPropostaParcelas.map(parcelaRegistro => 
                                                <Parcela 
                                                    key={parcelaRegistro.temp_id || parcelaRegistro.dados.id}
                                                    dados={parcelaRegistro.dados}
                                                    alterarDados={novosDados => {
                                                        parcelaRegistro.dados = novosDados;
                                                        alterarDadosPropostaParcelas([...dadosPropostaParcelas]);
                                                        atualizarValorBruto();
                                                    }}
                                                    alterouVencimento={() => {
                                                        ajustarVencimentoParcelasPosterior(parcelaRegistro);
                                                    }}
                                                    modo="proposta"
                                                />
                                            )}
                                        </Parcelas>
                                    </Card.Body>
                                </Card>

                                <Row>
                                    <Col lg={6}>
                                        <Card className='border-0 shadow-sm'>
                                            <Card.Body>
                                                <AnexosFicha 
                                                    anexos={anexosProposta}
                                                    alterar={alterarAnexosProposta}
                                                    colunas={1}
                                                    height={128}
                                                    salvando={salvando}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={6}>
                                        <Vigencia 
                                            dados={dadosProposta}
                                            alterarDados={alterarDadosProposta}
                                            dadosVigencia={dadosVigencia}
                                            alterarDadosVigencia={alterarDadosVigencia}
                                            dadosFormulario={dados}
                                            alterarDadosFormulario={alterarDados}
                                            erros={erros}
                                        />
                                    </Col>
                                </Row>

                            </Col>
                            <Col lg={3}>
                                <Card className='border-0 shadow-sm mb-3'>
                                    <Card.Body>
                                        <FormaPagamento 
                                            modo="proposta"
                                            dados={dadosProposta}
                                            alterarDados={alterarDadosProposta}
                                        />
                                    </Card.Body>
                                </Card>
                                
                                <Cancelamento 
                                    dados={dadosProposta}
                                    alterarDados={alterarDadosProposta}
                                    modo="proposta"
                                    desabilitar={dadosEmissao !== null ? true : false}
                                />
                            </Col>
                        </Row>

                    </>
                }
            </div>
        </ContainerComponent>
    </>

}