import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { toast } from 'react-toastify';
import axios from 'axios';

// componentes
import useIsMounted from 'ismounted';
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConteudoEmail from './../../ModelosEmail/ConteudoEmail';
import InfoAdicional from './../EscreverEmail/InfoAdicional';
import Tags from './../../Componentes/Tags';

// cria filtro
function EmailsMassa(){

    // pega os parametros da consulta de clientes
    const isMounted = useIsMounted();
    let history = useHistory();
    let location = useLocation();
    let login = useSelector(state => state.login);
    let [parametros, alterarParametros] = useState(null);
    let [dados, alterarDados] = useState({
        id: null,
        alvo_tabela: 'clientes',
        descricao: null,
        titulo: null,
        conteudo: null,
        responder_para: null,
        agendado_para_data: null,
        agendado_para_hora: null,
        padrao_novo: 'S',
        destaque: null,
        conteudo_corpo: null,
        id_imagem: null,
        // servidor_email_usar: 'servidorEmailMarketing' correto
    });
    let [salvando, alterarSalvando] = useState(false);

    // verifica se possui os parâmetros necessários para o envio em massa
    useEffect(() => {

        // se os estados não forem definidos, retorna para a página de consulta
        if(!location.state || !location.state.parametros){
            history.push('/painel/clientes');
        }else{
            alterarParametros(location.state.parametros);
        }
    }, []);

    // carrega modelo de email
    function carregarModeloEmailCliente(id){

        // envia email
        if(id === null){
            return;
        }

        // carrega os dados do modelo de email
        axios.get(`/modelosEmail/${id}`)
        .then(({data, ...retorno}) => {
            alterarDados(data.dados);
            dados.id_imagem = data.dados.id_imagem;
            dados.titulo = data.dados.titulo;
            dados.destaque = data.dados.destaque;
            dados.conteudo_corpo = data.dados.conteudo_corpo;
            alterarDados({...dados});
        })
        .catch(() => {})
        .then(() => {})
    }

    // salva
    function salvar(){
        alterarSalvando(true);

        // faz a requisição para salvar
        axios.post(`emailsEnviado/massa`, {
            parametrosConsulta: parametros,
            dados: dados
        })
        .then(({data, ...retorno}) => {

            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Emails enviados!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    <p
                        onClick={() => {
                            history.push('/painel/caixa-saida')
                            closeToast();
                        }}
                    >Clique para ver a caixa de saída</p>
                </div>
            </>);
            
            // mostra toast e retorna
            history.goBack();
        })
        .catch(() => {})
        .then(() => {
            alterarSalvando(false);
        });

    }


    return <>
        <Row>
            <Col md='8'>
                <Card className='border-0 shadow-sm'>
                    <Card.Header className='mb-0 border-0 bg-white'>
                        <Card.Title className='pb-0 mb-0'>Envio de emails em massa</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <ConteudoEmail 
                            value={{
                                id_imagem: dados.id_imagem,
                                titulo: dados.titulo,
                                destaque: dados.destaque,
                                conteudo_corpo: dados.conteudo_corpo
                            }}
                            onChange={dadosNovos => {
                                Object.assign(dados, dadosNovos);
                                alterarDados({...dados});
                            }}
                        />
                    </Card.Body>
                </Card>
                
                <SalvarFormulario 
                    salvando={salvando}
                    cancelar={() => {
                        history.goBack();
                    }}
                    onClick={() => salvar(dados)}
                />
            </Col>
            <Col md='4'>
                <Card className='border-0 shadow-sm mb-4'>
                    <Card.Body className='pb-0'>

                        <InfoAdicional 
                            dados={dados}
                            alterarDados={alterarDados}
                            alterouIdModelo={carregarModeloEmailCliente}
                        />
                    </Card.Body>
                </Card>
                <Card className='border-0 shadow-sm'>
                    <Card.Header className='mb-0 border-0 bg-white pb-0'>
                        <Card.Title className='mb-0' as='h5'>Tags</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Tags />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
}

// salvar
function SalvarFormulario({
    salvando,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Enviando</span>
                </> : 
                <>
                    <FontAwesomeIcon className="icone" icon={["fas", 'paper-plane']} />
                <span>Enviar</span>
                </>
            }
        </Button>
    </Form.Group>
}

// retorna página de consulta
export default EmailsMassa;
