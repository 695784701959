import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/lib/Async';
import { Form } from 'react-bootstrap';
import axios from 'axios';

// componentes
import OptionSelect from './OptionSelect';

// componente
export default function ComboModeloEmail({
    id=null, // id do modelo
    tipo=null,
    desabilitado=false,
    alterou
}){

    // estados
    const [consulta, alterarConsulta] = useState([]);
    const [carregando, alterarCarregando] = useState(true);
    const [dados, alterarDados] = useState(null);
    
    // consulta o id do modelo de email
    useEffect(() => {
        
        // carrega alguns
        consultar('');

        if(id) {
            consultarId();
        } else {
            alterarDados(null);
            alterarCarregando(false);
        }

    }, [id]);

    // consulta os responsaveis
    async function consultar(inputValue, callback = null){

        try {

            // faz a requisição
            const { data } = await axios.get(`/modelosEmail`, {
                params: {
                    texto: inputValue,
                    pagina: 1,
                    registrosPorPagina: 5,
                    tipo
                }
            });

            // retorno
            alterarConsulta(data.registros);
            if(callback !== null){
                callback(data.registros)
            }

        } catch({response}) {
            console.error(response);
        } finally {

        }
    }

    // consulta o id
    async function consultarId(){

        // carregando
        alterarCarregando(true);

        try {

            // faz a requisição
            const { data } = await axios.get(`/modelosEmail/${id}`);
            alterarDados(data.dados);
            alterarCarregando(false);

        } catch({ response }){
            console.error(response);
        }
    }

    return <AsyncSelect 
        isDisabled={carregando || desabilitado}
        isLoading={carregando}
        isClearable
        loadOptions={consultar}     // ao alterar, carrega
        defaultOptions={consulta}   // lista pré carregada
        value={dados && {
            label: dados.descricao
        }}  
        onChange={(e) => {

            if(e === null){
                alterarDados(null);
                alterou(null);
            }else{
                alterarDados({...e});
                alterou(e.id);
            }
        }}
        components={{
            Option: OptionSelect
        }}
        noOptionsMessage={() => 'Digite algo para fazer a consulta'}
        loadingMessage={() => 'Carregando...'}
        placeholder='Modelo de email'
    />

}
