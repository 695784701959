import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ConsultarContexto } from './../../../ConsultarContexto';
import BotaoTabela from './../../../../../Componentes/BotaoTabela';
import ModalConfirmar from './../../../../../Componentes/ModalConfirmar';

// exclui evento
export default function BotaoExcluir({
    id,
    funcaoCallback
}){

    // estados
    const [confirmar, alterarConfirmar] = useState(false);
    const { parametros, alterarParametros } = useContext(ConsultarContexto);
    let [salvando, alterarSalvando] = useState(false);


    // se confirma exclui
    async function excluir(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.delete(`/seguroAventura/erros/${id}`);
            alterarConfirmar(false);
            alterarParametros({...parametros});

            if(funcaoCallback){
                funcaoCallback()
            }

        }catch({response}){}
        finally{
            alterarSalvando(false);
        }


    }

    return <>
        <BotaoTabela 
            onClick={e => {
                alterarConfirmar(true);
            }}
            icone={["fa", "trash"]}
        >Excluir</BotaoTabela>

        <ModalConfirmar
            mostrar={confirmar}
            titulo={`Excluir erro participante`}
            texto={`Tem certeza que quer excluir o erro?`}
            textoBotaoConfirmar={'Sim, excluir'}
            clicouConfirmar={excluir}
            clicouCancelar={() => {
                alterarConfirmar(false)
            }}
            carregando={salvando}
        />
    </>

}