import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import axios from 'axios';
import uniqid from 'uniqid';

// importa contexto
import { FichaCadastrarContexto } from './../FichaCadastrarContexto';

// componentes
import { ContainerComponent } from './components';
import { Card, ButtonToolbar, OverlayTrigger, Button, Tooltip, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faHandsHelping, faPlus, faFileImport, faAngleRight, faAngleDown, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Custos, { CustosTotais, FormaPagamento, NumeroApolice } from './../CobrancaFicha/Custos';
import Parcelas, { Parcela, AgendarSms, ajustarLimiteParcelas, ajustarValorParcelas, ajustarVencimentoParcelas, calcularComissao, calcularValorLiquido } from './../CobrancaFicha/Parcelas';
import Cancelamento from './../CobrancaFicha/Cancelamento';
import AnexosFicha from './../AnexosFicha';


// componente
export default function Emissao({

}){

    // estados
    const { 
        dados, dadosProposta,
        dadosEmissao, alterarDadosEmissao, 
        dadosEmissaoParcelas, alterarDadosEmissaoParcelas,
        anexosEmissao, alterarAnexosEmissao,
        anexosProposta, dadosPropostaParcelas,
        agendarSmsEmissao, alterarAgendarSmsEmissao,
        salvando, erros
    } = useContext(FichaCadastrarContexto);
    const [desfazerExclusaoDisponivel, alterarDesfazerExclusaoDisponivel] = useState(false);
    const [minimizado, alterarMinimizado] = useState(true);
    const [valorBruto, alterarValorBruto] = useState('0.00');
    const [verificando, alterarVerificando] = useState(false);

    // inclui emissão zerada
    async function incluirEmissao(){

        let dadosInicial = {
            id: null,
            id_cliente_ficha: dados.id,
            id_seguradora: null,
            valor_impostos: '0.0',
            forma_pagamento: '1',
            comissao: '0.0',
            data_emitido: null,
            numero_apolice: '',
            juros: '0.0',
            adicional_fracionamento: '0.0',
            cancelado: 'N',
            data_cancelado: null,
            valor_extornado: '0.0',
            motivo_cancelado: null
        };
        let parcelasInicial = [];
        let anexosInicial = [];

        // se for uma ficha de edição, verifica se já possui uma proposta incluida
        if(dados.id !== null){
            alterarVerificando(true);

            try{

                // faz a requisição
                let { data } = await axios.get(`/fichas/${dados.id}`);
                if(data.emissao !== null){
                    dadosInicial = data.emissao;
                    parcelasInicial = data.emissaoParcelas;
                    anexosInicial = data.anexosEmissao;
                }
                alterarVerificando(false);
                

            }catch({response}){}
        }

        // altera
        alterarDadosEmissao(dadosInicial);
        alterarDadosEmissaoParcelas(parcelasInicial);
        alterarAnexosEmissao(anexosInicial);
        alterarMinimizado(false);
    }

    // copia proposta
    async function copiarProposta(){

        // copia os dados de emissão
        alterarDadosEmissao({
            ...dadosEmissao,
            id_seguradora: dadosProposta.id_seguradora,
            valor_impostos: dadosProposta.valor_impostos,
            forma_pagamento: dadosProposta.forma_pagamento,
            comissao: dadosProposta.comissao,
            juros: dadosProposta.juros,
            adicional_fracionamento: dadosProposta.adicional_fracionamento,
        });

        // copia as parcelas
        let valorBrutoNovo = 0.0;
        let parcelasNovas = dadosPropostaParcelas.map(parcelaNova => {
            valorBrutoNovo += parseFloat(parcelaNova.dados.valor);
            return {
                dados: {
                    id: null,
                    numero: parcelaNova.dados.numero,
                    valor: parcelaNova.dados.valor,
                    vencimento: parcelaNova.dados.vencimento,
                    valor_juros: parcelaNova.dados.valor_juros,
                    alterado_manualmente: 'N',
                    pago: 'N',
                    data_pago: null
                },
                temp_id: uniqid()
            }
        });
        alterarDadosEmissaoParcelas(parcelasNovas);
        alterarValorBruto(valorBrutoNovo.toFixed(2));

    }

    // exclui a emissão
    async function excluirEmissao(){
        alterarDadosEmissao(null);
        alterarMinimizado(true);
    }

    // ajusta quantidade de parcelas
    async function alterarQuantidadeParcelas(novoLimite){
        let novoArrayParcelas = ajustarLimiteParcelas('emissao', dadosEmissaoParcelas, novoLimite, valorBruto, dadosEmissao);
        alterarDadosEmissaoParcelas(novoArrayParcelas);
    }

    // após alterar o valor da parcela, ajusta o bruto
    async function atualizarValorBruto(){
        let novoValorBruto = 0.0;
        let novoValorImpostos = 0.0;
        dadosEmissaoParcelas.forEach(parcelaRegistro => {
            novoValorBruto += parseFloat(parcelaRegistro.dados.valor);
            novoValorImpostos += parseFloat(parcelaRegistro.dados.valor_juros);
        });
        dadosEmissao.valor_impostos = novoValorImpostos.toFixed(2);
        alterarDadosEmissao({...dadosEmissao});
        alterarValorBruto(novoValorBruto.toFixed(2));
    }

    // atualiza o vencimento das próximas parcelas a partir da parcela alterada
    async function ajustarVencimentoParcelasPosterior(parcelaVerificar){
        let novoArrayParcelas = ajustarVencimentoParcelas('emissao', dadosEmissaoParcelas, parcelaVerificar);
        alterarDadosEmissaoParcelas(novoArrayParcelas);
    }

    // recalcular valor das parcelas
    useEffect(() => {
        if(dadosEmissao !== null){
            let novoArrayParcelas = ajustarValorParcelas('emissao', dadosEmissaoParcelas, valorBruto, dadosEmissao);
            alterarDadosEmissaoParcelas(novoArrayParcelas);
        }
    }, [valorBruto, dadosEmissao && dadosEmissao.valor_impostos]);

    // se foi inserido uma proposta nova, insere uma parcela
    useEffect(() => {
        if(dadosEmissao !== null && dadosEmissaoParcelas.length == 0){
            alterarQuantidadeParcelas(1);
        }
    }, [dadosEmissao, dadosEmissaoParcelas]);

    // carregando a primeira vez, calcula o valor bruto somando os totais
    useEffect(() => {
        let valorBrutoAtualizar = 0.0;
        dadosEmissaoParcelas.forEach(parcela => {
            valorBrutoAtualizar += parseFloat(parcela.dados.valor);
        });
        alterarValorBruto(valorBrutoAtualizar.toFixed(2));
    }, []);


    return <>
        <ContainerComponent
            minimizado={minimizado}
        >
            <div className={'mr-2'}>
                { dadosEmissao !== null && 
                    <>
                        <div className="mb-2">
                            <ButtonToolbar>
                                <OverlayTrigger
                                    placement='top'
                                    overlay={<Tooltip>Excluir Emissão</Tooltip>}
                                >
                                    <Button
                                        variant='padrao'
                                        size='sm'
                                        onClick={excluirEmissao}
                                        className='rounded-circle'
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </OverlayTrigger>
                            </ButtonToolbar>
                        </div>

                        <ButtonToolbar>
                            <OverlayTrigger
                                placement='right'
                                overlay={<Tooltip>Copiar Proposta</Tooltip>}
                            >
                                <Button
                                    variant='padrao'
                                    size='sm'
                                    onClick={copiarProposta}
                                    className='rounded-circle'
                                >
                                    <FontAwesomeIcon icon={faFileImport} />
                                </Button>
                            </OverlayTrigger>
                        </ButtonToolbar>
                    </>
                }

                { dadosEmissao == null && 
                    <ButtonToolbar>
                        <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip>Incluir Emissao</Tooltip>}
                        >
                            <Button
                                variant='padrao'
                                size='sm'
                                onClick={incluirEmissao}
                                className='rounded-circle'
                                disabled={dadosProposta === null || anexosProposta.length == 0 || dadosProposta.cancelado === 'S'}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </OverlayTrigger>
                    </ButtonToolbar>
                }

            </div>

            <div className="flex-grow-1 emissao">
                <p className="m-0 text-secondary mb-1">
                    <FontAwesomeIcon icon={faHandsHelping} /> 
                    <span className="mx-2">Emissão</span>
                    {dadosEmissao !== null &&
                        <>
                            { minimizado ? 
                                <FontAwesomeIcon className="minimizar" icon={faAngleRight} onClick={() => {alterarMinimizado(false)}} />
                                :
                                <FontAwesomeIcon className="minimizar" icon={faAngleDown} onClick={() => {alterarMinimizado(true)}} />
                            }
                        </>
                    }

                    { (verificando) &&
                        <small>
                            <FontAwesomeIcon icon={faSpinner} pulse /> Verificando
                        </small>
                    }
                </p>
                { (dadosEmissao !== null && !minimizado) && 
                    <>
                        <Row>
                            <Col lg={9}>
                                <Row>
                                    <Col lg={9}>
                                        <Card className='border-0 shadow-sm mb-3'>
                                            <Card.Body>
                                                <Custos 
                                                    dados={dadosEmissao}
                                                    alterarDados={alterarDadosEmissao}
                                                    quantidadeParcelas={dadosEmissaoParcelas.length}
                                                    alterarQuantidadeParcelas={alterarQuantidadeParcelas}
                                                    valorBruto={valorBruto} 
                                                    alterarValorBruto={alterarValorBruto}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={3}>
                                        <Card className='border-0 shadow-sm mb-3'>
                                            <Card.Body>
                                                <CustosTotais 
                                                    comissao={calcularComissao('emissao', valorBruto, dadosEmissao)}
                                                    liquido={calcularValorLiquido('emissao', valorBruto, dadosEmissao)}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>

                                <Card className='border-0 shadow-sm mb-3'>
                                    <Card.Body>
                                        <Parcelas>
                                            {dadosEmissaoParcelas.map(parcelaRegistro => 
                                                <Parcela 
                                                    key={parcelaRegistro.temp_id || parcelaRegistro.dados.id}
                                                    dados={parcelaRegistro.dados}
                                                    statusSms={parcelaRegistro.statusSms}
                                                    alterarDados={novosDados => {
                                                        parcelaRegistro.dados = novosDados;
                                                        alterarDadosEmissaoParcelas([...dadosEmissaoParcelas]);
                                                        atualizarValorBruto();
                                                    }}
                                                    alterouVencimento={() => {
                                                        ajustarVencimentoParcelasPosterior(parcelaRegistro);
                                                    }}
                                                />
                                            )}
                                        </Parcelas>

                                        <AgendarSms 
                                            dados={agendarSmsEmissao}
                                            alterarDados={alterarAgendarSmsEmissao}
                                        />
                                    </Card.Body>
                                </Card>

                                <Row>
                                    <Col lg={6}>
                                        <Card className='border-0 shadow-sm'>
                                            <Card.Body>
                                                <AnexosFicha 
                                                    anexos={anexosEmissao}
                                                    alterar={alterarAnexosEmissao}
                                                    colunas={1}
                                                    height={80}
                                                    salvando={salvando}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={6}>
                                        <Card className='border-0 shadow-sm mb-3'>
                                            <Card.Body>
                                                <NumeroApolice 
                                                    dados={dadosEmissao}
                                                    alterarDados={alterarDadosEmissao}
                                                    erros={erros}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={3}>
                                <Card className='border-0 shadow-sm mb-3'>
                                    <Card.Body>
                                        <FormaPagamento 
                                            modo="emissao"
                                            dados={dadosEmissao}
                                            alterarDados={alterarDadosEmissao}
                                            erros={erros}
                                        />
                                    </Card.Body>
                                </Card>

                                <Cancelamento 
                                    dados={dadosEmissao}
                                    alterarDados={alterarDadosEmissao}
                                />
                            </Col>
                        </Row>

                    </>
                }
            </div>
        </ContainerComponent>
    </>

}