import React, { useState, useContext } from 'react';
import axios from 'axios';

// componentes
import { faCog, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ComboModeloEmail from './../../Componentes/ComboModeloEmail';
import { ConfiguracaoSistemaContexto } from './../ConfiguracaoSistemaContexto';

// configura email de cancelamento de participante
export default function ConfiguraEmailSeguroAventuraCancelamento(){

    // estados
    const [salvando, alterarSalvando] = useState(false);
    const { chaves, alterarChaves } = useContext(ConfiguracaoSistemaContexto);

    // salva valor novo
    async function salvar(idNovo){
        alterarSalvando(true);
        try{

            let { data } = await axios.put(`/configuracaoSistema/modeloEmailSeguroAventuraParticipanteCancelamento`, {
                valor: idNovo
            });

        }catch({response}){}
        finally{
            alterarSalvando(false);
        }
    }

    return <div
        className="d-flex align-items-start"
    >
        <div className="flex-grow-1 p-1">
            Modelo de email utilizado para quando o participante do seguro de aventura é cancelado
        </div>
        <div style={{width: '300px'}}>
            <ComboModeloEmail 
                id={chaves.modeloEmailSeguroAventuraParticipanteCancelamento}
                alterou={idNovo => {
                    salvar(idNovo);
                }}
                tipo={3}
            />
        </div>
        <div className="carregador p-2">
            <FontAwesomeIcon className={[salvando ? 'text-success' : 'text-light'].join(` `)} icon={salvando ? faSpinner : faCog} pulse={salvando} />
        </div>
    </div>

}