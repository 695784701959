import React, { useState, useEffect } from 'react';
// import styes from './index.module.scss';
import axios from 'axios';
import moment from 'moment';

// componentes
import GraficoSeguradorasFunil from './../FunilVendas/GraficoSeguradorasFunil';
import RegistroEmissao from './../FunilVendas/RegistroEmissao';
import styles from './../FunilVendas/index.module.scss';
import { Row, Col, Card } from 'react-bootstrap';
import PaginacaoComponente from './../../Componentes/Paginacao';
import ComboResponsavel from './../../Componentes/ComboResponsavel';

// todas as fichas vigêntes
export default function Vigente(){

    // estados
    const [carregando, alterarCarregando] = useState(true);
    const [consulta, alterarConsulta] = useState([]);
    const [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10,
        data: moment().format('YYYY-MM-DD'),
        id_responsavel: null
    });
    const [totalRegistros, atualizarTotalRegistros] = useState(0);

    // faz a requisição
    useEffect(() => {
        consultarRegistros();
    }, [parametros]);

    // faz a consulta
    async function consultarRegistros(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/relatorios/vigente`, { params: parametros });
            alterarConsulta(data);
            atualizarTotalRegistros(parseInt(data.totalRegistros));

        }catch({response}){}
        finally{
            alterarCarregando(false);
        }

    }

    // carregando
    if(carregando){
        return <>Carregando</>
    }

    return <>
        <Row>
            <Col lg={8}>

                <Card className='border-0 shadow-sm mb-4'>
                    <Card.Body>
                        <div className={'styles.registros'}>
                            {consulta.registros.map(registro => 
                                <RegistroEmissao 
                                    key={registro.dados.id}
                                    dados={registro.dados}
                                    cliente={registro.cliente}
                                    emissao={registro.emissao}
                                    parcelasEmissao={registro.parcelasEmissao}
                                    className={styles.registro}
                                    etapaConsulta={'emitido'}
                                    vigencia={true}
                                />
                            )}
                        </div>
                        <PaginacaoComponente
                            pagina={parametros.pagina}
                            registrosPorPagina={parametros.registrosPorPagina}
                            totalRegistros={totalRegistros}
                            alterouPagina={(pagina) => {
                                alterarParametros({...parametros, pagina: pagina});
                            }}
                        />
                    </Card.Body>
                </Card>
            
            </Col>
            <Col lg={4}>
                <Card
                    className={'border-0 shadow-sm mb-3'}
                >
                    <Card.Body
                        className={'p-1'}
                    >
                        <div
                            className='d-flex'
                        >
                            <div
                                className='flex-grow-1 mr-2'
                            >
                                <ComboResponsavel 
                                    id={parametros.id_responsavel}
                                    alterou={idNovo => {
                                        parametros.id_responsavel = idNovo;
                                        alterarParametros({...parametros});
                                    }}
                                />
                            </div>
                        </div>
                    </Card.Body>
                </Card>

                <Card className='border-0 shadow-sm mb-4'>
                    <Card.Body>
                        <small>Emissões por seguradoras</small>
                        <GraficoSeguradorasFunil 
                            registros={consulta.seguradoras}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>

    </>

}