import React, {useState, useEffect} from 'react';
import moment from 'moment';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Badge } from 'react-bootstrap';

// funçõs
import { converterReal } from './../../../../Funcoes';

// registro da emissão
function RegistroEmissao({
    dados,
    cliente,
    emissao,
    parcelasEmissao,
    className,
    etapaConsulta,
    vigencia=false
}){

    // estados
    let [total, alterarTotal] = useState(0.0);
    let [totalLiquido, alterarTotalLiquido] = useState(0.0);
    let [mais, alterarMais] = useState(false);

    // calcula totais
    useEffect(() => {
        let total = 0.0;

        // faz a somatória
        parcelasEmissao.forEach(parcela => {
            total += parseFloat(parcela.valor);
        });

        // outros valores
        let juros = parseFloat(emissao.juros);
        let valor_impostos = parseFloat(emissao.valor_impostos);
        let adicional_fracionamento = parseFloat(emissao.adicional_fracionamento);

        // altera os totais
        alterarTotal(total);
        alterarTotalLiquido(total - juros - valor_impostos - adicional_fracionamento);

    }, []);

    return <div className={[className, 'mb-3 pb-2'].join(' ')}>
        <p className='mb-0'>
            <Badge
                variant={etapaConsulta === 'emitidoCancelado' ? 'danger' : 'success'}
            >
                { etapaConsulta === 'emitidoCancelado' ?
                    <>
                        <FontAwesomeIcon title="Cancelado em" className='icone mr-2' icon={['fas', 'calendar-times']} />
                        {moment(emissao.data_cancelado).format('DD')}
                    </>
                    :
                    <>
                        <FontAwesomeIcon title="Emitido em" className='icone mr-2' icon={['fas', 'calendar-check']} />
                        {vigencia ?
                            moment(emissao.data_emitido).format('DD/MM/YYYY')
                            :
                            moment(emissao.data_emitido).format('DD')
                        }
                        
                    </>
                }
            </Badge>
            <FontAwesomeIcon className='icone mx-2' icon={['fas', 'user']} /> 
            {cliente.nome}
            <small title='Abrir ficha de atendimento em nova aba!'>
                <a href={`/painel/fichas/${dados.id_cliente}/alterar/${dados.id}`} target="_blank">
                    <FontAwesomeIcon className='icone mx-2' icon={['fas', 'external-link-alt']} /> 
                </a>
            </small>
        </p>
        <p className='m-0'>{dados.titulo}</p>
        <Row className='mt-2'>
            <Col md='6'>
                {dados.data_vigencia &&
                    <small>
                        <span>Vigência <b>{moment(dados.data_vigencia).format('DD/MM/YYYY')}</b></span>
                    </small>
                }
            </Col>
            <Col md='6' className='text-right'>
                {/* <small>Total</small> */}
                <p className='m-0' title='Total liquido'>
                    <b>R$ {converterReal(totalLiquido)}</b>
                    { !mais &&
                        <Button
                            className='ml-2'
                            size='sm'
                            variant='warning'
                            onClick={() => {alterarMais(true)}}
                            title='Mais valores'
                        >
                            <FontAwesomeIcon className='icone' icon={['fas', 'plus']} /> 
                        </Button>
                    }
                </p>
            </Col>
        </Row>
        { mais &&
            <>
                <Row>
                    <Col>
                        <p className='m-0'>Bruto</p>
                        <b>R$ {converterReal(total)}</b>
                    </Col>
                    <Col>
                        <p className='m-0'>Juros</p>
                        <b>R$ {converterReal(emissao.juros)}</b>
                    </Col>
                    <Col>
                        <p className='m-0'>Impostos</p>
                        <b>R$ {converterReal(emissao.valor_impostos)}</b>
                    </Col>
                    <Col>
                        <p className='m-0 text-nowrap'>Ad. Fracionamento</p>
                        <b>R$ {converterReal(emissao.adicional_fracionamento)}</b>
                    </Col>
                </Row>
            </>
        }
        
    </div>
}

export default RegistroEmissao;