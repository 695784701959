import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {ModalConfirmarComponent} from './components';
import styles from './index.module.scss';

// exclui evento
export default function ModalConfirmar({
    mostrar,
    titulo,
    texto,
    clicouCancelar,
    clicouConfirmar,
    textoBotaoCancelar,
    textoBotaoConfirmar,
    textoCarregando,
    carregando=false
}){

    return <>
        <ModalConfirmarComponent 
            show={mostrar} 
            onExited={() => {}}
            onHide={() => {}}
            size={'sm'}
            backdropClassName={[styles.modalBackdrop, 'teste'].join(' ')}
        >
            <ModalConfirmarComponent.Header className='border-0'>
                <ModalConfirmarComponent.Title>
                    {titulo}
                </ModalConfirmarComponent.Title>
            </ModalConfirmarComponent.Header>
            <ModalConfirmarComponent.Body>
                <p className="text-center">{texto}</p>
            </ModalConfirmarComponent.Body>
            <ModalConfirmarComponent.Footer className='border-0'>
                <Button
                    variant='padrao'
                    onClick={clicouCancelar}
                    disabled={carregando}
                >
                    <span>{textoBotaoCancelar || 'Cancelar'}</span>
                </Button>
                <Button
                    onClick={clicouConfirmar}
                    variant='success'
                    disabled={carregando}
                >
                    {carregando ? 
                        <>
                            <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                            <span>{textoCarregando || `Carregando`}</span>
                        </> : 
                        <span>{textoBotaoConfirmar || 'Confirmar'}</span>
                    }
                </Button>
            </ModalConfirmarComponent.Footer>
        </ModalConfirmarComponent>
    </>

}