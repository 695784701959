import styled from 'styled-components';

export const ContainerComponent = styled.div.attrs({
    className: `mt-3`
})`

    display: flex;

    button {
        ${props => props.salvando && `
            visibility: hidden !important;
        `}
    }
    
    .coluna-eventos {
        width: calc(100% - 40px);
    }

    .eventos {
        max-height: 200px;
        overflow-y: scroll;
        padding-right: 4px;

        &::-webkit-scrollbar-track{
            box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            border-radius: 10px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar{
            width: 8px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb{
            border-radius: 10px;
            box-shadow: inset 0 0 6px rgba(0,0,0,.2);
            background-color: #848484;
        }

        .eventos-container {
            column-count: 6;
            grid-column-gap: 0px;
            column-gap: 0px;
            box-sizing: inherit;
            position: relative;

        }
    }

`;

// evento
export const EventoComponent = styled.div.attrs({
    className: ``
})`


    width: 100%;
    height: auto;
    padding: 1px 5px;
    padding-bottom: 9px;
    break-inside: avoid-column;
    -webkit-column-break-inside: avoid;
    position: relative;

    .card {
        padding: 1px 5px;
        position: relative;

        ${props => props.novo &&  `
            background: #87f987;
        `}

        ${props => props.remover &&  `
            background: #ff8383;
        `}

        ${props => props.automatico && `
            box-shadow: 1px 1px 3px #dac4ff;
        `}

        .cabecalho {
            display: flex;
            font-size: 0.7rem;
            align-items: flex-end;
            border-bottom: 1px solid #d2d2d2;
            margin-bottom: 4px;
            padding-bottom: 4px;

            ${props => props.novo &&  `
                    border-bottom: 1px solid #949494;
            `}

            ${props => props.remover &&  `
                    border-bottom: 1px solid #ff8383;
            `}

            span {
                flex-grow: 1;
                color: grey;
            }

            .icone-concluido {
                color: green;
            }

            div {
                display: flex;
                align-items: center;

                button {
                    color: grey;
                    padding: 0;
                    font-size: 0.7rem;
                    padding: 3px;
                }
            }
        }

        .descricao {
            font-size: 0.8rem;
            margin: 0;
        }

        .concluido {
            position: absolute;
            color: #2fb72f;
            bottom: -10px;
            right: -8px;
            transform: rotate(-13deg);
            font-size: 1.8rem;
        }
    }




`;