import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

// componentes
import { useHistory } from 'react-router-dom';
import { Card, Col, Row, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carregador from './../../Componentes/Carregador';
import PaginacaoComponente from './../../Componentes/Paginacao';
import FiltroDataCabecalho from './../FunilVendas/FiltroDataCabecalho'
import Registro from './Registro';

// componente
export default function RelatorioParcelas(){

    //variaveis
    const history = useHistory();
    let [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10,
        pago: ['N'],
        vencimento_ano: moment().format('YYYY'),
        vencimento_mes: moment().format('MM')
    });
    let [data, alterarData] = useState(moment().format('YYYY-MM-DD'));
    let [totalRegistros, atualizarTotalRegistros] = useState(0);
    let [carregando, mostrarCarregando] = useState(false);
    let [consulta, alterarConsulta] = useState([]);

    // se os parametros forem alterados, faz a consulta
    useEffect(() => {
        mostrarCarregando(true);

        // faz a requisição
        axios.get(`/fichasEmissao/parcelas`, {
            params: parametros
        })
        .then(({data, ...retorno}) => {
            alterarConsulta(data.registros);
            atualizarTotalRegistros(parseInt(data.totalRegistros));
        })
        .catch(() => {})
        .then(() => {
            mostrarCarregando(false);
        });

    }, [parametros]);

    return  <>
        <FiltroDataCabecalho 
            value={data}
            onChange={novaData => {
                parametros.vencimento_ano = moment(novaData).format('YYYY');
                parametros.vencimento_mes = moment(novaData).format('MM');
                alterarData(novaData);
                alterarParametros({...parametros});
            }}
            carregando={carregando}
        />
        <Card className="painel-consulta border-0 shadow-sm" >
            <Card.Body>

                <table className="table table-hover tabela">
                    <thead>
                        <tr>
                            <th className={'text-center'}><FontAwesomeIcon className="icone" icon={["fas", "calendar-day"]} /></th>
                            <th></th>
                            <th>Juros</th>
                            <th>Valor</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        { consulta.map(registro => 
                            <Registro 
                                key={registro.dados.id}
                                dados={registro.dados}
                                ficha={registro.ficha}
                                cliente={registro.cliente}
                                status={registro.status}

                                abrirFicha={() => {
                                    history.push(`/painel/fichas/${registro.cliente.id}/alterar/${registro.ficha.id}`);
                                }}
                            />
                        )}
                    </tbody>
                </table>


                <PaginacaoComponente
                    pagina={parametros.pagina}
                    registrosPorPagina={parametros.registrosPorPagina}
                    totalRegistros={totalRegistros}
                    alterouPagina={(pagina) => {
                        alterarParametros({...parametros, pagina: pagina});
                    }}
                />
                {carregando && 
                    <Carregador className="rounded-bottom" />
                }
            </Card.Body>
        </Card>
    </>


};