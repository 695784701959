import React from 'react';
import logoRoca2 from './../../imagensRoca/logoRoca2.png';

// componentes
import { Navbar, NavDropdown, Form, FormControl, Button, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Cabecalho({

}){


    return <Navbar bg="light" expand="lg">
        <Navbar.Brand><a className='nav-link' href='https://app.talksup.com.br/roca' target="_blank">Roca Atendimento</a></Navbar.Brand>
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
                <Link className='nav-link' to='/meuseguro/apolice'>Apolice</Link>
            </Nav>
            {/* <Nav className="">
                <Link className='nav-link' to='/meuseguro/fianca-cliente'>Fiança Cliente</Link>
            </Nav>
            <Nav className="">
                <Link className='nav-link' to='/meuseguro/fianca-imobiliaria'>Fiança Imobiliaria</Link>
            </Nav> */}
        </Navbar.Collapse>

        

        <Navbar.Brand>
            <img src={logoRoca2} style={{maxHeight: '45px'}} />
        </Navbar.Brand>
    </Navbar>
}

export default Cabecalho;