import React from 'react';
import styles from './index.module.scss';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// importa imagens do componente original
import IconeDoc from './../../../../Painel/Componentes/Anexos/Arquivo/icones/doc.png';

// retorna componente de arquivo
function Arquivo({
    arquivo
}){

    return <div className={[styles.arquivo , 'p-2 rounded'].join(' ')}>

    { arquivo.nome_temporario.split('.').pop() === 'jpg' ?
        <img src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/anexos/${arquivo.nome_temporario}`} className={[styles.icone, 'mr-2'].join(' ')} />
        :
        <img src={IconeDoc} className={[styles.icone, 'mr-2'].join(' ')} />
    }

    <div className={styles.descricao}>
        <div title={arquivo.nome} className={[styles.nome, 'text-truncate'].join(' ')}>{arquivo.nome}</div>
        <div><small className='text-secondary'><Moment format='DD/MM/YYYY'>{arquivo.data_cadastro}</Moment></small></div>
        <div className={[styles.opcoesArquivo, 'p-2'].join(' ')}>

            <OverlayTrigger
                placement='top'
                overlay={<Tooltip>Abrir Arquivo</Tooltip>}
            >
                <a 
                    href={`${process.env.REACT_APP_URL_ARQUIVOS}/download.php/${encodeURIComponent(arquivo.nome)}?p=anexos&f=${encodeURIComponent(arquivo.nome_temporario)}&n=${encodeURIComponent(arquivo.nome)}`} 
                    target="_blank" 
                    download={true}
                    className={[styles.opcao, 'rounded ml-1 pb-1 px-1'].join(' ')}
                >
                    <FontAwesomeIcon className={styles.iconeOpcao} icon={["fas", 'download']} />
                </a>
            </OverlayTrigger>
            
        </div>
    </div>
</div>
}

export default Arquivo;