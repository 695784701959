import styled from 'styled-components';

// componente
export const AnexosFichaComponent = styled.div.attrs({
    className: ``
})`


    ${props => props.height !== null && `
        height: ${props.height}px;
        max-height: ${props.height}px;
        padding-right: 8px;
        overflow-y: scroll;
    `}

    button {
        ${props => props.salvando && `
            visibility: hidden !important;
        `}
    }

    &::-webkit-scrollbar-track{
        box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar{
        width: 8px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0,0,0,.2);
        background-color: #848484;
    }

    .botao-upload {
        position: absolute;
        top: -10px;
        left: -10px;

        &:disabled {
            opacity: 0.6;
        }
    }

    .container-anexos {
        column-count: ${props => props.colunas};
        grid-column-gap: 0px;
        column-gap: 0px;
        box-sizing: inherit;
        position: relative;
        column-width: 0;
    }


`
// anexo
export const AnexoComponent = styled.div.attrs({
    className: ``
})`

    width: 100%;
    height: auto;
    padding: 1px 5px;
    padding-bottom: 9px;
    break-inside: avoid-column;
    -webkit-column-break-inside: avoid;
    position: relative;

    button {
        ${props => props.salvando && `
            visibility: hidden !important;
        `}
    }

    .anexo-conteudo {
        display: inline-flex;
        align-items: center;
        width: 100%;

        ${props => props.remover && `
            opacity: 0.7;
        `}

        .icone {
            align-self: flex-start;
            
            img {
                width: 30px;
                height: 30px;
            }

            i {

            }
        }

        .dados {
            flex-grow: 1;
            width: calc(100% - 60px);

            .nome {
                font-size: 0.8rem;
            }

            .info {
                font-size: 0.7rem;
                ${props => props.upload && `display: none;`}
            }

            .opcoes {
                display: flex; 
                align-items: center;

                .barra-upload {
                    flex-grow: 1;
                    ${props => !props.upload && `display: none;`}
                }

                .botoes {
                    margin-top: -15px;
                    ${props => !props.upload && `flex-grow: 1;`}


                    button, a {
                        padding: 1px 2px;
                        font-size: 0.8rem;


                        &.botao-download {
                            ${props => props.upload && `display: none;`}
                        }
                    }

                }

            }

        }
    }




    

`