import React from 'react';
import styles from './index.module.scss';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';

// componente
function Abas({
    value,
    onChange,
    modo,
    modoUsuario,
    permissao
}){
    

    return <>
        <div className={[styles.abas, 'mb-2'].join(' ')}>

            <div className={styles.abasCliente}>

                <BotaoAba 
                    ativo={value === 'cliente'}
                    onClick={() => {onChange('cliente')}}
                    icone={["fas", modoUsuario ? 'user-tie' : 'user']}
                >
                    { modoUsuario ? 'Usuário' : 'Cliente' }
                </BotaoAba>

                
                { (parseInt(permissao.clienteAnexos) > 1) &&
                    <BotaoAba 
                        ativo={value === 'anexos'}
                        // disabled={modo === 'cadastro'}
                        onClick={() => {onChange('anexos')}}
                        icone={["fas", 'cloud']}
                    >
                        Anexos
                    </BotaoAba>
                }

                <BotaoAba 
                    ativo={value === 'eventos'}
                    // disabled={modo === 'cadastro'}
                    onClick={() => {onChange('eventos')}}
                    icone={["fas", 'thumbtack']}
                >
                    Eventos
                </BotaoAba>
                
                <BotaoAba 
                    ativo={value === 'fichas'}
                    disabled={modo === 'cadastro'}
                    onClick={() => {onChange('fichas')}}
                    icone={["fas", 'clipboard-list']}
                >
                    Fichas
                </BotaoAba>

                <BotaoAba 
                    ativo={value === 'apolices'}
                    disabled={modo === 'cadastro'}
                    onClick={() => {onChange('apolices')}}
                    icone={["fas", 'clipboard-list']}
                >
                    Apólices
                </BotaoAba>
                
                <BotaoAba 
                    ativo={value === 'sms'}
                    disabled={modo === 'cadastro'}
                    onClick={() => {onChange('sms')}}
                    icone={["fas", 'sms']}
                >
                    Sms
                </BotaoAba>

            </div>
            <div className={styles.abasUsuario}>
                {/* <BotaoAba 
                    ativo={value === 'permissoes'}
                    disabled={modo === 'cadastro'}
                    onClick={() => {onChange('permissoes')}}
                    icone={["fas", 'user-lock']}
                >
                    Permissões
                </BotaoAba> */}
            </div>

            
        </div>
    </>
}

function BotaoAba({
    ativo,
    disabled,
    onClick,
    icone,
    children
}){


    return <button 
        size='sm'
        variant="padrao"
        className={[styles.aba, ativo && styles.ativo, 'mr-1'].join(' ')}
        disabled={disabled}
        onClick={onClick}
    >
        <FontAwesomeIcon className={[styles.icone, 'mr-1'].join(' ')} icon={icone} />
        <span>{children}</span>
    </button>
}

export default Abas;