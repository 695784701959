import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import moment from 'moment';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Button, Modal, Row, Col, Container, Form } from 'react-bootstrap';
import InputHora from './../../../Componentes/InputHora';

function Horario({
    dados,
    alterou,
    excluir
}){

    return <div className='mb-2 bg-light rounded p-2 d-flex align-items-center'>
        { dados.id === null ?
            <FontAwesomeIcon className="icone mr-2" icon={["fas", 'plus']} />
            :
            <FontAwesomeIcon className="icone mr-2" icon={["far", 'clock']} />
        }

        <div className='flex-grow-1'>
            <InputHora 
                value={moment(dados.data_batida).format('HH:mm:ss')}
                aceitarNull={false}
                onChange={novaHora => {
                    console.log(novaHora);
                    dados.data_batida = `${moment(dados.data_batida).format('YYYY-MM-DD')} ${novaHora}`;
                    alterou();
                }}
                className={[styles.hora, 'text-center form-control-sm'].join(' ')}
            />
        </div>

        <Button
            variant='padrao'
            className='ml-2'
            size='sm'
            onClick={excluir}
        >
            <FontAwesomeIcon className="icone" icon={["fas", 'trash']} />
        </Button>
    </div>
}

export default Horario;