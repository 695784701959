
// define reducer de painel
export default function(state = Object.assign({
    dados: {},
    configuracoesSistema: {},
    dispositivo: null,
    ponto: null
}, JSON.parse(window.localStorage.getItem('painel'))), action){

    // se a ação não for para esse reducer
    switch(action.type){
        case 'painelReducer/CONFIGURACOES_SISTEMA':
            let dadosAtualizar =  Object.assign({}, state, {
                configuracoesSistema: action.configuracoesSistema
            });
            window.localStorage.setItem('painel', JSON.stringify(dadosAtualizar));
            return Object.assign({}, state, dadosAtualizar);
        case 'painelReducer/DISPOSITIVO':
            let dadosDispositivoAtualizar = Object.assign({}, state, {
                dispositivo: action.dispositivo,
                ponto: action.ponto
            });
            window.localStorage.setItem('painel', JSON.stringify(dadosDispositivoAtualizar));
            return Object.assign({}, state, dadosDispositivoAtualizar);
        case 'painelReducer/ATUALIZARPONTO':
            let dadosPontoAtualizar = Object.assign({}, state, {
                ponto: action.ponto
            });
            window.localStorage.setItem('painel', JSON.stringify(dadosPontoAtualizar));
            return Object.assign({}, state, dadosPontoAtualizar);
        default:
            return state;
    }
}