import React, { useState, useEffect } from 'react';

// componentes
import Select from 'react-select';
import OptionSelect from './OptionSelect';

// componente
function ComboTipoCliente({
    value,
    onChange
}){

    // estados
    let [consulta] = useState([
        {value: '1', label: 'Colaborador'},
        {value: '2', label: 'Produtor'},
        {value: '3', label: 'Cliente'},
        {value: '4', label: 'Fornecedor'},
        {value: '5', label: 'Parceiro'},
    ]);
    let [tiposClientes, alterarTiposClientes] = useState([]);

    // ao alterar, retorna somente valores
    useEffect(() => {
        onChange(tiposClientes.map(t => t.value));
    }, [tiposClientes]);

    return <Select 
        isMulti
        isClearable
        options={consulta}
        components={{
            Option: OptionSelect
        }}
        value={tiposClientes}
        onChange={(evento, validar) => {

            // verifica ações
            if(validar.action === 'pop-value'){
                tiposClientes.pop();
                alterarTiposClientes([...tiposClientes]);
            }else if(validar.action === 'remove-value'){
                alterarTiposClientes(tiposClientes.filter((tipo, a) => {
                    if(tipo.value === validar.removedValue.value){
                        return false;
                    }
                    return true
                }));
            }else if(validar.action === 'clear'){
                alterarTiposClientes([]);
            }else if(validar.action === 'select-option'){
                alterarTiposClientes([...tiposClientes, validar.option]);
            }
        }}
        noOptionsMessage={() => 'Digite algo para fazer a consulta'}
        placeholder='Tipos de cliente'
    />
}


export default ComboTipoCliente;