import { combineReducers } from 'redux';
import { withReduxStateSync } from 'redux-state-sync';

// importa reducers e actions
import LoginReducer from './paginas/Login/LoginReducer';
import PainelReducer from './paginas/Painel/PainelReducer';
import dadosCartaOferta from './paginas/Atendimento/SeguroAventura/Consultar/ConsultarReducer';

// unifica reducers
let rootReducer  = combineReducers({
    login: LoginReducer,
    painel: PainelReducer,
    cartaOferta: dadosCartaOferta
});

// retorna reducer global
export default withReduxStateSync(rootReducer);