import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as R from 'ramda';
import styles from './index.module.scss';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Card, Row, Col, Form, Button, Badge } from 'react-bootstrap';
import Participante from './Participante';
import { toast } from 'react-toastify';
import Formulario from './Formulario';

import logoTipoAventura from './../../../assets/rocaSeguroAventura.png';

// componente
export default function FormularioProposta({

}){

    // estados
    const dadosDefault = {
        id: null,
        codigoEvento: null,
        cartaOferta: null,
        nomeEmpresa: '',
        cnpj: '',
        nomeEvento: '',
        descricaoEvento: null,
        localEvento: null,
        dataInicio: null,
        horaInicio: null,
        dataFinal: null,
        horaFinal: null,
        responsavel: '',
        telefoneCelular: '',
        email: '',
        participantes: "[]",
        inscricaoFinalizada: 'N', // N S
        emailFinalizadoEnviado: 'N',
        dataCadastro: null,
        eventoCancelado: 'N',
        motivoCancelado: null,
        bloqueado: 'N'
    };
    const [dados, alterarDados] = useState({...dadosDefault});
    const [salvando, alterarSalvando] = useState(false);
    const [erros, alterarErros] = useState([]);
    const [modo, alterarModo] = useState(null); // cadastro, edicao
    const [mostrarCampoCodigo, alterarMostrarCampoCodigo] = useState(true);
    const [cancelarEvento, alterarCancelarEvento] = useState(false);
    const [participantes, alterarParticipantes] = useState([]);
    const [codigoEvento, alterarCodigoEvento] = useState('');
    const [mostrarCancelar, alterarMostrarCancelar] = useState(false);
    const [codigoEventoCancelar, alterarCodigoEventoCancelar] = useState(null);

    // adicionar participante
    function adicionarParticipante(){

        let numeroInscricao = (participantes.length + 1).toString().padStart(3, '0');

        // insere participante
        participantes.push({
            numeroInscricao: numeroInscricao,
            nome: null,
            email: null,
            cpf: null,
            telefone: null,
            dataNascimento: null,
            genero: 'M'
        });
        alterarParticipantes([...participantes]);
    }

    // remove o participante
    function removerParticipante(pos){
        let participantesNovoArray = R.clone(participantes);
        participantesNovoArray.splice(pos, 1);
        alterarParticipantes(participantesNovoArray);
    }

    // salva proposta
    async function salvar(){
        alterarErros([]);
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/formularioAcidentesPessoais`, { dados: dados });
            
            
            // altera os dados de retorno
            toast(({closeToast }) => <>
                <div className="toast-header d-block">
                    <strong className="mr-auto">Proposta salva com sucesso!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    { modo === 'cadastro' &&
                        <div className="toast-body">
                            { data.dados.inscricaoFinalizada === 'N' ?
                                <>
                                    Você receberá um email com o seu código de solicitação caso queira alterar.<br/>
                                    Código da solicitação <Badge variant='success'>{data.dados.codigoEvento}</Badge>
                                </>
                                :
                                <>
                                    Inscrição finalizada!
                                </>
                            }
                        </div>
                    }
                </div>
            </>);

            // se finalizado volta para a tela anterior
            if(data.dados.inscricaoFinalizada === 'S'){
                alterarDados({...dadosDefault});
                alterarParticipantes([]);
                alterarModo(null);
                alterarMostrarCampoCodigo(true);
            }else{
                alterarDados(data.dados);
                alterarParticipantes(JSON.parse(data.dados.participantes));
                alterarModo('edicao');
            }

        }catch({response}){
            if(response){
                if(response.status === 400){
                    alterarErros(response.data);
                    toast(<>
                        <div
                            style={{background: '#ff6271'}}
                        >
                            <div className="toast-header">
                                <strong className="mr-auto">Houve alguns erros :(</strong>
                                <button 
                                    className="ml-2 mb-1 close btn-outline-light outline-0"
                                >
                                </button>
                            </div>
                            <div className="toast-body text-light">
                                Verifique os campos que precisam ser preenchidos corretamente!
                            </div>
                        </div>
                    </>);
                }
            }
        }finally{
            alterarSalvando(false);
        }
    }

    // consulta código
    async function consultarCodigo(){
        alterarErros([]);

        try{

            // faz a requisição
            let { data } = await axios.get(`/formularioAcidentesPessoais/codigo`, { params: { codigoEvento: codigoEvento } });

            // se finalizado apresenta mensagem
            if(data.dados.inscricaoFinalizada === 'S'){
                window.alert('Essa inscrição já foi finalizada!');
                alterarCodigoEventoCancelar(data.dados.codigoEvento);
                alterarMostrarCancelar(true);
            }else{
                alterarDados(data.dados);
                alterarParticipantes(JSON.parse(data.dados.participantes));
                alterarModo('edicao');
            }

        }catch({response}){
            if(response){
                if(response.status === 400){
                    alterarErros(response.data);
                }
            }
        }

    }

    // função para cancelar
    async function cancelar(){
        
        // confirma para ter certeza
        if(!window.confirm('Tem certeza que quer cancelar?')){
            return;
        }

        try {

            // faz a requisição
            let { data } = await axios.put(`/formularioAcidentesPessoais/cancelar`, {codigoEvento: codigoEventoCancelar});
            alert('Evento cancelado com sucesso!');
            alterarMostrarCancelar(false);
            alterarCodigoEventoCancelar(null);

        }catch({response}){
            if(response && response.status && response.status === 400){
                alert(response.data.prazo);
            }
        }

    }


    // altera os dados
    function funcaoAlterarDados(e){
        dados[e.target.name] = e.target.value;
        alterarDados({...dados});
    }

    useEffect(() => {
        dados.participantes = JSON.stringify(participantes);
        alterarDados({...dados});
    }, [participantes]);

    return <Container className='mt-4 pb-5'>
        <div className={styles.logoTipoAventura}>
            <img src={logoTipoAventura} />
        </div>
        <Row>
            <Col lg={{span:8, offset: 2}}>
                <Card className='border-0 shadow-sm'>
                    <Card.Body>
                        <h3 className='text-center mb-4'>PROPOSTA SEGURO ACIDENTES PESSOAIS</h3>

                        { modo === null &&
                            <>
                                { !mostrarCampoCodigo &&
                                    <>
                                        <Button
                                            variant='success'
                                            className='mb-2 mx-1'
                                            disabled={salvando}
                                            onClick={() => {
                                                // salvar();
                                                alterarMostrarCampoCodigo(false);
                                                alterarModo('cadastro');
                                            }}
                                        >
                                            <span>Novo evento?</span>
                                        </Button>
                                        {/* <Button
                                            variant='danger'
                                            className='mb-2 mx-1'
                                            disabled={salvando}
                                            onClick={() => {
                                                alterarCancelarEvento(true);
                                                alterarMostrarCampoCodigo(true);
                                            }}
                                        >
                                            Cancelar Evento!
                                        </Button> */}
                                        {/* <Button
                                            variant='padrao'
                                            className='mb-2 mx-1'
                                            disabled={salvando}
                                            onClick={() => {
                                                
                                            }}
                                        >
                                            Consultar Código!
                                        </Button> */}
                                    </>
                                }
                                {mostrarCampoCodigo &&
                                    <>
                                        <Form.Row>
                                            <Col lg={8}>
                                                <Form.Group>
                                                    <Form.Control 
                                                        className='mb-2'
                                                        value={codigoEvento}
                                                        onChange={e => {
                                                            alterarCodigoEvento(e.target.value);
                                                        }}
                                                        isInvalid={erros.codigoEvento}
                                                        placeholder="Informe o código para consulta"
                                                    />
                                                    {erros.codigoEvento &&
                                                        <Form.Control.Feedback type="invalid">
                                                            {erros.codigoEvento}
                                                        </Form.Control.Feedback>
                                                    }
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4}>
                                                <Button
                                                    variant='warning'
                                                    onClick={consultarCodigo}
                                                >
                                                    Buscar
                                                </Button>
                                            </Col>
                                        </Form.Row>
                                        <Button
                                            variant='success'
                                            className='mb-2 mx-1'
                                            disabled={salvando}
                                            onClick={() => {
                                                // salvar();
                                                alterarMostrarCampoCodigo(false);
                                                alterarModo('cadastro');
                                            }}
                                        >
                                            <span>Novo evento?</span>
                                        </Button>
                                    </>
                                }

                                { mostrarCancelar &&
                                    <div className='mt-4'>
                                        <p>Deseja cancelar o evento <b>{codigoEventoCancelar}</b> ?</p>
                                        <Button 
                                            variant='danger'
                                            onClick={cancelar}
                                        >
                                            Sim, quero cancelar!
                                        </Button>
                                    </div>
                                }
                            </>
                        }

                        { (modo !== null) && 
                            <>
                                <Formulario 
                                    dados={dados}
                                    alterarDados={alterarDados}
                                    funcaoAlterarDados={funcaoAlterarDados}
                                    erros={erros}
                                    modo={modo}
                                />

                                <h5 className='text-right mb-4'>DADOS DOS PARTICIPANTES</h5>
                                
                                {participantes.map((participante, pos) => 
                                    <Participante 
                                        key={pos}
                                        dados={participante}
                                        alterarDados={(dadosNovos) => {
                                            Object.assign(participante, dadosNovos);
                                            alterarParticipantes([...participantes]);
                                        }}
                                        remover={() => {
                                            removerParticipante(pos);
                                        }}
                                        erros={(erros.participantes && erros.participantes[pos]) ? erros.participantes[pos] : []}
                                    />
                                )}

                                <p>
                                    <Button
                                        variant='padrao'
                                        onClick={adicionarParticipante}
                                    >Adicionar Participante</Button>
                                </p>

                                <Row>
                                    <Col lg={4}>
                                        <div className="bg-warning p-4 rounded shadow-sm">
                                            <Form.Check 
                                                custom
                                                id="evento-finalizado-checkbox"
                                                label="Inscrição finalizada?"
                                                checked={dados.inscricaoFinalizada === 'S'}
                                                onChange={e => {
                                                    dados.inscricaoFinalizada = e.target.checked ? 'S' : 'N';
                                                    alterarDados({...dados});
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    {dados.inscricaoFinalizada === 'S' && 
                                        <Col>
                                            <div className="bg-warning p-4 rounded shadow-sm text-center">
                                                Assim que finalizada a inscrição, não poderá mais ser alterada!
                                            </div>
                                        </Col>
                                    }
                                </Row>
                                
                                {erros.finalizado && 
                                    <div className="bg-danger p-4 rounded shadow-sm text-center">
                                        Evento já finalizado!
                                    </div>
                                }

                                <SalvarFormulario 
                                    salvando={salvando}
                                    cancelar={() => {
                                        alterarDados({...dadosDefault});
                                        alterarModo(null);
                                        alterarMostrarCampoCodigo(true);
                                    }}
                                    onClick={() => salvar(dados)}
                                />
                            </>
                        }


                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
}


function SalvarFormulario({
    salvando,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <span>Salvar</span>
            }
        </Button>
    </Form.Group>
}