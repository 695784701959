import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import axios from 'axios';

// componentes
import OptionSelect from './OptionSelect';

// combo para consulta de grupos
export default function ComboGruposConsulta({
    ids=[],
    alterou,
    placeholder='Grupos'
}){

    // estados
    const [consulta, alterarConsulta] = useState([]);
    const [gruposSelecionado, alterarGruposSelecionado] = useState([]);
    const [carregando, alterarCarregando] = useState(true);


    // para inclusão na consulta
    let semGrupos = {
        id: '',
        descricao: 'Sem grupos'
    };

    // consultar grupos
    async function consultarGrupos(inputValue, callback = null){

        try{

            // faz a requisição
            const { data } = await axios.get(`/grupos`, {
                params: {
                    texto: inputValue,
                    registrosPorPagina: 5
                }
            })
            // incrementa variavale para consulta
            data.registros.push(semGrupos);

            // filtra registros, desabilita quem já está na lista
            const registros = data.registros.map(grupo => {
                // verifica se já está adicionado
                if(gruposSelecionado.find(gruposFind => {
                    if(gruposFind.id === grupo.id){
                        return true;
                    }
                    return false;
                })){
                    grupo.isDisabled = true;
                }
                return grupo;
            });
            
            // altera os dados
            alterarConsulta(registros);
            if(callback !== null){
                callback(registros);
            }

        }catch({response}){}
    }

    // consulta os ids dos grupos
    async function consultarGruposIds(){

        try{

            // faz a requisição
            const { data } = await axios.get(`/grupos`, {
                params: { ids: ids }
            });
            alterarGruposSelecionado(data.registros);

        }catch({response}){}
    }

    // ao alterar grupos, retorna ids
    useEffect(() => {
        alterou(gruposSelecionado.map(g => g.id));
    }, [gruposSelecionado]);

    useEffect(() => {
        
        if(ids.length > 0){
            consultarGruposIds();
        }

    }, []);

    return <AsyncSelect 
        isMulti
        isClearable
        loadOptions={consultarGrupos}
        options={consulta}
        components={{
            Option: OptionSelect
        }}
        value={gruposSelecionado.map(opcao => {
            return {label: opcao.descricao, value: opcao.id}
        })}
        onChange={(evento, validar) => {

            // verifica ações
            if(validar.action === 'pop-value'){
                gruposSelecionado.pop();
                alterarGruposSelecionado([...gruposSelecionado]);
            }else if(validar.action === 'remove-value'){
                alterarGruposSelecionado(gruposSelecionado.filter((grupo, a) => {
                    if(grupo.id === validar.removedValue.value){
                        return false;
                    }
                    return true
                }));
            }else if(validar.action === 'clear'){
                alterarGruposSelecionado([]);
            }else if(validar.action === 'select-option'){
                alterarGruposSelecionado([...gruposSelecionado, validar.option]);
            }
        }}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Digite algo para fazer a consulta'}
        placeholder={placeholder}        
    />
    
}
