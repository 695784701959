import React, { 
    useEffect, 
    useState 
} from 'react';
import { Card } from 'react-bootstrap';
import axios from "axios";

// componentes
import Carregador from './../../../Painel/Componentes/Carregador';
import PaginacaoComponente from './../../../Painel/Componentes/Paginacao';
import Registro from './Registro';

// fechamentos
export default function FechamentosCartaOferta() {

    const [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10
    });
    const [totalRegistros, alterarTotalRegistros] = useState(0);
    const [carregando, alterarCarregando] = useState(false);
    const [registros, alterarRegistros] = useState([]);

    // inicia
    useEffect(() => {
        consultarFechamentos();
    }, [parametros]);

    async function consultarFechamentos() {
        alterarCarregando(true);

        try {

            // faz a requisição
            const { data } = await axios.get("/seguroAventuraFechamento/cartaOferta", {
                params: parametros
            });

            alterarRegistros(data.registros);
            alterarTotalRegistros(parseInt(data.totalRegistros))

        } catch ({ response }) { }
        finally {
            alterarCarregando(false);
        }

    }

    return <>
        <Card className="painel-consulta border-0 shadow-sm" >
            <div className="card-header">
                <div className="d-flex justify-content-between align-items-center mb-1">
                    <h4>Relatórios Mensais</h4>
                </div>
            </div>

            <Card.Body className="pt-0">
                <table className="table table-hover tabela">
                    <thead>
                        <tr>
                            {/* <th className="text-center"><FontAwesomeIcon icon={faUsers} /></th> */}
                            <th>Data Fechamento</th>
                            <th>Link de Cobrança</th>
                            <th>Participantes</th>
                            <th>Diárias</th>
                            <th>Total</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {registros.map((registro, a) =>
                            <Registro
                                key={registro.dados.id}
                                dados={registro}
                                clicouAbrir={() => {}}
                            />
                        )}
                    </tbody>
                </table>
                <PaginacaoComponente
                    pagina={parametros.pagina}
                    registrosPorPagina={parametros.registrosPorPagina}
                    totalRegistros={totalRegistros}
                    alterouPagina={(pagina) => {
                        alterarParametros({ ...parametros, pagina: pagina });
                    }}
                />
            </Card.Body>
            {carregando &&
                <Carregador className="rounded-bottom rounded-top" />
            }
        </Card>
    </>

}