import React from 'react';

import { Container, Row, Col, Button, Card } from 'react-bootstrap';

function Opcoes({
    history
}){

    return <Container className='mt-4'>
        <Row>
            <Col md={{span: 4, offset:4}}>
                <Button
                    variant='padrao'
                    className='w-100'
                    onClick={() => {history.push('/meuseguro/apolice');}}
                >
                    <span>Consultar Apolice</span>
                </Button>
                
                {/* <Button
                    variant='padrao'
                    className='w-100 mt-3'
                    onClick={() => {history.push('/meuseguro/fianca-cliente');}}
                >
                    <span>Cadastrar Fiança Cliente</span>
                </Button>

                <Button
                    variant='padrao'
                    className='w-100 mt-3'
                    onClick={() => {history.push('/meuseguro/fianca-imobiliaria');}}
                >
                    <span>Cadastrar Fiança Imobiliaria</span>
                </Button> */}



                
            </Col>
        </Row>
    </Container>
}

export default Opcoes;