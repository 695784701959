import React, { useState } from 'react';
import axios from 'axios';
import moment from 'moment';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

export default function GerarRelatorioXls({
    parametros
}){

    // estados
    const [carregando, alterarCarregando] = useState(false);

    // solicitar relatório
    async function solicitar(){
        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/relatorios/funil/xls`, { params: {
                ano: moment(parametros.data).format('YYYY'),
                mes: moment(parametros.data).format('MM'),
                data: parametros.data,
                id_responsavel: parametros.id_responsavel
            } });

            // faz o download
            window.open(
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=temp&f=${encodeURIComponent(data.arquivo)}&n=${encodeURIComponent(data.nome)}`, 
                '_blank'
            );

        }catch({response}){}
        finally{
            alterarCarregando(false);
        }
    }


    return <>
        <Button
            variant='padrao'
            onClick={solicitar}
            size='sm'
            disabled={carregando}
        >
            { carregando ?
                <FontAwesomeIcon className='icone fa-fw' pulse icon={['fas', 'spinner']} />
                :
                <FontAwesomeIcon className='icone fa-fw' icon={['fas', 'file-excel']} />
            }
        </Button>
    </>
}