import styled from 'styled-components';

export const ParcelasComponent = styled.div.attrs({
    className: ``
})`

    thead {

        tr {

            th {
                font-size: 0.9rem;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
        }
    }


`


export const ParcelaComponent = styled.tr.attrs({
    className: ``
})`

    .data {
        text-align: center;
    }

    .valor {
        text-align: right;
    }

    .valor-total {
        text-align: right;
        font-weight: bold !important;
        background: transparent !important;
    }

    .numero {
        vertical-align: middle !important;
    }

    ${props => props.alterado && `
        td {
            .valor, .valorTotal {
                color: rgb(228, 0, 0) !important;
            }
        }
    `}

    .pago {
        width: 1%;
        vertical-align: middle !important;

        .input-check {
            display: inline;;
            text-align: center;
            margin: 0;
            vertical-align: middle;

            input {
                margin: 0;
            }
        }
    }


`