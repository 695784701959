// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './botaoFiltroEvento.scss';

function BotaoFiltroEvento({
    onClick,
    texto,
    cor,
    icone,
    selecionado
}) {

    return (
        <button 
            onClick={onClick}
            className={['botao-filtro-evento', selecionado ? 'selecionado' : ''].join(' ')}
        >
            <FontAwesomeIcon 
                className={"icone"}
                icon={["fas", icone]} 
                color={cor}
            />
            <p
                className={selecionado ? 'texto-filtro-selecionado' : ''}
            >
                {texto}
            </p>
        </button>
    )

}   

export default BotaoFiltroEvento;