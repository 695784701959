import React, { useState, useEffect } from 'react';
import moment from 'moment';
import InputData from './../../../Painel/Componentes/InputData';

import { Container, Card, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import MaskedInput from 'react-maskedinput';

function Participante({
    dados,
    alterarDados,
    remover,
    desabilitarRemover=false,
    erros=[]
}){

    // referencias
    const telefoneInput = React.createRef();

    function funcaoAlterarDados(e){
        dados[e.target.name] = e.target.value;
        alterarDados({...dados});
    }

    return <div className='rounded bg-light mb-3 p-3'>

        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Número Inscrição</Form.Label>
                <Form.Control
                    value={dados.numeroInscricao || ''}
                    maxLength='15'
                    name='numeroInscricao'
                    onChange={funcaoAlterarDados}
                    isInvalid={erros.numeroInscricao}
                />
                {erros.numeroInscricao &&
                    <Form.Control.Feedback type="invalid">
                        {erros.numeroInscricao}
                    </Form.Control.Feedback>
                }
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                    value={dados.nome || ''}
                    maxLength='100'
                    name='nome'
                    onChange={funcaoAlterarDados}
                    isInvalid={erros.nome}
                />
                {erros.nome &&
                    <Form.Control.Feedback type="invalid">
                        {erros.nome}
                    </Form.Control.Feedback>
                }
            </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>CPF</Form.Label>
                <Form.Control
                    value={dados.cpf || ''}
                    maxLength='30'
                    name='cpf'
                    onChange={funcaoAlterarDados}
                    isInvalid={erros.cpf}
                />
                {erros.cpf &&
                    <Form.Control.Feedback type="invalid">
                        {erros.cpf}
                    </Form.Control.Feedback>
                }
            </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Data Nascimento</Form.Label>
                <InputData 
                    data={dados.dataNascimento}
                    onChange={(data) => {
                        dados.dataNascimento = data;
                        alterarDados({...dados});
                    }}
                />
            </Form.Group>
        </Form.Row>
        { (erros.dataNascimento || (dados.dataNascimento && dados.dataNascimento.length === 10 && moment().diff(dados.dataNascimento, 'years') <= 14)) &&
            <Alert variant="danger">
                Para os menores de 14 anos a cobertura de Morte Acidental estará limitada ao reembolso de despesas com o funeral.
            </Alert>
        }

        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Gênero</Form.Label>
                <Form.Control
                    value={dados.genero}
                    maxLength='300'
                    name='genero'
                    onChange={funcaoAlterarDados}
                    as='select'
                >
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                </Form.Control>
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                    value={dados.email || ''}
                    maxLength='100'
                    name='email'
                    onChange={funcaoAlterarDados}
                    isInvalid={erros.email}
                />
                {erros.email &&
                    <Form.Control.Feedback type="invalid">
                        {erros.email}
                    </Form.Control.Feedback>
                }
            </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Telefone</Form.Label>
                <MaskedInput 
                    className={["form-control", erros.telefone ? 'is-invalid' : ''].join(` `)}
                    mask="(11) 11111-1111"
                    value={dados.telefone}
                    ref={telefoneInput}
                    onChange={(e) => {
                        dados.telefone = telefoneInput.current.mask.getRawValue().split('_').join('')
                        alterarDados({...dados});
                    }}
                />
                {erros.telefone &&
                    <Form.Control.Feedback type="invalid">
                        {erros.telefone}
                    </Form.Control.Feedback>
                }
            </Form.Group>
        </Form.Row>

        { !desabilitarRemover && 
            <Button
                variant='danger'
                onClick={remover}
            >
                Remover Participante
            </Button>
        }
    </div>
}

export default Participante;