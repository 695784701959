import React, {useState, useEffect} from 'react';
import styles from './index.module.scss';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import BotaoTabela from './../../../Componentes/BotaoTabela';
import { Badge } from 'react-bootstrap';

// funçõs
import { estiloEvento } from './../../../ConfiguracaoSistema';

// elemento registro
function Registro({
    dados, 
    clicouEditar
}){

    let icone = ['far', 'square'];
    if(dados.lido === 'S'){
        icone = ['far', 'check-square'];
    }

    return <tr>
        <td className="estreito text-center font-weight-bold">{dados.codigoEvento}</td>
        <td className='estreito text-center'>
            <FontAwesomeIcon className='mr-1' icon={icone} />
        </td>
        <td>
            {dados.nomeEmpresa}
        </td>
        <td className='estreito text-center font-weight-bold text-nowrap'>
            {moment(dados.dataCadastro).format('DD/MM/YYYY HH:mm')}
        </td>
        <td className="text-nowrap estreito">
            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >Editar</BotaoTabela>
        </td>
    </tr>
}

export default Registro;