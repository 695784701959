import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import ConteudoEmail from './../../ModelosEmail/ConteudoEmail';
import Destinatario from './Destinatario';
import InfoAdicional from './InfoAdicional';
import useIsMounted from 'ismounted';
import AnexosEmail from './AnexosEmail';

// escreve o email
function EscreverEmail({
    aniversariante='N',
}){

    // dados do email
    const isMounted = useIsMounted();
    const history = useHistory();
    let { idade, id, idcliente } = useParams();
    const [modo, alterarModo] = useState('cadastro'); // cadastro, edicao
    const [dados, alterarDados] = useState({
        id: null,
        alvo_tabela: 'clientes',
        id_alvo: null,
        descricao: null,
        titulo: null,
        para_email: null,
        para_nome: null,
        outros_destinatarios: [],
        conteudo: null,
        responder_para: null,
        tipo_aniversariante: aniversariante,
        idade_referencia: idade || null,
        id_cliente: null,
        prioridade: 1,
        // agendado_para: null, // backend converte
        agendado_para_data: null,
        agendado_para_hora: null,
        data_reenvio: null,
        padrao_novo: 'S',
        destaque: null,
        conteudo_corpo: null,
        id_imagem: null,
        // servidor_email_usar: aniversariante === 'N' ? 'servidorEmail'  : 'servidorEmailMarketing' correto
    });
    const [anexos, alterarAnexos] = useState([]);
    const [dadosCliente, alterarDadosCliente] = useState({});
    const [salvando, alterarSalvando] = useState(false);
    const [carregando, alterarCarregando] = useState(true);
    const [anexosPreCarregado, alterarAnexosPreCarregado] = useState([]);

    // inicializa
    useEffect(() => {
        // console.log(parametros);

        // verifica se é alteração
        if(id){
            alterarModo('edicao');

            // carrega os dados do email enviado
            axios.get(`/emailsEnviado/${id}`)
            .then(({data, ...retorno}) => {
                alterarAnexosPreCarregado(data.anexos);
                alterarDados(data.dados);
            })
            .catch(() => {})
            .then(() => {
                alterarCarregando(false);
            });

        }else{

            // carrega os dados do cliente
            axios.get(`/clientes/${idcliente}`)
            .then(({data, ...retorno}) => {
                dados.id_cliente = idcliente;
                dados.id_alvo = idcliente;
                dados.para_nome = data.dados.nome;
                dados.para_email = data.dados.email;
                alterarDados({...dados});
                alterarDadosCliente(data.dados);
            })
            .catch(() => {})
            .then(() => {
                alterarCarregando(false);
            })
        }

    }, []);

    // adiciona destinatario
    function adicionarDestinatario(tipo = ''){
        dados.outros_destinatarios.push({
            nome: '',
            email: '',
            tipo: tipo
        });
        alterarDados({...dados});
    }

    // carrega modelo de email com os dados do cliente
    function carregarModeloEmailCliente(id){

        // envia email
        if(id === null){
            return;
        }

        // faz a requisição para carregar os dados do modelo de email
        axios.get(`/modelosEmail/${id}/${dados.alvo_tabela}/${idcliente}`)
        .then(({data, ...retorno}) => {
            dados.id_imagem = data.dados.id_imagem;
            dados.titulo = data.dados.titulo;
            dados.destaque = data.dados.destaque;
            dados.conteudo_corpo = data.dados.conteudo_corpo;
            alterarDados({...dados});
        })
        .catch(() => {})
        .then(() => {})

    }

    // faz o envio do email
    async function salvar(){
        alterarSalvando(true);

        try{



            // faz a requisição
            let { data } = await axios.post(`/emailsEnviado/enviar`, { dados, anexos });

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Email programado!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    {dadosCliente.nome}
                </div>
            </>);

            // vai para a consulta de sms's enviados (por enquanto volta para a consulta de clientes)
            history.push('/painel/caixa-saida');

        }catch({response}){}
        finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
        }

    }

    if(carregando){
        return <div>Carregando...</div>
    }

    return <>
        <Row>
            <Col md='8'>
                <Card className='border-0 shadow-sm'>
                    <Card.Header className='mb-0 border-0 bg-white'>
                        <Card.Title className='pb-0 mb-0'>Destinatarios</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Destinatario 
                            value={{
                                nome: dados.para_nome,
                                email: dados.para_email
                            }}
                            onChange={dadosNovos => {
                                dados.para_nome = dadosNovos.nome;
                                dados.para_email = dadosNovos.email;
                                alterarDados({...dados});
                            }}
                        />

                        { dados.outros_destinatarios.map((destinatario, a) => 
                            <Destinatario 
                                key={a}
                                value={destinatario}
                                onChange={e => {
                                    alterarDados({...dados});
                                }}
                                excluir={() => {
                                    dados.outros_destinatarios.splice(a, 1);
                                    alterarDados({...dados});
                                }}
                            />
                        )}

                        <p className='text-right mt-2'>
                            
                            <Button
                                onClick={e => {
                                    adicionarDestinatario('');
                                }}
                                variant='padrao'
                                size='sm'
                                className='mr-2'
                            >
                                Adicionar
                            </Button>
                            
                            <Button
                                onClick={e => {
                                    adicionarDestinatario('CC');
                                }}
                                variant='padrao'
                                size='sm'
                                className='mr-2'
                            >
                                Adicionar CC
                            </Button>
                            
                            <Button
                                onClick={e => {
                                    adicionarDestinatario('CCO');
                                }}
                                variant='padrao'
                                size='sm'
                            >
                                Adicionar CCO
                            </Button>
                        </p>
                    </Card.Body>
                </Card>

                <Card className='border-0 shadow-sm mt-4'>
                    <Card.Header className='mb-0 border-0 bg-white'>
                        <Card.Title className='pb-0 mb-0'>Escrever Email</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <ConteudoEmail 
                            value={{
                                id_imagem: dados.id_imagem,
                                titulo: dados.titulo,
                                destaque: dados.destaque,
                                conteudo_corpo: dados.conteudo_corpo
                            }}
                            onChange={dadosNovos => {
                                Object.assign(dados, dadosNovos);
                                alterarDados({...dados});
                            }}
                        />
                    </Card.Body>
                </Card>

                <SalvarFormulario 
                    salvando={salvando}
                    modo={modo}
                    cancelar={() => {
                        history.goBack();
                    }}
                    onClick={() => salvar(dados)}
                />
            </Col>
            <Col md='4'>
                <Card className='border-0 shadow-sm'>
                    <Card.Body className='pb-0'>

                        <InfoAdicional 
                            dados={dados}
                            alterarDados={alterarDados}
                            alterouIdModelo={carregarModeloEmailCliente}
                        />
                    </Card.Body>
                </Card>

                <AnexosEmail 
                    leitura={id ? true : false }
                    anexosPreCarregado={anexosPreCarregado}
                    alterarAnexos={alterarAnexos}
                />

                {/* <Card className='border-0 shadow-sm mt-4'>
                    <Card.Body>

                    </Card.Body>
                </Card> */}
            </Col>
        </Row>
    </>
}

function SalvarFormulario({
    salvando,
    cancelar,
    modo,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button 
            variant="success" 
            disabled={salvando} 
            onClick={onClick}
            // disabled={modo === 'edicao'}
            hidden={modo === 'edicao' ? true : false}
        >
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Enviando</span>
                </> : 
                <>
                    <FontAwesomeIcon className="icone" icon={["fas", 'paper-plane']} />
                <span>{modo === 'edicao' ? 'Reenviar' : 'Enviar'}</span>
                </>
            }
        </Button>
    </Form.Group>
}

// retorna página de consulta
export default EscreverEmail;