import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import ContentEditable from "react-contenteditable";
import BotaoMenu from './BotaoMenu';
import ModalImagensEmail from './ModalImagensEmail';

// retorna conteudo
export default function ConteudoEmail({
    value={
        id_imagem: null,
        titulo: null,
        destaque: null,
        conteudo_corpo: null
    },
    onChange,
    esconderAdicionarImagem=false,
    esconderDestaque=false
}){

    // estados
    let login = useSelector(state => state.login);
    let [mostrarModalImagensEmail, alterarMostrarModalImagensEmal] = useState(null);
    let [imagem, alterarImagem] = useState(null);
    let [carregandoImagem, alterarCarregandoImagem] = useState(false);

    useEffect(() => {
        if(value.conteudo_corpo === null){
            document.execCommand('formatBlock', false, 'p');
        }

        // se possuir imagem, carrega os dados da imagem
        if(value.id_imagem !== null){

            consultarImagem();
        }

    }, []);

    // carrega imagem alterada
    useEffect(() => {
        
        // imagem alterada
        if(imagem && value.id_imagem !== imagem.id){
            consultarImagem();
        }

        // se não possuir imagem, inclui
        if(imagem === null && value.id_imagem !== null){
            consultarImagem();
        }

    }, [value.id_imagem]);

    // consultar imagem 
    function consultarImagem(){
        // carrega
        alterarCarregandoImagem(true);

        // // faz a requisição para buscar os dados da imagem
        // ImagensEmailsServico.consultarCodigo({
        //     id: value.id_imagem
        // }, login.dadosUsuario.token)
        // .then(({data, ...retorno}) => {
        //     console.log(data);
        //     alterarImagem(data.dados);
        //     alterarCarregandoImagem(false);
        // });

        axios.get(`/imagensEmails/${value.id_imagem}`)
        .then(({data, ...retorno}) => {
            alterarImagem(data.dados);
        })
        .catch(() => {})
        .then(() => {
            alterarCarregandoImagem(false);
        })

    }

    return <>
        { mostrarModalImagensEmail && 
            <ModalImagensEmail 
                alterou={dadosImagem => {
                    console.log(dadosImagem);
                    value.id_imagem = dadosImagem.id;
                    alterarImagem({...dadosImagem});
                    onChange(value);
                }}
                fechou={() => {
                    alterarMostrarModalImagensEmal(null);
                }}
            />
        }

        {/* se estiver carregando os dados da imagem */}
        { carregandoImagem &&
            <div>
                Carregando Imagem <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
            </div>
        }

        {!esconderAdicionarImagem &&
            <div hidden={carregandoImagem} className={[styles.imagemCabecalho, 'mb-2'].join(' ')}>
                <Button
                    size='sm'
                    variant='padrao'
                    className='mb-1'
                    onClick={() => {

                        // inclui
                        if(imagem === null){
                            alterarMostrarModalImagensEmal({});
                        }else{

                            // remove imagem
                        value.id_imagem = null;
                        alterarImagem(null);
                        onChange(value);

                        }
                        
                    }}
                >
                    { imagem ?
                        <span>Remover imagem</span>
                        :
                        <span>Incluir imagem</span>
                    }
                    
                </Button>

                {/* se a imagem já carregou */}
                { imagem && 
                    <img 
                        src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/imagensEmail/${imagem.nome_temporario}`} 
                        className={[styles.imagem, 'mr-2 rounded'].join(' ')} 
                    />
                }

            </div> 
        }
        <Form.Group>
            <Form.Label>Titulo</Form.Label>
            <Form.Control 
                value={value.titulo || ''}
                onChange={e => {
                    value.titulo = e.target.value;
                    onChange(value);
                }}
            />
        </Form.Group>
        <div className='mt-2 '>
            <div className='mb-2'>
                {/* <Button
                    size='sm'
                    variant='padrao'
                    onMouseDown={e => {
                        e.preventDefault();
                        document.execCommand('bold', false, null);
                    }}
                >
                    <FontAwesomeIcon className="icone" icon={["fas", 'bold']} />
                </Button> */}

                <BotaoMenu 
                    icone={["fas", 'bold']}
                    descricao='Negrito'
                    onMouseDown={e => {
                        document.execCommand('bold', false, null);
                    }}
                />
                <BotaoMenu 
                    icone={["fas", 'align-left']}
                    descricao='Alinhar texto a esquerda'
                    onMouseDown={e => {
                        document.execCommand('justifyLeft', false, null);
                    }}
                />
                <BotaoMenu 
                    icone={["fas", 'align-center']}
                    descricao='Centralizar texto'
                    onMouseDown={e => {
                        document.execCommand('justifyCenter', false, null);
                    }}
                />
                <BotaoMenu 
                    icone={["fas", 'align-right']}
                    descricao='Alinhar texto a direita'
                    onMouseDown={e => {
                        document.execCommand('justifyRight', false, null);
                    }}
                />
                <BotaoMenu 
                    icone={["fas", 'align-justify']}
                    descricao='Justificar texto'
                    onMouseDown={e => {
                        document.execCommand('justifyFull', false, null);
                    }}
                />

                <BotaoMenu 
                    icone={["fas", 'eraser']}
                    descricao='Apagar estilos'
                    onMouseDown={e => {
                        document.execCommand('removeFormat', false, null);
                    }}
                />
            </div>
            <div className='flex-grow-1'>
                {!esconderDestaque &&
                    <Form.Group>
                        <Form.Label>Destaque</Form.Label>
                        <ContentEditable 
                            html={value.destaque || ''}
                            disabled={false}
                            className={'form-control h-auto'}
                            style={{minHeight: '50px', background: '#284786', color: 'white'}}
                            onKeyUp={e => {
                                e = e || window.event
                                if (e.keyCode === 13)
                                document.execCommand('formatBlock', false, 'p');
                            }}
                            onChange={e => {
                                value.destaque = e.target.value;
                                onChange(value);
                            }}
                            onPaste={e => {
                                // e.preventDefault();
                                // document.execCommand('inserttext', false, e.clipboardData.getData('text/plain'));
                            }}
                        />
                    </Form.Group>
                }
                <Form.Group>
                    <Form.Label>Conteudo</Form.Label>
                    <ContentEditable 
                        html={value.conteudo_corpo || ''}
                        disabled={false}
                        className={['form-control h-auto'].join(' ')}
                        style={{minHeight: '100px'}}
                        onKeyUp={e => {
                            e = e || window.event
                            if (e.keyCode === 13)
                            document.execCommand('formatBlock', false, 'p');
                        }}
                        onPaste={e => {
                            // e.preventDefault();
                            // document.execCommand('inserttext', false, e.clipboardData.getData('text/plain'));
                        }}
                        onChange={e => {
                            if(e.target.value === ''){
                                e.target.value = '<p></p>';
                            }
                            value.conteudo_corpo = e.target.value;
                            onChange(value);
                        }}
                    />
                </Form.Group>
            </div>
        </div>
    </>
}
