import styled from 'styled-components';

export const FichaComponent = styled.div.attrs({
    className: ``
})`
    padding-bottom: 50vh;
    position: relative;

    .tag-etapa {
        position: fixed;
        right: 0;
        z-index: 2;
        padding: 6px 47px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        font-weight: bold;
        color: white;
        text-transform: uppercase;
        margin-top: -48px;
        opacity: 0.7;


        ${props => props.etapa === '1' && `background: rgb(169, 48, 169);`}
        ${props => props.etapa === '2' && `background: rgb(215, 215, 2);`}
        ${props => props.etapa === '3' && `background: rgb(95, 95, 255);`}
        ${props => props.etapa === '4' && `background: rgb(51, 217, 51);`}

        /* cancelada */
        ${props => props.propostaCancelado && `background: rgb(237, 56, 56);`}
        ${props => props.emissaoCancelado && `background: rgb(237, 56, 56);`}


    }
`;