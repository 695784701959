import React, {useState, useEffect} from 'react';
import styles from './index.module.scss';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import BotaoTabela from './../../../Componentes/BotaoTabela';
import { Badge } from 'react-bootstrap';

// funçõs
import { estiloEvento } from './../../../ConfiguracaoSistema';

// elemento registro
function Registro({
    dados, 
    clicouEditar,
    clicouDesvincular
}){

    return <tr>
        <td className="estreito text-center font-weight-bold">{dados.id}</td>
        <td>{dados.descricao}</td>
        <td className="text-nowrap estreito">
            { dados.hashcookie !== null &&
                <BotaoTabela 
                onClick={clicouDesvincular}
                icone={["fas", "unlink"]}
                >Desvincular</BotaoTabela>
            }

            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >Editar</BotaoTabela>
        </td>
    </tr>
}

export default Registro;