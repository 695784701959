import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

// componentes
import { Card, Col, Row } from 'react-bootstrap';
import Carregador from './../../Componentes/Carregador';
import PaginacaoComponente from './../../Componentes/Paginacao';
// import Parametros from './Parametros';
import Registro from './Registro';

// consulta
export default function Consultar(){

    // estados
    const history = useHistory();
    let [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10
    });
    let [totalRegistros, atualizarTotalRegistros] = useState(0);
    let [carregando, mostrarCarregando] = useState(false);
    let [consulta, alterarConsulta] = useState([]);

    // inicializa
    useEffect(() => {
        consultarRegistros();
    }, [parametros]);

    // consulta registros
    async function consultarRegistros(){
        mostrarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get('/backups', { params: parametros });
            alterarConsulta(data.registros);
            atualizarTotalRegistros(parseInt(data.totalRegistros));

        }catch({response}){

        }finally{
            mostrarCarregando(false);
        }
    }

    // retorna
    return <>

        <Row>
            <Col>
                <Card className="painel-consulta border-0 shadow-sm" >
                    <div className="card-header ">
                        <h4 className="card-title font-weight-bolder">Backups</h4>
                        {/* <p className="card-category">Here is a subtitle for this table</p> */}

                        {/* <Parametros parametros={parametros} alterarParametros={alterarParametros} /> */}
                    </div>
                    
                    <Card.Body>
                        
                        <table className="table table-hover tabela">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Data</th>
                                    <th>Descrição</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {consulta.map((registro, a) => 
                                    <Registro 
                                        key={a} 
                                        dados={registro.dados}
                                        onClickTr={() => console.log('clicou TR')}
                                        clicouBaixar={() => {
                                            // history.push(`/painel/grupos/alterar/${registro.id}`)
                                            window.open(
                                                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=backups&f=${encodeURIComponent(registro.dados.arquivo)}&n=${encodeURIComponent(registro.dados.arquivo)}`, 
                                                '_blank'
                                            );
                                        }}
                                    />
                                )}
                            </tbody>
                        </table>
                        <PaginacaoComponente
                            pagina={parametros.pagina}
                            registrosPorPagina={parametros.registrosPorPagina}
                            totalRegistros={totalRegistros}
                            alterouPagina={(pagina) => {
                                alterarParametros({...parametros, pagina: pagina});
                            }}
                        />
                        {carregando && 
                            <Carregador className="rounded-bottom" />
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>

}
