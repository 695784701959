import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// componentes
import { Card, Col, Row } from 'react-bootstrap';
import Carregador from './../../Componentes/Carregador';
import PaginacaoComponente from './../../Componentes/Paginacao';
import Parametros from './Parametros';
import Registro from './Registro';

// serviços
import EventosModalCadastrar from './../ModalCadastrar';

// componente
export default function Consultar(){

    // estados
    const { painel } = useSelector(state => state);
    let [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10,
        ativo: ['S'],
        texto: '',
        dados_extras: 'S',
        concluido: ['N'],
        responsaveis: []
    });
    let [totalRegistros, atualizarTotalRegistros] = useState(0);
    let [carregando, mostrarCarregando] = useState(false);
    let [consulta, alterarConsulta] = useState([]);
    let [cadastroEvento, alterarCadastroEvento] = useState(null);

    // inicializa
    useEffect(() => {
        consultarRegistros();
    }, [parametros]);

    // consulta
    async function consultarRegistros(){
        mostrarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/eventosLegado`, { params: parametros });
            alterarConsulta(data.registros);
            atualizarTotalRegistros(parseInt(data.totalRegistros));

        }catch({response}){}
        finally{
            mostrarCarregando(false);
        }
    }

    return <>
        { cadastroEvento &&
                <EventosModalCadastrar 
                    fechou={() => {
                        alterarCadastroEvento(null);
                    }}
                    salvou={dadosEvento => {
                        alterarParametros({...parametros});
                    }}
                    registro={cadastroEvento}
                />
            }
        <Row>
            <Col>
                <Card className="painel-consulta border-0 shadow-sm" >
                    <div className="card-header ">
                        <h4 className="card-title font-weight-bolder">Eventos</h4>
                        {/* <p className="card-category">Here is a subtitle for this table</p> */}

                        <Parametros parametros={parametros} alterarParametros={alterarParametros} />
                    </div>
                    
                    <Card.Body>
                        
                        <table className="table table-hover tabela">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th></th>
                                    <th>Descrição</th>
                                    <th>Para</th>
                                    <th>Data</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {consulta.map((registro, a) => 
                                    <Registro 
                                        key={a} 
                                        dados={registro}
                                        coresEventos={painel.configuracoesSistema.coresEventos}
                                        parametros={parametros}
                                        onClickTr={() => console.log('clicou TR')}
                                        clicouEditar={() => {
                                            // props.history.push(`/painel/clientes/alterar/${registro.id}`)
                                            alterarCadastroEvento(registro);
                                        }}
                                    />
                                )}
                            </tbody>
                        </table>
                        <PaginacaoComponente
                            pagina={parametros.pagina}
                            registrosPorPagina={parametros.registrosPorPagina}
                            totalRegistros={totalRegistros}
                            alterouPagina={(pagina) => {
                                alterarParametros({...parametros, pagina: pagina});
                            }}
                        />
                        {carregando && 
                            <Carregador className="rounded-bottom" />
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
}
