import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import MaskedInput from 'react-maskedinput';
import { Button, Form, Card, Row, Col, Container, InputGroup, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Registro from './Registro';
import { useParams } from 'react-router-dom';

function Apolice({
    
}){

    // estados
    let { codigo } = useParams();
    let [consulta, alterarConsulta] = useState([]);
    let [carregando, alterarCarregando] = useState(false);
    let [parametros, alterarParametros] = useState({
        tipo: 'F', // F, J
        cpf: '',
        cnpj: ''
    });
    let [dadosCliente, alterarDadosCliente] = useState(null);
    let [carregandoCodigo, alterarCarregandoCodigo] = useState(false)
    let [modoCodigo, alterarModoCodigo] = useState(false);

    // referencias
    let cpfInput = React.createRef();
    let cnpjInput = React.createRef();

    // inicializa
    useEffect(() => {

        // se possui codigo, faz a requisição
        if(codigo){
            alterarCarregandoCodigo(true);
            alterarModoCodigo(true);
            fazerConsulta();
        }

    }, []);

    // faz a consulta enviando o código do link
    async function fazerConsulta(){

        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/clientes/apolice/${codigo}`);

            alterarConsulta(data.fichas);
            alterarDadosCliente(data.dadosCliente);
            alterarCarregandoCodigo(false);
            alterarCarregando(false);

        }catch({response}){

            if(response){
                if(response.status == 400){
                    window.alert('codigo não encontrado');
                }
            }

        }finally{
            
        }

    }

    // solicita acesso as apolices
    async function solicitarAcesso(){

        //faz a solicitação de link para ter acesso a apólice
        alterarCarregando(true);

        // faz a solicitação
        try{

            let { data } = await axios.post(`/clientes/apolice`, parametros);
            alterarConsulta(data.apolices.fichas);
            alterarDadosCliente(data.apolices.dadosCliente);

        }catch({response}){
            if(response){
                if(response.status === 400){
                    window.alert('Não foi possível fazer solicitação!');
                }
            }
        }finally{
            alterarCarregando(false);
        }
    }

    // retorna componente
    return <>
        <Container>
            <Row>
                <Col lg={{span:6, offset: 3}}>

                    { !modoCodigo &&
                        <Card className='border-0 shadow-sm mt-4 bg-light'>
                            <Card.Header className='bg-light border-0 text-center'>
                                <Card.Title>Consultar apolice</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                
                                {[
                                    { id: 'F', descricao: 'Pessoa Física'},
                                    { id: 'J', descricao: 'Pessoa Juridica'}
                                ].map(opcao => 
                                    <Form.Check 
                                        key={opcao.id}
                                        label={opcao.descricao}
                                        checked={parametros.tipo === opcao.id}
                                        type={'radio'}
                                        id={`tipo-pessoa-${opcao.id}`}
                                        value={opcao.id}
                                        inline
                                        onChange={(e) => {
                                            parametros.tipo = e.target.value;
                                            alterarParametros({...parametros});
                                        }}
                                    />
                                )}

                                <InputGroup className={["mb-3 mt-1", styles.inputconsulta].join(' ')}>
                                    <InputGroup.Prepend className={styles.labelCpf}>
                                        <InputGroup.Text className={styles.labelTexto}>
                                            { parametros.tipo === 'F' ? 'Informe seu CPF' : 'Informe seu CNPJ'}
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    { parametros.tipo === 'F' &&
                                        <MaskedInput 
                                            className={["form-control", styles.campoCpf].join(' ')}
                                            mask="111.111.111-11"
                                            value={parametros.cpf}
                                            ref={cpfInput}
                                            onChange={(e) => {
                                                parametros.cpf = cpfInput.current.mask.getRawValue().split('_').join('');
                                                alterarParametros({...parametros});
                                            }}
                                            onKeyUp={e => {
                                                if(!carregando && e.keyCode === 13){
                                                    fazerConsulta();
                                                }
                                            }}
                                        />
                                    }

                                    { parametros.tipo === 'J' &&
                                        <MaskedInput 
                                            className={["form-control", styles.campoCpf].join(' ')}
                                            mask="11.111.111/1111-11"
                                            value={parametros.cnpj}
                                            ref={cnpjInput}
                                            onChange={(e) => {
                                                parametros.cnpj = cnpjInput.current.mask.getRawValue().split('_').join('');
                                                alterarParametros({...parametros});
                                            }}
                                            onKeyUp={e => {
                                                if(!carregando && e.keyCode === 13){
                                                    solicitarAcesso();
                                                }
                                            }}
                                        />
                                    }
                                    <InputGroup.Append className={styles.botaoCpf}>
                                        <Button 
                                            className={styles.botao}
                                            variant="padrao"
                                            disabled={carregando}
                                            onClick={solicitarAcesso}
                                        >
                                            { carregando &&
                                                <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                            }
                                            <span>Solicitar</span>
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>

                            </Card.Body>
                        </Card>
                    }

                    { carregandoCodigo &&
                        <Card className='border-0 shadow-sm mt-4 bg-light'>
                            <Card.Body>
                            <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} /> Aguarde, carregando as apolices! 
                            </Card.Body>
                        </Card>
                    }

                    { dadosCliente && 
                        <>
                            <Card className='border-0 shadow-sm my-3'>
                                <Card.Body>
                                    
                                    { !modoCodigo &&
                                        <>
                                            <Alert variant='warning' className='border-0'>
                                                <p className='text-center'>
                                                    Enviamos um link com informações completas e Apólices de Seguro para seu email, abra o link para acessar suas informações!
                                                </p>
                                                <p className='text-center'>
                                                    OK!
                                                </p>
                                            </Alert>
                                        </>
                                    }
                                    <p className='mt-3 text-center'>Seguros encontrados para <b>{dadosCliente.nome}</b></p>

                                    
                                </Card.Body>
                            </Card>
                            { consulta.map(ficha => 
                                <Registro 
                                    key={ficha.id}
                                    dados={ficha}
                                    extra={modoCodigo}
                                />
                            )}
                        </>
                    }
                </Col>
            </Row>
        </Container>
    </>
}

export default Apolice;