import React, { useState } from 'react';
import { 
    useHistory, 
    useLocation 
} from "react-router-dom";
import { 
    Card, 
    Form, 
    Row, 
    Col, 
    Button, 
    Badge 
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { Portuguese } from "flatpickr/dist/l10n/pt";
import Flatpick from 'react-flatpickr';
import axios from 'axios';

import Carregador from '../../Componentes/Carregador';
import InputHora from '../../Componentes/InputHora';
import ComboModeloSms from './../../Componentes/ComboModeloSms';
import Tags from './../../Componentes/Tags';

// componente
export default function EnviarWhatsappMassa() {

    // variaveis
    const location = useLocation();
    const history = useHistory();
    const [dados, alterarDados] = useState({
        filtrosClientes: location.state.parametros,
        mensagem: '',
        data_agendado: null
    });
    const [salvando, alterarSalvando] = useState(false);
    const [carregando, alterarCarregando] = useState(false);
    
    // carregar modelo de sms com as informaões do cliente
    async function carregarModelo(idModelo) {

        // ativa carregamento
        alterarCarregando(true);

        try {

            // faz a requisição para carregar o modelo com os dados do cliente
            const { data } = await axios.get(`/modelosSms/${idModelo}`);

            // altera mensagem de acordo com o modelo
            alterarDados({
                ...dados,
                mensagem: data.mensagem
            });

        } catch(e) {
            console.error(e?.response);

            toast(({ closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Erro!</strong>
                    <button
                        onClick={closeToast}
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Houve um erro ao carregar o modelo
                </div>
            </>);
        } finally {

            // desativa carregamento
            alterarCarregando(false);
        }
        
    }
    
    // salva
    async function salvar(){

        // ativa carregamento
        alterarSalvando(true);

        try {

            // faz a requisição para enviar whatsapp em massa
            await axios.post(`/clientes/enviarWhatsappMassa`, dados);                        

            // mensagem de cadastro realizado com sucesso
            toast(({ closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Mensagem via WhatsApp enviado para os clientes!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>, { autoClose: 5000 });   

        } catch(e) {
            console.error(e?.response);
            
            // se teve retorno do erro
            if(e?.response?.data) {

                // verifica se é array no retorno
                if(Array.isArray(e.response.data)) {

                    // resgata array de erros
                    const arrayErros = e.response.data;

                    // percorre erros
                    arrayErros.forEach((erro) => {
                        
                        // mostra toast de erro
                        toast(({ closeToast }) => <>
                            <div className="toast-header bg-danger text-white">
                                <strong className="mr-auto">{Object.keys(erro)[0]}</strong>
                                <button
                                    onClick={closeToast}
                                    className="ml-2 mb-1 close btn-outline-light outline-0"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="toast-body bg-danger text-white">
                                {Object.values(erro)[0]}
                            </div>
                        </>, { autoClose: 5000 });

                    });

                } else {

                    // resgata erros
                    const erros = e.response.data;
                    const errosChave = Object.keys(e.response.data);

                    // percorre erros
                    errosChave.forEach((e) => {
                        toast(({ closeToast }) => <>
                            <div className="toast-header bg-danger text-white">
                                <strong className="mr-auto">Erro ao enviar!</strong>
                                <button
                                    onClick={closeToast}
                                    className="ml-2 mb-1 close btn-outline-light outline-0"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="toast-body bg-danger text-white">
                                {erros[e]}
                            </div>
                        </>, { autoClose: 5000 });
                    });

                }
                
            } else {

                toast(({ closeToast }) => <>
                    <div className="toast-header bg-danger text-white">
                        <strong className="mr-auto">Erro ao enviar!</strong>
                        <button
                            onClick={closeToast}
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="toast-body bg-danger text-white">
                        Houve um erro ao enviar
                    </div>
                </>, { autoClose: 5000 });

            }

        } finally {

            // desativa carregamento
            alterarSalvando(false);
        }

    }

    return (<>
        <Row>
            <Col lg={8}>
                <Card className={'border-0 shadow-sm'}>
                    <Card.Header className={'bg-white border-0'}>
                        <Card.Title as='h4'>Enviar WhatsApp em massa</Card.Title>
                    </Card.Header>
                    <Card.Body>

                        <Form.Group>
                            <Form.Label>Mensagem</Form.Label>
                            <Form.Control 
                                as='textarea'
                                value={dados.mensagem}
                                onChange={e => {
                                    alterarDados({
                                        ...dados,
                                        mensagem: e.target.value
                                    });
                                }}
                                style={{ minHeight: 180 }}
                            />
                            </Form.Group>
                        <p className={'text-right'}>
                            <span>Caracteres </span>
                            <Badge
                                variant={'info'}
                            >
                                {dados.mensagem.length}
                            </Badge>
                        </p>
                    </Card.Body>
                </Card>

                <Form.Group className="d-flex justify-content-around my-4">
                    <Button 
                        variant="danger" 
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        <span>Cancelar</span>
                    </Button>
                    <Button 
                        variant="success" 
                        disabled={salvando} 
                        onClick={salvar}
                    >
                        {salvando ? 
                            <>
                                <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                <span>Enviando</span>
                            </> : 
                            <span>Enviar mensagem</span>
                        }
                    </Button>
                </Form.Group>
            </Col>
            <Col lg={4}>
                <Card className={'border-0 shadow-sm'}>
                    <Card.Body>
                        <Form.Row className={'mb-3'}>
                            <Col>
                                <Form.Label>Data</Form.Label>
                                <Flatpick 
                                    className={"form-control"} 
                                    value={dados?.data_agendado?.split(' ')[0] ?? undefined}
                                    options={{
                                        dateFormat: "Y-m-d",
                                        altFormat: "d/m/Y",
                                        altInput: true,
                                        minDate: new Date(),
                                        locale: Portuguese,
                                        onChange: (date, dateStr, instance) => {
                                            if(dateStr === '') dateStr = null;
                                            dados.data_agendado = `${dateStr} ${dados.data_agendado?.split(' ')[1] ?? ''}`;
                                            alterarDados({...dados});  
                                        }
                                    }}
                                    disabled={false}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Hora</Form.Label>
                                <InputHora 
                                    value={dados?.data_agendado?.split(' ')[1] ?? undefined}
                                    onChange={(valor) => {
                                        dados.data_agendado = `${dados.data_agendado?.split(' ')[0] ?? ''} ${valor}`;
                                        alterarDados({...dados});  
                                    }}
                                    aceitarNull={false}
                                    style={{
                                        backgroundColor: '#e9ecef'
                                    }}
                                />
                            </Col>
                        </Form.Row>
                        
                        {dados.mensagem === '' && 
                            <ComboModeloSms 
                                alterou={carregarModelo}
                                tipo={['3']}
                                placeholder='Escolher modelo de WhatsApp'
                            />
                        }
                    </Card.Body>
                </Card>

                <Card className={'border-0 shadow-sm mt-3'}>
                    <Card.Body>
                        <Tags />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        {carregando && 
            <Carregador className="rounded-bottom" />
        }
    </>)

}