import React, { useState } from 'react';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import useIsMounted from 'ismounted';

// componete
export default function BuscarCartaOferta({
    dados,
    alterarDados
}){

    // consultar
    const isMounted = useIsMounted();
    const [carregando, alterarCarregando] = useState(false);

    async function fazerConsulta(){
        alterarCarregando(true);

        try {

            // faz a requisição
            const { data } = await axios.get(`/formularioAcidentesPessoais/cartaOferta`, { 
                params: {
                    cartaOferta: dados.cartaOferta
                } 
            });
            alterarDados({...dados, ...data.dados, bloqueado: 'S'});

        } catch({response}) {
            if(response && response.status && response.status === 400){
                alert('Não encontramos os dados');
            }
        } finally {
            if(isMounted.current) {
                alterarCarregando(false);
            }
        }

    }


    return <Button
        size="sm"
        className="mt-1"
        disabled={carregando}
        onClick={fazerConsulta}
    >
        {carregando ?
            <>Buscando <FontAwesomeIcon pulse icon={faSpinner} /> </>
            :
            <>Buscar meus dados <FontAwesomeIcon icon={faSearch} /></>
        }
        
    </Button>

}