import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const ModalParticipanteComponent = styled(Modal).attrs({
    className: ``
})`

    .carregamento{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        font-size: 1.1rem;

        .icone{
            margin-right: 5px;
        }
    }

`