import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import { useHistory, useParams } from "react-router-dom";
import { Card, Col, Row, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carregador from './../../Componentes/Carregador';
import PaginacaoComponente from './../../Componentes/Paginacao';
// import Parametros from './Parametros';
import Registro from './Registro';
import ModalExcluir from './ModalExcluir';

// componentes

// componente
export default function SolicitacaoPagamento(){

    // varaiveis
    let history = useHistory();
    let [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10,
        ativo: ['S']
    });
    let [totalRegistros, atualizarTotalRegistros] = useState(0);
    let [carregando, mostrarCarregando] = useState(false);
    let [consulta, alterarConsulta] = useState([]);
    let [excluirRegistro, alterarExcluirRegistro] = useState(null);

    // inicializa
    useEffect(() => {

        // consulta inicial
        fazerConsulta();

    }, [parametros]);
    
    // carrega os registros
    function fazerConsulta(){

        // carregando
        mostrarCarregando(true);

        // faz a requisição
        axios.get('/solicitacaoPagamento', {
            params: parametros
        })
        .then(({data, ...retorno}) => {
            alterarConsulta(data.registros);
            atualizarTotalRegistros(parseInt(data.totalRegistros));
        })
        .catch(e => {})
        .then(() => {
            mostrarCarregando(false);
        });
    }


    return <>
        { excluirRegistro && 
            <ModalExcluir 
                registro={excluirRegistro}
                excluido={() => {
                    fazerConsulta();
                }}
                fechou={() => {
                    alterarExcluirRegistro(null);
                }}
            />
        }
        <Row>
            <Col>
                <Card className="painel-consulta border-0 shadow-sm" >
                    <div className="card-header">
                        <div className={'d-flex'}>
                            <h4 className="card-title font-weight-bolder flex-grow-1">Solicitações de pagamento</h4>
                        </div>
                    </div>
                    <Card.Body className={'pt-0'}>
                        <table className="table table-hover tabela">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Nome</th>
                                    <th>Valor</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {consulta.map((registro, a) => 
                                    <Registro 
                                        key={registro.dados.id} 
                                        dados={registro.dados}
                                        parametros={parametros}
                                        onClickTr={() => console.log('clicou TR')}
                                        clicouSolicitacao={() => {
                                            history.push(`/painel/solicitacao-pagamento/alterar/${registro.dados.id}`);
                                        }}
                                        excluir={() => {
                                            alterarExcluirRegistro(registro);
                                        }}
                                    />
                                )}
                            </tbody>
                        </table>
                        <PaginacaoComponente
                            pagina={parametros.pagina}
                            registrosPorPagina={parametros.registrosPorPagina}
                            totalRegistros={totalRegistros}
                            alterouPagina={(pagina) => {
                                alterarParametros({...parametros, pagina: pagina});
                            }}
                        />
                        {carregando && 
                            <Carregador className="rounded-bottom" />
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>

}