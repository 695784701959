import React, { useContext } from 'react';
import { connect } from 'react-redux';

// componentes
import { 
    Map, 
    Marker, 
    GoogleApiWrapper 
} from 'google-maps-react';

// importa icones
import iconePessoaFisica from './../icones/iconePessoaFisica.png';
import iconePessoaFisica2 from './../icones/iconePessoaFisica2.png';

// importa contexto
import { ClientesMapaContexto } from './../../ClientesMapa';

// instanca do mapa do google
function MapaGoogle({
    google
}){
    
    // contextos
    const mapaDados = useContext(ClientesMapaContexto);

    function aplicarCordenadasClienteSelecionado(e){

        // novas cordenadas
        const { latLng } = e;
        const lat = latLng.lat();
        const lng = latLng.lng();
        
        // aplica os dados no cliente selecionado
        // mapaDados.alterarClienteSelecionado.latitude = 
        mapaDados.alterarClienteSelecionado({
            ...mapaDados.clienteSelecionado,
            latitude: lat,
            longitude: lng
        });
        
    }
    
    return <Map
        google={google}
        zoom={mapaDados.zoom}
        style={{
            width: '100%',
            height: '100%',
        }}
        initialCenter={mapaDados.cordenadaCentral}
        center={mapaDados.cordenadaCentral}
        onClick={(props, marker, e) => {
            if(mapaDados.clienteSelecinadoEventos && mapaDados.clienteSelecinadoEventos.draggable){
                aplicarCordenadasClienteSelecionado(e);
            }
        }}
    >
        {mapaDados.clientesProximidade.filter(f => {
            if(mapaDados.clienteSelecionado && f.id === mapaDados.clienteSelecionado.id){
                return false;
            }
            return true;
        }).map((cliente) => { 
            
            return <Marker
                key={cliente.id}
                title={cliente.nome}
                name={cliente.nome}                
                id={cliente.id}
                position={{lat: cliente.latitude, lng: cliente.longitude}} 
                onClick={(props, marker, e) => {
                    
                    mapaDados.alterarCliente(cliente.id);

                    // se clicou nele mesmo, desmarca
                    if(mapaDados.clienteSelecionado === null){
                        mapaDados.alterarClienteSelecionado(cliente);
                    }
                    
                }}
                icon={{
                    url: iconePessoaFisica2,
                    scaledSize: new google.maps.Size(45, 45)
                }}
                zIndex={1}

            /> 
        })}

        {/* // cliente selecionado */}
        {mapaDados.clienteSelecionado &&
            <Marker
                zIndex={2}
                key={mapaDados.clienteSelecionado.id}
                title={mapaDados.clienteSelecionado.nome}
                name={mapaDados.clienteSelecionado.nome}
                id={mapaDados.clienteSelecionado.id}
                position={{lat: mapaDados.clienteSelecionado.latitude, lng: mapaDados.clienteSelecionado.longitude}} 
                onDragend={(props, marker, e) => {
                    aplicarCordenadasClienteSelecionado(e);
                }}
                onClick={(props, marker, e) => {

                    // altera cliente selecionado
                    if(mapaDados.cliente === null && mapaDados.cliente !== mapaDados.clienteSelecionado.id){
                        mapaDados.alterarCliente(mapaDados.clienteSelecionado.id);
                    }

                    // se clicou nele mesmo, desmarca
                    if(mapaDados.clienteSelecionado !== null){
                        mapaDados.alterarClienteSelecionado(null);
                    }

                }}
                icon={{
                    url: iconePessoaFisica,
                    scaledSize: new google.maps.Size(45, 45)
                }}

                // eventos
                draggable={mapaDados.clienteSelecinadoEventos.draggable}
            /> 
        }


    </Map>
}

// pega as propriedades do painel
const mapStateToProps = (state) => {    
    const estado = {
        painel: state.painel,
        login: state.login
    };
    return estado
}

export default connect(mapStateToProps)(
    GoogleApiWrapper(
    (props) => ({
        apiKey: props.chaveApi        
    }))(MapaGoogle)
);