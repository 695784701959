import React, {useState, useEffect} from 'react';
import styles from './index.module.scss';
import moment from 'moment';

// componentes
import BotaoTabela from './../../../Componentes/BotaoTabela';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { converterReal } from './../../../../Funcoes';

export default function Registro({
    dados, 
    clicouSolicitacao,
    excluir
}){

    return <tr>
        <td className="estreito text-center font-weight-bold">{dados.id}</td>
        <td
            className="text-left"
        >
            {dados.nome}
        </td>
        <td className={'text-center estreito text-nowrap'}>
            R$ {converterReal(dados.valor)}
        </td>
        <td className="text-nowrap estreito">
            <BotaoTabela 
                onClick={clicouSolicitacao}
                icone={["fas", "pencil-alt"]}
            >
                Editar
            </BotaoTabela>
            <BotaoTabela 
                onClick={excluir}
                icone={["fas", "trash"]}
            >
                Excluir
            </BotaoTabela>
        </td>
    </tr>

}