import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';
import { useParams } from 'react-router-dom';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';

export default function Permissoes(){

    // estados
    const { id } = useParams();
    const [carregando, alterarCarregando] = useState(true);
    const [salvando, alterarSalvando] = useState(false);
    const [dados, alterarDados] = useState({
        clienteAnexos: '1',
        clienteEnviarEmail: 'N',
        clienteVerRelatorioAtendimentos: 'N',
        clientes: '1',
        dispositivos: '1',
        habilitarCadastroLoginCliente: 'N',
        habilitarGerarPdfPonto: 'N',
        habilitarPontoConsultaUsuario: 'N',
        habilitarTemporariamenteDefinirDispositivo: 'N',
        usuarios: '1',
        usuariosPermissao: 'N',
        habilitarLiberarIpUsuario: 'N'
    });

    // carrega as permissões do usuário
    useEffect(() => {
        consultarChavesUsuario();
    }, []);

    // consulta as chaves do usuários
    async function consultarChavesUsuario(){
        
        try{

            // faz a requisição
            let { data } = await axios.get(`/usuariosPermissao/${id}`);
            alterarDados(data.registros);
            alterarCarregando(false);

        }catch({response}){}
    }

    // salva
    async function salvar(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/usuariosPermissao`, {
                chaves: dados,
                id_usuario: id
            });
            alterarSalvando(false);

        }catch({response}){}
    }

    // carregando
    if(carregando){
        return <div>Carregando...</div>
    }

    // permissões
    return <>

        {/* CONFIGURAÇÕES GERAIS */}
        <div className={[styles.categoria, 'mb-2'].join(' ')}>
            <FontAwesomeIcon className={styles.icone} icon={["fas", "robot"]} />
            <span>Geral</span>
        </div>
        <Row className='mb-4'>
            <Col md='6'>
                <Card className='border-0 shadow-sm ' >
                    <Card.Header className={styles.cabecalho}>
                        <Card.Title as='h6' className={[styles.titulo].join(' ')} >
                        Opções
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Check
                            label='Habilita consultar os backups do sistema'
                            checked={dados.habilitarConsultaBackups === 'S'}
                            id={`consultar-backups-sistema`}
                            onChange={e => {
                                dados.habilitarConsultaBackups = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        {/* USUÁRIOS */}
        <div className={[styles.categoria, 'mb-2'].join(' ')}>
            <FontAwesomeIcon className={styles.icone} icon={["fas", "user-friends"]} />
            <span>Usuários</span>
        </div>
        <Row className='mb-4'>
            <Col md='3'>
                <Card className='border-0 shadow-sm ' >
                    <Card.Header className={styles.cabecalho}>
                        <Card.Title as='h6' className={[styles.titulo].join(' ')} >
                        Geral
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {[
                            {value: '1', label: 'Sem acesso'},
                            {value: '2', label: 'Consulta'},
                            {value: '3', label: 'Cadastro/Edição'},
                            {value: '4', label: 'Exclusão'},
                        ].map(permissao => 
                            <Form.Check
                                key={permissao.value} 
                                {...permissao}
                                type={'radio'}
                                checked={permissao.value === dados.usuarios}
                                id={`usuarios-${permissao.value}`}
                                onChange={e => {alterarDados({...dados, usuarios: e.target.value})}}
                                className={styles.inputCheck}
                            />    
                        )}
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className='border-0 shadow-sm ' >
                    <Card.Header className={styles.cabecalho}>
                        <Card.Title as='h6' className={[styles.titulo].join(' ')} >
                        Opções
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Check
                            label='Habilita Editar as Permissões do Usuário'
                            checked={dados.usuariosPermissao === 'S'}
                            id={`usuarios-permissao`}
                            onChange={e => {
                                dados.usuariosPermissao = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            label='Habilita Acessar a tela de consulta de ponto'
                            checked={dados.habilitarPontoConsultaUsuario === 'S'}
                            id={`habilitar-ponto-consulta-usuario`}
                            onChange={e => {
                                dados.habilitarPontoConsultaUsuario = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            label='Habilita o usuário a gerar pdf dos pontos, inclusive o seu próprio'
                            checked={dados.habilitarGerarPdfPonto === 'S'}
                            id={`habilitar-gerar-pdf-ponto`}
                            onChange={e => {
                                dados.habilitarGerarPdfPonto = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            label='Habilita o usuário a liberar os ips'
                            checked={dados.habilitarLiberarIpUsuario === 'S'}
                            id={`habilitar-liberar-ip-usuario`}
                            onChange={e => {
                                dados.habilitarLiberarIpUsuario = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        <div className={[styles.categoria, 'mb-2'].join(' ')}>
            <FontAwesomeIcon className={styles.icone} icon={["fas", "users"]} />
            <span>Clientes</span>
        </div>
        <Row className='mb-4'>
            <Col md='3'>
                <Card className='border-0 shadow-sm ' >
                    <Card.Header className={styles.cabecalho}>
                        <Card.Title as='h6' className={[styles.titulo].join(' ')} >
                        Geral
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {[
                            {value: '1', label: 'Sem acesso'},
                            {value: '2', label: 'Consulta'},
                            {value: '3', label: 'Cadastro/Edição'},
                            {value: '4', label: 'Exclusão'},
                        ].map(permissao => 
                            <Form.Check
                                key={permissao.value} 
                                {...permissao}
                                type={'radio'}
                                checked={permissao.value === dados.clientes}
                                id={`clientes-${permissao.value}`}
                                onChange={e => {alterarDados({...dados, clientes: e.target.value})}}
                                className={styles.inputCheck}
                            />    
                        )}
                    </Card.Body>
                </Card>     
            </Col>
            <Col md='3'>
                <Card className='border-0 shadow-sm ' >
                    <Card.Header className={styles.cabecalho}>
                        <Card.Title as='h6' className={[styles.titulo].join(' ')} >
                        Anexos
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {[
                            {value: '1', label: 'Sem acesso'},
                            {value: '2', label: 'Consulta'},
                            {value: '3', label: 'Cadastro/Edição'},
                            {value: '4', label: 'Exclusão'},
                        ].map(permissao => 
                            <Form.Check
                                key={permissao.value} 
                                {...permissao}
                                type={'radio'}
                                checked={permissao.value === dados.clienteAnexos}
                                id={`clientes-anexos-${permissao.value}`}
                                onChange={e => {alterarDados({...dados, clienteAnexos: e.target.value})}}
                                className={styles.inputCheck}
                            />    
                        )}
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className='border-0 shadow-sm ' >
                    <Card.Header className={styles.cabecalho}>
                        <Card.Title as='h6' className={[styles.titulo].join(' ')} >
                        Opções
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Check
                            label='Enviar email'
                            checked={dados.clienteEnviarEmail === 'S'}
                            id={`cliente-enviar-email`}
                            onChange={e => {
                                dados.clienteEnviarEmail = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            label='Ver relatório de atendimentos'
                            checked={dados.clienteVerRelatorioAtendimentos === 'S'}
                            id={`cliente-ver-relatorio-atendimentos`}
                            onChange={e => {
                                dados.clienteVerRelatorioAtendimentos = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            label='Habilita cadastro de login e senha para cadastro/edição de cliente'
                            checked={dados.habilitarCadastroLoginCliente === 'S'}
                            id={`habilitar-cadastro-login-cliente`}
                            onChange={e => {
                                dados.habilitarCadastroLoginCliente = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        <div className={[styles.categoria, 'mb-2'].join(' ')}>
            <FontAwesomeIcon className={styles.icone} icon={["fas", "desktop"]} />
            <span>Dispositivos</span>
        </div>
        <Row className='mb-4'>
            <Col md='3'>    
                <Card className='border-0 shadow-sm ' >
                    <Card.Header className={styles.cabecalho}>
                        <Card.Title as='h6' className={[styles.titulo].join(' ')} >
                        Geral
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {[
                            {value: '1', label: 'Sem acesso'},
                            {value: '2', label: 'Consulta'},
                            {value: '3', label: 'Cadastro/Edição'},
                            {value: '4', label: 'Exclusão'},
                        ].map(permissao => 
                            <Form.Check
                                key={permissao.value} 
                                {...permissao}
                                type={'radio'}
                                checked={permissao.value === dados.dispositivos}
                                id={`dispositivos-${permissao.value}`}
                                onChange={e => {alterarDados({...dados, dispositivos: e.target.value})}}
                                className={styles.inputCheck}
                            />    
                        )}
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className='border-0 shadow-sm ' >
                    <Card.Header className={styles.cabecalho}>
                        <Card.Title as='h6' className={[styles.titulo].join(' ')} >
                        Opções
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Check
                            label='Habilitar o usuário a definir o dispositivo temporariamente 
                            até que seja definido'
                            checked={dados.habilitarTemporariamenteDefinirDispositivo === 'S'}
                            id={`habilitar-temporariamente-definir-dispositivo`}
                            onChange={e => {
                                dados.habilitarTemporariamenteDefinirDispositivo = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        <Form.Group className="d-flex justify-content-around my-4">
            <Button variant="danger" onClick={() => {}}>Cancelar</Button>
            <Button variant="success" disabled={salvando} onClick={salvar}>
                {salvando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Salvando</span>
                    </> : 
                    <span>Salvar</span>
                }
            </Button>
        </Form.Group>

    </>
}
