import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

// componentes
import { 
    faListOl, 
    faLock, 
    faDownload, 
    faPencilAlt 
} from '@fortawesome/free-solid-svg-icons';
import { Badge, Form } from 'react-bootstrap';
import BotaoTabela from './../../../Componentes/BotaoTabela';
import { converterReal } from './../../../../Funcoes';

// elemento registro
function Registro({
    dados, 
    diarias,
    cliente,
    fechado,
    clicouEditar,
    selecionado,
    selecionadoBoleto,
    clicouFechar,
    dataMes,
    valorMinimo,
    clicouCheckBox,
    clicouCheckBoxBoleto,
    indice
}){

    const [simulando, alterarSimulando] = useState(false);
    const [desabilitadoFechamento, alterarDesabilitadoFechamento] = useState(false);
    const [desabilidadoBoleto, alterarDesabilidadoBoleto] = useState(
        (
            dados.possuiBoletos === "S" && 
            dados.possuiBoletosEmAberto === "N" 
            && dados.possuiEventos === "S"
        ) || !cliente.idAsaas
    );

    // simula um fechamento
    async function simularFechamento(registroDados){
        alterarSimulando(true);

        try {

            // faz a requisição
            let { data } = await axios.post('/seguroAventura/fechamentoCartaOferta', { 
                dados: {
                    cartaOferta: dados.cartaOferta,
                    data: dataMes,
                    simular: "S",
                    valorMinimo
                }
            });
           
            // faz o download
            window.open(
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=anexosEmail&f=${encodeURIComponent(data.anexo.temporario)}&n=${encodeURIComponent(data.anexo.nome)}`, 
                '_blank'
            );
             
        }catch({response}) {
            console.log(response);
        }finally {
            alterarSimulando(false);
        }
    }

    // função para fazer as verificações do valor da diária
    function verificaValor() {
        
        // verifica se está fechado
        if(fechado !== null) {
            
            // verifica se o fechamento teve valor editado
            if(fechado.valorDiariaEditado) {
                
                // retorna valor editado convertido
                return converterReal(parseFloat(fechado.valorDiariaEditado));

            } else {

                // retorna valor normal
                return converterReal(parseInt(diarias.periodo) * parseFloat(cliente.seguroValorDiaria));

            }

        }

        // verifica se não foi passado valor mínimo e nem editado
        if(!valorMinimo && !cliente.seguroValorDiariaEditado) {

            // retorna valor multiplicado
            return converterReal(parseInt(diarias.periodo) * parseFloat(cliente.seguroValorDiaria));

        } else {

            // se tiver um valor editado
            if(cliente.seguroValorDiariaEditado) {

                // se foi passado um valor mínimo
                if(valorMinimo) {

                    // verifica se o valor editado é menor que o valor mínimo
                    if(parseFloat(cliente.seguroValorDiariaEditado) < parseFloat(valorMinimo)) {

                        // retorna o valor mínimo
                        return converterReal(valorMinimo.replace(',', '.'));
        
                    } else {

                        // retorna o valor editado
                        return converterReal(cliente.seguroValorDiariaEditado);        

                    }

                }

                // retorna o valor editado
                return converterReal(cliente.seguroValorDiariaEditado);

            } else {

                // se foi passado o valor mínimo
                if(valorMinimo) {

                    // verifica se o valor do valor é menor que o valor mínimo
                    if((parseInt(diarias.periodo) * parseFloat(cliente.seguroValorDiaria)) < parseFloat(valorMinimo)) {

                        // retorna o valor mínimo
                        return converterReal(valorMinimo.replace(',', '.'));
        
                    } else {

                        // retorna valor multiplicado
                        return converterReal(parseInt(diarias.periodo) * parseFloat(cliente.seguroValorDiaria));

                    }

                }

                // retorna valor multiplicado
                return converterReal(parseInt(diarias.periodo) * parseFloat(cliente.seguroValorDiaria));

            }

        }

    }
    
    useEffect(() => {

        // se cliente estiver desabilitado
        if(cliente.clienteDesabilitado === "S") {

            // desabilitado checks
            alterarDesabilitadoFechamento(true);
            alterarDesabilidadoBoleto(true);

        }

    }, []);
    
    return <tr
        className={`border-bottom`}
    >
        <td className='estreito text-center'>
            <Form.Check 
                type={'checkbox'}
                inline
                checked={selecionado}
                id={dados.id + indice}
                onChange={(e) => {
                    clicouCheckBox({
                        selecao: selecionado ? true : false,
                        indice
                    });
                }}
                style={{ cursor: 'pointer' }}
                disabled={desabilitadoFechamento}
            />
        </td>
        <td className='estreito text-center'>
            <Form.Check 
                type={'checkbox'}
                inline
                checked={selecionadoBoleto}
                id={dados.id + indice}
                onChange={(e) => {
                    clicouCheckBoxBoleto({
                        selecao: selecionadoBoleto ? true : false,
                        indice
                    });
                }}
                style={{ cursor: 'pointer' }}
                disabled={desabilidadoBoleto}
            />
        </td>
        <td className="estreito text-center font-weight-bold text-nowrap">{dados.cartaOferta}</td>
        <td style={!cliente.idAsaas ? { color:  'red', fontWeight: 'bold' } : {}}>{cliente.nome}</td>        
        <td className="estreito text-right font-weight-bold text-nowrap">
            <Badge 
                variant={
                    (
                        fechado === null ? (
                            cliente.semFaturamentoMinimo === "S" ? 
                            "danger" : 
                            "warning"
                        ) : 
                        "success"
                    )
                }
            >
                {                    
                    fechado === null ? 
                    (
                        cliente.semFaturamentoMinimo === "S" ? 
                        "Sem faturamento" : 
                        "Aberto"
                    ) : 
                    "Fechado"                    
                }
            </Badge>
        </td>
        <td
            className="estreito text-right font-weight-bold text-nowrap"
        >

            { parseInt(diarias.participantes) > 0 &&
                <Badge
                    variant='info'
                    title="Participantes"
                    className="mr-2"
                >
                    {diarias.participantes}
                </Badge>
            }

            { parseInt(diarias.periodo) > 0 &&
                <Badge
                    variant='primary'
                    title="Participantes"
                    className="mr-2"
                >
                    {diarias.periodo}
                </Badge>
            }


            { (fechado !== null) &&
                <Badge
                    variant='success'
                    title="Participantes"
                >
                    R$ {(fechado.valorDiariaEditado && fechado.valorDiariaEditado > 0) ? converterReal(fechado.valorDiariaEditado) : converterReal(parseInt(fechado.quantidadeDiarias) * parseFloat(fechado.valorDiaria))}
                </Badge>
            }

            { (!fechado) &&
                <Badge
                    variant='success'
                    title="Participantes"
                >
                    {(dados.possuiBoletos === "S" && dados.possuiBoletosEmAberto === "N" && dados.possuiEventos === "S") ? 'Eventos pagos' :`R$ ${verificaValor()}`}
                </Badge>
            }

        </td>
        <td className="text-nowrap text-right estreito">

            {/* se possuir participantes */}
            {parseInt(diarias.participantes) > 0 && 
                <BotaoTabela 
                    onClick={simularFechamento}
                    icone={fechado ? faDownload : faListOl}
                    carregando={simulando}
                >
                    {!fechado ? 'Simular fechamento' : 'Baixar fechamento'}
                </BotaoTabela>
            }

            {/* se estiver aberto e possuir participantes */}
            {(fechado === null && cliente.clienteDesabilitado === "N" && parseInt(diarias.participantes) > 0) &&
                <BotaoTabela 
                    onClick={clicouFechar}
                    icone={faLock}
                >
                    Concluir fechamento
                </BotaoTabela>
            } 

            {/* se não estiver fechado */}
            {(fechado === null && cliente.clienteDesabilitado === "N") &&
                <BotaoTabela 
                    onClick={clicouEditar}
                    icone={faPencilAlt}
                >
                    Editar
                </BotaoTabela>
            }
            
        </td>
    </tr>
}

export default Registro;