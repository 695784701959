import React, { useState } from 'react';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BotaoTabela from './../../../Componentes/BotaoTabela';

// elemento registro
export default function Registro({
    dados,
    clicouEditar,
    clicouPermissoes,
    clicouPonto,
    clicouTransferir,
    onClickTr,
    permissao,
    alterouStatus,
    clicouLiberarIp
}){

    // estados
    let [alterandoStatus, alterarStatus] = useState(false);

    // clicou para inativar/ativar
    async function ativarOuInativar(){
        alterarStatus(true);

        try{

            // faz a requisição
            let statusNovo = (dados.status === 'A') ? 'I' : 'A';
            let { data } = await axios.post(`/usuarios`, {
                dados: {
                    id: dados.id,
                    status: statusNovo
                }
            });
            alterouStatus(statusNovo);

        }catch({response}){}
        finally{
            alterarStatus(false);
        }
    }
    
    return <tr onClick={onClickTr}>
        <td className="estreito text-center font-weight-bold">{dados.id}</td>
        <td>{dados.login}</td>
        <td className="text-nowrap estreito">
            
            { permissao.habilitarLiberarIpUsuario === 'S' &&
                <BotaoTabela 
                    onClick={clicouLiberarIp}
                    icone={["fas", "wifi"]}
                >Liberar Ip</BotaoTabela>
            }

            { parseInt(permissao.usuarios) > 1 &&
                <BotaoTabela 
                    onClick={clicouEditar}
                    icone={["fas", "pencil-alt"]}
                >Editar</BotaoTabela>
            }

            { permissao.usuariosPermissao === 'S' &&
                <BotaoTabela 
                    onClick={clicouPermissoes}
                    icone={["fas", "user-lock"]}
                >Permissão</BotaoTabela>
            }

            { permissao.habilitarPontoConsultaUsuario === 'S' &&
                <BotaoTabela 
                    onClick={clicouPonto}
                    icone={["fas", "clock"]}
                >Ponto</BotaoTabela>
            }

            <BotaoTabela 
                onClick={clicouTransferir}
                icone={["fas", "recycle"]}
            >Transferir informações</BotaoTabela>

            {(dados.status === 'A')? 
                <BotaoTabela 
                    onClick={ativarOuInativar}
                    icone={["fas", "toggle-on"]}
                    color="#219221"
                    carregando={alterandoStatus}
                >   
                    Ativo
                </BotaoTabela>
                : 
                <BotaoTabela 
                    onClick={ativarOuInativar}
                    icone={["fas", "toggle-off"]}
                    carregando={alterandoStatus}
                >
                    Inativo
                </BotaoTabela>
            }

        </td>
    </tr>
}
