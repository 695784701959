import React from 'react';
import MaskedInput from 'react-maskedinput';

// funções
import { validarData } from './../../../Funcoes';

// componentes
import { Form } from 'react-bootstrap';

const InputData = React.forwardRef(({
    data,
    onChange,
    placeholder='',
    size="md",
    isInvalid=false,
    readOnly=false,
    style,
    ...props
}, ref) => {

    return <>
        <MaskedInput 
            placeholder={placeholder}
            mask="11/11/1111"
            ref={ref}
            value={(data === null)? '' : data.split('-').reverse().join('/')} 
            onChange={(e) => {
                data = e.target.value.split('_').join('').split('/').reverse().join('-');
                if(e.target.value === ''){
                    data = null;
                }
                onChange(data);
            }}
            className={["form-control", isInvalid ? 'is-invalid' : '' , validarData(data, 'invalid') ? 'is-invalid': '', size === "sm" && "form-control-sm"].join(' ')}
            readOnly={readOnly}
            style={style}
            {...props}
        />
        { validarData(data, 'invalid') &&
            <Form.Control.Feedback type="invalid">Data inválida</Form.Control.Feedback>
        }
    </>
})

// retorna
export default InputData; 