import React, { useState } from 'react';
import styles from './index.module.scss';
import imagemVazia from './imagem.png';
import moment from 'moment';
import imagemBalao from './balao.png';
import { useHistory } from 'react-router-dom';

// componentes
import BotaoAniversariante from './BotaoAniversariante';

function CardAniversariante({
    dados,
    parabenizar,
    parabenizarSms
}){

    // estados
    const history = useHistory();
    const [parabenizarOpcao, alterarParabenizarOpcao] = useState(false);

    // dados
    let aniversarioHoje = false;
    if(moment().format('MMDD') === moment(dados.data_nascimento).format('MMDD')){
        aniversarioHoje = true;
    }

    function abrirFormularioCliente(){
        history.push(`/painel/clientes/alterar/${dados.id}`);
    }

    return <div className={[styles.cardAniversariante, aniversarioHoje && styles.parabenizar, 'rounded mb-2 p-2'].join(' ')}>
        
        <div className={[styles.info].join(' ')}>
            
            { (aniversarioHoje) &&
                <img className={styles.balao} src={imagemBalao} />
            }

            { dados.foto ?
                <img className={styles.foto} onClick={abrirFormularioCliente} src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/fotos/${dados.foto}`} />
                :
                <img className={styles.foto} onClick={abrirFormularioCliente} src={imagemVazia} />
            }

            <div className={styles.dados}>
                <div className={[styles.nome, 'text-truncate'].join(' ')}>{dados.nome}</div>
                <div className={styles.idade}>
                    { dados.data_nascimento &&
                        <small 
                            title={`Completando ${dados._idade_referencia} anos, atualmente possui ${moment().diff(dados.data_nascimento, 'years')} anos`}
                        >
                            {dados._idade_referencia} anos
                        </small>
                    }
                    <div
                        className='d-flex'
                    >
                        { (dados._emailAniversarioEnviado || dados._smsAniversarioEnviado) && 
                            <BotaoAniversariante 
                                label={`${dados._emailAniversarioEnviado ? 'Email' : ''}${(dados._smsAniversarioEnviado && dados._emailAniversarioEnviado) ? ' e ' : ''}${dados._smsAniversarioEnviado ? 'SMS' : ''} enviado`}
                                className={styles.emailEnviado}
                                icone={["fas", 'thumbs-up']}
                                onClick={() => {
                                    alterarParabenizarOpcao(true);
                                }}
                            />
                        }
                        { (!parabenizarOpcao && ((!dados._emailAniversarioEnviado || !dados._smsAniversarioEnviado))) &&
                            <BotaoAniversariante 
                                label='Parabenizar'
                                icone={["fas", 'handshake']}
                                onClick={() => {
                                    alterarParabenizarOpcao(true);
                                }}
                            />
                        }

                        { parabenizarOpcao &&
                            <>
                            { !dados._smsAniversarioEnviado &&
                                <BotaoAniversariante 
                                    label='Parabenizar com SMS'
                                    icone={["fas", 'sms']}
                                    onClick={parabenizarSms}
                                />
                            }

                                { !dados._emailAniversarioEnviado &&
                                    <BotaoAniversariante 
                                        label='Parabenizar com email'
                                        icone={["fas", 'envelope']}
                                        onClick={parabenizar}
                                    />
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default CardAniversariante;
