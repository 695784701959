import styled from 'styled-components';

export const ContainerComponent = styled.div.attrs({
    className: `mt-3`
})`

    margin-left: 40px;

    .card-renovar {
        ${props => (props.edicao && props.etapa !== '4') && `
            visibility: hidden;
        `}
    }
    

`;