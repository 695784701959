import React from 'react';

import { Form, Row, Col } from 'react-bootstrap';
import InputData from './../../../Painel/Componentes/InputData';

// retorna formulario
function FormularioResidente({
    dados,
    alterarDados,
    funcaoAlterarDados,
    numeroResidente
}){

    return <>
        <h6 className=' mb-4'>Residente {numeroResidente}</h6>

        <Form.Row>
            <Form.Group as={Col} lg='8' >
                <Form.Label>{numeroResidente}. Nome</Form.Label>
                <Form.Control
                    value={dados[`residente_${numeroResidente}_nome`]}
                    name={`residente_${numeroResidente}_nome`}
                    onChange={funcaoAlterarDados}
                    maxLength='100'
                />
            </Form.Group>
            <Form.Group as={Col} >
                <Form.Label>{numeroResidente}. Data de nascimento</Form.Label>
                <InputData 
                    data={dados[`residente_${numeroResidente}_data_nascimento`]}
                    onChange={(data) => {
                        dados[`residente_${numeroResidente}_data_nascimento`] = data;
                        alterarDados({...dados});
                    }}
                />
            </Form.Group>
        </Form.Row>
        
        {/* mascara */}
        <Form.Row>
            <Form.Group as={Col} lg='8' >
                <Form.Label>{numeroResidente}. CPF</Form.Label>
                <Form.Control
                    value={dados[`residente_${numeroResidente}_cpf`]}
                    name={`residente_${numeroResidente}_cpf`}
                    onChange={funcaoAlterarDados}
                    maxLength='100'
                />
            </Form.Group>
            <Form.Group as={Col} >
                <Form.Label>{numeroResidente}. Grau de Parentesco</Form.Label>
                <Form.Control
                    value={dados[`residente_${numeroResidente}_grau_parentesco`]}
                    name={`residente_${numeroResidente}_grau_parentesco`}
                    onChange={funcaoAlterarDados}
                    maxLength='10'
                />
            </Form.Group>
        </Form.Row>


    </>
}

export default FormularioResidente;