import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardAniversariante from './CardAniversariante';

// componente
export default function Aniversariantes({
    dataBase
}){

    // estados
    const history = useHistory();
    const [registros, alterarRegistros] = useState([]);

    // inicializa
    useEffect(() => {
        consultarRegistros();
    }, [dataBase]);
    
    // consultar registros
    async function consultarRegistros(){

        try {

            const { data } = await axios.get(`/clientes/aniversariantes`, {
                params: {
                    data: dataBase
                }
            });
            alterarRegistros(data.datas);

        }catch({response}){

        }finally{

        }

    }

    return <div className={[styles.container, 'rounded p-2  h-100'].join(' ')}>
        
        <div
            className={[styles.aniversariantes, ' p-1 '].join(' ')}
        >

            {registros.map(registro => 
                <React.Fragment key={registro.data}>
                    <p className={styles.data}>{moment(registro.data).format('DD/MM/YYYY')} <FontAwesomeIcon className={styles.icone} icon={["fas", 'calendar-day']} /></p>
                    { registro.registros.map(aniversario => 
                        <CardAniversariante 
                            key={aniversario.id}
                            dados={aniversario}
                            parabenizar={idade => {
                                history.push(`/painel/clientes/enviar-email-aniversariante/${aniversario.id}/${aniversario._idade_referencia}`);
                            }}
                            parabenizarSms={() => {
                                history.push(`/painel/clientes/enviar-sms-aniversariante/${aniversario.id}/${aniversario._idade_referencia}`);
                            }}
                        />
                    )}
                </React.Fragment>    
            )}

        </div>

    </div>
}