import React, { useState } from 'react';

// componentes
import { Button, Form, Row, Col, Container, Card, Alert } from 'react-bootstrap';
import InputValor from './../../../Painel/Componentes/InputValor';
import Cartao from './Cartao';

export default function Formulario({
    dados,
    alterarDados,
    erros=null
}){

    return <>

        <Form.Group>
            { dados.id === null &&
                <Form.Label>Selecione a bandeira do seu cartão de crédito</Form.Label>
            }
            <Cartao 
                dados={dados}
                alterarDados={alterarDados}
            />
            { (erros && erros.bandeira) &&
                <Alert
                    className='border-0'
                    variant='danger'
                    size='sm'
                >{erros.bandeira}</Alert>
            }
        </Form.Group>
        

        <Form.Group>
            <Form.Label>Nome (igual ao do cartão)</Form.Label>
            <Form.Control 
                value={dados.nome}
                onChange={e => {
                    dados.nome = e.target.value;
                    alterarDados({...dados});
                }}
            />
        </Form.Group>
        <Form.Row>
            <Col lg={6}>
                <Form.Group>
                    <Form.Label>Número do cartão</Form.Label>
                    <Form.Control 
                        value={dados.numero}
                        onChange={e => {
                            dados.numero = e.target.value;
                            alterarDados({...dados});
                        }}
                    />
                </Form.Group>
            </Col>
            <Col lg={3}>
                <Form.Group>
                    <Form.Label>Vencimento</Form.Label>
                    <Form.Control
                        placeholder='00/0000'
                        value={dados.vencimento}
                        onChange={e => {
                            dados.vencimento = e.target.value;
                            alterarDados({...dados});
                        }}
                    />
                </Form.Group>
            </Col>
            <Col lg={3}>
                <Form.Group>
                    <Form.Label>Cód. Segurança</Form.Label>
                    <Form.Control 
                        value={dados.codigo}
                        onChange={e => {
                            dados.codigo = e.target.value;
                            alterarDados({...dados});
                        }}
                    />
                </Form.Group>
            </Col>
        </Form.Row>
        <Form.Row>
            <Col lg={{span: 4}}>
                <Form.Group>
                    <Form.Label>Vencimento Fatura</Form.Label>
                    <Form.Control 
                        placeholder='00/00/0000'
                        value={dados.vencimento_fatura}
                        onChange={e => {
                            dados.vencimento_fatura = e.target.value;
                            alterarDados({...dados});
                        }}
                    />
                </Form.Group>
            </Col>
            <Col lg={{span: 4, offset: 4}}>
                <Form.Group>
                    <Form.Label>Valor R$</Form.Label>
                    <InputValor 
                        valor={dados.valor || '0.00'}
                        onChange={valorNovo => {
                            dados.valor = valorNovo;
                            alterarDados({...dados});
                        }}
                    />
                </Form.Group>
            </Col>
        </Form.Row>
    </>

}