import React from 'react';
import styles from './index.module.scss';
import moment from 'moment';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Badge, Button } from 'react-bootstrap';

// filtra data
export default function FiltroDataCabecalho({
    value,
    onChange,
    carregando=false,
    periodo="mensal",
    className=""
}){

    return <>
        <Card className={['border-0 shadow-sm', className].join(' ')}>
            <Card.Body className='p-2'>
                <div className={[styles.cabecalho, 'd-flex justify-content-between align-items-center'].join(' ')}>
                    <Button
                        variant='padrao'
                        size='sm'
                        onClick={() => {
                            let dataNova = null;
                            if(periodo === "diario"){
                                dataNova = moment(value).subtract(1, 'day');
                            }else{
                                dataNova = moment(value).subtract(1, 'month');
                            }
                            onChange(dataNova.format('YYYY-MM-DD'));
                        }}
                        disabled={carregando}
                    >
                        <FontAwesomeIcon className='icone' icon={['fas', 'angle-left']} />
                        {periodo === "diario" && 
                            <span>{moment(value).subtract(1, 'day').format('DD/MM')}</span>
                        }
                        {periodo === "mensal" && 
                            <span>{moment(value).subtract(1, 'month').format('MM/YYYY')}</span>
                        }
                    </Button>
                    <span>
                        { periodo === "diario" && moment(value).format('DD, MMMM [de] YYYY')}
                        { periodo === "mensal" &&  moment(value).format('MMMM [de] YYYY')}
                    </span>
                    <Button
                        variant='padrao'
                        size='sm'
                        onClick={() => {
                            let dataNova = null;
                            if(periodo === "diario"){
                                dataNova = moment(value).add(1, 'day');
                            }else{
                                dataNova = moment(value).add(1, 'month');
                            }
                            onChange(dataNova.format('YYYY-MM-DD'));
                        }}
                        disabled={carregando}
                    >
                        {periodo === "diario" && 
                            <span>{moment(value).add(1, 'day').format('DD/MM')}</span>
                        }
                        {periodo === "mensal" && 
                            <span>{moment(value).add(1, 'month').format('MM/YYYY')}</span>
                        }
                        <FontAwesomeIcon className='icone' icon={['fas', 'angle-right']} />
                    </Button>
                </div>
            </Card.Body>
        </Card>
    </>

}

