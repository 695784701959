import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Container, Button, Form } from 'react-bootstrap';

// excluir registro
export default function ModalExcluir({
    id,
    fechou,
    excluido
}){

    // estados
    const [mostrar, alterarMostrar] = useState(true);
    const [salvando, alterarSalvando] = useState(false);

    // exclui registro
    async function excluir(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.delete(`/grupos/${id}`);
            excluido();
            alterarMostrar(false);

        }catch({response}){

        }finally{
            alterarSalvando(false);
        }
        
    }

    return <>
        <Modal 
            show={mostrar} 
            onExited={fechou}
            onHide={() => {}}
            size={'sm'}
        >
            <Modal.Body>
                <p
                    className='text-center m-0 text-danger font-weight-bold'
                >
                    O grupo será removido permanentemente!<br /><br />
                    Isso irá desvincular este grupo dos clientes também, deseja continuar?
                </p>
            </Modal.Body>
            <Modal.Footer  className='border-0'>
                <Button
                    variant='danger'
                    onClick={() => {alterarMostrar(false)}}
                    disabled={salvando}
                    size='sm'
                >
                    <span>Desistir</span>
                </Button>
                <Button
                    onClick={excluir}
                    variant='success'
                    disabled={salvando}
                    size='sm'
                >
                    {salvando ? 
                        <>
                            <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                            <span>Excluindo</span>
                        </> : 
                        <span>Quero excluir!</span>
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    </>

}