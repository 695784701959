import React, { useState } from 'react';
import { 
    Badge, 
    OverlayTrigger, 
    Tooltip 
} from 'react-bootstrap';
import { 
    faBarcode, 
    faScroll 
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import moment from 'moment';

// componentes
import { converterReal } from "./../../../../Funcoes";
import BotaoTabela from './../../../../Painel/Componentes/BotaoTabela';

// elemento
function Registro({
    dados,
    clicouAbrir
}) {

    // estados
    const [gerandoXls, alterarGerandoXls] = useState(false);
    const [gerandoFaturamento, alterarGerandoFaturamento] = useState(false);

    // gerar xls de fechamento
    async function baixarEventosXlsFechamento() {

        // ativa carregamento
        alterarGerandoXls(true);

        try {

            // gera xls
            const { data } = await axios.get(`/seguroAventuraFechamento/eventosFechamentoXls/${dados.dados.id}`);

            // faz o download
            window.open(
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=temp&f=${encodeURIComponent(data.temporario)}&n=${encodeURIComponent(data.nome)}`,
                '_blank'
            );

        } catch (e) {
            console.error(e);
        } finally {

            // desativa carregamento
            alterarGerandoXls(false);

        }

    }

    // realiza a requisição para realizar o download do pdf referente ao faturamento do mes selecionado
    async function baixarResumoFaturamentoMensal() {

        // bota pra carregar
        alterarGerandoFaturamento(true);

        // prepara parametros
        const params = {
            dataFechamento: moment(dados.dados.data).format('MMMM / YYYY'),
            linkCobranca: dados.linkFatura,
            participantes: parseInt(dados.soma.participantes),
            diarias: dados.soma.periodo,
            total: (dados.dados.valorDiariaEditado && dados.dados.valorDiariaEditado > 0) ? dados.dados.valorDiariaEditado : parseInt(dados.dados.quantidadeDiarias) * parseFloat(dados.dados.valorDiaria)
        }

        // gera xls
        const { data } = await axios.get(`/seguroAventura/gerarResumoFaturamento/${dados.dados.id}`, {
            params
        });

        // faz o download
        window.open(
            `${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=anexosEmail&f=${encodeURIComponent(data.temporario)}&n=${encodeURIComponent(data.nome)}`,
            '_blank'
        );

        // esconde carregador
        alterarGerandoFaturamento(false);

    }

    // verifica cor de acordo com status do boleto
    function verificaStatusCor() {

        // verifica se não veio status 
        if (!dados.statusBoleto) return {
            cor: "unset",
            status: ""
        }

        // verifica se foi recebida ou confirmada
        if (dados.statusBoleto == "S") {

            // retorna verde
            return {
                status: "Pago",
                cor: "green"
            }

            // verifica se status está atrasado ou estornado 
        } else if (dados.statusBoleto == "N") {

            // retorna vermelho
            return {
                status: "Vencido",
                cor: "red",
            }

            // verifica se o status está pendente
        } else if (dados.statusBoleto == "P") {

            // retorna laranja
            return {
                status: "Pendente",
                cor: "orange"
            }

        } else {

            // retorna preto
            return {
                status: "",
                cor: "black"
            }

        }

    }

    // verifica se está pago ou mostrar o valor final
    function verificaSituacaoValor() {

        // prepara string de retorno
        let valorString = '';

        // verifica se possui valor de diaria editado (valor final) e se não está com todos os eventos pagos
        if (dados.dados.valorDiariaEditado && dados.dados.valorDiariaEditado > 0 && dados.todosEventosPagos === 'N') {

            // adiciona valor ediado ao retorno da string
            valorString = 'R$ ' + converterReal(dados.dados.valorDiariaEditado);

            // se todos os eventos estiverem pagos
        } else if (dados.todosEventosPagos === "S") {

            // adiciona mensagem a string de retorno
            valorString = 'Eventos pagos';

        } else {

            // adiciona valor calculo a string de retorno
            valorString = 'R$ ' + converterReal(parseInt(dados.dados.quantidadeDiarias) * parseFloat(dados.dados.valorDiaria))
        }

        // retorna mensagem final
        return valorString;

    }

    return <tr style={{ cursor: 'pointer' }}>
        <td className="estreito text-center font-weight-bold">
            {moment(dados.dados.data).format('MMMM / YYYY')}
        </td>
        <td className="text-nowrap estreito text-center">
            {dados.linkFatura ?
                <OverlayTrigger
                    placement='top'
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            {verificaStatusCor().status}
                        </Tooltip>
                    }
                >
                    <a
                        href={dados.linkFatura}
                        style={{ color: verificaStatusCor().cor }}
                        className="d-flex flex-column align-items-center justify-content-center"
                    >
                        <FontAwesomeIcon
                            icon={faBarcode}
                            style={{ fontSize: 30 }}
                        />
                        Boleto
                    </a>
                </OverlayTrigger>
                : ''}
        </td>
        <td className="text-nowrap estreito text-center">
            {parseInt(dados.soma.participantes) > 0 &&
                <Badge
                    variant='info'
                    title="Participantes"
                    className="mr-2"
                >
                    {dados.soma.participantes}
                </Badge>
            }
        </td>
        <td className="text-nowrap estreito text-center">
            <Badge
                variant='primary'
                title="Participantes"
                className="mr-2"
            >
                {dados.soma.periodo}
            </Badge>
        </td>
        <td className="text-nowrap estreito text-center">
            <Badge
                variant='success'
                title="Participantes"
            >
                {verificaSituacaoValor()}
            </Badge>
        </td>
        <td className="text-nowrap estreito text-right">
            <BotaoTabela
                carregando={gerandoXls}
                onClick={baixarEventosXlsFechamento}
                icone={["fas", "file-excel"]}
                color={'green'}
            >
                Exportar dados eventos
            </BotaoTabela>
            <BotaoTabela
                carregando={gerandoFaturamento}
                onClick={baixarResumoFaturamentoMensal}
                icone={faScroll}
                color={'blue'}
            >
                Resumo do faturamento
            </BotaoTabela>
        </td>
    </tr>

}

export default Registro;