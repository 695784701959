import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { Button, Modal, Form, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ConsultarContexto } from './../../ConsultarContexto';
import InputData from './../../../../Componentes/InputData';
import InputHora from './../../../../Componentes/InputHora';
import { intersectObject } from './../../../../../Funcoes'
import { toast } from 'react-toastify';


// exclui evento
export default function BotaoEditar({
    id
}){

    // estados
    const [mostrar, alterarMostrar] = useState(false);
    const { parametros, alterarParametros, fazerConsulta } = useContext(ConsultarContexto);
    const [salvando, alterarSalvando] = useState(false);
    const dadosPadrao = {
        id: null,
        dataInicio: null,
        horaInicio: null,
        dataFinal: null,
        horaFinal: null,
        email: null,
        tipoPessoa: null,
        cartaOferta: null
    };
    const [ dados, alterarDados ] = useState(null);
    const [ carregando, alterarCarregando ] = useState(true);
    const [ erros, alterarErros ] = useState({});
    

    // se confirma exclui
    async function salvar(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/seguroAventura`, {
                dados: intersectObject(dadosPadrao, dados),
                painel: true  // ignora validações de data
            });
            
            // altera os dados de retorno
            toast(({closeToast }) => <>
                <div className="toast-header d-block">
                    <strong className="mr-auto">Evento salvo!</strong>
                    <div className="toast-body">
                        Evento salvo com sucesso
                    </div>
                </div>
            </>);
            fazerConsulta();
            alterarMostrar(false);

        }catch({response}){
            if(response && response.status && response.status === 400){
                alterarErros(response.data);
            }
        }
        finally{
            alterarSalvando(false);
        }
    }


    // se confirma exclui
    async function consultarId(){
        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/seguroAventura/${id}`);
            alterarDados(data.dados);
            alterarCarregando(false);

        }catch({response}){}
        finally{}
    }

    return <>
        <Button
            className="rounded-pill p-1"
            variant="primary"
            onClick={e => {
                alterarMostrar(true);
                consultarId();
            }}
        >
            <FontAwesomeIcon icon={faPencilAlt} size="xs" />
        </Button>

        <Modal 
            show={mostrar} 
            onExited={() => {}}
            onHide={() => {}}
            size={'md'}
        >
            <Modal.Header className='border-0'>
                <Modal.Title as="h5">
                    Editar evento
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <p>Tem certeza que quer excluir o evento?</p> */}

                {carregando ? 
                    <>Aguarde <FontAwesomeIcon pulse icon={faSpinner} /></>
                : 
                    <>
                        <p className="text-center">{dados.nomeEvento}</p>

                        <Form.Row>
                            <Form.Group as={Col} lg='12'>
                                <Form.Label><small>Data / Horario Inicio do evento</small></Form.Label>
                                <Form.Row>
                                    <Col lg='4'>
                                        <InputData 
                                            data={dados.dataInicio}
                                            onChange={(data) => {
                                                dados.dataInicio = data;
                                                alterarDados({...dados});
                                            }}
                                            size="sm"
                                            readOnly={dados.propriedade==='S'}
                                        />
                                    </Col>
                                    <Col lg='4'>
                                        <InputHora 
                                            className='text-center'
                                            value={dados.horaInicio}
                                            onChange={novaHora => {
                                                dados.horaInicio = novaHora;
                                                alterarDados({...dados});
                                            }}
                                            size="sm"
                                        />
                                    </Col>
                                </Form.Row>
                                {erros.dataInicio &&
                                    <p className="text-danger">
                                        {erros.dataInicio}
                                    </p>
                                }
                            </Form.Group>
                        </Form.Row>
                
                        <Form.Row>
                            <Form.Group as={Col} lg='12'>
                                <Form.Label><small>Data/Horario Final do evento</small></Form.Label>
                                <Form.Row>
                                    <Col lg='4'>
                                        <InputData 
                                            data={dados.dataFinal}
                                            onChange={(data) => {
                                                dados.dataFinal = data;
                                                alterarDados({...dados});
                                            }}
                                            size="sm"
                                            readOnly={dados.propriedade==='S'}
                                        />
                                    </Col>
                                    <Col lg='4'>
                                        <InputHora 
                                            className='text-center'
                                            value={dados.horaFinal}
                                            onChange={novaHora => {
                                                dados.horaFinal = novaHora;
                                                alterarDados({...dados});
                                            }}
                                            size="sm"
                                        />
                                    </Col>
                                </Form.Row>
                                {erros.dataFinal &&
                                    <p className="text-danger">
                                        {erros.dataFinal}
                                    </p>
                                }
                            </Form.Group>
                        </Form.Row>
                    </>
                }





            </Modal.Body>
            <Modal.Footer  className='border-0'>
                <Button
                    variant='padrao'
                    onClick={() => {alterarMostrar(false)}}
                    disabled={salvando}
                >
                    <span>Voltar</span>
                </Button>
                <Button
                    onClick={salvar}
                    variant='success'
                    disabled={salvando}
                >
                    {salvando ? 
                        <>
                            <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                            <span>Salvando</span>
                        </> : 
                        <span>Salvar</span>
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    </>

}