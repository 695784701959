import React from 'react';
import moment from 'moment';

// componentes
import BotaoTabela from './../../../../Componentes/BotaoTabela';
import { 
    faCalendarCheck, 
    faCalendarTimes, 
    faClipboardList, 
    faSignOutAlt, 
    faTable
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'react-bootstrap';

// elemento registro
function Registro({
    dados, 
    evento,
    clicouEditar,
    clicouExcluir
}){
    
    return <tr className="clicavel" onClick={clicouEditar}>
        <td 
            className={[
                'estreito text-center font-weight-bold',
                evento.integrado === "S" ? 'text-success' : 'text-info'
            ].join(` `)}
        >
            <FontAwesomeIcon className='mr-1' icon={evento.integrado === "S" ? faTable : faClipboardList} />
        </td>
        <td>
            {dados.nome} <br />
            <small><Badge vartiant="secondary">{evento.cartaOferta}</Badge> {evento.nomeEvento}</small>
        </td>
        <td className="text-center">
            {dados.dataNascimento && 
                <small>{moment(dados.dataNascimento).format('DD/MM/YYYY')}</small>
            }
        </td>
        <td className="text-center">
            <small>{dados.cpf}</small>
        </td>
        {dados.cancelado === 'S' && 
            <>
                <td className='estreito text-center font-weight-bold text-nowrap text-danger'>
                    <Badge variant="danger">
                        cancelado
                    </Badge>
                </td>
                <td className='estreito text-center font-weight-bold text-nowrap text-danger'>
                    <Badge variant="danger">
                        <FontAwesomeIcon icon={faCalendarTimes} className="mr-1" /> 
                        {moment(dados.dataCancelado).format('DD/MM')}
                    </Badge>
                </td>
            </>
        }        
        {dados.cancelado === 'N' && 
            <>
                <td className='estreito text-center font-weight-bold text-nowrap'>
                    <Badge variant="info">
                        <FontAwesomeIcon icon={faCalendarCheck} className="mr-1" /> 
                        {moment(dados.dataEntrada).format('DD/MM')}
                    </Badge>
                </td>
                <td className='estreito text-center font-weight-bold text-nowrap'>
                    <Badge variant="info">
                        <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" /> 
                        {moment(dados.dataSaida).format('DD/MM')}
                    </Badge>
                </td>
            </>
        }
        <td className="text-center">
            <small>{moment(dados.dataCadastro).format('HH:mm:ss')}</small>
        </td>
        <td className="text-nowrap estreitotext-right">
            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >
                Editar
            </BotaoTabela>

            {evento.finalizado === 'S' &&
                <BotaoTabela 
                    onClick={clicouExcluir}
                    icone={["fas", "trash"]}
                >
                    Cancelar
                </BotaoTabela>
            }
            
        </td>
    </tr>
}

export default Registro;