import React from 'react';
import styles from './index.module.scss';
import moment from 'moment';

// componentes
import { Badge } from 'react-bootstrap';
import BotaoTabela from './../../../Componentes/BotaoTabela';
import { converterReal, mascaraCpf, mascaraCnpj } from './../../../../Funcoes';

// componente
export default function Registro({
    dados,
    ficha,
    cliente,
    status,
    abrirFicha
}){

    // retorna status
    function corStatus(){
        
        let cor = 'danger';
        switch(status){
            case 2: cor = 'warning'; break;
            case 3: cor = 'info'; break;
            case 4: cor = 'success'; break;
        }
        return cor;
    }

    return <tr>
        <td className="text-nowrap estreito font-weight-bold">
            <Badge 
                size={'lg'} 
                variant={corStatus()}
            >
                {moment(dados.vencimento).format('DD')}
            </Badge>
        </td>
        <td>
            <p className={'m-0'}><small>{cliente.nome}</small></p>
            <p className={'m-0'}>{ficha.titulo}</p>
        </td>
        <td className="text-nowrap estreito">
            <small>R$</small> {converterReal(dados.valor_juros)}
        </td>
        <td className="text-nowrap estreito font-weight-bold">
            <small>R$</small> {converterReal(dados.valor)}
        </td>
        <td className="text-nowrap estreito">
            <BotaoTabela 
                onClick={abrirFicha}
                icone={["fas", "clipboard-list"]}
            >Ficha</BotaoTabela>
        </td>
    </tr>
}