import React from 'react';
import styles from './index.module.scss';

function OptionSelect({
    data, 
    ...props
}){
    
    let { innerProps, innerRef, isFocused, isDisabled } = props;
    
    // retorna componente
    return <div 
        tabIndex='-1' 
        ref={innerRef} 
        {...innerProps} 
        className={[
            styles.option, 
            isFocused && styles.focado,
            isDisabled && styles.desabilitar,
            data.__isNew__ && styles.novo
        ].join(' ')}
        >
        <div className={styles.dadosUsuario}>
            { data.cliente.foto &&
                <img src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/fotos/${data.cliente.foto}`} className='rounded' />
            }
            <div>
                <p><b>{data.dados.id}</b> - {data.cliente.nome}</p>
                <p>{data.cliente.profissao}</p>
            </div>
        </div>
        
    </div>
}


export default OptionSelect;