import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

// componentes
import Registro from './../../../Atendimento/Apolice/Registro';
import { Col, Row } from 'react-bootstrap';

function Apolices({
    idCliente
}){

    // estados
    let [carregando, alterarCarregando] = useState(true);
    let [consulta, alterarConsulta] = useState([]);


    // inicializa
    useEffect(() => {
        
        // faz a requisição
        consultarApolices();

    }, []);

    // faz a consulta das apólices externas
    async function consultarApolices(){
        
        try{

            // consulta as apólices do cliente
            let { data } = await axios.get(`/clientes/apolice/${idCliente}`);
            alterarConsulta(data.fichas);
            alterarCarregando(false);

        }catch({response}){

        }

    }

    if(carregando){
        return <p>Carregando...</p>
    }

    return <div>
        <br />
        <Row>
            <Col lg={{span: 6, offset: 3}}>
                {consulta.map(ficha =>
                    <Registro 
                        key={ficha.id}
                        dados={ficha}
                        extra={true}
                    />
                )}
            </Col>
        </Row>
    </div>


}

export default Apolices;