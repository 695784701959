import React from 'react';

import { Form, Row, Col } from 'react-bootstrap';
import InputData from './../../../Painel/Componentes/InputData';

function FormularioImobiliaria({
    dados,
    alterarDados,
    funcaoAlterarDados
}){

    return <>

        <Form.Row>
            <Form.Group as={Col} lg='8' >
                <Form.Label>CNPJ/CPF</Form.Label>
                <Form.Control
                    value={dados.locatario_cnpj_cpf}
                    name='locatario_cnpj_cpf'
                    onChange={funcaoAlterarDados}
                    maxLength='40'
                />
            </Form.Group>
        </Form.Row>
        

        {/* mascara */}
        <Form.Row>
            <Form.Group as={Col} lg='8' >
                <Form.Label>Nome</Form.Label>
                <Form.Control
                    value={dados.locatario_nome}
                    name='locatario_nome'
                    onChange={funcaoAlterarDados}
                    maxLength='100'
                />
            </Form.Group>
            <Form.Group as={Col} >
                <Form.Label>Data de nascimento</Form.Label>
                <InputData 
                    data={dados.locatario_data_nascimento}
                    onChange={(data) => {
                        dados.locatario_data_nascimento = data;
                        alterarDados({...dados});
                    }}
                />
            </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                    value={dados.locatario_email}
                    name='locatario_email'
                    onChange={funcaoAlterarDados}
                    maxLength='100'
                />
            </Form.Group>
            <Form.Group as={Col} lg='4'>
                <Form.Label>Estado Civil</Form.Label>
                <Form.Control
                    as='select'
                    value={dados.locatario_estado_civil}
                    name='locatario_estado_civil'
                    onChange={funcaoAlterarDados}
                >
                    {[
                        {value: '1', children: 'Casado'},
                        {value: '2', children: 'Solteiro'},
                        {value: '3', children: 'Separo'},
                        {value: '4', children: 'Divorciado'},
                        {value: '5', children: 'Viúvo'},
                        {value: '6', children: 'União Estável'}
                    ].map(opcao => 
                        <option key={opcao.value} {...opcao} />   
                    )}
                </Form.Control>
            </Form.Group>
        </Form.Row>
        

        {/* mascara */}
        <Form.Row>
            <Form.Group as={Col}>
                <Form.Label>Telefone Residencial</Form.Label>
                <Form.Control
                    value={dados.locatario_telefone_residencial}
                    name='locatario_telefone_residencial'
                    onChange={funcaoAlterarDados}
                    maxLength='30'
                />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>Telefone celular</Form.Label>
                <Form.Control
                    value={dados.locatario_telefone_celular}
                    name='locatario_telefone_celular'
                    onChange={funcaoAlterarDados}
                    maxLength='30'
                />
            </Form.Group>
        </Form.Row>

        <Form.Group>
            <Form.Label>Locatário irá residir no Imóvel?</Form.Label> <br />
            <Form.Check 
                type='radio'
                inline
                id='locatario-residir-imovel-s'
                label='Sim'
                name='locatario_residir_imovel'
                checked={dados.locatario_residir_imovel === 'S'}
                value='S'
                onChange={funcaoAlterarDados}
            />
            <Form.Check 
                type='radio'
                inline
                id='locatario-residir-imovel-n'
                label='Não'
                name='locatario_residir_imovel'
                checked={dados.locatario_residir_imovel === 'N'}
                value='N'
                onChange={funcaoAlterarDados}
            />
        </Form.Group>

        {/* mascara */}
        <Form.Row>
            <Form.Group as={Col} lg='4'>
                <Form.Label>CEP - Residência Atual</Form.Label>
                <Form.Control
                    value={dados.locatario_cep}
                    name='locatario_cep'
                    onChange={funcaoAlterarDados}
                    maxLength='15'
                />
            </Form.Group>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Endereço Atual</Form.Label>
                <Form.Control
                    value={dados.locatario_endereco_atual}
                    name='locatario_endereco_atual'
                    onChange={funcaoAlterarDados}
                    maxLength='100'
                />
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Bairro Atual</Form.Label>
                <Form.Control
                    value={dados.locatario_bairro_atual}
                    name='locatario_bairro_atual'
                    onChange={funcaoAlterarDados}
                    maxLength='100'
                />
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Cidade Atual</Form.Label>
                <Form.Control
                    value={dados.locatario_cidade_atual}
                    name='locatario_cidade_atual'
                    onChange={funcaoAlterarDados}
                    maxLength='100'
                />
            </Form.Group>
            <Form.Group as={Col} lg='4'>
                <Form.Label>Estado Atual</Form.Label>
                <Form.Control
                    as='select'
                    value={dados.locatario_estado_atual}
                    name='locatario_estado_atual'
                    onChange={funcaoAlterarDados}
                >
                    {[
                        {id: 'AC'},
                        {id: 'AL'},
                        {id: 'AP'},
                        {id: 'AM'},
                        {id: 'BA'},
                        {id: 'CE'},
                        {id: 'DF'},
                        {id: 'ES'},
                        {id: 'GO'},
                        {id: 'MA'},
                        {id: 'MT'},
                        {id: 'MS'},
                        {id: 'MG'},
                        {id: 'PA'},
                        {id: 'PB'},
                        {id: 'PR'},
                        {id: 'PE'},
                        {id: 'PI'},
                        {id: 'RJ'},
                        {id: 'RN'},
                        {id: 'RS'},
                        {id: 'RO'},
                        {id: 'RR'},
                        {id: 'SC'},
                        {id: 'SP'},
                        {id: 'SE'},
                        {id: 'TO'}
                    ].map(opcao => 
                        <option key={opcao.id} value={opcao.id}>{opcao.id}</option>    
                    )}
                </Form.Control>
            </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group as={Col} lg='4'>
                <Form.Label>Tempo de Residência</Form.Label>
                <Form.Control
                    as='select'
                    value={dados.locatario_tempo_residencia}
                    name='locatario_tempo_residencia'
                    onChange={funcaoAlterarDados}
                >
                    {[
                        {value: '1', children: 'Menos de 1 ano'},
                        {value: '2', children: 'De 1 a 2 anos'},
                        {value: '3', children: 'De 3 a 4 anos'},
                        {value: '4', children: 'Acima de 5 anos'}
                    ].map(opcao => 
                        <option key={opcao.value} {...opcao} />
                    )}
                </Form.Control>
            </Form.Group>

            <Form.Group as={Col} lg='4'>
                <Form.Label>Situação da Residência</Form.Label>
                <Form.Control
                    as='select'
                    value={dados.locatario_situacao_residencia}
                    name='locatario_situacao_residencia'
                    onChange={funcaoAlterarDados}
                >
                    {[
                        {value: '1', children: 'Alugada'},
                        {value: '2', children: 'Financiada'},
                        {value: '3', children: 'Hotel/apart'},
                        {value: '4', children: 'Própria'}
                    ].map(opcao => 
                        <option key={opcao.value} {...opcao} />
                    )}
                </Form.Control>
            </Form.Group>
            
            <Form.Group as={Col} lg='4'>
                <Form.Label>Em nome de</Form.Label>
                <Form.Control
                    as='select'
                    value={dados.locatario_nome_de}
                    name='locatario_nome_de'
                    onChange={funcaoAlterarDados}
                >
                    {[
                        {value: '1', children: 'Amigos'},
                        {value: '2', children: 'Familiares'},
                        {value: '3', children: 'Empresa'},
                        {value: '4', children: 'Locatário'},
                        {value: '5', children: 'Outros'}
                    ].map(opcao => 
                        <option key={opcao.value} {...opcao} />
                    )}
                </Form.Control>
            </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Nome Proprietário / Imobiliária</Form.Label>
                <Form.Control
                    value={dados.locatario_nome_proprietario}
                    name='locatario_nome_proprietario'
                    onChange={funcaoAlterarDados}
                    maxLength='100'
                />
            </Form.Group>
            <p>Se o Imóvel for alugado, informe o nome do proprietário ou Imobiliária.</p>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Telefone Proprietario / Imobiliária</Form.Label>
                <Form.Control
                    value={dados.locatario_telefone_proprietario}
                    name='locatario_telefone_proprietario'
                    onChange={funcaoAlterarDados}
                    maxLength='100'
                />
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>CPF do Cônjugue</Form.Label>
                <Form.Control
                    value={dados.locatario_cpf_conjugue}
                    name='locatario_cpf_conjugue'
                    onChange={funcaoAlterarDados}
                    maxLength='20'
                />
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Nome do Cônjugue</Form.Label>
                <Form.Control
                    value={dados.locatario_nome_conjugue}
                    name='locatario_nome_conjugue'
                    onChange={funcaoAlterarDados}
                    maxLength='100'
                />
            </Form.Group>
        </Form.Row>
        
        <Form.Group>
            <Form.Label>Cônjugue irá residir no Imóvel?</Form.Label> <br />
            <Form.Check 
                type='radio'
                inline
                id='locatario-conjugue-residir-s'
                label='Sim'
                name='locatario_conjugue_residir'
                checked={dados.locatario_conjugue_residir === 'S'}
                value='S'
                onChange={funcaoAlterarDados}
            />
            <Form.Check 
                type='radio'
                inline
                id='locatario-conjugue-residir-n'
                label='Não'
                name='locatario_conjugue_residir'
                checked={dados.locatario_conjugue_residir === 'N'}
                value='N'
                onChange={funcaoAlterarDados}
            />
        </Form.Group>

        <Form.Group>
            <Form.Label>Cônjugue vai arcar com a locação?</Form.Label> <br />
            <Form.Check 
                type='radio'
                inline
                id='locatario-conjugue-arcar-locacao-s'
                label='Sim'
                name='locatario_conjugue_arcar_locacao'
                checked={dados.locatario_conjugue_arcar_locacao === 'S'}
                value='S'
                onChange={funcaoAlterarDados}
            />
            <Form.Check 
                type='radio'
                inline
                id='locatario-conjugue-arcar-locacao-n'
                label='Não'
                name='locatario_conjugue_arcar_locacao'
                checked={dados.locatario_conjugue_arcar_locacao === 'N'}
                value='N'
                onChange={funcaoAlterarDados}
            />
        </Form.Group>
    </>

}


export default FormularioImobiliaria;

