import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

// elemento registro
function Registro({
    dados, 
    mostrarNomeCliente
}){

    return <tr>
        <td className="estreito text-center font-weight-bold">
            {dados.dados.id}
        </td>
        <td>
            {mostrarNomeCliente &&
                <p className={'mb-0'}>
                    <small>{dados.cliente.nome}</small>
                </p>
            
            }

            <p className={'mb-0'}>
                {dados.dados.mensagem}  
            </p>

            {!dados.dados.dataAgendado ?
                <p className={'mb-0'}>
                    <small className={'mr-4'}>
                        <FontAwesomeIcon title={'Data do envio'} className="icone mr-1 text-secondary fa-sm" icon={["fas", "calendar-check"]} />
                        <b>{moment(dados.dados.dataCadastro).format('DD/MM/YYYY')}</b>
                    </small>
                </p> :
                <p className={'mb-0'}>
                    <small className={'mr-4'}>
                        <FontAwesomeIcon title={'Data agendamento'} className="icone mr-1 text-secondary fa-sm" icon={["fas", "calendar"]} />
                        <b>{moment(dados.dados.dataAgendado).format('DD/MM/YYYY')}</b>
                    </small>
                    <small className='mr-4'>
                        <FontAwesomeIcon title={'Horario'} className="icone mr-1 text-secondary fa-sm" icon={["fas", "clock"]} />
                        <b>{moment(dados.dados.dataAgendado).format('HH:mm')}</b>
                    </small>
                </p>
            }

        </td>
        <td className={'text-center estreito'}>
            {dados.dados?.erro === 'S' ? 
                <Badge variant='danger'>
                    Erro
                </Badge> :
                <Badge variant='primary'>
                    Enviada
                </Badge>
            }
        </td>
    </tr>
}

export default Registro;