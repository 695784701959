import React from 'react';
import styles from './index.module.scss';
import { ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// botão para tabelas
function BotaoTabela({
    carregando=false,
    tamanhoIcone=undefined,
    estiloBotao={},
    ...props
}){
    
    // botao
    return <ButtonToolbar style={{
        display: 'inline-flex'
    }}>
        <OverlayTrigger 
            placement='top'
            overlay={
                <Tooltip id={`tooltip-top`}>
                    {props.children}
                </Tooltip>
            }
        >            
            <button 
                disabled={carregando}
                className={(props.classe || '') + ' ' + styles.botao}
                {...{
                    onClick: e => {
                        e.stopPropagation();
                        if(props.onClick) props.onClick(e) 
                    },
                }}
                style={estiloBotao}                
            >
                {carregando ?
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    :
                    <FontAwesomeIcon 
                        style={{color: props.color}} 
                        className="icone" 
                        icon={props.icone} 
                        size={tamanhoIcone}
                    />
                }                
            </button>
        </OverlayTrigger>
    </ButtonToolbar>

}


export default BotaoTabela;