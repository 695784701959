import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// componentes
import { Row, Col, Button, Card } from 'react-bootstrap';
import Formulario from './../../../Atendimento/FormularioProposta/Formulario';
import Participante from './../../../Atendimento/FormularioProposta/Participante';

// cadastrar
export default function Cadastrar(){

    // estados
    const { id } = useParams();
    let [carregando, alterarCarregando] = useState(true);
    let [dados, alterarDados] = useState(null);
    let [participantes, alterarParticipantes] = useState([]);

    // inicializa
    useEffect(() => {
        consultarId();
    }, []);

    // consulta id
    async function consultarId(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/formularioAcidentesPessoais/${id}`);
            alterarDados(data.dados);
            alterarParticipantes(JSON.parse(data.dados.participantes));
            alterarCarregando(false);

        }catch({ response }){}

    }

    // carregando
    if(carregando){
        return <div>Carregando...</div>
    }

    // componente
    return <>
        <Row>
            <Col lg='8'>
                <Card className='border-0 shadow-sm'>
                    <Card.Body>
                        
                        <Formulario 
                            dados={dados}
                            alterarDados={() => {}}
                            funcaoAlterarDados={() => {}}
                            erros={[]}
                        />

                        <h5 className='text-right mb-4'>DADOS DOS PARTICIPANTES</h5>
                                                        
                        {participantes.map((participante, pos) => 
                            <Participante 
                                key={pos}
                                dados={participante}
                                alterarDados={(dadosNovos) => {}}
                                remover={() => {}}
                                desabilitarRemover={true}
                            />
                        )} 
                    </Card.Body>   
                </Card>            
            </Col>
        </Row>
    </>
}
