import React, { useState, useEffect, useContext} from 'react';
import styles from './index.module.scss';
import useIsMounted from 'ismounted';
import axios from 'axios';
import Geocode from 'react-geocode';
import MaskedInput from 'react-maskedinput';

// componentes
import { 
    FontAwesomeIcon 
} from '@fortawesome/react-fontawesome';
import { 
    Card, 
    Form, 
    Col, 
    Button, 
    OverlayTrigger, 
    Tooltip,  
    Row
} from 'react-bootstrap';
import { 
    mascaraCpf, 
    mascaraCnpj 
} from './../../../Funcoes';
import Estados from './../../Clientes/Cadastrar/Estados';

// importa contexto
import { ClientesMapaContexto } from './../../ClientesMapa';

// cliente focado
export default function ClienteFocado({
    chaveApi
}){

    const isMounted = useIsMounted();

    // configurações do geocode
    Geocode.setApiKey(chaveApi);

    // contextos
    let mapaDados = useContext(ClientesMapaContexto);

    // estados
    const [carregando, alterarCarregando] = useState(true);
    const [dados, alterarDados] = useState(null);
    const [buscandoEndereco, alterarBuscandoEndereco] = useState(false);
    const [salvando, alterarSalvando] = useState(false);
    const [mostrarDadosCliente, alterarMostrarDadosCliente] = useState(true);
    
    // parametro estativo
    const parametrosClienteSelecionadoDefault = {
        draggable: false,
        posicaoClick: false
    };

    // referencias
    const cepInput = React.createRef();

    // carrega os dados do cliente
    useEffect(() => {
        carregarClienteSelecionado(mapaDados.cliente);
    }, []);

    // se o cliente selecionado for alterado, recarrega os dados
    useEffect(() => {        
        if(dados !== null && mapaDados.clienteSelecionado !== null && mapaDados.clienteSelecionado.id !== dados.id){
            carregarClienteSelecionado(mapaDados.clienteSelecionado.id);
        }
    }, [mapaDados.clienteSelecionado]);

    // carrega os dados do cliente selecionado
    async function carregarClienteSelecionado(id){

        if(!id) return;

        alterarCarregando(true);

        mapaDados.alterarClienteSelecinadoEventos({...parametrosClienteSelecionadoDefault});
        mapaDados.alterarClienteSelecionado(null);

        try{

            // faz a requisição
            const { data } = await axios.get(`/clientes/${id}`);

            // se não estiver mais aqui, ignora
            if (!isMounted.current) {
                return
            }

            // mostra dados do cliente
            alterarDados(data.dados);

            // se possuir latitude/longitude, centraliza
            if(data.dados.latitude && data.dados.longitude){
                mapaDados.alterarCordenadaCentral({
                    lat: data.dados.latitude,
                    lng: data.dados.longitude
                });
                mapaDados.alterarZoom(20);
                
                
            }else{
                mapaDados.alterarClientesProximidade([]);
            }

            // altera o cliente selecionado
            mapaDados.alterarClienteSelecionado(data.dados);

            // finalizado
            alterarCarregando(false);

        }catch({response}){}


    }

    // ao confirmar novas cordenadas do cliente, altera
    function confirmarNovasCordenadas(){

        // faz a requisição e salvar o novo endereço
        dados.latitude = mapaDados.clienteSelecionado.latitude;
        dados.longitude = mapaDados.clienteSelecionado.longitude;
        alterarDados({...dados});

    }

    // cancelou novas cordenadas
    function cancelarNovasCordenadas(){
        mapaDados.alterarClienteSelecionado({...dados});
    }

    // busca cep
    function buscarCep(cepBuscar){

        const buscarCepCon = axios.create({ headers: null });

        // se foi totalmente preenchido
        if(cepBuscar.length === 8){

            // faz a busca
            buscarCepCon.get("https://viacep.com.br/ws/"+ cepBuscar +"/json/", {
                responseType: 'json'
            }).then(retorno => {
                
                alterarDados({...dados, 
                    'cidade': retorno.data.localidade,
                    'estado': retorno.data.uf,
                    'endereco': retorno.data.logradouro,
                    'bairro': retorno.data.bairro,
                    'cep': cepBuscar
                });
            });

        }
    }

    // buscar cordenadas do endereço
    function buscarCordenadas(){
        alterarBuscandoEndereco(true);

        // Get latidude & longitude from address.
        Geocode.fromAddress(`${dados.endereco} ${dados.numero} - ${dados.bairro}, ${dados.cidade} - ${dados.estado} ${dados.cep} `).then(
            (response) => {
                alterarBuscandoEndereco(false);
                const { lat, lng } = response.results[0].geometry.location;
                
                // altera
                dados.latitude = lat;
                dados.longitude = lng;

                // aplica as varaiveis globais de contexto
                mapaDados.alterarClienteSelecionado({...dados});
                alterarDados({...dados});
                mapaDados.alterarCordenadaCentral({lat: lat, lng: lng});

            },
            (error) => {
                alterarBuscandoEndereco(false);
                console.error(error);
                alert('Endereço não encontrado');
            }
        );
    }

    // salvar endereço novo
    async function salvarEndereco(){

        alterarSalvando(true);

        try {

            // salvarSimples
            await axios.post(`/clientes/salvarSimples`, {
                dados: {
                    id: dados.id,
                    latitude: dados.latitude,
                    longitude: dados.longitude,
                    endereco: dados.endereco,
                    bairro: dados.bairro,
                    numero: dados.numero,
                    cidade: dados.cidade,
                    estado: dados.estado,
                    cep: dados.cep
                }
            });
            
            // altera as cordenadas desse cliente na proximidade
            mapaDados.clientesProximidade.map((cliente) => {
                if(cliente.id === dados.id){
                    cliente.latitude = dados.latitude;
                    cliente.longitude = dados.longitude;
                }
                return cliente;
            });
            mapaDados.alterarClientesProximidade([...mapaDados.clientesProximidade]);

            // mostra toast, 
            alterarSalvando(false);

        }catch({response}){}

    }

    // carreagndo
    if(carregando){
        return <div className={[styles.clienteFocado, styles.carregando].join(' ')}>
            <Card>
                <Card.Body>
                    Carregando cliente <FontAwesomeIcon className="icone" pulse icon={["fas", "spinner"]} />
                </Card.Body>
            </Card>
        </div>
    }

    // se ninguém está selecionado, não mostra
    if(mapaDados.clienteSelecionado === null){
        return <></>
    }

    // só mostra se possuir algum cliente selecionado
    return <div className={styles.clienteFocado}>
        <Card>
            <Card.Header className={styles.cabecalho}>
                <Col lg={12} className='pr-0 pl-0'>
                    <Row 
                        className={'d-flex w-100 align-items-center justify-content-end'}
                    >
                        {(!mostrarDadosCliente) && <div className={styles.containerTitulo}>
                            <b className={styles.titulo}>
                                {dados.nome}
                            </b>
                        </div>}
                        <FontAwesomeIcon 
                            className={styles.btMinimizar + " icone fa-lg"}
                            icon={['fas', 'minus']}
                            onClick={() => alterarMostrarDadosCliente(!mostrarDadosCliente)}
                        />
                        <FontAwesomeIcon 
                            className="icone fa-lg" 
                            icon={["fas", "times"]}
                            onClick={() => {
                                mapaDados.alterarClienteSelecionado(null);
                                mapaDados.alterarCliente(null);
                            }}
                            style={{ cursor:'pointer' }} 
                        />
                    </Row>
                </Col>
            </Card.Header>
            <Card.Body 
                style={{ display: mostrarDadosCliente ? 'block' : 'none' }}
            >
                <div className={styles.identificacao}>
                    <div className={styles.foto}>
                    { dados.foto ?
                        <img src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/fotos/${dados.foto}`} className='rounded' />
                        :
                        <></>
                    }
                    </div>
                    <div className={styles.info}>
                        <b>{dados.nome}</b>
                        { dados.tipo_pessoa === 'F' ?
                            <p>{mascaraCpf(dados.cpf)}</p>
                            :
                            <p>{mascaraCnpj(dados.cnpj)}</p>
                        }
                    </div>
                </div>
                <hr />
                <div className={[styles.endereco].join(' ')}>
                    
                    <Form.Row>
                        <Form.Group sm='6' as={Col} className='mb-0'>
                            <Form.Label className='col-form-label-sm mb-0'>CEP</Form.Label>
                            <MaskedInput
                                className={['form-control form-control-sm'].join(' ')}
                                mask="11111-111"
                                value={dados.cep}
                                ref={cepInput}
                                onChange={(e) => {
                                    let cepNovo = cepInput.current.mask.getRawValue().split('_').join('');
                                    buscarCep(cepNovo);
                                    alterarDados({...dados, cep: cepNovo});
                                    
                                }}
                            />
                        </Form.Group>
                        <div className={styles.opcoes}>
                            
                            { mapaDados.clienteSelecinadoEventos.draggable ?
                                <>
                                    <OverlayTrigger
                                        overlay={<Tooltip>Aplicar cordenadas novas</Tooltip>}
                                        placement={'top'}
                                    >
                                        <Button
                                            variant='success'
                                            size='sm'
                                            className={[styles.opcao, 'mr-1'].join(' ')}
                                            onClick={() => {
                                                mapaDados.alterarClienteSelecinadoEventos({
                                                    ...parametrosClienteSelecionadoDefault,
                                                    draggable: false
                                                });
                                                confirmarNovasCordenadas();
                                            }}
                                        >
                                            <FontAwesomeIcon className="icone" icon={["fas", "check"]} />
                                        </Button>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        overlay={<Tooltip>Cancelar</Tooltip>}
                                        placement={'top'}
                                    >
                                        <Button
                                            variant='danger'
                                            size='sm'
                                            className={[styles.opcao, 'mr-1'].join(' ')}
                                            onClick={() => {
                                                mapaDados.alterarClienteSelecinadoEventos({
                                                    ...parametrosClienteSelecionadoDefault,
                                                    draggable: false
                                                });
                                                cancelarNovasCordenadas();
                                            }}
                                        >
                                            <FontAwesomeIcon className="icone" icon={["fas", "times"]} />
                                        </Button>
                                    </OverlayTrigger>
                                    
                                </>
                                :
                                <>
                                    <OverlayTrigger
                                        overlay={<Tooltip>Pesquisar endereço</Tooltip>}
                                        placement={'top'}
                                    >
                                        <Button
                                            variant='padrao'
                                            size='sm'
                                            className={[styles.opcao, 'mr-1'].join(' ')}
                                            onClick={buscarCordenadas}
                                        >
                                            <FontAwesomeIcon className="icone" icon={["fas", "search-location"]} />
                                        </Button>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        overlay={<Tooltip>Arrastar/clicar para alterar cordenadas</Tooltip>}
                                        placement={'top'}
                                    >
                                        <Button
                                            variant='padrao'
                                            size='sm'
                                            className={[styles.opcao, 'mr-1'].join(' ')}
                                            onClick={() => {
                                                mapaDados.alterarClienteSelecinadoEventos({
                                                    ...parametrosClienteSelecionadoDefault,
                                                    draggable: true
                                                });
                                            }}
                                        >
                                            <FontAwesomeIcon className="icone" icon={["fas", "arrows-alt"]} />
                                        </Button>
                                    </OverlayTrigger>
                                    
                                    {/* <OverlayTrigger
                                        overlay={<Tooltip>Clica no mapa para escolher posição</Tooltip>}
                                        placement={'top'}
                                    >
                                        <Button
                                            variant='padrao'
                                            size='sm'
                                            className={[styles.opcao, 'mr-1'].join(' ')}
                                            onClick={() => {
                                                mapaDados.alterarClienteSelecinadoEventos({
                                                    ...parametrosClienteSelecionadoDefault,
                                                    posicaoClick: true
                                                });
                                            }}
                                        >
                                            <FontAwesomeIcon className="icone" icon={["fas", "map-marker-alt"]} />
                                        </Button>
                                    </OverlayTrigger> */}
                                </>
                            }


                        </div>
                    </Form.Row>
                    
                    <Form.Row>
                        <Form.Group sm='9' as={Col} className='mb-0'>
                            <Form.Label className='col-form-label-sm mb-0'>Endereço</Form.Label>
                            <Form.Control 
                                size='sm' 
                                value={dados.endereco}
                                onChange={e => {
                                    dados.endereco = e.target.value;
                                    alterarDados({...dados});
                                }}
                            />
                        </Form.Group>
                        
                        <Form.Group sm='3' as={Col} className='mb-0'>
                            <Form.Label className='col-form-label-sm mb-0'>Nº</Form.Label>
                            <Form.Control
                                size='sm' 
                                value={dados.numero}
                                onChange={e => {
                                    dados.numero = e.target.value;
                                    alterarDados({...dados});
                                }}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Group className='mb-0'>
                        <Form.Label className='col-form-label-sm mb-0'>Bairro</Form.Label>
                        <Form.Control 
                            size='sm' 
                            value={dados.bairro}
                            onChange={e => {
                                dados.bairro = e.target.value;
                                alterarDados({...dados});
                            }}
                        />
                    </Form.Group>
                    
                    <Form.Row>
                        <Form.Group sm='9' as={Col} className='mb-0'>
                            <Form.Label className='col-form-label-sm mb-0'>Cidade</Form.Label>
                            <Form.Control 
                                size='sm' 
                                value={dados.cidade}
                                onChange={e => {
                                    dados.cidade = e.target.value;
                                    alterarDados({...dados});
                                }}
                            />
                        </Form.Group>
                        
                        <Form.Group sm='3' as={Col} className='mb-0'>
                            <Form.Label className='col-form-label-sm mb-0'>Estado</Form.Label>                            
                            <Estados 
                                value={dados.estado} 
                                name='estado'
                                onChange={e => {
                                    dados.estado = e.target.value;
                                    alterarDados({...dados});
                                }}
                                size='sm'
                            />                            
                        </Form.Group>
                    </Form.Row>
                    <p className='text-right m-0 mt-3'>
                        <Button
                            variant='success'
                            size='sm'
                            onClick={salvarEndereco}
                            disabled={salvando}
                        >
                            {salvando ? 
                                <>
                                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                    <span>Salvando</span>
                                </> : 
                                <span>Salvar</span>
                            }
                        </Button>
                    </p>
                </div>
            </Card.Body>
        </Card>
    </div>
}
