import React, {useState, useEffect} from 'react';
import styles from './index.module.scss';

// componentes
import { Badge } from 'react-bootstrap';
import BotaoTabela from './../../../Componentes/BotaoTabela';

// elemento registro
function Registro({
    dados, 
    clicouEditar,
    clicouExcluir
}){

    return <tr
        onClick={clicouEditar}
        className={`clicavel`}
    >
        <td className="estreito text-center font-weight-bold">{dados.id}</td>
        <td>{dados.descricao}</td>
        <td
            className="estreito text-center font-weight-bold"
        >
            { parseInt(dados._quantidade) > 0 &&
                <Badge
                    variant='info'
                    title="Quantidade de clientes nesse grupo"
                >
                    {dados._quantidade}
                </Badge>
            }
        </td>
        <td className="text-nowrap estreito">

            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >Editar</BotaoTabela>
            
            <BotaoTabela 
                onClick={clicouExcluir}
                icone={["fas", "trash"]}
            >Excluir</BotaoTabela>
        </td>
    </tr>
}

export default Registro;