import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Container, Button, Form } from 'react-bootstrap';
import Registro from './Registro';

// componente
export default function ModalInformar({
    fechou
}){

    // estados
    const { painel } = useSelector(state => state);
    const dispatch = useDispatch();
    const [mostrar, alterarMostrar] = useState(true);
    const [salvando, alterarSalvando] = useState(false);
    const [carregando, alterarCarregando] = useState(true);
    const [consulta, alterarConsulta] = useState([]);

    // consultar os registros
    async function consultarRegistros(){
        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/dispositivos`, { params: { disponivel: 'S' } });
            alterarConsulta(data.registros);

        }catch({response}){}
        finally{
            alterarCarregando(false);
        }
    }

    // faz a consulta
    useEffect(() => {
        consultarRegistros();
    }, []);

    // salva
    async function salvar(registro){
        alterarSalvando(true);

        try{

            let { data } = await axios.post(`/dispositivos/definir`, {id: registro.id})
            dispatch({
                type: 'painelReducer/DISPOSITIVO',
                dispositivo: data.dispositivo,
                ponto: painel.ponto
            });
            alterarMostrar(false);


        }catch({response}){}

    }

    // retorna modal de carregando
    if(carregando){
        return <Modal 
            show={mostrar}
            onHide={() => {}}
        >
            <Modal.Body>
                <p>Carregando Dispositivos <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} /></p>
            </Modal.Body>
        </Modal>
    }

    return <Modal 
        show={mostrar} 
        onExited={fechou}
        onHide={() => {}}
        size={'md'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title>
                Escolher dispositivo
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
            <table className="table table-hover tabela">
                <thead>
                    <tr>
                        <th>Descrição</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    { consulta.map(registro => 
                        <Registro 
                            key={registro.id}
                            dados={registro}
                            clicouSelecionar={() => {
                                salvar(registro);
                            }}
                        />    
                    )}
                </tbody>
            </table>

            </Container>
        </Modal.Body>
        <Modal.Footer  className='border-0'>
            <Button
                variant={salvando ? 'success' : 'danger'}
                onClick={() => {alterarMostrar(false)}}
                disabled={salvando}
            >
                {/* <span>Fechar</span> */}
                {salvando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Salvando</span>
                    </> : 
                    <span>Fechar</span>
                }
            </Button>
        </Modal.Footer>
    </Modal>

}
