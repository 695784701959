import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Container, Button, Form } from 'react-bootstrap';

// excluir registro
export default function ModalCadastrar({
    idUsuario,
    fechou,
    salvou
}){

    // estados
    const [mostrar, alterarMostrar] = useState(true);
    const [salvando, alterarSalvando] = useState(false);
    const [dados, alterarDados] = useState({
        id: null,
        id_usuario: idUsuario,
        endereco_ip: ''
    })

    // exclui registro
    async function salvar(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/usuariosIp`, dados);
            salvou();
            alterarMostrar(false);

        }catch({response}){

        }finally{
            alterarSalvando(false);
        }
        
    }

    return <>
        <Modal 
            show={mostrar} 
            onExited={fechou}
            onHide={() => {}}
            size={'sm'}
        >
            <Modal.Body>
                <Form.Group>
                    <Form.Label>ip</Form.Label>
                    <Form.Control 
                        value={dados.endereco_ip}
                        onChange={e => {
                            dados.endereco_ip = e.target.value;
                            alterarDados({...dados});
                        }}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer  className='border-0'>
                <Button
                    variant='padrao'
                    onClick={() => {alterarMostrar(false)}}
                    disabled={salvando}
                    size='sm'
                >
                    <span>Cancelar</span>
                </Button>
                <Button
                    onClick={salvar}
                    variant='success'
                    disabled={salvando}
                    size='sm'
                >
                    {salvando ? 
                        <>
                            <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                            <span>Salvando</span>
                        </> : 
                        <span>salvar!</span>
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    </>

}