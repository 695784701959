import React, { useState, useEffect } from 'react';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Col, Row, Button, Alert } from 'react-bootstrap';
import InputInteiro from './../../../../Componentes/InputInteiro';
import InputValor from './../../../../Componentes/InputValor';
import InputDataCombo from './../../../../Componentes/InputDataCombo';
import ComboCliente from './../../../../Componentes/ComboCliente';
import { FormularioCusto, QuantidadeParcelas } from './components';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

// component de cursos
export default function Custos({
    dados,
    alterarDados,
    quantidadeParcelas,
    alterarQuantidadeParcelas,
    valorBruto, 
    alterarValorBruto
}){

    return <FormularioCusto>
        <Form.Row>
            <Col md='4'>
                <Form.Label>Juros</Form.Label>
                <InputValor 
                    valor={dados.juros}
                    onChange={valorNovo => {
                        dados.juros = valorNovo;
                        alterarDados({...dados});
                    }}
                    size="sm"
                />
            </Col>
            <Col md='4'>
                <Form.Label size='sm' className='label-sm' title="Adicional Fracionamento">Adicional Frac.</Form.Label>
                <InputValor 
                    valor={dados.adicional_fracionamento}
                    onChange={valorNovo => {
                        dados.adicional_fracionamento = valorNovo;
                        alterarDados({...dados});
                    }}
                    size="sm"
                />
            </Col>
            <Col md='4'>
                <Form.Label>Comissão %</Form.Label>
                <InputValor 
                    valor={dados.comissao}
                    onChange={valorNovo => {
                        dados.comissao = valorNovo;
                        alterarDados({...dados});
                    }}
                    tipo='%'
                    size="sm"
                />
            </Col>
        </Form.Row>
        <Form.Row className='mt-3'>
            <Col md='4'>
                <Form.Label>Valor Bruto</Form.Label>
                <InputValor 
                    valor={valorBruto}
                    onChange={valorNovo => {
                        alterarValorBruto(valorNovo);
                    }}
                    size="sm"
                />
            </Col>
            <Col md='4'>
                <Form.Label>Valor Impostos</Form.Label>
                <InputValor 
                    valor={dados.valor_impostos}
                    onChange={valorNovo => {
                        dados.valor_impostos = valorNovo;
                        alterarDados({...dados});
                    }}
                    size="sm"
                />
            </Col>
            <Col md={{ span: 3, offset: 1 }}>
                <Form.Label>Parcelas</Form.Label>
                <QuantidadeParcelas>
                    <Button 
                        size='sm' 
                        variant='padrao'
                        onClick={() => {
                            alterarQuantidadeParcelas(quantidadeParcelas + 1);
                        }}
                    >
                        <FontAwesomeIcon className="icone" icon={["fas", 'plus']} />
                    </Button>
                    <InputInteiro 
                        valor={quantidadeParcelas}
                        onChange={(valorNovo) => {
                            alterarQuantidadeParcelas(parseInt(valorNovo));
                        }}
                        size="sm"
                    />
                    <Button 
                        size='sm' 
                        variant='padrao'
                        onClick={() => {
                            alterarQuantidadeParcelas(quantidadeParcelas - 1);
                        }}
                    >
                        <FontAwesomeIcon className="icone" icon={["fas", 'minus']} />
                    </Button>
                </QuantidadeParcelas>
            </Col>
        </Form.Row>
    </FormularioCusto>

}

// custos totais
export function CustosTotais({
    liquido,
    comissao
}){


    return <>
        <FormularioCusto>
            <Form.Group>
                <Form.Label>Valor Liquido</Form.Label>
                <InputValor 
                    valor={liquido}
                    disabled={true}
                    size="sm"
                />
            </Form.Group>
            <Form.Group className='mb-0'>
                <Form.Label>Valor Comissão</Form.Label>
                <InputValor 
                    // valor={calcularComissao().toFixed(2)}
                    valor={comissao}
                    disabled={true}
                    size="sm"
                />
            </Form.Group>
        </FormularioCusto>
    </>

}

export function FormaPagamento({
    modo="emissao",
    dados,
    alterarDados,
    erros
}){

    // verifica se não seguradora
    if(!dados.id_seguradora) {
        
        // insere id Capemisa seguradora (padrão)
        alterarDados({ ...dados, id_seguradora: 216 });

    }

    return <>
        <FormularioCusto>

            { modo === 'proposta' &&
                <Form.Group>
                    <Form.Label>Data Proposta</Form.Label>
                    
                    <InputDataCombo 
                        data={dados.data_proposta}
                        onChange={(novaData) => {
                            alterarDados({...dados, data_proposta: novaData});
                        }}
                        size="sm"
                    />
                </Form.Group>
            }

            { modo === 'emissao' &&
                <Form.Group>
                    <Form.Label>Data Emitido</Form.Label>
                    
                    <InputDataCombo 
                        data={dados.data_emitido}
                        onChange={(novaData) => {
                            alterarDados({...dados, data_emitido: novaData});
                        }}
                        size="sm"
                    />
                    { erros.data_emitido &&
                        <Alert variant="danger" className="border-0 mt-2">
                            <FontAwesomeIcon icon={faExclamationCircle} /> {erros.data_emitido}
                        </Alert>
                    }
                </Form.Group>

            }

            <Form.Group>
                <Form.Label>Forma de pagamento</Form.Label>
                <Form.Control 
                    as='select'
                    value={dados.forma_pagamento}
                    onChange={(e) => {
                        dados.forma_pagamento = e.target.value;
                        alterarDados({...dados});
                    }}
                    size="sm"
                >
                    {[
                        {value: '1', children: 'Boleto'},
                        {value: '2', children: 'Cartão de crédito'},
                        {value: '3', children: 'Débito em conta'}
                    ].map(forma => 
                        <option key={forma.value} {...forma} />    
                    )}
                </Form.Control>
            </Form.Group>

            <Form.Group className='mb-0'>
                <Form.Label>Seguradora</Form.Label>
                <ComboCliente 
                    id={dados.id_seguradora} // id do cliente
                    alterou={(novoId) => {
                        dados.id_seguradora = novoId;
                        alterarDados({...dados});
                    }}
                    filtrosExtras={{}}
                    size="sm"
                />
            </Form.Group>

        </FormularioCusto>
    </>


}

// número da apolice
export function NumeroApolice({
    dados,
    alterarDados,
    erros
}){


    return <>
        <FormularioCusto>
            <Form.Group>
                <Form.Label>Número Apólice</Form.Label>
                <Form.Control 
                    value={dados.numero_apolice}
                    onChange={(e) => {
                        dados.numero_apolice = e.target.value;
                        alterarDados({...dados});
                    }}
                    size="sm"
                />
                { erros.numero_apolice &&
                    <Alert variant="danger" className="border-0 mt-2">
                        <FontAwesomeIcon icon={faExclamationCircle} /> {erros.numero_apolice}
                    </Alert>
                }
            </Form.Group>
        </FormularioCusto>
    </>

}