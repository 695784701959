import React, { useState } from 'react';
import axios from 'axios';
import { converterReal } from "./../../../../Funcoes";
import { toast } from 'react-toastify';

// componentes
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import BotaoTabela from './../../../../Painel/Componentes/BotaoTabela';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';

// elemento
function Registro({
    dados,
    clicouAbrir,
    cliente,
    editarEvento,
    consultarCartaOferta
}) {

    // estados
    const [gerandoPdf, alterarGerandoPdf] = useState(false);
    
    // gerar pdf
    async function baixarPdfParticipantes() {

        // ativa carregamento 
        alterarGerandoPdf(true);

        try {

            // gera pdf
            const { data } = await axios.get(`/seguroAventura/pdfComprovanteContratacao/${dados.dados.token}`);

            // faz o download
            window.open(
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=anexosEmail&f=${encodeURIComponent(data.temporario)}&n=${encodeURIComponent(data.nome)}`,
                '_blank'
            );

        } catch({ response }) {
            console.error(response);

            // cria toast
            toast(({ closeToast }) => <>
                <div className="toast-header bg-danger text-white">
                    <strong className="mr-auto">Erro!</strong>
                    <button
                        onClick={closeToast}
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body  bg-danger text-white">
                    Erro ao baixar o comprovante de contratação
                </div>
            </>, { autoClose: 5000 });

        }         

        // desativa carregamento
        alterarGerandoPdf(false);

    }

    // função para retornar a cor de acordo 
    // com o status do boleto
    function verificaStatusBoleto() {

        // prepara cor
        let cor = '#000';

        // verifica o status do boleto
        switch (dados.boletoPago) {

            case "N":
                cor = 'orange';
                break;
            case "S":
                cor = 'green';
                break;
            case "P":
                cor = 'orange';
                break;
            default:
                cor = "black";
                break;
        }

        // retorna cor
        return cor;

    }

    // verifica o status do evento (aberto, andamento, finalizado, cancelado)
    function verificaStatusEvento() {

        // prepara o status de retorno do evento
        let status = {
            texto: '',
            cor: ''
        };

        // verifica se está cancelado
        const cancelado = dados.dados.cancelado === "S";

        // verifica se está finalizado
        const finalizado = dados.dados.finalizado === "S";

        // resgata data atual
        const dataAtual = new Date();

        // transforma datas e horas em um objeto date  
        const dataHoraInicio = new Date(dados.dados.dataInicio + ' ' + dados.dados.horaInicio);
        const dataHoraFinal = new Date(dados.dados.dataFinal + ' ' + dados.dados.horaFinal);

        // quando o evento estiver cancelado
        if(cancelado) {
            status = {
                texto: 'Cancelado',
                cor: 'danger'
            }
        // quando o evento estiver em aberto
        } else if(!finalizado && !cancelado) {
            status = {
                texto: 'Aberto',
                cor: 'warning'
            }
        // quando o evento estiver em andamento 
        } else if(
            finalizado &&
            !cancelado &&
            dataAtual > dataHoraInicio &&
            dataAtual < dataHoraFinal
        ) {
            status = {
                texto: 'Andamento',
                cor: 'success'
            }
        // quando o evento estiver finalizado
        } else if(
            finalizado &&
            !cancelado &&
            dataAtual > dataHoraFinal
        ) {
            status = {
                texto: 'Finalizado',
                cor: 'primary'
            }
        }
        
        return status;

    }
    
    return <tr
        style={{ cursor: 'pointer' }}
        onClick={clicouAbrir}
    >
        <td className='estreito text-center'>
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip id={`tooltip-top`}>
                        {verificaStatusEvento().texto}
                    </Tooltip>
                }
            >
                <Badge 
                    variant={verificaStatusEvento().cor}
                >
                    {dados.quantidadeParticipantes}
                </Badge>
            </OverlayTrigger>
        </td>
        <td>
            {dados.dados.nomeEvento}
        </td>
        <td>
            {dados.dados.localEvento}
        </td>
        <td>
            {dados.dados.dataInicio ? moment(dados.dados.dataInicio).format('DD/MM/YYYY') : ' - '}
        </td>
        <td>
            {dados.dados.dataFinal ? moment(dados.dados.dataFinal).format('DD/MM/YYYY') : ' - '}
        </td>
        <td>
            {(dados.previsaoFechamentoMesAtual && parseInt(dados.previsaoFechamentoMesAtual.periodo) > 0) &&
                <>
                    <Badge variant="success">
                        <span>R$ </span>
                        {converterReal(parseInt(dados.previsaoFechamentoMesAtual.periodo) * parseFloat(cliente.seguroValorDiaria))}
                    </Badge>
                </>
            }
        </td>
        <td>
            {(dados.previsaoFechamentoMesPosterior && parseInt(dados.previsaoFechamentoMesPosterior.periodo) > 0) &&
                <>
                    <Badge variant="info">
                        <span>R$ </span>
                        {converterReal(parseInt(dados.previsaoFechamentoMesPosterior.periodo) * parseFloat(cliente.seguroValorDiaria))}
                    </Badge>
                </>
            }
        </td>
        <td className="text-nowrap estreito" style={{ textAlign: 'right' }}>
            {(dados.linkBoleto) &&
                <BotaoTabela
                    onClick={() => window.open(dados.linkBoleto)}
                    icone={faBarcode}
                    color={dados.boletoPago ? verificaStatusBoleto() : "#000"}
                >
                    {dados.boletoPago == "S" ? "Boleto pago" : 'Pagar boleto'}
                </BotaoTabela>
            }
            {(dados.quantidadeParticipantes > 0 && dados.dados.cancelado === 'N') &&
                <BotaoTabela
                    carregando={gerandoPdf}
                    onClick={baixarPdfParticipantes}
                    icone={["fas", "file-pdf"]}
                    color={'rgb(170, 0, 0)'}
                >
                    Baixar Comprovante de Contratação
                </BotaoTabela>
            }
            <BotaoTabela
                onClick={editarEvento}
                icone={["fas", "pencil-alt"]}
                color={'#007bff'}
            >
                Editar Evento
            </BotaoTabela>
        </td>
    </tr>

}

export default Registro;