import React, { useState, useEffect } from 'react';
import './LoginPagina.scss';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import publicIp from 'react-public-ip';

// componentes
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// pagina login
export default function LoginPagina(){

    // estados
    let [carregar, carregarHabilitar] = useState(false);
    let [erro, mostrarErro] = useState({
        campo: '',
        mensagem: ''
    });
    const [erros, alterarErros] = useState({});
    const dispatch = useDispatch();

    // define dados do formulário
    let [dados, updateDados] = useState({
        login: '',
        senha: '',
        ip: null,
        lembrar: false
    });

    // inicializa
    useEffect(() => {
        carregarIp();
    }, []);

    // busca ip, se não encontrar apresenta erro
    async function carregarIp(){
        const ipv4 = await publicIp.v4() || null;
        dados.ip = ipv4;
        updateDados({...dados});
        if(ipv4 === null){
            alert('login indisponivel, tente mais tarde!');
        }
    }

    let clicouEntrar = async (e) => {
        carregarHabilitar(true);
        alterarErros({});

        try{

            // faz a requisição
            const { data } = await axios.post(`/login`, dados);
            
            // se for para lembrar então esquece o horario
            let lembrar = new Date();
            if(dados.lembrar){
                lembrar = null;
            }

            // pré define configurações default
            dispatch({
                type: 'painelReducer/CONFIGURACOES_SISTEMA',
                configuracoesSistema: data.configuracaoSistema
            });

            // altera os dados do usuário
            dispatch({
                type: 'FAZER_LOGIN',
                reducer: 'login',
                dadosUsuario: data.dadosUsuario,
                dadosCliente: data.dadosCliente,
                permissao: data.permissao,
                horaLogin: lembrar
            });

        }catch({response}){
            if(response){
                if(response.status === 400){
                    alterarErros(response.data);
                }
            }
        }finally{
            carregarHabilitar(false);
        }

    };

    // retorna componente
    return <div className="container-fluid login-pagina">
        <div className="row no-gutter">
            <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
            <div className="col-md-8 col-lg-6">
                <div className="login d-flex align-items-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-lg-8 mx-auto">
                            <h3 className="login-heading mb-4">Bem vindo!</h3>
                                <div className="form-label-group">
                                    <input 
                                        type="text" id="inputEmail"
                                        className={["form-control", erros.login ? 'input-erro' : ''].join(' ')} 
                                        placeholder="Usuário" 
                                        required autoFocus
                                        value={dados.login}
                                        onChange={(e) => {
                                            updateDados({
                                                ...dados,
                                                login: e.target.value
                                            });
                                        }}
                                        onKeyDown={e => {
                                            if(e.keyCode === 13) clicouEntrar(e);
                                        }}
                                    />
                                    <label htmlFor="inputEmail">Usuário</label>
                                    {erros.login && 
                                        <Alert className="alerta-erro" dismissible onClose={() => alterarErros({})} variant="danger">
                                            <p>{erros.login}</p>
                                        </Alert>
                                    }
                                    
                                </div>

                                <div className="form-label-group">
                                    <input 
                                        id="inputConfirmPassword"
                                        type="password"
                                        className={["form-control", erros.senha && 'input-erro'].join(' ')}
                                        placeholder="Senha" required 
                                        value={dados.senha}
                                        onChange={(e) => {
                                            updateDados({
                                                ...dados,
                                                senha: e.target.value
                                            });
                                        }}
                                        onKeyDown={e => {
                                            if(e.keyCode === 13) clicouEntrar(e);
                                        }}
                                    />
                                    <label htmlFor="inputConfirmPassword">Senha</label>
                                    {erros.senha && 
                                        <Alert className="alerta-erro" dismissible onClose={() => alterarErros({})} variant="danger">
                                            <p>{erros.senha}</p>
                                        </Alert>
                                    }
                                </div>

                                <div className="custom-control custom-checkbox mb-3">
                                    <input 
                                        type="checkbox" 
                                        className="custom-control-input" 
                                        id="customCheck1" 
                                        value={dados.lembrar}
                                        onChange={(e) => {dados.lembrar = e.target.value;}} 
                                    />
                                    <label className="custom-control-label" htmlFor="customCheck1">Lembrar</label>
                                </div>
                                <button 
                                    onClick={clicouEntrar} 
                                    className="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2" 
                                    type="submit"
                                    disabled={carregar || (dados.ip === null)}
                                >
                                {carregar ? 
                                   (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : ''
                                }
                                {carregar ? ' Carregando' : 'Entrar' }
                                </button>
                                <div className="text-center">
                                <Link className="small" to="lembrarSenha">Esqueceu a senha?</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
