import React, { useState } from 'react';

// componentes
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faSpinner } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

// componente
export default function GerarRelatorioSeguradoras(){

    // gera relatório para seguradoras
    const [gerando, alterarGerando] = useState(false);

    // faz a requisição
    async function gerarRelatorio(){

        // inicia
        alterarGerando(true);

        // inicia
        try{

            // faz a requisição
            let { data } = await axios.get(`/clientes/relatorioObservacaoLogin`);
            
            // faz o download
            window.open(
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=temp&f=${encodeURIComponent(data.arquivo)}&n=${encodeURIComponent(data.nome)}`, 
                '_blank'
            );

        }catch(e){

        }finally{
            alterarGerando(false);
        }


    }

    return <>
        
        <OverlayTrigger 
            overlay={<Tooltip>Gerar um relatório das seguradoras!</Tooltip>}
            placement='bottom'
        >
            <Button
                className='mr-2 rounded-circle'
                variant='padrao'
                size='sm'
                onClick={gerarRelatorio}
                disabled={gerando}
            >
                <FontAwesomeIcon 
                    className="icone" 
                    pulse={gerando}
                    icon={gerando ? faSpinner : faBuilding } 
                />
            </Button>
        </OverlayTrigger>
    </>

}