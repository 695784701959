import React from 'react';
import moment from 'moment';

// componentes
import { Card, Row, Col, Form, Badge } from 'react-bootstrap';
import Arquivo from './Arquivo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// retorna componente
function Registro({
    dados,
    extra
}){


    return <Card className='border-0 shadow-sm bg-light mb-3'>
        <Card.Body>
            <Row>
                <Col md='6'>
                    { extra &&
                        <>
                            <small>Apólice Nº </small>
                            <p className='m-0'><Badge variant='warning'>{dados._emitido.numero_apolice}</Badge></p>
                        </>
                    }
                </Col>
                <Col md='6' className='text-right'>
                    <small>Vigência</small>
                    
                    <p>
                        <FontAwesomeIcon className="icone mr-2" icon={["fas", 'calendar-alt']} />
                        {moment(dados.data_vigencia).format('DD/MM/YYYY')}
                    </p>
                </Col>
            </Row>
            
            <h5>{dados.titulo}</h5>
            <p>
                Seguradora: {dados._emitido._seguradora.nome}<br/>
                {/* <small></small> */}
                <Form.Control
                    as='textarea'
                    readOnly={true}
                    size="sm"
                    rows="4"
                    defaultValue={dados._emitido._seguradora.descricao_indicacao}
                />
            </p>
            { extra &&
                <>
                    <hr />
                    <p>{dados.descricao_indicacao}</p>
                    {/* <p>{dados.descricao}</p> */}
                    { dados._arquivos.map(arquivo => 
                        <Arquivo 
                            key={arquivo.id}
                            arquivo={arquivo}
                        />
                    )}
                </>
            }
        </Card.Body>
    </Card>
}

export default Registro;