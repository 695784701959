import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { Button, Modal, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';

// exclui evento
export default function BotaoCancelar({
    id,
    salvou
}){

    // estados
    const [confirmar, alterarConfirmar] = useState(false);
    const [salvando, alterarSalvando] = useState(false);
    const [esconderOpcao, alterarEsconderOpcao] = useState(false);
    const [erros, alterarErros] = useState({});


    // se confirma exclui
    async function cancelar(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/seguroAventuraParticipante/cancelar/${id}`);
            salvou();
            alterarConfirmar(false);
            alterarEsconderOpcao(true);

        }catch({response}){}
        finally{
            alterarSalvando(false);
        }

    }

    // o componente não deve ser renderizado
    if(esconderOpcao){
        return null;
    }

    return <>
        <Button
            size="sm"
            className="rounded-pill"
            variant="danger"
            onClick={e => {
                alterarConfirmar(true);
            }}
        >
            <FontAwesomeIcon className="mr-2" icon={faBan} />
            <span>Cancelar</span>
        </Button>
        {erros.cancelado && 
            <Alert variant="danger" className="border-0">{erros.cancelado}</Alert>
        }

        <Modal 
            show={confirmar} 
            onExited={() => {}}
            onHide={() => {}}
            size={'sm'}
        >
            <Modal.Header className='border-0'>
                <Modal.Title>
                    Cancelar participante!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Tem certeza que quer cancelar o participante?</p>
            </Modal.Body>
            <Modal.Footer  className='border-0'>
                <Button
                    variant='padrao'
                    onClick={() => {alterarConfirmar(false)}}
                    disabled={salvando}
                >
                    <span>Voltar</span>
                </Button>
                <Button
                    onClick={cancelar}
                    variant='success'
                    disabled={salvando}
                >
                    {salvando ? 
                        <>
                            <FontAwesomeIcon className="icone" pulse icon={faSpinner} />
                            <span>Cancelando</span>
                        </> : 
                        <span>Sim, cancelar!</span>
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    </>

}