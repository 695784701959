import React, { useState, useEffect, useRef } from 'react';
import styles from './index.module.scss';
import moment from 'moment';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Container, Button, Form } from 'react-bootstrap';
import Anexos from './../../Componentes/Anexos';

// concluir
export default function ModalConcluir({
    registro,
    fechou,
    salvou,
    login
}){

    //estados
    let [mostrar, alterarMostrar] = useState(true);
    let [salvando, alterarSalvando] = useState(false);
    let [carregando, alterarCarregando] = useState(true);
    let [dados, alterarDados] = useState({
        id: null,
        concluido: 'S',
        observacao_concluido: ''
    });
    let [salvandoAnexos, alterarSalvandoAnexos] = useState(false);
    let [arquivosFila, alterarArquivosFila] = useState(null);
    let [fecharAoSalvarArquivos, alterarFecharAoSalvarArquivos] = useState(false);

    // referencias
    let textInput = useRef(null);

    // inicializa
    useEffect(() => {

        // verifica se é evento recorrente e replica os dados
        if(registro._ficticio){

            consultarId();

        }else{
            dados.id = registro.id;
            alterarDados({...dados});
            alterarCarregando(false);
        }
    }, []);

    // foca no elemento de texto para inserir texto
    useEffect(() => {
        if(!carregando){
            textInput.current.focus();
        }
    }, [carregando]);

    useEffect(() => {
        console.log(arquivosFila);
        if(fecharAoSalvarArquivos && arquivosFila === 0){
            salvou();
            alterarMostrar(false);
        }

    }, [arquivosFila]);

    // carrega registro ficticio
    async function consultarId(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/eventos/${registro.id}`);
            Object.assign(dados, data.registro);
            delete dados.data_cadastro;
            delete dados.data_concluido;
            delete dados.hora_concluido;
            dados.data_lembrar = registro.data_lembrar;
            dados.data_programada = registro.data_programada;
            dados.recorrente_pai = dados.id;
            dados.recorrente = 'N';
            dados.data_reagendamento = null;
            dados.id = null;
            dados.concluido = 'S';
            dados.observacao_concluido = '';

            // alterado
            alterarDados({...dados});
            alterarCarregando(false);

        }catch({response}){}
    }

    // conclui
    async function salvar(){

        // salva
        alterarSalvando(true);
        
        try{

            // faz a requisição
            let { data } = await axios.post(`/eventos/concluir`, dados);

            // se for edição, altera id
            if(dados.id === null){
                alterarDados({...dados, id: data.id})
            }

            // // finalizado, verifica se está salvando anexos
            // if(salvandoAnexos){
            //     console.log('salvando arquivos');
            // }else{
                // salvou();
                // alterarMostrar(false);
                
            // }

            if(arquivosFila === 0){
                salvou();
                alterarMostrar(false);
            }else{
                alterarFecharAoSalvarArquivos(true);
            }
            
            // // fecha assim que salvar os arquivos
            // alterarFecharAoSalvarArquivos(true);
            

        }catch({response}){}

    }

    // retorna modal de carregando
    if(carregando){
        return <Modal 
            show={mostrar}
            onHide={() => {}}
        >
            <Modal.Body>
                <p>Carregando Evento <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} /></p>
            </Modal.Body>
        </Modal>
    }

    return <Modal 
        show={mostrar} 
        onExited={fechou}
        onHide={() => {}}
        size={'md'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title>
                Concluir Evento
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <Form.Control 
                    as='textarea' 
                    value={dados.observacao_concluido}
                    onChange={e => {
                        dados.observacao_concluido = e.target.value;
                        alterarDados({...dados});
                    }}
                    ref={textInput}
                />
                <div
                    className={'mt-3'}
                >
                    <Anexos 
                        tabela={'eventos'}
                        id={dados.id}
                        salvando={(flag) => {
                            alterarSalvandoAnexos(flag);
                        }}
                        arquivosFila={alterarArquivosFila}
                        size='sm'
                    />
                </div>
            </Container>
        </Modal.Body>
        <Modal.Footer  className='border-0'>
            <Button
                variant='danger'
                onClick={() => {alterarMostrar(false)}}
                disabled={salvando}
            >
                <span>Fechar</span>
            </Button>
            <Button
                onClick={salvar}
                variant='success'
                disabled={salvando}
            >
                {salvando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Salvando</span>
                    </> : 
                    <span>Salvar</span>
                }
            </Button>
        </Modal.Footer>
    </Modal>


}
