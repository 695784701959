import styled from 'styled-components';

export const EventosCartaOfertaComponent = styled.div.attrs({
    className: `pb-5`
})`

    min-height: 100vh;
    background-color: #595959 !important;

    .formulario {

        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 1rem;
        display: flex;
        position: relative;

        .logo {
            max-width: 200px;
            margin: auto;

            img {
                width: 100%;
            }
        }

        .titulo {
            font-size: 1.2rem;
            font-weight: bold
        }

        label {
            font-size: 0.9rem;
        }
        
    }

    .participantes {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;

        @media only screen and (max-width: 900px) {
            grid-template-columns: 1fr 1fr;
        }


        @media only screen and (max-width: 600px) {
            grid-template-columns: 1fr;
        }

        
    }

    .botao-cadastrar {       
        
        box-shadow: none;

        &:hover {
            box-shadow: none;
        }

        /* background-color: #284787;
        color: white; */
    }

    .container-bem-vindo {

        position: absolute;
        left: 15px;
        bottom: 0px;
        height: 100%;
        display: flex;
        min-width: 200px;
        max-width: 350px;
        align-items: flex-end;
        justify-content: center;

        .container-background-texto {

            background: #284787;    
            border-radius: 5px;
            display: flex;
            flex: 1;
            position: relative;
            gap: 2px;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
            flex-wrap: nowrap;
            justify-content: center;
            padding: 10px;

            p {
                color: white;
                margin: 0px;
                width: 100%;
                overflow: hidden;                
                text-overflow: ellipsis;
                text-align: left;
                display: flex;
            }

            .container-titulo-icone {

                display: flex;
                width: 100%;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .titulo-bem-vindo {
                    font-size: 14px;
                    line-height: 1rem;
                    font-weight: lighter;
                }

                .botao-icone-usuario {                    
                    width: 32px;
                    height: 28px;
                    display: flex;
                    padding: 0px;
                    border-width: 0px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50px;
                    background-color: white;

                    &:hover {
                        opacity: 0.5;
                    }

                }

                .botao-icone-usuario svg {
                    width: 16px;
                    height: 16px;
                    padding: 0px;
                }

            }

            .nome-usuario {
                font-size: 20px;
                flex-wrap: nowrap;
                line-height: 1.25rem;
                font-weight: bold;
            }

        }

    }

    .container-opcoes-topbar {

        z-index: 0;
        position: absolute;
        right: 0;
        margin-right: 0.9em;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .botao-sinistro {

            background: #284787;
            box-shadow: none;
            border-radius: 5px;
            border: none;            

        }
            
        .custom-switch {

            height: 50px;
            padding-left: 0px;

            .custom-control-label {
                color: white;
                height: 100%;
                font-size: 0.9rem;
                text-align: right;
                vertical-align: top;
                text-overflow: ellipsis;
            }

            .custom-control-input {
                height: 100%;
                outline: none;
                width: 100%;                
                z-index: 1;
                cursor: pointer;
            }

            .custom-control-label::before {
                width: 50px !important;
                border-radius: 1.5rem !important;
                top: auto !important;
                bottom: 0px !important;
                left: auto !important;
                right: 0px !important;
                height: 1.25rem !important;
                outline: none;
                box-shadow: none;
            }            

            .custom-control-input:checked~.custom-control-label::after {
                background-color: #fff;
                transform: translateX(1rem)!important;
                top: auto !important;
                bottom: 4px !important;
                left: auto !important;
                right: 20px !important;
            }

            .custom-control-input:not(:checked)~.custom-control-label::after {
                background-color: gray;
                transform: translateX(1rem)!important;
                top: auto !important;
                bottom: 4px !important;
                left: auto !important;
                right: 50px !important;
            }

        }
        

    }

`