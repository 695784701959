import React from 'react';
import moment from 'moment';

// icones
import docPng from './icones/doc.png';

// componentes
import { AnexosFichaComponent, AnexoComponent } from './components';
import { Button, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload, faFile, faFileArchive, faFileAlt, faCloudUploadAlt, faFileUpload, faTrashRestore } from '@fortawesome/free-solid-svg-icons';

// fichas
export default function AnexosFicha({
    anexos,
    alterar,
    height=null,
    colunas=1,
    salvando=false
}){

    // estados
    let inputRef = React.createRef();

    // adicionar novos arquivos
    async function adicionarArquivos(files){

        // pega os novos arquivos para inclusão
        let novosArquivosArray = [];
        Array.from(files).forEach(arquivo => {
            novosArquivosArray.push({upload: {
                arquivo: arquivo, 
                progresso: 0, 
                erro: false 
            }});
        });
        alterar([...novosArquivosArray, ...anexos]);
    }

    // remover arquivo
    async function excluirArquivo(arquivo){
        if(arquivo.dados){
            arquivo.remover = !arquivo.remover;
            alterar([...anexos]);
        }else{
            let novoArrayAnexos = anexos.filter(anexo => {
                return !(anexo === arquivo);
            })
            alterar(novoArrayAnexos);
        }
    }

    return <>
        <AnexosFichaComponent
            height={height}
            colunas={colunas}
            salvando={salvando}
        >
            <input 
                hidden 
                type="file" 
                ref={inputRef} 
                onChange={() => {
                    if(inputRef.current.files.length > 0){
                        adicionarArquivos(inputRef.current.files);
                    }
                }} 
                multiple
            />
            <Button
                variant="padrao"
                className="rounded-pill mr-2 botao-upload"
                size="sm"
                onClick={() => {inputRef.current.click();}}
            >
                <FontAwesomeIcon icon={faCloudUploadAlt} className="fa-fw" />
            </Button>
            <div className="container-anexos">
                {anexos.map(anexo => 
                    <AnexoComponent
                        key={anexo.dados ? anexo.dados.id : anexo.upload.arquivo.name }
                        upload={anexo.upload ? true : false}
                        remover={anexo.remover ? true : false}
                    >
                        <div className="anexo-conteudo bg-light rounded p-2 shadow-sm">
                            <div className="icone mr-2">
                                { anexo.upload ?
                                    <FontAwesomeIcon icon={faFileUpload} size='2x' className="text-secondary" />
                                    :
                                    <FontAwesomeIcon icon={faFileAlt} size='2x' className="text-secondary" />
                                }
                            </div>
                            <div className="dados">
                                <div className="nome text-truncate" title={anexo.dados ? anexo.dados.nome : anexo.upload.arquivo.name}>
                                    {anexo.dados ? anexo.dados.nome : anexo.upload.arquivo.name}
                                </div>
                                { anexo.dados && 
                                    <div className="info text-muted">
                                        <span>{moment(anexo.dados.data_cadastro).calendar()}</span>
                                    </div>
                                }
                                <div className={'text-right opcoes'}>
                                    {anexo.upload &&
                                        <div className="barra-upload mr-2">
                                            <ProgressBar 
                                                animated={anexo.upload.erro ? false : true}
                                                now={anexo.upload.progresso} 
                                                label={`${anexo.upload.progresso}%`}
                                                variant={anexo.upload.erro ? 'danger' : 'success'}
                                            />
                                        </div>
                                    }
                                    <div className="botoes">
                                        {/* <Button
                                            variant="padrao"
                                            className="rounded-pill mr-2 botao-download"
                                            size="sm"
                                        >
                                            
                                        </Button> */}
                                        {anexo.dados &&
                                            <a 
                                                href={`${process.env.REACT_APP_URL_ARQUIVOS}/download.php/${encodeURIComponent(anexo.dados.nome)}?p=anexos&f=${encodeURIComponent(anexo.dados.nome_temporario)}&n=${encodeURIComponent(anexo.dados.nome)}`} 
                                                target="_blank" 
                                                className={'btn btn-padrao rounded rounded-pill mr-2 botao-download'}
                                            >
                                                <FontAwesomeIcon icon={faDownload} className="fa-fw" />
                                            </a>
                                        }

                                        <Button
                                            variant="padrao"
                                            className="rounded-pill"
                                            size="sm"
                                            onClick={() => {
                                                excluirArquivo(anexo);
                                            }}
                                            disabled={salvando}
                                        >
                                            <FontAwesomeIcon icon={anexo.remover ? faTrashRestore : faTrash} className="fa-fw" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AnexoComponent>
                )}

            </div>
        </AnexosFichaComponent>
    </>

}