import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';
import * as R from 'ramda';

// componentes
import { useSelector } from 'react-redux';
import { Card, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ObservacaoLogin({
    dados,
    alterarDados,
    grupos
}){

    // estados
    const configuracoes = useSelector(state => state.painel.configuracoesSistema);
    const [habilitar, alterarHabilitar] = useState(false);


    // inicializa
    useEffect(() => {

        // se for nulo, altera para ser um array
        if(dados.observacao_grupo === null){
            dados.observacao_grupo = [];
            alterarDados({...dados});
            incluirObservacao();
        }

        // verifica se é para habilitar ou desabilitar esse campo
        let possuiGrupo = grupos.find(grupo => {
            if(grupo.id === configuracoes.habilitarCampoLoginGrupo){
               return true; 
            }
            return false;
        });

        // verifica se encontrou
        if(possuiGrupo !== habilitar){
            alterarHabilitar(possuiGrupo);
        }

    }, [grupos]);

    // incluir nova observacao
    function incluirObservacao(){
        dados.observacao_grupo.push({
            descricao: "",
            id: uniqid()
        });
        alterarDados({...dados});
    }

    // se não estiver configurado, retorna falso
    if(configuracoes.habilitarCampoLoginGrupo === null){
        return <></>
    }

    // se o valor padrão for nulo, não renderiza ainda,
    if(dados.observacao_grupo === null){
        return <></>
    }

    // se não for para habilitar
    if(!habilitar){
        return <></>
    }
    
    return <>
        <Card
            className={'border-0 shadow-sm mt-3'}
        >
            <Card.Body>
                <p
                    className={''}
                >Dados de acesso</p>
                {dados.observacao_grupo.map((observacao, i) => 
                    <Form.Control as="textarea" 
                        value={observacao.descricao}
                        key={observacao.id}
                        onChange={e => {
                            let novosDados = R.clone(dados.observacao_grupo);
                            novosDados[i].descricao = e.target.value;
                            dados.observacao_grupo = novosDados;
                            alterarDados({...dados});
                        }}
                        className={'mb-2'}
                    />
                )}
                <p
                    className={'text-right m-0'}
                >
                    <Button
                        variant='padrao'
                        size='sm'
                        onClick={incluirObservacao}
                    >
                        <FontAwesomeIcon className="icone" icon={["fas", 'plus']} />
                    </Button>
                </p>
            </Card.Body>
        </Card>
    </>
}