import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/lib/Async';
import { Form } from 'react-bootstrap';
import axios from 'axios';

// componentes
import OptionSelect from './OptionSelect';

export default function ComboResponsavel({
    id=null, // id do usuário
    alterou,
    ignorarIds=[]
}){

    // estados
    const { login } = useSelector(state => state);
    let [responsaveis, alterarResponsaveis] = useState([]);
    let [carregando, alterarCarregando] = useState(true);
    let [dadosResponsavel, alterarDadosResponsavel] = useState(null);

    // consulta id do usuário
    useEffect(() => {

        // carrega alguns
        consultarResponsaveis('');

        // verifica se possui usuário
        if(id !== null){

            // consulta responsável
            consultarId();
        }else{
            alterarCarregando(false);
        }

    }, []);

    // consulta id do responsável
    async function consultarId(){

        // carregando
        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/usuarios/${id}`);
            alterarDadosResponsavel(data);
            alterarCarregando(false);

        }catch({response}){}

    }

    // consulta os responsaveis
    async function consultarResponsaveis(inputValue, callback = null){

        try{

            // faz a requisição
            let { data } = await axios.get(`/usuarios/responsaveis`, { params: {
                texto: inputValue,
                registrosPorPagina: 8,
                ignorarIds: ignorarIds
            } });

            let registrosConsulta = data.registros.filter(registro => {
                if(dadosResponsavel && registro.dados.id === dadosResponsavel.dados.id){
                    return false;
                }
                return true;
            });
            
            if(dadosResponsavel){
                registrosConsulta.push(dadosResponsavel);
            }
            
            alterarResponsaveis(registrosConsulta);

            if(callback !== null){
                callback(registrosConsulta)
            }

        }catch({response}){}

    }

    return <>
        <AsyncSelect 
            isDisabled={carregando}
            isClearable
            loadOptions={consultarResponsaveis} // ao alterar, carrega
            defaultOptions={responsaveis}       // lista pré carregada
            value={dadosResponsavel && {
                label: <div className='d-flex align-items-center'>
                    {dadosResponsavel.cliente?.foto &&
                        <img 
                            className={styles.foto} 
                            src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/fotos/${dadosResponsavel.cliente.foto}`} 
                        />
                    }
                    <div>{dadosResponsavel.cliente?.nome}</div>
                    { dadosResponsavel.dados?.status === 'I' &&
                        <small className='ml-2'> (Inativo)</small>
                    }
                </div>
            }}  
            onChange={(e) => {

                if(e === null){
                    alterarDadosResponsavel(null);
                    alterou(null);
                }else{
                    console.log(e);
                    alterarDadosResponsavel(e);
                    alterou(e.dados.id);
                }
            }}
            components={{
                Option: OptionSelect
            }}
            noOptionsMessage={() => 'Digite algo para fazer a consulta'}
            loadingMessage={() => 'Carregando...'}
            placeholder='Responsável'
        />
    </>
}
