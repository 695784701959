import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { ResponsiveContainer, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

function GraficoBarrasFunil({
    dados
}){

    
    let corCotacao = '#a930a9';
    let corProposta = '#5f5fff';
    let corEmitido = '#33d933';
    let corEmitidoCancelado = '#ed3838';

    // estados
    let [grafico, alterarGrafico] = useState([]);


    useEffect(() => {

        alterarGrafico(dados.map(dado => {
            return {
                name: moment(dado.data).format('MM/YYYY'),
                cotacao: parseInt(dado.totalCotacaoMes),
                proposta: parseInt(dado.totalPropostasMes),
                emitido: parseInt(dado.totalEmissaoMes),
                emitidoCancelado: parseInt(dado.totalEmissaoCanceladaMes),
            }
        }).reverse());


    }, [dados]);



    return  <div style={{ width: '100%', height: 220 }}>
        <ResponsiveContainer>
            <BarChart 
                // width={600} 
                // height={300} 
                data={grafico}
                margin={{top: 20, right: 30, left: 20, bottom: 5}}
                // layout="vertical"
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis/>
                <Tooltip/>
                <Legend />
                <Bar name='Cotação' dataKey="cotacao" stackId="a" fill={corCotacao} />
                <Bar name='Proposta' dataKey="proposta" stackId="a" fill={corProposta} />
                <Bar name='Emitido' dataKey="emitido" stackId="a" fill={corEmitido} />
                <Bar name='Emissões Canceladas' dataKey="emitidoCancelado" stackId="a" fill={corEmitidoCancelado} />
            </BarChart>
        </ResponsiveContainer>
    </div>
}

export default GraficoBarrasFunil;