import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';
import { toast } from 'react-toastify';

// componentes
import { Card, Button } from 'react-bootstrap';
import Arquivo, { ArquivoUpload } from './../../../Componentes/Anexos/Arquivo';

// anexos
export default function AnexosEmail({
    leitura=false,
    anexosPreCarregado=[],
    alterarAnexos
}){

    // estados
    const [salvando, alterarSalvando] = useState(false);
    const [arquivos, alterarArquivos] = useState(anexosPreCarregado);
    const [arquivosUpload, alterarArquivosUpload] = useState([]);
    const inputRef = React.createRef();

    // faz upload de arquivo
    function adicionarFila(){
        let arrayNovo = arquivosUpload;
        Array.from(inputRef.current.files).forEach(arquivo => {
            // console.log(arquivo);
            arrayNovo.push({arquivo, progresso: null, erro: null});
        });
        alterarArquivosUpload([...arrayNovo]);

        // salva
        salvar();
    }

    // salva todos
    async function salvar(){

        // salva todos os arquivos
        arquivosUpload.forEach(async (dados, a) => {

            // verifica o progresso da imagem do card
            function progressoArquivo(progressEvent){
                let progress = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                dados.progresso = progress;
                alterarArquivosUpload([...arquivosUpload]);
            }

            // dados arquivo
            let salvarArquivo = new FormData();
            salvarArquivo.append('arquivo', dados.arquivo);

            try{

                // faz a requisição
                let { data } = await axios.post(`/emailsEnviadoAnexo`, salvarArquivo, {
                    onUploadProgress: progressoArquivo,
                    headers: {'Content-type': 'multipart/form-data'}
                });
                
                // atualiza
                let index = arquivosUpload.indexOf(dados);
                if(index >= 0){
                    arquivosUpload.splice(index, 1);
                }
                alterarArquivosUpload([...arquivosUpload]);
                
                
                // bem sucedido
                arquivos.push(data.dados);
                alterarAnexos(arquivos.map(arquivo => arquivo.id));
                alterarArquivos([...arquivos]);

            }catch({response}){
                if(response){

                    // resposta
                    if(response.status === 400){

                        // mostra mensagem de erro
                        toast(({closeToast }) => <>
                            <div className="toast-header bg-danger text-white">
                                <strong className="mr-auto">Erro ao salvar arquivo!</strong>
                                <button 
                                    onClick={closeToast} 
                                    className="ml-2 mb-1 close btn-outline-light outline-0"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="toast-body">
                                {dados.arquivo.name}
                            </div>
                        </>);
                    }
                }
            }finally{
                arquivosUpload.splice(a, 1);
                alterarArquivosUpload([...arquivosUpload]);

            }

        });

        // zera variavel
        inputRef.current.value = '';

    }

    return <>

        <Card
            className='border-0 mt-3 shadow-sm'
        >
            <Card.Body>
                { !leitura &&
                <>
                    <Button 
                        variant='padrao'
                        size={'sm'}
                        onClick={() => inputRef.current.click()}
                        disabled={salvando}
                    >
                        <span>Anexar arquivo</span>
                    </Button>
                    <input hidden type='file' multiple ref={inputRef}
                        onChange={adicionarFila}
                    />
                </>
                }


                <div className={[styles.arquivos, 'mt-3'].join(' ')}>
                    {arquivos.map((arquivo, a) => 
                        <Arquivo 
                            key={arquivo.id}
                            arquivo={arquivo}
                            rotaExcluir={'emailsEnviadoAnexo'}
                            rotaBaixar={'anexosEmail'}
                            // excluir={excluir}
                            excluido={() => {
                                arquivos.splice(a, 1);
                                alterarAnexos(arquivos.map(arquivo => arquivo.id));
                                alterarArquivos([...arquivos]);
                            }}
                            abrir={() => {
                                console.log(arquivo);
                                // window.open(`${process.env.REACT_APP_URL_PHP}/arquivos/anexos/${arquivo.nome_temporario}`, '_blank');
                            }}
                            size={'sm'}
                            habilitarExcluir={!leitura}
                        />    
                    )}

                    { arquivosUpload.map((upload, a) => 
                        <ArquivoUpload 
                            key={`${upload.arquivo.name}${a}`}
                            upload={upload}
                            emEspera={false}
                            size={'sm'}
                        />
                    )}
                </div>


            </Card.Body>
        </Card>

    </>
}