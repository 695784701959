import styled from 'styled-components';

export const ConfiguracaoPropostaComponent = styled.div.attrs({ 
    className: 'p-3'
})`

    /* position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    text-align: right; */

    .habilitar-email-seguradora {
        font-size: 0.8rem;
        /* top: 5px; */
        /* z-index: 0; */

        label {
            &:before {
                /* top: 2px; */
            }
            &:after {
                /* top: 2px; */
            }
        }
    }

    .carregando {
        /* position: absolute;
        left: -5px;
        top: 22px; */
    }

`