import React, { useState } from 'react';


import { Col, Form, Button } from 'react-bootstrap';
import InputData from './../../../Painel/Componentes/InputData';
import InputHora from './../../../Painel/Componentes/InputHora';
import MaskedInput from 'react-maskedinput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import BuscarCartaOferta from './BuscarCartaOferta';

function Formulario({
    dados,
    alterarDados,
    funcaoAlterarDados,
    erros,
    modo
}){


    // referencias
    const telefoneInput = React.createRef();
    const [ bloquearEmpresa ] = useState((dados.bloqueado === 'S' && dados.nomeEmpresa !== '') ? true : false);
    const [ bloquearCnpj ] = useState((dados.bloqueado === 'S' && dados.cnpj !== '') ? true : false);
    const [ bloquearTelefoneCelular ] = useState((dados.bloqueado === 'S' && dados.telefoneCelular !== '') ? true : false);
    const [ bloquearEmail ] = useState((dados.bloqueado === 'S' && dados.email !== '') ? true : false);

    return <>
        {dados.id !== null &&
            <>
                <Form.Row>
                    <Form.Group as={Col} lg='8'>
                        <Form.Label>Código do evento</Form.Label>
                        <Form.Control
                            value={dados.codigoEvento || ''}
                            name='codigoEvento'
                            onChange={funcaoAlterarDados}
                            maxLength='200'
                            disabled={true}
                        />
                    </Form.Group>
                </Form.Row>

                <hr />
            </>
        }
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Carta oferta</Form.Label>
                <Form.Control
                    value={dados.cartaOferta || ''}
                    maxLength='100'
                    name='cartaOferta'
                    onChange={funcaoAlterarDados}
                    isInvalid={erros.cartaOferta}
                    disabled={dados.bloqueado === 'S'}
                />
                {erros.cartaOferta &&
                    <Form.Control.Feedback type="invalid">
                        {erros.cartaOferta}
                    </Form.Control.Feedback>
                }
                {dados.bloqueado === "N" && 
                    <BuscarCartaOferta 
                        dados={dados}
                        alterarDados={alterarDados}
                    />
                }
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Nome Empresa / Entidade / Responsável</Form.Label>
                <Form.Control
                    value={dados.nomeEmpresa || ''}
                    maxLength='300'
                    name='nomeEmpresa'
                    onChange={funcaoAlterarDados}
                    isInvalid={erros.nomeEmpresa}
                    disabled={bloquearEmpresa}
                />
                {erros.nomeEmpresa &&
                    <Form.Control.Feedback type="invalid">
                        {erros.nomeEmpresa}
                    </Form.Control.Feedback>
                }
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>CPF / CNPJ</Form.Label>
                <Form.Control
                    value={dados.cnpj || ''}
                    maxLength='30'
                    name='cnpj'
                    onChange={funcaoAlterarDados}
                    isInvalid={erros.cnpj}
                    disabled={bloquearCnpj}
                />
                {erros.cnpj &&
                    <Form.Control.Feedback type="invalid">
                        {erros.cnpj}
                    </Form.Control.Feedback>
                }
            </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Nome Do Evento</Form.Label>
                <Form.Control
                    value={dados.nomeEvento || ''}
                    maxLength='150'
                    name='nomeEvento'
                    onChange={funcaoAlterarDados}
                    isInvalid={erros.nomeEvento}
                />
                {erros.nomeEvento &&
                    <Form.Control.Feedback type="invalid">
                        {erros.nomeEvento}
                    </Form.Control.Feedback>
                }
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Descrição Do Evento</Form.Label>
                <Form.Control
                    as='textarea'
                    value={dados.descricaoEvento || ''}
                    maxLength='1000'
                    name='descricaoEvento'
                    onChange={funcaoAlterarDados}
                    isInvalid={erros.descricaoEvento}
                />
                {erros.descricaoEvento &&
                    <Form.Control.Feedback type="invalid">
                        {erros.descricaoEvento}
                    </Form.Control.Feedback>
                }
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Local do Evento</Form.Label>
                <Form.Control
                    value={dados.localEvento || ''}
                    maxLength='400'
                    name='localEvento'
                    onChange={funcaoAlterarDados}
                    isInvalid={erros.localEvento}
                />
                {erros.localEvento &&
                    <Form.Control.Feedback type="invalid">
                        {erros.localEvento}
                    </Form.Control.Feedback>
                }
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Data / Horario Inicio do evento</Form.Label>
                <Form.Row>
                    <Col lg='4'>
                        <InputData 
                            data={dados.dataInicio}
                            onChange={(data) => {
                                dados.dataInicio = data;
                                alterarDados({...dados});
                            }}

                        />
                    </Col>
                    <Col lg='4'>
                        <InputHora 
                            className='text-center'
                            value={dados.horaInicio}
                            onChange={novaHora => {
                                dados.horaInicio = novaHora;
                                alterarDados({...dados});
                            }}
                        />
                    </Col>
                </Form.Row>
                {erros.dataInicio &&
                    <p className="text-danger">
                        {erros.dataInicio}
                    </p>
                }
            </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Data/Horario Final do evento</Form.Label>
                <Form.Row>
                    <Col lg='4'>
                        <InputData 
                            data={dados.dataFinal}
                            onChange={(data) => {
                                dados.dataFinal = data;
                                alterarDados({...dados});
                            }}
                        />
                    </Col>
                    <Col lg='4'>
                        <InputHora 
                            className='text-center'
                            value={dados.horaFinal}
                            onChange={novaHora => {
                                dados.horaFinal = novaHora;
                                alterarDados({...dados});
                            }}
                        />
                    </Col>
                </Form.Row>
                {erros.dataFinal &&
                    <p className="text-danger">
                        {erros.dataFinal}
                    </p>
                }
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Responsável</Form.Label>
                <Form.Control
                    value={dados.responsavel || ''}
                    maxLength='100'
                    name='responsavel'
                    onChange={funcaoAlterarDados}
                    isInvalid={erros.responsavel}
                    disabled={dados.bloqueado === 'S'}
                />
                {erros.responsavel &&
                    <Form.Control.Feedback type="invalid">
                        {erros.responsavel}
                    </Form.Control.Feedback>
                }
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Telefone celular</Form.Label>
                <MaskedInput 
                    className={["form-control", erros.telefoneCelular ? 'is-invalid' : ''].join(` `)}
                    mask="(11) 11111-1111"
                    value={dados.telefoneCelular}
                    ref={telefoneInput}
                    onChange={(e) => {
                        dados.telefoneCelular = telefoneInput.current.mask.getRawValue().split('_').join('')
                        alterarDados({...dados});
                    }}
                    disabled={bloquearTelefoneCelular}
                />
                {erros.telefoneCelular &&
                    <Form.Control.Feedback type="invalid">
                        {erros.telefoneCelular}
                    </Form.Control.Feedback>
                }
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                    value={dados.email || ''}
                    maxLength='100'
                    name='email'
                    onChange={funcaoAlterarDados}
                    isInvalid={erros.email}
                    disabled={modo === 'edicao'}
                    disabled={bloquearEmail}
                />
                {erros.email &&
                    <Form.Control.Feedback type="invalid">
                        {erros.email}
                    </Form.Control.Feedback>
                }
            </Form.Group>
        </Form.Row>
        
    </>

}


export default Formulario;