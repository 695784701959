import styled from 'styled-components';

export const ContainerComponent = styled.div.attrs({
    className: `mt-3`
})`
    
    display: flex;
    position: relative;

    .ficha {

    }


    &:after {
        content: "";
        border-left: 2px solid rgb(51,217,51);
        border-bottom: 2px solid rgb(51,217,51);
        width: 2%;
        left: 0;
        bottom: 0;
        position: absolute;
        height: 84%;
        border-bottom-left-radius: 10px;

        ${props => props.minimizado && `
            display: none;
        `}
    }


`;