import React from 'react';
import styles from './index.module.scss';
import moment from 'moment';

// componentes
import { estiloEvento } from './../../../../ConfiguracaoSistema';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Card, ButtonToolbar, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';

export default function Ficha({
    dados,
    proposta,
    emissao,
    coresEventos,
    clicouEditar,
    finalizado
}){
    
    // variaveis
    let tipos = {
        '1': 'Direito',
        '2': 'Seguro',
        '3': 'Consultoria',
        '4': 'Avaliação',
        '5': 'Imóveis'
    };
    let estilo = estiloEvento(coresEventos, dados.tipo);

    return <Card className={[styles.ficha, 'border-0 shadow-sm mb-2'].join(' ')}>
        <Card.Header className='border-0 pb-0 bg-white mb-0'>
            
            <Card.Title as='h6' className=''>
                { ((emissao && emissao.cancelado === 'S') || (!emissao && proposta && proposta.cancelado === "S")) &&
                    <span
                        className={[styles.tag, 'p-1 px-2 bg-danger text-white'].join(' ')}
                    >
                        Cancelado
                    </span>
                }
                <span className={[styles.tag, 'p-1 px-2'].join(' ')} style={estilo}>{tipos[dados.tipo]}</span>
                { dados.etapa === '1' &&
                    <span
                        className={[styles.tag, styles.tagCotacao, 'p-1 px-2 text-white'].join(' ')}
                    >
                        Cotação
                    </span>
                }
                { dados.etapa === '2' &&
                    <span
                        className={[styles.tag, styles.tagRenovacao, 'p-1 px-2 text-white'].join(' ')}
                    >
                        Renovação
                    </span>
                }
                { dados.etapa === '3' &&
                    <span
                        className={[styles.tag, styles.tagProposta, 'p-1 px-2 text-white'].join(' ')}
                    >
                        Proposta
                    </span>
                }
                { dados.etapa === '4' &&
                    <span
                        className={[
                            styles.tag, 
                            styles.tagEmitido, 
                            finalizado ? styles.finalizado : '', 
                            'p-1 px-2 text-white'
                        ].join(' ')}
                    >
                        {finalizado && 
                            <FontAwesomeIcon icon={faCheck} className='mr-1' />
                        }
                        {finalizado ? 'Finalizado' : 'Emitido'}
                    </span>
                }
                {dados.titulo}
            </Card.Title>
            {(emissao && emissao.numero_apolice && emissao.numero_apolice.length > 0) &&
                <div>
                    <small>
                        Nº apólice <b>{emissao.numero_apolice}</b>
                    </small>
                </div>
            }
        </Card.Header>
        <Card.Body className='pt-1'>
            {dados.descricao.length > 0 &&
                <div className={styles.descricao}>
                    <hr className='my-2' />
                    {dados.descricao}
                    <hr className='my-2' />
                </div>
            }
            <div
                className='d-flex align-items-center'
            >
                <div className='flex-grow-1'>
                    <Badge variant="light" className={[styles.dataCadastro, 'mr-2'].join(' ')}>{moment(dados.data_cadastro).calendar(null, { lastWeek: '[última] dddd' })}</Badge>
                    { proposta &&
                        <Badge variant="light" className={[styles.dataCadastro, 'mr-2'].join(' ')}>Proposta {moment(proposta.data_proposta).calendar(null, { lastWeek: '[última] dddd' })}</Badge>
                    }
                    { dados.possui_vigencia === 'S' &&
                        <Badge variant="light" className={[styles.dataCadastro, 'mr-2'].join(' ')}>Vigência {moment(dados.data_vigencia).calendar(null, { lastWeek: '[última] dddd' })}</Badge>
                    }
                    {/* { emissao &&
                        <Badge variant="light" className={[styles.dataCadastro, 'mr-2'].join(' ')}>Nº apólice {emissao.numero_apolice}</Badge>
                    } */}
                </div>
                <div className={[styles.opcoes, ''].join(' ')}>
                    <OpcaoPadrao 
                        label={'Editar'}
                        icone={["fas", 'pencil-alt']}
                        onClick={clicouEditar}
                    />
                </div>
            </div>
        </Card.Body>
    </Card>
}

function OpcaoPadrao({
    label,
    icone,
    onClick
}){


    return <ButtonToolbar>
        <OverlayTrigger
            placement='top'
            overlay={<Tooltip>{label}</Tooltip>}
        >
            <button
                onClick={onClick}
            >
                <FontAwesomeIcon className={styles.icone} icon={icone} />
            </button>
        </OverlayTrigger>
    </ButtonToolbar>
}
