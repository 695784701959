import React from 'react';
import styles from './index.module.scss';

// componentes
import { ButtonToolbar, OverlayTrigger, Tooltip  } from 'react-bootstrap';

// funções
import { converterReal } from './../../../../Funcoes';


function Funil({
    cotacao,
    renovacao,
    proposta,
    emitido,
    emitidoCancelado,
    totalLiquido,
    alterouEtapa
}){

    // cores
    let corCotacao = '#a930a9';
    let corRenovacao = '#d7d702';
    let corProposta = '#5f5fff';
    let corEmitido = '#33d933';
    let corEmitidoCancelado = '#ed3838';

    // retorna
    return <>
        <div className={[styles.funil, ''].join(' ')}>

            <Item 
                cor={corEmitidoCancelado}
                nivel={styles.b0}
                onClick={() => {
                    alterouEtapa('emitidoCancelado');
                }}
                valor={emitidoCancelado}
                descricao={'CANCELADOS'}
                tooltipEtapa={'Ver cancelados'}
            />
            <hr />

            <Item 
                cor={corCotacao}
                onClick={() => {
                    alterouEtapa('cotacao');
                }}
                valor={cotacao}
                descricao={'COTAÇÃO'}
                tooltipEtapa={'Ver Cotações'}
            />
            
            <Item 
                cor={corRenovacao}
                nivel={styles.b2}
                onClick={() => {
                    alterouEtapa('renovacao');
                }}
                valor={renovacao}
                descricao={'RENOVAÇÃO'}
                tooltipEtapa={'Ver renovações'}
            />
            
            <Item 
                cor={corProposta}
                nivel={styles.b3}
                onClick={() => {
                    alterouEtapa('proposta');
                }}
                valor={proposta}
                descricao={'PROPOSTA'}
                tooltipEtapa={'Ver propostas'}
            />
            
            <Item 
                cor={corEmitido}
                nivel={styles.b4}
                onClick={() => {
                    alterouEtapa('emitido');
                }}
                valor={emitido}
                descricao={'EMITIDO'}
                tooltipEtapa={'Ver emissões'}
            />


        </div>
        <p className='text-center mb-0 mt-3'>
            Total liquido <b>R$ {converterReal(totalLiquido)}</b>
        </p>
    </>
}

// item
function Item({
    cor,
    nivel = '',
    onClick, // carregar
    valor,
    descricao,
    tooltipEtapa
}){
    
    
    return <div className={[styles.item, 'mb-2'].join(' ')}>
        <div className={styles.label} style={{color: cor}}>
            <OverlayTrigger
                placement={'top'}
                overlay={<Tooltip>{tooltipEtapa}</Tooltip>}
            >
                <span 
                    onClick={onClick}
                    className={styles.labelSpan}
                >{descricao}</span>
            </OverlayTrigger>
        </div>
        <div className={styles.valor}>
            <div 
                className={[styles.bloco, nivel].join(' ')} 
                style={{background: cor}}
            >
                {valor}
            </div>
        </div>
    </div>
}

export default Funil;