import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

// componentes
import { Button, Row, Col, Form, Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carregador from './../../Componentes/Carregador';
import Registro from './Registro';
import ModalCadastrar from './../ModalCadastrar';

// faz a consulta
export default function Consultar(){

    // params.id
    const { login } = useSelector(state => state);
    const params = useParams();
    let [parametros, alterarParametros] = useState({
        id_usuario: params.id || null,
        data: moment().format('YYYY-MM-DD')
    });
    let [consulta, alterarConsulta] = useState([]);
    let [carregando, alterarCarregando] = useState(true);
    let [modo, alterarModo] = useState('consulta'); // consulta, edicao
    let [cadastroPonto, alterarCadastroPonto] = useState(null);
    let [criandoPdf, alterarCriandoPdf] = useState(false);

    // consultar o ponto do mês
    async function consultarMes(){
        alterarCarregando(true);

        // ajusta variaveis
        parametros.ano = moment(parametros.data).format('YYYY');
        parametros.mes = moment(parametros.data).format('MM');

        try{

            // faz a requisição
            let { data } = await axios.get(`/ponto/consultar/mes`, { params: parametros });
            alterarConsulta(data.datas);
            alterarCarregando(false);
        }catch({response}){}
    }

    // inicializa
    useEffect(() => {
        consultarMes();

    }, [parametros]);

    // verifica se é edição
    useEffect(() => {

        // é edição
        if(params.id){
            alterarModo('edicao');
        }

    }, []);

    // cria pdf
    async function criarPdf(){

        // inicia a transição
        alterarCriandoPdf(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/ponto/mes/pdf`, { params: parametros });

            // faz o download
            window.open(
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=temp&f=${encodeURIComponent(data.arquivo)}&n=${encodeURIComponent(data.nome)}`, 
                '_blank'
            );

        }catch({response}){}


    }

    return <>
        { cadastroPonto && 
            <ModalCadastrar 
                idUsuario={parametros.id_usuario}
                dia={cadastroPonto}
                salvou={(datas) => {
                    alterarConsulta([...datas])
                }}
                fechou={() => {
                    alterarCadastroPonto(null);
                }}
            />
        }

        <Row>
            <Col lg={{span:8, offset:2}}>
                <Card className='shadow-sm border-0'>
                    <Card.Header className='bg-white border-0 mb-0 d-flex'>
                        <Card.Title as='h4' className='mb-0 flex-grow-1'>Ponto</Card.Title>
                        <Button
                            size='sm'
                            variant='info'
                            disabled={criandoPdf}
                            onClick={criarPdf}
                        >
                            { criandoPdf ?
                                <FontAwesomeIcon className="icone" pulse icon={["fas", "spinner"]} />
                                :
                                <FontAwesomeIcon className="icone" icon={["fas", "file-pdf"]} />
                            }
                            
                            <span>Impressão</span>
                        </Button>
                    </Card.Header>
                    <Card.Body>
                        <div className='d-flex align-items-center justify-content-between'>
                            <Button
                                variant='padrao'
                                disabled={carregando}
                                onClick={e => {
                                    parametros.data = moment(parametros.data).subtract(1, 'month');
                                    alterarParametros({...parametros});
                                }}
                            >
                                <FontAwesomeIcon className="icone" icon={["fas", "chevron-left"]} />
                            </Button>
                            <div className='flex-grow-1 text-center font-weight-bold'>
                                {/* Julho / 2019 */}
                                {moment(parametros.data).format('MMMM[ / ]YYYY')}
                            </div>
                            <Button
                                variant='padrao'
                                disabled={carregando}
                                onClick={() => {
                                    parametros.data = moment(parametros.data).add(1, 'month');
                                    alterarParametros({...parametros});
                                }}
                            >
                                <FontAwesomeIcon className="icone" icon={["fas", "chevron-right"]} />
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        <Card className='shadow-sm border-0 mt-4'>
            <Card.Body style={{minHeight: '100px'}}>

                <Table className='tabela'>
                    <thead>
                        <tr>
                            <th colSpan='2'>Dia</th>
                            {/* <th>Dia</th> */}
                            <th>Entradas / Saídas</th>
                            <th>Horas Totais</th>
                            { modo === 'edicao' &&
                                <th></th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {consulta.map(registro => 
                            <Registro 
                                key={registro.data}
                                dados={registro}
                                modo={modo}
                                clicouEditar={() => {
                                    alterarCadastroPonto(registro.data);
                                }}
                            />    
                        )}
                    </tbody>
                </Table>

                {carregando && 
                    <Carregador className="rounded" />
                }
            </Card.Body>
        </Card>
    </>
}
