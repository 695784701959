import React from 'react';

// componentes
import { Form, Row, Col } from 'react-bootstrap';
import InputValor from './../../../Painel/Componentes/InputValor';


function FormularioDadosImovelLocacao({
    dados,
    alterarDados,
    funcaoAlterarDados
}){


    return <>
    
        <Form.Row>
            <Form.Group as={Col} lg='4'>
                <Form.Label>Finalidade do imóvel</Form.Label>
                <Form.Control
                    as='select'
                    value={dados.imovel_finalidade}
                    name='imovel_finalidade'
                    onChange={funcaoAlterarDados}
                >
                    {[
                        {value: '1', children: 'Residencial'},
                        {value: '2', children: 'Comercial'},
                    ].map(opcao => 
                        <option key={opcao.value} {...opcao} />
                    )}
                </Form.Control>
            </Form.Group>

            <Form.Group as={Col} lg='4'>
                <Form.Label>Tipo de imóvel</Form.Label>
                <Form.Control
                    as='select'
                    value={dados.imovel_tipo}
                    name='imovel_tipo'
                    onChange={funcaoAlterarDados}
                >
                    {[
                        {value: '1', children: 'Apartamento'},
                        {value: '2', children: 'Casa'},
                        {value: '3', children: 'Sobrado'},
                        {value: '4', children: 'Comercial'},
                        {value: '5', children: 'Consultório'},
                        {value: '6', children: 'Escritório'},
                        {value: '7', children: 'Outros'},
                    ].map(opcao => 
                        <option key={opcao.value} {...opcao} />
                    )}
                </Form.Control>
            </Form.Group>
            
            <Form.Group as={Col} lg='4'>
                <Form.Label>Motivo da Locação</Form.Label>
                <Form.Control
                    as='select'
                    value={dados.imovel_motivo_locacao}
                    name='imovel_motivo_locacao'
                    onChange={funcaoAlterarDados}
                >
                    {[
                        {value: '1', children: 'Mais adequado'},
                        {value: '2', children: 'Transferência de empresa'},
                        {value: '3', children: 'Estudo'},
                        {value: '4', children: 'Divorcio'},
                        {value: '5', children: 'Outros'}
                    ].map(opcao => 
                        <option key={opcao.value} {...opcao} />
                    )}
                </Form.Control>
            </Form.Group>

        </Form.Row>
        { dados.imovel_motivo_locacao === '5' &&
            <Form.Row>
                <Form.Group as={Col} lg='8'>
                    <Form.Label>Em caso de outros, especifique</Form.Label>
                    <Form.Control
                        value={dados.imovel_especificar_outro}
                        name='imovel_especificar_outro'
                        onChange={funcaoAlterarDados}
                        maxLength='200'
                    />
                </Form.Group>
            </Form.Row>
        }

        {/* mascara */}
        <Form.Row>
            <Form.Group as={Col} lg='4'>
                <Form.Label>Cep do imóvel</Form.Label>
                <Form.Control
                    value={dados.imovel_cep}
                    name='imovel_cep'
                    onChange={funcaoAlterarDados}
                    maxLength='20'
                />
            </Form.Group>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Endereço do imóvel</Form.Label>
                <Form.Control
                    value={dados.imovel_endereco}
                    name='imovel_endereco'
                    onChange={funcaoAlterarDados}
                    maxLength='100'
                />
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Bairro do imóvel</Form.Label>
                <Form.Control
                    value={dados.imovel_bairro}
                    name='imovel_bairro'
                    onChange={funcaoAlterarDados}
                    maxLength='100'
                />
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Cidade do imovel</Form.Label>
                <Form.Control
                    value={dados.imovel_cidade}
                    name='imovel_cidade'
                    onChange={funcaoAlterarDados}
                    maxLength='100'
                />
            </Form.Group>
            <Form.Group as={Col} lg='4'>
                <Form.Label>Estado do imóvel</Form.Label>
                <Form.Control
                    as='select'
                    value={dados.imovel_estado}
                    name='imovel_estado'
                    onChange={funcaoAlterarDados}
                >
                    {[
                        {id: 'AC'},
                        {id: 'AL'},
                        {id: 'AP'},
                        {id: 'AM'},
                        {id: 'BA'},
                        {id: 'CE'},
                        {id: 'DF'},
                        {id: 'ES'},
                        {id: 'GO'},
                        {id: 'MA'},
                        {id: 'MT'},
                        {id: 'MS'},
                        {id: 'MG'},
                        {id: 'PA'},
                        {id: 'PB'},
                        {id: 'PR'},
                        {id: 'PE'},
                        {id: 'PI'},
                        {id: 'RJ'},
                        {id: 'RN'},
                        {id: 'RS'},
                        {id: 'RO'},
                        {id: 'RR'},
                        {id: 'SC'},
                        {id: 'SP'},
                        {id: 'SE'},
                        {id: 'TO'}
                    ].map(opcao => 
                        <option key={opcao.id} value={opcao.id}>{opcao.id}</option>    
                    )}
                </Form.Control>
            </Form.Group>
        </Form.Row>

        
        <Form.Row>
            <Form.Group as={Col} lg='5'>
                <Form.Label>Valor do aluguel</Form.Label>
                <InputValor 
                    valor={dados.imovel_valor_aluguel}
                    onChange={valorNovo => {
                        dados.imovel_valor_aluguel = valorNovo;
                        alterarDados({...dados});
                    }}
                />
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='5'>
                <Form.Label>Valor do IPTU</Form.Label>
                <InputValor 
                    valor={dados.imovel_valor_iptu}
                    onChange={valorNovo => {
                        dados.imovel_valor_iptu = valorNovo;
                        alterarDados({...dados});
                    }}
                />
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='5'>
                <Form.Label>Valor do condominio</Form.Label>
                <InputValor 
                    valor={dados.imovel_valor_condominio}
                    onChange={valorNovo => {
                        dados.imovel_valor_condominio = valorNovo;
                        alterarDados({...dados});
                    }}
                />
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='5'>
                <Form.Label>Valor da Luz</Form.Label>
                <InputValor 
                    valor={dados.imovel_valor_luz}
                    onChange={valorNovo => {
                        dados.imovel_valor_luz = valorNovo;
                        alterarDados({...dados});
                    }}
                />
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='5'>
                <Form.Label>Valor da Água</Form.Label>
                <InputValor 
                    valor={dados.imovel_valor_agua}
                    onChange={valorNovo => {
                        dados.imovel_valor_agua = valorNovo;
                        alterarDados({...dados});
                    }}
                />
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='5'>
                <Form.Label>Valor do Gás</Form.Label>
                <InputValor 
                    valor={dados.imovel_valor_gas}
                    onChange={valorNovo => {
                        dados.imovel_valor_gas = valorNovo;
                        alterarDados({...dados});
                    }}
                />
            </Form.Group>
        </Form.Row>

        <Form.Group>
            <Form.Label>Coberturas Acessórias?</Form.Label> <br />
            <Form.Check 
                type='radio'
                inline
                id='imovel-cobertura-acessorio-s'
                label='Sim'
                name='imovel_cobertura_acessorio'
                checked={dados.imovel_cobertura_acessorio === 'S'}
                value='S'
                onChange={funcaoAlterarDados}
            />
            <Form.Check 
                type='radio'
                inline
                id='imovel-cobertura-acessorio-n'
                label='Não'
                name='imovel_cobertura_acessorio'
                checked={dados.imovel_cobertura_acessorio === 'N'}
                value='N'
                onChange={funcaoAlterarDados}
            />
        </Form.Group>
        <p>
            Não: Cobertura básica (Aluguel + Encargos) <br />
            Sim: Cobertura básica (Aluguel + Encargos + Danos ao Imóvel + Multas Contratuais + Pintura do Imóvel)
        </p>
    
    </>
}

export default FormularioDadosImovelLocacao;