import React from 'react';
import {
    BrowserRouter as Router, 
    Route, 
    Redirect, 
    Switch 
} from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

// componentes
import LoginPagina from './paginas/Login';
import PainelPagina from './paginas/Painel';
import AtendimentoPagina from './paginas/Atendimento';
import Fianca from './paginas/Atendimento/Fianca';
import FormularioProposta from './paginas/Atendimento/FormularioProposta';
import SeguroAventura from './paginas/Atendimento/SeguroAventura';
import AutenticidadeContratacao from './paginas/Atendimento/AutenticidadeContratacao';

// componente raiz
function App({
    login
}){

    return <Router>
        <div>
            <ToastContainer 
                // toastClassName='toast-container rounded shadow-sm'
                toastClassName='toast fade show p-0'
                closeOnClick={false}
                // toastClassName='bg-transparent border-0'
                hideProgressBar={true}
                closeButton={false}
                // render={<Toast />}
                // progressClassName='toast-barra-progreso'
            />

            <Switch>

                <Route 
                    path="/login" 
                    render={() => 
                        login.logado ? <Redirect to="/painel"/> : <LoginPagina />
                    }
                />

                <Route 
                    exact path="/" 
                    render={(propsRoute) => 
                        // redireciona para painel se estiver logado, caso contrario para /login, se já estiver em login, ignora
                        login.logado ? <Redirect to="/painel"/> : <Redirect to="/login"/>
                    
                    }
                />

                <Route 
                    path="/painel" 
                    render={() => 
                        login.logado ? <PainelPagina /> : <Redirect to="/login"/>
                    }
                />

                <Route
                    path='/meuseguro'
                    component={AtendimentoPagina}
                />


                {/* links roca */}

                <Route 
                    path='/seguroAventura/pagina-autenticidade/:token'
                    component={AutenticidadeContratacao}
                />

                <Route
                    path='/formularios/fiancacliente/'
                    component={Fianca}
                />
                <Route
                    path='/formularios/fiancaimobiliaria/'
                    render={(propsRoute => <Fianca {...propsRoute} modo='imobiliaria' />)} 
                />
                <Route
                    path='/formularios/propostaSeguro-temporario/'
                    // component={AtendimentoPagina}
                    component={FormularioProposta}
                />


                <Route
                    path='/formularios/propostaSeguro'
                    component={SeguroAventura}
                />



            </Switch>
        
        </div>
    </Router>
}

// retorna componente
const mapStateToProps = function(state) {

    // retorna os estados
    let estado = {
        login: state.login
    };
    return estado;
    
}

// retorna componente
export default connect(mapStateToProps)(App);

