import React, {useState, useEffect} from 'react';
import styles from './index.module.scss';
import moment from 'moment';

// componentes
import BotaoTabela from './../../../Componentes/BotaoTabela';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { converterReal } from './../../../../Funcoes';

// elemento registro
function Registro({
    dados, 
    clicouFicha,
    mostrarNomeCliente
}){

    return <tr>
        <td className="estreito text-center font-weight-bold">{dados.dados.id}</td>
        <td>
            {mostrarNomeCliente &&
                <p className={'mb-0'}>
                    <small>{dados.cliente.nome}</small>
                </p>
            
            }

            <p className={'mb-0'}>
            { dados.dados.flashsms === 'S' &&
                <FontAwesomeIcon title={'Flashsms'} className="icone mr-1 text-secondary fa-sm" icon={["fas", "eye"]} />
            }
            {dados.dados.mensagem}
            </p>
            { dados.dados.data_agendado === null &&
                <p className={'mb-0'}>
                    <small className={'mr-4'}>
                        <FontAwesomeIcon title={'Data do envio'} className="icone mr-1 text-secondary fa-sm" icon={["fas", "calendar-check"]} />
                        <b>{moment(dados.dados.data_cadastro).format('DD/MM/YYYY')}</b>
                    </small>
                </p>
            }

            { dados.dados.data_agendado !== null &&
                <p className={'mb-0'}>
                    <small className={'mr-4'}>
                        <FontAwesomeIcon title={'Data agendamento'} className="icone mr-1 text-secondary fa-sm" icon={["fas", "calendar"]} />
                        <b>{moment(dados.dados.data_agendado).format('DD/MM/YYYY')}</b>
                    </small>
                    <small className='mr-4'>
                        <FontAwesomeIcon title={'Horario'} className="icone mr-1 text-secondary fa-sm" icon={["fas", "clock"]} />
                        <b>{moment(dados.dados.data_agendado).format('HH:mm')}</b>
                    </small>

                    {/* verifica se é parcela */}
                    { dados.parcela !== null && 
                        <small className={['mr-4'].join(' ')}>
                            <FontAwesomeIcon title={'Parcela da ficha'} className="icone mr-1 text-secondary fa-sm" icon={["fas", "barcode"]} />
                            <b className={'mr-3'}>nº{dados.parcela.numero}</b>
                            <b>R$ {converterReal(dados.parcela.valor)}</b>
                            { dados.parcela.pago === 'S' &&
                                <FontAwesomeIcon title={'Parcela paga'} className="icone mr-1 text-green fa-sm" icon={["fas", "check-circle"]} />
                            }
                        </small>
                    }
                </p>
            }
        </td>
        <td className={'text-center estreito'}>
            
            {   dados.dados.cancelado === 'S' && 
                <Badge variant='danger'>Cancelado</Badge>
            }
            {   dados.dados.cancelado === 'N' &&
                <>
                    {   dados.dados.status_operadora === '1' && 
                        <Badge variant='secondary'>Enfileirada</Badge>
                    }
                    {   dados.dados.status_operadora === '2' && 
                        <Badge variant='light'>Agendada</Badge>
                    }
                    {   dados.dados.status_operadora === '3' && 
                        <Badge variant='info'>Enviando</Badge>
                    }
                    {   dados.dados.status_operadora === '4' && 
                        <Badge variant='primary'>Enviada</Badge>
                    }
                    {   dados.dados.status_operadora === '5' && 
                        <Badge variant='danger'>Erro</Badge>
                    }
                    {   dados.dados.status_operadora === '7' && 
                        <Badge variant='warning'>Usuário sem Créditos</Badge>
                    }
                    {   dados.dados.status_operadora === '9' && 
                        <Badge variant='success'>Recebido</Badge>
                    }
                </>
            } 
        </td>
        <td className="text-nowrap estreito">

            {dados.dados.id_cliente_ficha_emitido_parcelas !== null &&
                <BotaoTabela 
                    onClick={clicouFicha}
                    icone={["fas", "clipboard-list"]}
                >
                    Ficha
                </BotaoTabela>
            }

        </td>
    </tr>
}

export default Registro;