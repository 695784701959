import React, { useState, useEffect } from 'react';

// componentes
import { Form } from 'react-bootstrap';

// funções
import { converterReal } from './../../../Funcoes';

function InputValor({
    valor,
    onChange,
    disabled=false,
    tipo='R', // R: Real, %: porcentagem
    className='',
    onkeyDown=null,
    ...props
}){

    // variaveis
    let [mascara, alterarMascara] = useState(0);
    let [focado, alterarFoco] = useState(false);
    let inputRef = React.createRef();

    // altera a mascara
    useEffect(() => {
        
        criarMascara();
    }, [])

    useEffect(() => {
        criarMascara();
    //     // alterarValorDigitado(valor.replace('.', ','));
    }, [valor]);

    useEffect(() => {
        if(focado){
            inputRef.current.select();
        }
    }, [focado]);

    function criarMascara(){
        
        if(tipo === '%'){
            let mascara = '% ' + converterReal(valor).split('.').join('');
            alterarMascara(mascara);
        }else{
            let mascara = 'R$ ' + converterReal(valor);
            alterarMascara(mascara);
        }
    }
    
    return <Form.Control 
        disabled={disabled}
        type="text" 
        // style={{width: '110px'}}
        className={className}
        ref={inputRef}
        value={focado ? valor.replace('.', ',') : mascara}
        onKeyDown={(e) => {
            // console.log(e.key);
            // if (e.which !== 8 && e.which !== 0 && e.which !== 188 && (e.which < 48 || e.which > 57) && (e.which < 96 || e.which > 105)) {
            //     e.preventDefault();
            // }

            // metodo correto
            if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', 'Backspace'].includes(e.key)){
                e.preventDefault();
            }

            if(e.key === 'Enter'){
                e.target.blur();
            }

            if(e.key === 'Tab'){
                e.target.blur();
            }

            if(e.key === 'Escape'){
                e.target.blur();
            }
            
            if(onkeyDown){
                onkeyDown(e);
            }

            
        }}
        onChange={(e) => {
            let vlorAtualizar = e.target.value === '' ? '0,0' : e.target.value;
            onChange(vlorAtualizar.split('.').join('').replace(',', '.'));
            
        }} 
        onFocus={(e) => {
            alterarFoco(true);
        }}
        onBlur={(e) => {
            let valorCorrigido = parseFloat(e.target.value.split('.').join('').replace(',', '.')).toFixed(2);
            onChange(valorCorrigido);
            alterarFoco(false);
            criarMascara();
        }}
        {...props}
    />
}


export default InputValor;