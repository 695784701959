import styled from 'styled-components';

export const ContainerComponent = styled.div.attrs({
    className: `mt-3`
})`
    
    display: flex;
    position: relative;

    .ficha {

    }

    .minimizar {
        cursor: pointer;
    }

    &:after {
        content: "";
        border-left: 2px solid rgb(95,95,255);
        border-bottom: 2px solid rgb(95,95,255);
        width: 2%;
        left: 0;
        bottom: 0;
        position: absolute;
        height: 91%;
        border-bottom-left-radius: 10px;

        ${props => props.minimizado && `
            display: none;
        `}
    }


`;