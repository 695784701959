import React, { 
    useState, 
    useEffect
} from 'react';
import { 
    Card, 
    Row, 
    Col, 
    Form, 
    Button,
    Tooltip,
    OverlayTrigger
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

// contexto
import { ConsultarContexto } from './ConsultarContexto';

// components
import { 
    faCalendarDay, 
    faClock, 
    faSmile,
    faSmileWink,
    faLaughBeam,
    faFrown,
    faExclamationTriangle, 
    faHiking, 
    faSpinner, 
    faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import { ConsultarComponent } from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FiltroDataCabecalho from './../../Componentes/FiltroDataCabecalho';
import PaginacaoComponente from './../../Componentes/Paginacao';
import Registro from './Registro';
import ModalDiarias from './ModalDiarias';
import ModalErros from './ModalErros'
import Filtros from './Filtros';
import ConfiguracaoProposta from './ConfiguracaoProposta';

// consulta
export default function Consultar(){
    
    // estados
    const history = useHistory();
    const [consulta, alterarConsulta] = useState([]);
    const [chaveApi, alterarChaveApi] = useState(null);
    const [botaoCarregando, alterarBotaoCarregando] = useState(false);
    const [carregando, alterarCarregando] = useState(true);
    const [totalRegistros, atualizarTotalRegistros] = useState(0);
    const [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 12,
        data:  moment().format('YYYY-MM-DD'),
        desconsiderarFinalizado: 'S',
        desconsiderarBoleto: 'S',
        periodo: 'mensal',
        codigo: '',
        participante: '',
        carta: '',
        idCliente: '',
        estados: []
    });
    const [modalParticipantes, alterarModalParticipantes] = useState(null); // {idSeguroAventura: null, texto: ''}
    const [modalErros, alterarModalErros] = useState(null);
    
    // faz a consulta do relatório
    async function fazerConsulta() {

        // ativa o carregamento
        alterarCarregando(true);

        try {
            
            // realiza a requisição ao backend
            const { data } = await axios.get(`/seguroAventura/relatorio`, {
                params: parametros
            });
            
            // resgata dados
            alterarConsulta(data);
            atualizarTotalRegistros(parseInt(data.totalRegistros));
            
        } catch({response}){
            console.error(response);
        } finally {
            alterarCarregando(false);
        }

    }

    // função feita para resgatar chave da api da google do cliente
    async function consultarChaveMapaGoogle() {

        // ativa o carregamento
        alterarCarregando(true);

        try {

            // consulta a chave
            const { data } = await axios.get(`/configuracaoSistema/chaveApiGoogle`);
            
            // altera estado com valor
            alterarChaveApi(data.valor);

        } catch(e){
            console.log(e);
        } finally {

            // esconde carregamento
            alterarCarregando(false);

        }

    }

    useEffect(() => {
        consultarChaveMapaGoogle();
    }, [])

    // faz uma consulta
    useEffect(() => {
        fazerConsulta();
    }, [parametros]);

    return <ConsultarContexto.Provider value={{parametros, alterarParametros, fazerConsulta}}>
        <ConsultarComponent>
            <Row>
                <Col md={5}>
                    <Row 
                        className="mb-2" 
                        style={{ justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <Col>
                            <Row>    
                                <Col>    
                                    <Button
                                        variant="success"
                                        size="sm"
                                        onClick={() => {
                                            history.push(`/painel/seguro-aventura/fechamento/${parametros.data}`)
                                        }}
                                        className={'mr-2'}
                                    >
                                        Fechamento
                                    </Button>                                 
                                    <Button
                                        variant="info"
                                        disabled={botaoCarregando}
                                        size="sm"
                                        onClick ={async () => {                                          

                                            // carrega
                                            alterarBotaoCarregando(true)                                                
                                            
                                            // cria novo axios
                                            let sincronizarDadosXls = axios.create({ 'Access-Control-Allow-Origin': '*' });                                                 

                                            try {

                                                // faz a busca                                        
                                                await sincronizarDadosXls.get(`${process.env.REACT_APP_URL_API}/sincronizarXlsSeguroAventura.php`);                                        

                                            } catch(e) {

                                                console.log(e)

                                            } finally {

                                                // esconde carregamento
                                                alterarBotaoCarregando(false)

                                            }                                                                               

                                        }}
                                    >
                                        {!botaoCarregando ? 'Sincronizar XLS': 'Carregando...'}
                                    </Button>
                                </Col>                   
                            </Row>
                        </Col>
                            
                        <Col lg={5}>
                            <div className="text-right">
                                <Form.Check 
                                    custom
                                    inline
                                    type="radio"
                                    label="Diario"
                                    id="radio-periodo-seguro-aventura-diario"
                                    name="radio-periodo-seguro-aventura"
                                    checked={parametros.periodo === "diario"}
                                    onChange={e => {
                                        alterarParametros({...parametros, periodo: "diario", data: moment().format('YYYY-MM-DD'), pagina: 1});
                                    }}
                                />
                                <Form.Check 
                                    custom
                                    inline
                                    label="Mensal"
                                    type="radio"
                                    id="radio-periodo-seguro-aventura-mensal"
                                    name="radio-periodo-seguro-aventura"
                                    checked={parametros.periodo === "mensal"}
                                    onChange={e => {
                                        alterarParametros({...parametros, periodo: "mensal", data: moment().format('YYYY-MM-DD'), pagina: 1});
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <FiltroDataCabecalho 
                        value={parametros.data}
                        onChange={novaData => {
                            alterarParametros({...parametros, data: novaData, pagina: 1});
                        }}
                        periodo={parametros.periodo}
                        className="mb-2"
                    />

                </Col>
                <Col md={7}>
                    <Row className='justify-content-end mb-2'>
                        <Col>
                            <Card 
                                className={["border-0 bg-warning card-abertos", parametros.estados.includes('aberto') ? 'card-selecionado' : ''].join(' ')}
                                onClick={() => {
                                    if(parametros.estados.includes('aberto')) {
                                        return alterarParametros({
                                            ...parametros,
                                            estados: parametros.estados.filter(e => e !== 'aberto')
                                        });
                                    }
                                    alterarParametros({
                                        ...parametros,
                                        estados: [...parametros.estados, 'aberto']
                                    });
                                }}
                            >
                                <Card.Body className="text-center text-white font-weight-bold p-2">
                                    <div className="text-nowrap">Abertos</div>
                                    <div className="d-flex align-items-center justify-content-around text-center mt-2">
                                        <FontAwesomeIcon icon={faSmile} size="2x" />
                                        <b className='h4 mb-0'>{consulta.totalAbertos}</b>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                        
                        <Col>
                            <Card 
                                className={["border-0 bg-success card-andamento", parametros.estados.includes('andamento') ? 'card-selecionado' : ''].join(' ')}
                                onClick={() => {
                                    if(parametros.estados.includes('andamento')) {
                                        return alterarParametros({
                                            ...parametros,
                                            estados: parametros.estados.filter(e => e !== 'andamento')
                                        });
                                    }
                                    alterarParametros({
                                        ...parametros,
                                        estados: [...parametros.estados, 'andamento']
                                    });
                                }}
                            >
                                <Card.Body className="text-center text-white font-weight-bold p-2">
                                    <div className="text-nowrap">Em andamento</div>
                                    <div className="d-flex align-items-center justify-content-around text-center mt-2">
                                        <FontAwesomeIcon icon={faSmileWink} size="2x" />
                                        <b className='h4 mb-0'>{consulta.totalAndamento}</b>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>   
                        <Col>
                            <Card 
                                className={["border-0 bg-primary card-finalizados", parametros.estados.includes('finalizado') ? 'card-selecionado' : ''].join(' ')}
                                onClick={() => {
                                    if(parametros.estados.includes('finalizado')) {
                                        return alterarParametros({
                                            ...parametros,
                                            estados: parametros.estados.filter(e => e !== 'finalizado')
                                        });
                                    }
                                    alterarParametros({
                                        ...parametros,
                                        estados: [...parametros.estados, 'finalizado']
                                    });
                                }}
                            >
                                <Card.Body className="text-center text-white font-weight-bold p-2">
                                    <div className="text-nowrap">Finalizados</div>
                                    <div className="d-flex align-items-center justify-content-around text-center mt-2">
                                        <FontAwesomeIcon icon={faLaughBeam} size="2x" />
                                        <b className='h4 mb-0'>{consulta.totalFinalizados}</b>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>  
                        <Col>
                            <Card 
                                className={["border-0 bg-danger card-cancelados", parametros.estados.includes('cancelado') ? 'card-selecionado' : ''].join(' ')}
                                onClick={() => {
                                    if(parametros.estados.includes('cancelado')) {
                                        return alterarParametros({
                                            ...parametros,
                                            estados: parametros.estados.filter(e => e !== 'cancelado')
                                        });
                                    }
                                    alterarParametros({
                                        ...parametros,
                                        estados: [...parametros.estados, 'cancelado']
                                    });
                                }}
                            >
                                <Card.Body className="text-center text-white font-weight-bold p-2">
                                    <div className="text-nowrap">Cancelados</div>
                                    <div className="d-flex align-items-center justify-content-around text-center mt-2">
                                        <FontAwesomeIcon icon={faFrown} size="2x" />
                                        <b className='h4 mb-0'>{(parseInt(consulta?.totaisIntegrados?.cancelados ?? '0') + parseInt(consulta?.totais?.cancelados ?? '0')) ?? ''}</b>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                   
                    </Row>
                    <Row className='justify-content-end'>
                        <Col md={3}>
                            <Card className="border-0 bg-info">
                                <Card.Body className="text-center text-white font-weight-bold p-2">
                                    <div className="text-nowrap">Participantes</div>
                                    <div className="d-flex align-items-center justify-content-around text-center mt-2">
                                        <FontAwesomeIcon icon={faHiking} size="2x" />
                                        <b className='h4 mb-0'>{consulta?.diarias?.participantes ?? ''}</b>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card 
                                className="border-0 bg-dark card-diarias"
                                onClick={() => {
                                    alterarModalParticipantes({});
                                }}
                            >
                                <Card.Body className="text-center text-white font-weight-bold p-2">
                                    <div className="text-nowrap">Diarias</div>
                                    <div className="d-flex align-items-center justify-content-around text-center mt-2">
                                        <FontAwesomeIcon icon={faCalendarDay} size="2x" />
                                        <b className='h4 mb-0'>{parseInt(consulta?.diarias?.periodo ?? '0') ?? ''}</b>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr />

            <div>
                <Row className="mb-4">
                    {parseInt(consulta?.totaisIntegrados?.erros) > 0 &&
                        <Col md={3}>
                            <Card 
                                className="border-0 bg-warning card-erros" 
                                title="Eventos que ainda não foram concluidos"
                                onClick={() => {
                                    alterarModalErros({});
                                }}
                            >
                                <Card.Body className="text-center font-weight-bold p-2">
                                    {/* eventos que não estão dentro da data finalizada */}
                                    
                                    <div className="d-flex align-items-center justify-content-around text-center">
                                        <FontAwesomeIcon icon={faExclamationTriangle} size="1x" />
                                        <div className="text-nowrap" style={{fontSize: '0.7rem'}}>Com erros</div>
                                        <div>{consulta?.totaisIntegrados?.erros}</div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    }
                    {parseInt(consulta?.totalNovos?.eventosNaoConcluidos) > 0 &&
                        <Col md={3}>
                            <Card className="border-0 bg-info text-white" title="Eventos que ainda não foram concluidos">
                                <Card.Body className="text-center font-weight-bold p-2">
                                    {/* eventos que não estão dentro da data finalizada */}
                                    
                                    <div className="d-flex align-items-center justify-content-around text-center">
                                        <FontAwesomeIcon icon={faClock} size="1x" />
                                        <div className="text-nowrap" style={{fontSize: '0.7rem'}}>A concluir</div>
                                        <div>{consulta?.totalNovos?.eventosNaoConcluidos}</div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    }
                    {parseInt(consulta?.totalNovos?.participantesNaoConcluidos) > 0 &&
                        <Col md={3}>
                            <Card className="border-0 bg-success text-white" title="Participantes que ainda não foram concluidos">
                                <Card.Body className="text-center font-weight-bold p-2">
                                    <div className="d-flex align-items-center justify-content-around text-center">
                                        <FontAwesomeIcon icon={faUserPlus} size="1x" />
                                        <div className="text-nowrap" style={{fontSize: '0.7rem'}}>A participar</div>
                                        <div>{consulta?.totalNovos?.participantesNaoConcluidos}</div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    }
                    {(chaveApi) && <Row style={{ display: 'flex', flexDirection: 'row', flex:1, marginTop: -5, margin: 0, justifyContent: 'flex-end', paddingRight: 15 }}>
                        <OverlayTrigger
                            placement={'top'}
                            overlay={<Tooltip>
                                Abrir resultado de clientes no mapa
                            </Tooltip>}
                        >
                            <Button
                                variant='padrao'
                                size='lg'
                                onClick={() => {
                                    history.push({
                                        pathname: '/painel/seguro-aventura/clientes-eventos-mapa',
                                        state: { 
                                            chaveApi,
                                            parametros: {...parametros}
                                        }
                                    });
                                }}
                            >
                                <FontAwesomeIcon 
                                    className="icone"
                                    icon={["fas", "map-marked-alt"]} 
                                />
                            </Button>
                        </OverlayTrigger>            
                    </Row>}
                </Row>
            </div>

            <div className="mt-3">
                <Filtros 
                    parametros={parametros}
                    alterarParametros={alterarParametros}
                />
            </div>
            <hr />

            {
                (
                    !carregando && 
                    (
                        consulta?.registros && 
                        consulta?.registros.length > 0
                    )
                ) ? <div className="mt-3">
                <div className="registros-eventos mb-3">
                    {consulta.registros.map(registro => 
                        <Registro 
                            key={registro.dados.id}
                            dados={registro.dados}
                            fila={registro.fila}
                            diarias={registro.diarias}
                            clicou={() => {
                                alterarModalParticipantes({idSeguroAventura: registro.dados.id});
                            }}
                            clicouErros={() => {
                                alterarModalErros({idSeguroAventura: registro.dados.id});
                            }}
                        />
                    )}
                </div>

                <PaginacaoComponente
                    pagina={parametros.pagina}
                    registrosPorPagina={parametros.registrosPorPagina}
                    totalRegistros={totalRegistros}
                    alterouPagina={(pagina) => {
                        alterarParametros({...parametros, pagina: pagina});
                    }}
                />

                </div> : <>
                    <FontAwesomeIcon icon={faSpinner} pulse /> Aguarde!
                </>
            }
        </ConsultarComponent>

        {modalParticipantes &&
            <ModalDiarias 
                {...modalParticipantes}
                fechou={() => {
                    alterarModalParticipantes(null);
                }}
            />
        }

        {modalErros &&
            <ModalErros
                {...modalErros}
                fechou={() => {
                    alterarModalErros(null);
                }}
            />
        }

        <hr className="mt-5" />
        <div >
            <Row>
                <Col lg={{span: 6, offset: 6}}>
                    <ConfiguracaoProposta />
                </Col>
            </Row>
        </div>

    </ConsultarContexto.Provider>

}