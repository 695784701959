import React, {useState, useEffect} from 'react';
import styles from './index.module.scss';

// componentes
import { Badge } from 'react-bootstrap';
import BotaoTabela from './../../../Componentes/BotaoTabela';

// elemento registro
function Registro({
    dados, 
    clicouEditar,
    clicouExcluir
}){

    return <tr
        onClick={clicouEditar}
        className={`clicavel`}
    >
        <td>{dados.endereco_ip}</td>
        <td className="text-nowrap estreito">

            {/* <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >Editar</BotaoTabela> */}
            
            <BotaoTabela 
                onClick={clicouExcluir}
                icone={["fas", "trash"]}
            >Excluir</BotaoTabela>
        </td>
    </tr>
}

export default Registro;