import React from 'react';
import { useHistory } from 'react-router-dom';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faSms } from '@fortawesome/free-solid-svg-icons';
import { AtalhoComponent } from './components';
import { ButtonToolbar, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

// atalhos
export default function Atalhos({
    idCliente
}){

    // estados
    const history = useHistory();

    return <>

        <AtalhoComponent>
            <ButtonToolbar>
                <OverlayTrigger
                    placement='top'
                    overlay={<Tooltip>Ir para cadastro do cliente</Tooltip>}
                >
                    <Button
                        variant='padrao'
                        size='sm'
                        onClick={() => {
                            history.push(`/painel/clientes/alterar/${idCliente}/fichas`);
                        }}
                        className='rounded-circle'
                    >
                        <FontAwesomeIcon className="icone" icon={faUser} />
                    </Button>
                </OverlayTrigger>
            </ButtonToolbar>
        </AtalhoComponent>

        <AtalhoComponent>
            <ButtonToolbar>
                <OverlayTrigger
                    placement='right'
                    overlay={<Tooltip>Enviar email</Tooltip>}
                >
                    <Button
                        variant='padrao'
                        size='sm'
                        onClick={() => {
                            history.push(`/painel/clientes/enviar-email/${idCliente}`);
                        }}
                        className='rounded-circle'
                    >
                        <FontAwesomeIcon className="icone" icon={faEnvelope} />
                    </Button>
                </OverlayTrigger>
            </ButtonToolbar>
        </AtalhoComponent>

        <AtalhoComponent>
            <ButtonToolbar>
                <OverlayTrigger
                    placement='bottom'
                    overlay={<Tooltip>Enviar sms</Tooltip>}
                >
                    <Button
                        variant='padrao'
                        size='sm'
                        onClick={() => {
                            history.push(`/painel/clientes/enviar-sms/${idCliente}`);
                        }}
                        className='rounded-circle'
                    >
                        <FontAwesomeIcon className="icone" icon={faSms} />
                    </Button>
                </OverlayTrigger>
            </ButtonToolbar>
        </AtalhoComponent>


    </>

}