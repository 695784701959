import React from 'react';
import styles from './index.module.scss';

// componentes
import { BrowserView, MobileView, isMobile } from "react-device-detect";

// logos
import visa from './bandeiras/visa.png';
import dinners from './bandeiras/dinners.png';
import american from './bandeiras/american.png';
import mastercard from './bandeiras/mastercard.png';
import elo from './bandeiras/elo.png';

export default function Cartao({
    dados,
    alterarDados
}){

    return <>
        <div
            className={[
                styles.cartoes,
                isMobile ? styles.mobile : ''
            ].join(' ')}
        >

            {[
                {id: 1, img: visa},
                {id: 2, img: mastercard},
                {id: 3, img: american},
                {id: 4, img: dinners},
                {id: 5, img: elo},
            ].map(bandeira => 
                <div
                    onClick={e => {
                        dados.bandeira = bandeira.id;
                        alterarDados({...dados});
                    }}
                    key={bandeira.id}
                    className={[
                        (parseInt(dados.bandeira) === bandeira.id) ? styles.ativo: '',
                        'p-2 m-2',
                        styles.cartao
                    ].join(' ')}
                >
                    <img src={bandeira.img} />
                </div>
            )}
        </div>

    </>

}