import React, {useState, useEffect} from 'react';
import styles from './index.module.scss';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import BotaoTabela from './../../../Componentes/BotaoTabela';
import { Badge } from 'react-bootstrap';

// funçõs
import { estiloEvento } from './../../../ConfiguracaoSistema';

// elemento registro
export default function Registro({
    dados, 
    coresEventos,
    parametros,
    clicouEditar
}){

    // estilo do card
    let estilo = {
        background: '',
        color: ''
    };
    let tipo = 'Cliente';
    let nomeCliente = '';

    // altera estilo do card
    if(dados.alvo_tabela === 'clientes'){
        estilo.background = coresEventos['cliente'].fundo;
        estilo.color = coresEventos['cliente'].texto;
        nomeCliente = dados._dadosConsulta.nome;
    }else{
        estilo = estiloEvento(coresEventos, dados._dadosConsulta.tipo);
        switch(coresEventos, dados._dadosConsulta.tipo){
            case '1': tipo = 'Direito' ;break;
            case '2': tipo = 'Seguro' ;break;
            case '3': tipo = 'Consultoria' ;break;
            case '4': tipo = 'Avaliação' ;break;
            case '5': tipo = 'Imóveis' ;break;
        }
        nomeCliente = dados._dadosCliente.nome;
    }

    // define icone de data
    let icone = ["fas", 'clock'];
    let data = moment(dados.data_programada).format('DD/MM/YYYY');
    let corStatus = '';

    // switch(dados.status_programacao){
    //     case 2: corStatus = '#00bdf7'; break;
    //     case 3: corStatus = '#dddd00'; break;
    //     case 4: corStatus = '#dd0000'; break;
    // }

    // se concluido
    if(dados.concluido === 'S'){
        icone = ["fas", 'check-square'];
        data = moment(dados.data_concluido).format('DD/MM/YYYY');
        corStatus = '#00d100';
    }

    return <tr>
        <td className="estreito text-center font-weight-bold">{dados.id}</td>
        <td>
            { dados._dadosResponsavel.cliente.foto ?
                <img src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/fotos/${dados._dadosResponsavel.cliente.foto}`} className={styles.foto} />
                :
                <FontAwesomeIcon className={styles.iconeUsuario} icon={["fas", "user-circle"]}></FontAwesomeIcon>
            }
        </td>
        <td>
            {/* <Badge
                className={['p-1 mr-1 bg-light rounded', styles.responsavel].join(' ')}
            >
                <FontAwesomeIcon className='mr-1' icon={['fas', 'user-tie']} />
                {  dados._dadosResponsavel.cliente.nome.split(' ')[0]}
            </Badge> */}
            {/* <div> */}
                <Badge style={estilo} className='mr-1'>{tipo}</Badge>
                {dados.descricao}
            {/* </div> */}
        </td>
        <td>{nomeCliente}</td>
        <td style={{color: corStatus}} className='text-nowrap'>
            <FontAwesomeIcon className='mr-1' icon={icone} />
            <span>{data}</span>
        </td>
        <td className="text-nowrap estreito">
            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >Editar</BotaoTabela>
        </td>
    </tr>
}