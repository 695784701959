import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import ListaCheckbox from './../../../Componentes/ListaCheckbox';

// cria os parametros
function Parametros({
    parametros, 
    alterarParametros
}){

    // parametros
    return <div className="parametros">
        <div className="parametros-inicial">
            <Link to='/painel/modelos-email/cadastrar'>
                <Button variant="padrao" className="botao-cadastrar">
                    <FontAwesomeIcon className="icone" icon={["fas", "plus"]} />
                    <span>Cadastrar Modelo de Email</span>
                </Button>
            </Link>
            <DebounceInput 
                placeholder="" 
                debounceTimeout={400}
                element={Form.Control} 
                value={parametros.texto}
                onChange={(e) =>{
                    alterarParametros({...parametros, texto: e.target.value, pagina: 1})
                }}
            />
            <div className="campo-flags">
                <ListaCheckbox 
                    itens={[
                        {id: 'S', label: 'Ativos'},
                        {id: 'N', label: 'Inativos'}
                    ]}
                    value={parametros.ativo}
                    label='tipo-pessoa'
                    onChange={(ids) => {
                        parametros.ativo = ids;
                        alterarParametros({...parametros, pagina: 1});
                    }}
                />
            </div>
        </div>
    </div>

}

// importa states
let mapStateToProps = function(state){
    let estado = {
        login: state.login
    }
    return estado;
};

// retorna página de consulta
export default connect(mapStateToProps)(Parametros);