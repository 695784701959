import React, { useState } from 'react';
import { 
    Button, 
    Form, 
    Row, 
    Col 
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Portuguese } from "flatpickr/dist/l10n/pt";
import Flatpick from 'react-flatpickr';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import { validarData } from '../../../../Funcoes';
import ComboCheckbox from './../../../Componentes/ComboCheckbox';
import Estados from '../../Cadastrar/Estados';
import ListaCheckbox from './../../../Componentes/ListaCheckbox';
import ComboGrupos from './../../Cadastrar/ComboGrupos';
import ComboGruposConsulta from './../../../Componentes/ComboGruposConsulta';
import ComboTipoCliente from './../../../Componentes/ComboTipoCliente';
import ComboResponsavelConsulta from './../../../Componentes/ComboResponsavelConsulta';
import InputData from '../../../Componentes/InputData';
import ComboPlanos from '../../../Componentes/ComboPlanos';

// import GerarRelatorioSeguradoras from './GerarRelatorioSeguradoras';

// cria os parametros
export default function Parametros({
    parametros, 
    totalRegistros,
    reenviarNotificacoes,
    alterarParametros
}){

    // expande filtros
    const dataCadastroPicker = React.createRef();    
    const dataBoletoVencimento = React.createRef();
    const dataBoletoAVencer = React.createRef();
    const refSelect = React.createRef(null);
    const { login } = useSelector(state => state);
    const [mostrarMais, expandirParametros] = useState(false); 

    // parametros
    return <div className="parametros">
        <div className="parametros-inicial">
            { (parseInt(login.permissao.clientes) > 2) &&
                <Link to='/painel/clientes/cadastrar'>
                    <Button variant="padrao" className="botao-cadastrar">
                        <FontAwesomeIcon className="icone" icon={["fas", "plus"]} />
                        <span>Cadastrar Cliente</span>
                    </Button>
                </Link>
            }
            <DebounceInput 
                placeholder="Ex.: #154, alexandre, 816.969.586-44, 03.308.892/0001-80, 48 9999-99999, 18/09/1992" 
                debounceTimeout={400}
                element={Form.Control} 
                value={parametros.texto}
                onChange={(e) =>{
                    alterarParametros({...parametros, texto: e.target.value, pagina: 1})
                }}
            />
            <div className="campo-flags">
                <ListaCheckbox 
                    itens={[
                        {id: 'S', label: 'Ativos'},
                        {id: 'N', label: 'Inativos'}
                    ]}
                    value={parametros.ativo}
                    label='tipo-pessoa'
                    onChange={(ids) => {
                        parametros.ativo = ids;
                        alterarParametros({...parametros, pagina: 1});
                    }}
                />
            </div>
            {!mostrarMais && <Button 
                variant="padrao"
                onClick={() => expandirParametros(true)}
            >
                <FontAwesomeIcon className="icone" icon={["fas", "search-plus"]} />
            </Button>}
        </div>

        {mostrarMais && 
            <Row className='mt-3'>
                <Col 
                    md='8'
                    className='d-flex flex-column'
                    style={{
                        gap: 5,
                        
                    }}
                >
                    <Form.Group>
                        <ComboResponsavelConsulta 
                            value={parametros.responsaveis}
                            onChange={responsaveisArray => {
                                parametros.responsaveis = responsaveisArray;
                                alterarParametros({...parametros});
                            }}
                        />
                    </Form.Group>

                    <Form.Row>
                        <Col>
                            <Form.Group>
                                <ComboGruposConsulta 
                                    ids={parametros.grupos}
                                    alterou={(gruposArray) => {
                                        parametros.grupos = gruposArray;
                                        alterarParametros({...parametros});
                                    }}
                                    placeholder='Grupos a considerar'
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <ComboGruposConsulta 
                                    ids={parametros.grupos_desconsiderados}
                                    alterou={(gruposArray) => {
                                        parametros.grupos_desconsiderados = gruposArray;
                                        alterarParametros({...parametros});
                                    }}
                                    placeholder='Grupos a desconsiderar'
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    {/* campo vazio */}
                    <Form.Row>
                        <Col lg='6'>
                            <Form.Group>
                                <Form.Control
                                    as='select'
                                    value={parametros.campo_vazio}
                                    onChange={e => {
                                        parametros.campo_vazio = e.target.value;
                                        alterarParametros({...parametros});
                                    }}
                                >
                                    {[
                                        {value: '', children: 'Filtrar campo vazio'},
                                        {value: 'nome', children: 'Nome'},
                                        {value: 'nome_pai', children: 'Nome do Pai'},
                                        {value: 'nome_mae', children: 'Nome da Mãe'},
                                        {value: 'profissao', children: 'Profissão'},
                                        {value: 'data_nascimento', children: 'Data de nascimento'},
                                        {value: 'nome_fantasia', children: 'Nome Fantasia'},
                                        {value: 'foto', children: 'Sem foto'},
                                        {value: 'cpf', children: 'CPF'},
                                        {value: 'rg', children: 'RG'},
                                        {value: 'rg_data_emissao', children: 'Data de emissão do RG'},
                                        {value: 'cnh', children: 'CNH'},
                                        {value: 'cnh_data_emissao', children: 'Data de emissão da CNH'},
                                        {value: 'cnpj', children: 'CNPJ'},
                                        {value: 'responsavel_juridico_nome', children: 'Responsavel Juridico'},
                                        {value: 'responsavel_juridico_telefone', children: 'Telefone do responsavel do juridico'},
                                        {value: 'responsavel_juridico_email', children: 'Email do responsavel do Juridico'},
                                        {value: 'telefone_residencial', children: 'Telefone Residencial'},
                                        {value: 'telefone_comercial', children: 'Telefone Comercial'},
                                        {value: 'telefone_celular', children: 'Telefone Celular'},
                                        {value: 'email', children: 'Email'},
                                        {value: 'site', children: 'Site'},
                                        {value: 'cep', children: 'CEP'},
                                        {value: 'cidade', children: 'Cidade'},
                                        {value: 'estado', children: 'Estado'},
                                        {value: 'endereco', children: 'Endereço'},
                                        {value: 'numero', children: 'Numero'},
                                        {value: 'bairro', children: 'Bairro'},
                                        {value: 'complemento', children: 'Complemento'}

                                    ].map(opcao => 
                                        <option key={opcao.value} {...opcao} />    
                                    )}
                                    
                                </Form.Control>
                            </Form.Group>

                            {/* <GerarRelatorioSeguradoras /> */}
                        </Col>
                        <Col lg={6}>

                            <DebounceInput 
                                placeholder="Placa veículo" 
                                debounceTimeout={400}
                                element={Form.Control} 
                                value={parametros.placa_veiculo}
                                onChange={(e) =>{
                                    alterarParametros({...parametros, placa_veiculo: e.target.value, pagina: 1})
                                }}
                            />

                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Group>
                                <DebounceInput 
                                    placeholder="Número de apólice" 
                                    debounceTimeout={400}
                                    element={Form.Control} 
                                    value={parametros.numero_apolice}
                                    onChange={(e) =>{
                                        alterarParametros({ 
                                            ...parametros, 
                                            numero_apolice: e.target.value, 
                                            pagina: 1 
                                        })
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="d-flex align-itens-center">
                                <Flatpick
                                    ref={dataCadastroPicker}
                                    value={parametros.data_cadastro}
                                    options={{
                                        dateFormat: "Y-m-d",
                                        altFormat: "d/m/Y",
                                        altInput: false,
                                        allowInput: false,
                                        disableMobile: true,
                                        locale: Portuguese,
                                        onChange: (selectedDates, dateStr, instance) => {
                                            if (dateStr === '') dateStr = null;
                                            alterarParametros({ ...parametros, data_cadastro: dateStr, pagina: 1 })
                                        }
                                    }}
                                    render={
                                        ({ defaultValue, value, onChange, ...props }, ref) => {
                                            return <input 
                                                onChange={(selectedDates, dateStr, instance) => alterarParametros({ ...parametros, data_cadastro: dateStr })} 
                                                value={parametros.data_cadastro} 
                                                style={{ 
                                                    visibility: 'hidden', 
                                                    position: 'absolute' 
                                                }} 
                                                {...props} 
                                                defaultValue={defaultValue} 
                                                ref={ref} 
                                            />
                                        }
                                    }
                                />
                                <InputData
                                    data={parametros.data_cadastro}
                                    placeholder={'Data de Cadastro'}
                                    onChange={(data) => {                                                                                                           
                                        if(data && data.length === 10 && !validarData(data, 'invalid')) {
                                            alterarParametros({ ...parametros, data_cadastro: data, pagina: 1 });
                                        } else if(!data) {
                                            alterarParametros({ ...parametros, data_cadastro: '', pagina: 1 });
                                        }
                                    }}
                                    size="md"
                                    style={{
                                        width: 'auto',
                                        flex: 1
                                    }}
                                />
                                <FontAwesomeIcon
                                    style={{
                                        cursor: 'pointer',
                                        alignSelf: 'center',
                                        marginInline: 10,
                                        fontSize: 24
                                    }}
                                    icon={["fas", 'calendar-day']}
                                    onClick={() => dataCadastroPicker.current.flatpickr.open()}
                                />   
                                <Form.Check 
                                    inline                                    
                                    checked={(parametros.acima_data_cadastro === 'N') ? false : true}
                                    id={`parametro-a-partir-data`}
                                    type='checkbox'
                                    label={`A partir desta data?`}
                                    disabled={parametros.data_cadastro.length === 0}
                                    onChange={e => {
                                        parametros.acima_data_cadastro = e.target.checked ? 'S' : 'N';
                                        alterarParametros({...parametros});
                                    }}
                                    style={{ 
                                        marginLeft: 5,
                                        fontSize: 14
                                    }}
                                />                           
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Group className="d-flex align-itens-center">
                                <Flatpick
                                    ref={dataBoletoAVencer}
                                    value={parametros.data_boleto_a_vencer}
                                    options={{
                                        dateFormat: "Y-m-d",
                                        altFormat: "d/m/Y",
                                        altInput: false,
                                        allowInput: false,
                                        disableMobile: true,
                                        locale: Portuguese,
                                        onChange: (selectedDates, dateStr, instance) => {
                                            if (dateStr === '') dateStr = null;
                                            alterarParametros({ 
                                                ...parametros, 
                                                data_boleto_a_vencer: dateStr,
                                                fatura_minima_anterior: 'N', 
                                                pagina: 1 
                                            })
                                        }
                                    }}
                                    render={
                                        ({ defaultValue, value, onChange, ...props }, ref) => {
                                            return <input 
                                                onChange={(selectedDates, dateStr, instance) => {
                                                    alterarParametros({ 
                                                        ...parametros, 
                                                        data_boleto_a_vencer: dateStr, 
                                                        data_boleto_vencimento: '',
                                                        fatura_minima_anterior: 'N', 
                                                        pagina: 1 
                                                    })
                                                }} 
                                                value={parametros.data_boleto_a_vencer} 
                                                style={{ 
                                                    visibility: 'hidden', 
                                                    position: 'absolute' 
                                                }} 
                                                {...props} 
                                                defaultValue={defaultValue} 
                                                ref={ref}
                                                disabled={parametros.fatura_minima_anterior && parametros.fatura_minima_anterior === 'S'} 
                                            />                                                
                                        }
                                    }
                                />
                                <InputData
                                    data={parametros.data_boleto_a_vencer}
                                    placeholder={'Data de vencimento de boletos a vencer'}
                                    onChange={(data) => {                                                                                                           
                                        if(data && data.length === 10 && !validarData(data, 'invalid')) {
                                            alterarParametros({ 
                                                ...parametros, 
                                                data_boleto_a_vencer: data,
                                                data_boleto_vencimento: '',
                                                fatura_minima_anterior: 'N',                             
                                                pagina: 1 
                                            });
                                        } else if(!data) {
                                            alterarParametros({ 
                                                ...parametros, 
                                                data_boleto_a_vencer: '', 
                                                pagina: 1 
                                            });
                                        }
                                    }}
                                    size="md"
                                    style={{
                                        width: 'auto',
                                        flex: 1
                                    }}
                                    readOnly={parametros.fatura_minima_anterior && parametros.fatura_minima_anterior === 'S'}
                                />
                                {(parametros.data_boleto_a_vencer && parametros.data_boleto_a_vencer.length > 0) && <FontAwesomeIcon 
                                    className="icone fa-lg" 
                                    icon={["fas", "times"]}
                                    onClick={() => {                                                        
                                        parametros.data_boleto_a_vencer = '';
                                        alterarParametros({...parametros});
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        alignSelf: 'center',
                                        marginLeft: 5,
                                        marginTop: 2,
                                        fontSize: 28
                                    }} 
                                />}
                                <FontAwesomeIcon
                                    style={{
                                        cursor: 'pointer',
                                        alignSelf: 'center',
                                        marginInline: 10,
                                        fontSize: 24,
                                        opacity: parametros.fatura_minima_anterior && parametros.fatura_minima_anterior === 'S' ? 0.5 : 1
                                    }}                                    
                                    icon={["fas", 'calendar-day']}
                                    onClick={() => {
                                        if(parametros.fatura_minima_anterior && parametros.fatura_minima_anterior === 'S') return;
                                        dataBoletoAVencer.current.flatpickr.open()
                                    }}
                                />
                            </Form.Group>   
                        </Col>
                        <Col>     
                            <Form.Group className="d-flex align-itens-center">
                                <Flatpick
                                    ref={dataBoletoVencimento}
                                    value={parametros.data_boleto_vencimento}
                                    options={{
                                        dateFormat: "Y-m-d",
                                        altFormat: "d/m/Y",
                                        altInput: false,
                                        allowInput: false,
                                        disableMobile: true,
                                        locale: Portuguese,
                                        onChange: (selectedDates, dateStr, instance) => {
                                            if (dateStr === '') dateStr = null;
                                            alterarParametros({ 
                                                ...parametros, 
                                                data_boleto_vencimento: dateStr,
                                                data_boleto_a_vencer: '',
                                                fatura_minima_anterior: 'N', 
                                                pagina: 1 
                                            })
                                        }
                                    }}
                                    render={
                                        ({ defaultValue, value, onChange, ...props }, ref) => {
                                            return <input 
                                                onChange={(selectedDates, dateStr, instance) => alterarParametros({ ...parametros, data_boleto_vencimento: dateStr, fatura_minima_anterior: 'N', pagina: 1 })} 
                                                value={parametros.data_boleto_vencimento} 
                                                style={{ 
                                                    visibility: 'hidden', 
                                                    position: 'absolute' 
                                                }} 
                                                {...props} 
                                                defaultValue={defaultValue} 
                                                ref={ref}
                                                disabled={parametros.fatura_minima_anterior && parametros.fatura_minima_anterior === 'S'} 
                                            />
                                        }
                                    }
                                />
                                <InputData
                                    data={parametros.data_boleto_vencimento}
                                    placeholder={'Data de vencimento de boletos vencidos'}
                                    onChange={(data) => {                                                                                                           
                                        if(data && data.length === 10 && !validarData(data, 'invalid')) {
                                            alterarParametros({ 
                                                ...parametros, 
                                                data_boleto_vencimento: data,
                                                data_boleto_a_vencer: '',
                                                fatura_minima_anterior: 'N',                             
                                                pagina: 1 
                                            });
                                        } else if(!data) {
                                            alterarParametros({ 
                                                ...parametros, 
                                                data_boleto_vencimento: '', 
                                                pagina: 1 
                                            });
                                        }
                                    }}
                                    size="md"
                                    style={{
                                        width: 'auto',
                                        flex: 1
                                    }}
                                    readOnly={parametros.fatura_minima_anterior && parametros.fatura_minima_anterior === 'S'}
                                />
                                {(parametros.data_boleto_vencimento && parametros.data_boleto_vencimento.length > 0) && <FontAwesomeIcon 
                                    className="icone fa-lg" 
                                    icon={["fas", "times"]}
                                    onClick={() => {                                                        
                                        parametros.data_boleto_vencimento = '';
                                        alterarParametros({...parametros});
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        alignSelf: 'center',
                                        marginLeft: 5,
                                        marginTop: 2,
                                        fontSize: 28
                                    }} 
                                />}
                                <FontAwesomeIcon
                                    style={{
                                        cursor: 'pointer',
                                        alignSelf: 'center',
                                        marginInline: 10,
                                        fontSize: 24,
                                        opacity: parametros.fatura_minima_anterior && parametros.fatura_minima_anterior === 'S' ? 0.5 : 1
                                    }}                                    
                                    icon={["fas", 'calendar-day']}
                                    onClick={() => {
                                        if(parametros.fatura_minima_anterior && parametros.fatura_minima_anterior === 'S') return;
                                        dataBoletoVencimento.current.flatpickr.open()
                                    }}
                                />
                            </Form.Group>              
                        </Col>                        
                    </Form.Row>
                    <Form.Row>
                        <Col lg={6}>
                            <Form.Group>
                                <ComboPlanos 
                                    id={parametros.id_plano}
                                    alterou={(dadosPlano) => {
                                        parametros.id_plano = dadosPlano ? dadosPlano.id : dadosPlano;
                                        alterarParametros({ ...parametros });
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group>
                                <ComboTipoCliente 
                                    value={parametros.tipo_cliente}
                                    onChange={tipoClienteArray => {
                                        parametros.tipo_cliente = tipoClienteArray;
                                        alterarParametros({...parametros});
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col lg={6}>                            
                            <Form.Group className='d-flex align-items-center'>
                                <Estados 
                                    value={parametros.estado} 
                                    resgatarRef={(ref) => refSelect.current = ref}
                                    size={'md'}                        
                                    onChange={(e) => {
                                        parametros.estado = e.target.value;
                                        alterarParametros({...parametros});
                                    }}         
                                    placeholder={'Estados'}
                                    primeiraOption={
                                        <option 
                                            value="" 
                                            disabled 
                                            selected
                                        >
                                            Estados
                                        </option>
                                    }
                                />
                                {(parametros.estado) && <FontAwesomeIcon 
                                    className="icone fa-lg" 
                                    icon={["fas", "times"]}
                                    onClick={() => {
                                        if(refSelect.current) refSelect.current.selectedIndex = 0;
                                        parametros.estado = null;
                                        alterarParametros({...parametros});
                                    }}
                                    style={{
                                        cursor:'pointer',
                                        marginLeft: 10,
                                        marginRight: 5,
                                        marginTop: 5
                                    }} 
                                />}  
                            </Form.Group>
                        </Col>
                        {/* somente mostra botão de reenviar se filtro estiver preenchido e se possuir registros */}
                        {(parametros?.data_boleto_a_vencer && totalRegistros > 0) && 
                            <Col>
                                <Form.Group className='d-flex align-items-center justify-content-end'>
                                    <Button 
                                        variant="padrao" 
                                        className="botao-cadastrar" 
                                        onClick={reenviarNotificacoes}
                                    >
                                        <span>Reenviar notificações em massa!</span>
                                    </Button>
                                </Form.Group>
                            </Col>
                        }                            
                    </Form.Row>
                </Col>          
                <Col 
                    md='4' 
                    style={{
                        textAlign: 'end',
                        borderLeft: '1px solid hsl(0,0%,80%)'
                    }}
                >
                    <Form.Label 
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        Tipo Pessoa
                    </Form.Label>
                    <div className="campo-flags">
                        <ListaCheckbox 
                            itens={[
                                {id: 'F', label: 'Fisico'},
                                {id: 'J', label: 'Juridico'}
                            ]}
                            value={parametros.tipo_pessoa}
                            label='tipo-pessoa'
                            onChange={(ids) => {
                                parametros.tipo_pessoa = ids;
                                alterarParametros({...parametros, pagina: 1});
                            }}
                            style={{ fontSize: 14 }}
                        />
                    </div>

                    <Form.Label 
                        className="mt-2"
                        style={{
                            fontWeight: 'bold'
                        }} 
                    >
                        Pré Cliente?
                    </Form.Label>
                    <div className="campo-flags">
                        <ListaCheckbox 
                            itens={[
                                {id: 'S', label: 'Sim'},
                                {id: 'N', label: 'Não'}
                            ]}
                            value={parametros.pre_cliente}
                            label='pre-cliente'
                            onChange={(ids) => {
                                parametros.pre_cliente = ids;
                                alterarParametros({...parametros, pagina: 1});
                            }}
                            style={{ fontSize: 14 }}
                        />
                    </div>
                        
                    <Form.Label 
                        className="mt-2"
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        Com vigência?
                    </Form.Label>
                    <div>
                        <Form.Check 
                            inline
                            checked={parametros.possui_vigencia === 'N' ? false : true}
                            id={`parametro-com-vigencia`}
                            type='checkbox'
                            label={'Ficha vigente'}
                            onChange={e => {
                                parametros.possui_vigencia = e.target.checked ? 'S' : 'N';
                                alterarParametros({...parametros});
                            }}
                            style={{ fontSize: 14 }}
                        />
                    </div>

                    <Form.Label 
                        className="mt-2"
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        Sexo?
                    </Form.Label>
                    <div className="campo-flags">
                        <ListaCheckbox 
                            itens={[
                                {id: 'M', label: 'Masculino'},
                                {id: 'F', label: 'Feminino'}
                            ]}
                            value={parametros.sexo}
                            label='sexo'
                            onChange={(ids) => {
                                parametros.sexo = ids;
                                alterarParametros({...parametros, pagina: 1});
                            }}
                            style={{ fontSize: 14 }}
                        />
                    </div>

                    <Form.Label 
                        className="mt-2"
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        Seguro
                    </Form.Label>  
                    <div className="campo-flags">
                        <Form.Check 
                            inline                            
                            checked={parametros.sem_emissao_ultimos_meses === 'N' ? false : true}
                            id={`parametro-cliente-sem_emissao-ultimos-meses`}
                            type='checkbox'                            
                            label={'Sem emissão de seguro nos últimos 6 meses'}
                            onChange={(e) => {                  
                                
                                // altera valor
                                parametros.sem_emissao_ultimos_meses = e.target.checked ? 'S' : 'N';

                                // altera parametros
                                alterarParametros({...parametros});

                            }}
                            style={{ fontSize: 12 }}
                        />
                    </div>

                    <Form.Label 
                        className="mt-2"
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        ASAAS
                    </Form.Label>   
                    <div className="campo-flags">
                        <Form.Check 
                            inline
                            checked={parametros.clientes_em_atraso === 'N' ? false : true}
                            id={`parametro-clientes_em_atraso`}
                            type='checkbox'
                            label={'Clientes em atraso'}                            
                            onChange={(e) => {
                                parametros.clientes_em_atraso = e.target.checked ? 'S' : 'N';;
                                alterarParametros({...parametros});
                            }}
                            style={{ fontSize: 14 }}
                        />
                    </div> 
                    <div className="campo-flags">
                        <Form.Check 
                            inline                            
                            checked={parametros.fatura_minima_anterior === 'N' ? false : true}
                            id={`parametro-cliente-fatura-minima`}
                            type='checkbox'
                            label={'Fatura mínima no mês anterior'}
                            onChange={(e) => {
                                parametros.fatura_minima_anterior = e.target.checked ? 'S' : 'N';
                                parametros.data_boleto_vencimento = '';
                                parametros.data_boleto_a_vencer = '';
                                alterarParametros({...parametros});
                            }}
                            style={{ fontSize: 14 }}
                            disabled={(
                                (typeof parametros.data_boleto_vencimento === "string" && parametros.data_boleto_vencimento.length > 0) ||
                                (typeof parametros.data_boleto_a_vencer === "string" && parametros.data_boleto_a_vencer.length > 0)
                            )}
                        />
                    </div>
                    <Form.Label 
                        className="mt-2"
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        Autentique
                    </Form.Label>  
                    <div className="campo-flags">
                        <Form.Check 
                            inline                            
                            checked={parametros.cliente_documento_autentique_assinado.includes('S') ? true : false}
                            id={`parametro-cliente-assinou-documento`}
                            type='checkbox'                            
                            label={'Assinou contrato'}
                            onChange={(e) => {

                                // altera valor
                                parametros.cliente_documento_autentique_assinado = e.target.checked ? 
                                    parametros.cliente_documento_autentique_assinado.concat("S") : 
                                    parametros.cliente_documento_autentique_assinado.filter(cd => cd !== "S")
                                ;
                                
                                // altera parametros
                                alterarParametros({...parametros});

                            }}
                            style={{ fontSize: 12 }}
                        />
                        <Form.Check 
                            inline                            
                            checked={parametros.cliente_documento_autentique_assinado.includes('N') ? true : false}
                            id={`parametro-cliente-assinou-documento`}
                            type='checkbox'                            
                            label={'Não assinou contrato'}
                            onChange={(e) => {             
                                
                                // altera valor
                                parametros.cliente_documento_autentique_assinado = e.target.checked ? 
                                    parametros.cliente_documento_autentique_assinado.concat("N") : 
                                    parametros.cliente_documento_autentique_assinado.filter(cd => cd !== "N")
                                ;

                                // altera parametros
                                alterarParametros({...parametros});

                            }}
                            style={{ fontSize: 12 }}
                        />
                    </div>
                                
                </Col>                
            </Row>
        }
        
    </div>

}
