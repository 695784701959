import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';


import { Button, Modal, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons';
import Registro from './Registro';
import PaginacaoComponente from './../../../../Painel/Componentes/Paginacao';
import Filtros from './Filtros';

// componente
export default function ModalParticipantes({
    idSeguroAventura=null,    // para filtrar por eventos
    fechou,
    evento
}){

    // estados
    const [mostrar, alterarMostrar] = useState(true);
    const [ participantes, alterarParticipantes ] = useState([]);
    const [carregando, alterarCarregando] = useState(false);
    const [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 8,
        texto: '',
        idSeguroAventura: idSeguroAventura,
        cancelado: ['S', 'N']
    });
    const [totalRegistros, atualizarTotalRegistros] = useState(0);

    // consulta os registros
    async function fazerConsulta(){
        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/seguroAventura/participantesEvento`, { params: parametros });
            alterarParticipantes(data.registros);
            atualizarTotalRegistros(parseInt(data.totalRegistros));


        }catch({response}){}
        finally{
            alterarCarregando(false);
        }

    }

    // inicializa
    useEffect(() => {
        fazerConsulta();
    }, [parametros]);

    // estados
    return <>

        <Modal 
            show={mostrar} 
            onExited={() => {
                fechou();
            }}
            onHide={() => {
                alterarMostrar(false);
            }}
            size={'lg'}
        >
            <Modal.Header className='border-0 text-center'>
                <div className={'w-100 d-flex align-items-center titulo'}>
                    <Modal.Title className={['flex-grow-1 m-0 text-center'].join(' ')}>
                        <FontAwesomeIcon icon={faUsers} className="mx-2"/>
                        {evento.nomeEvento}
                    </Modal.Title>
                    <span class="badge badge-success"><b>{evento.codigo}</b></span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Filtros 
                    parametros={parametros}
                    alterarParametros={alterarParametros}
                />

                { carregando && 
                    <p className="text-center mt-3">
                        <FontAwesomeIcon icon={faSpinner} pulse /> Aguarde!
                    </p>
                }

                {!carregando && 
                    <>
                        <table className="mt-3 table table-hover tabela">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th className="text-center">Nascimento</th>
                                    <th className="text-center">CPF</th>
                                    {
                                        evento.integrado === 'S' &&
                                        <>
                                            <th></th>
                                            <th></th>
                                        </>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {participantes.map((registro, a) => 
                                    <Registro 
                                        key={registro.dados.id} 
                                        dados={registro.dados}
                                        evento={evento}
                                        onClickTr={() => console.log('clicou TR')}
                                    />
                                )}
                            </tbody>
                        </table>
                        <PaginacaoComponente
                            pagina={parametros.pagina}
                            registrosPorPagina={parametros.registrosPorPagina}
                            totalRegistros={totalRegistros}
                            alterouPagina={(pagina) => {
                                alterarParametros({...parametros, pagina: pagina});
                            }}
                        />
                    </>
                }

            </Modal.Body>
        </Modal>

    </>

}