import React, { useState } from 'react';
import moment from 'moment';
import axios from 'axios';

// componentes
import {
    Card,
    Row,
    Col,
    Badge
} from 'react-bootstrap';
import {
    faKey,
    faTable,
    faHiking,
    faFilePdf,
    faMobileAlt,
    faSignOutAlt,
    faUsersSlash,
    faCalendarDay,
    faClipboardList,
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { 
    BotaoPdf,
    RegistroComponent
} from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import BotaoExcluir from './BotaoExcluir';
import BotaoEditar from './BotaoEditar';

// componente
export default function Registro({
    dados,
    fila,
    diarias,
    clicou,
    clicouErros
}) {

    const [gerandoPdf, alterarGerandoPdf] = useState(false);
    
    // gerar pdf
    async function baixarPdfParticipantes() {

        // adiciona carregamento
        alterarGerandoPdf(true);

        try {

            // gera pdf
            const { data } = await axios.get(`/seguroAventura/pdfComprovanteContratacao/${dados.token}`);

            // faz o download
            window.open(
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=anexosEmail&f=${encodeURIComponent(data.temporario)}&n=${encodeURIComponent(data.nome)}`,
                '_blank'
            );

        } catch({ response }) {
            console.error(response);
        } finally {
            
            // remove carregamento
            alterarGerandoPdf(false);

        }        


    }

    // função que verifica ícone do evento
    function verificaIconeEvento() {

        // verifica se é evento cancelado
        if (dados.cancelado === 'S') {

            // reotorna icone X
            return <FontAwesomeIcon icon={faTimesCircle} className="mr-2 text-danger" style={{ fontSize: '1.3em' }} />;

            // verifica se evento foi criado pelo aplicativo
        } else if (dados.mobile === 'S') {

            // retorn ícone de celular
            return <FontAwesomeIcon icon={faMobileAlt} className={"mr-2 text-dark align-self-start"} style={{ fontSize: '1.3em' }} />

            // verifica se é evento propriedade   
        } else if (dados.propriedade === 'S') {

            // retorna ícone de tabela roxa
            return <FontAwesomeIcon icon={faTable} className={"mr-2"} style={{ fontSize: '1.3em', color: 'purple' }} />

        } else {

            // retorna ícone padrão e muda cor quando for integrado
            return <FontAwesomeIcon icon={faClipboardList} className={dados.integrado === 'S' ? "mr-2 text-secondary" : "mr-2 text-info align-self-start"} style={{ fontSize: '1.3em' }} />

        }

    }

    // verifica o status do evento (aberto, andamento, finalizado, cancelado)
    function verificaStatusEvento() {

        // prepara o status de retorno do evento
        let status = {
            texto: '',
            cor: ''
        };

        // verifica se está cancelado
        const cancelado = dados.cancelado === "S";

        // verifica se está finalizado
        const finalizado = dados.finalizado === "S";

        // resgata data atual
        const dataAtual = new Date();

        // transforma datas e horas em um objeto date  
        const dataHoraInicio = new Date(dados.dataInicio + ' ' + dados.horaInicio);
        const dataHoraFinal = new Date(dados.dataFinal + ' ' + dados.horaFinal);

        // quando o evento estiver cancelado
        if(cancelado) {
            status = {
                texto: 'Cancelado',
                cor: 'danger'
            }
        // quando o evento estiver em aberto
        } else if(!finalizado && !cancelado) {
            status = {
                texto: 'Aberto',
                cor: 'warning'
            }
        // quando o evento estiver em andamento 
        } else if(
            finalizado &&
            !cancelado &&
            dataAtual > dataHoraInicio &&
            dataAtual < dataHoraFinal
        ) {
            status = {
                texto: 'Em andamento',
                cor: 'success'
            }
        // quando o evento estiver finalizado
        } else if(
            finalizado &&
            !cancelado &&
            dataAtual > dataHoraFinal
        ) {
            status = {
                texto: 'Finalizado',
                cor: 'primary'
            }
        }
        
        return status;

    }

    return <RegistroComponent>
        <Card
            className="border-0 shadow-sm position-relative card-evento"
            onClick={clicou}
        >
            <Card.Body className="p-2 d-flex flex-column">
                <div className="d-flex flex-grow-1">
                    <div className="flex-grow-1 d-flex flex-column">
                        <div className="flex-grow-1">
                            <div className="d-flex align-items-center">
                                {verificaIconeEvento()}
                                <div className="flex-grow-1 " style={{ display: 'flex' }}>
                                    <div style={{ fontSize: '0.9rem', alignItems: 'center', justifyContent: 'center' }}>
                                        {dados.integrado === "N" && <div style={{ fontSize: '1rem', display: 'flex' }}>
                                            <Badge
                                                variant={verificaStatusEvento().cor}
                                                size="sm"
                                                className="mr-2"
                                            >
                                                {verificaStatusEvento().texto}
                                            </Badge>                                            
                                        </div>}
                                    </div>
                                </div>

                                {
                                    dados.cancelado === "S" ?
                                        <div className="text-nowrap bg-light shadow-sm rounded px-1">
                                            <div className="text-center d-inline mr-2">
                                                <small className="text-center text-danger">
                                                    {/* será representado por novos (entrou dentro de 24 horass) */}
                                                    <FontAwesomeIcon icon={faTimesCircle} size="1x" className="mr-2" />
                                                    <span>{moment(dados.dataCancelado).format('DD/MM/YYYY')}</span>
                                                </small>
                                            </div>
                                        </div>
                                        : <>
                                            {dados.integrado === "S" &&

                                                <div className="text-nowrap">
                                                    {parseInt(fila.erros) > 0 &&
                                                        <div
                                                            className="bg-warning shadow-sm rounded text-center d-inline px-1 card-erros"
                                                            title="Participantes que não foram importandos por erros"
                                                            onClick={(e) => {
                                                                if (clicouErros) {
                                                                    e.stopPropagation();
                                                                    clicouErros()
                                                                }
                                                            }}
                                                        >
                                                            <small className="text-center">
                                                                {/* será representado por novos (entrou dentro de 24 horass) */}
                                                                <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="mr-2" />
                                                                <b>{fila.erros}</b>
                                                            </small>
                                                        </div>
                                                    }
                                                </div>
                                            }

                                            {dados.integrado === "N" &&
                                                <div className="text-nowrap bg-light shadow-sm rounded px-1">
                                                    <div className="text-center d-inline mr-2">
                                                        <small className="text-center text-info">
                                                            {/* será representado por novos (entrou dentro de 24 horass) */}
                                                            <FontAwesomeIcon icon={faCalendarCheck} size="1x" className="mr-2" />
                                                            <span>{moment(dados.dataInicio).format('DD/MM/YYYY')}</span>
                                                        </small>
                                                    </div>
                                                    <div className="text-center d-inline">
                                                        <small className="text-center text-info">
                                                            {/* será representado por novos (entrou dentro de 24 horass) */}
                                                            <FontAwesomeIcon icon={faSignOutAlt} size="1x" className="mr-2" />
                                                            <span>{moment(dados.dataFinal).format('DD/MM/YYYY')}</span>
                                                        </small>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                }


                                <div className="ml-2 opcoes" onClick={e => { e.stopPropagation() }}>
                                    {dados.integrado === "N" &&
                                        <div className="text-nowrap d-flex align-items-center">
                                            <BotaoExcluir
                                                id={dados.id}
                                                className="mr-1"
                                            />
                                            <BotaoEditar
                                                id={dados.id}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="responsavel-evento mt-1">
                                {dados.tipoPessoa === "F" ? dados.responsavel : dados.razaoSocial}
                            </div>
                            <p className="my-1 text-black-50 evento-descricao"><b>{dados.nomeEvento}</b></p>
                            {/* <p className="m-0 text-body">Vanessa Laura Franz</p> */}
                            {dados.descricao &&
                                <p className="my-1 text-black-50 evento-descricao">{dados.descricao.substring(0, 100)} {dados.descricao.length > 100 && '...'} </p>
                            }
                        </div>
                    </div>
                </div>
                <Row>
                    <Col md={3}>
                        <small className="d-flex align-items-center mt-2 text-center">
                            <FontAwesomeIcon icon={faKey} className="mr-2 text-secondary" />
                            <Badge variant="light" className="mr-2">
                                {dados.codigo}
                            </Badge>
                            <Badge variant={dados.cancelado === "S" ? 'danger' : 'primary'}>
                                {dados.cartaOferta}
                            </Badge>
                        </small>
                    </Col>
                </Row>
                <Row>
                    <Col className='col-7 text-left'>
                        <small style={{ fontSize: '0.7rem' }}>
                            Criação: <b>{moment(dados.dataCadastro).format('DD/MM/YYYY HH:mm:ss')}</b>
                        </small>
                    </Col>
                    <Col className="col-5 text-right">

                        {dados.cancelado === "N" &&
                            <div className="text-center d-inline px-1 mr-2" title="Baixar Comprovante de Contratação">
                                {
                                    !gerandoPdf ?
                                        <BotaoPdf
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                baixarPdfParticipantes()
                                            }}
                                        >
                                            <small className={"text-center"}>
                                                <FontAwesomeIcon icon={faFilePdf} size={"1x"} color={'#c60f0f'} />
                                            </small>
                                        </BotaoPdf>
                                        : <small className={"text-center"}>
                                            <FontAwesomeIcon
                                                className="icone"
                                                pulse
                                                icon={["fas", 'spinner']}
                                            />
                                        </small>
                                }
                            </div>
                        }

                        {parseInt(diarias.periodo) > 0 &&
                            <div className="bg-light shadow-sm rounded text-center d-inline px-1 mr-2" title="Total de diárias do evento">
                                <small className={dados.finalizado === "S" ? "text-center text-success" : "text-center text-warning"}>
                                    {/* será representado por novos (entrou dentro de 24 horass) */}
                                    <FontAwesomeIcon icon={faCalendarDay} size="1x" className="mr-2" />
                                    <b>{diarias.periodo}</b>
                                </small>
                            </div>
                        }

                        {parseInt(fila.cancelados) > 0 &&
                            <div className="bg-light shadow-sm rounded text-center d-inline  px-1 mr-2" title="Participantes que cancelaram">
                                <small className="text-center text-danger">
                                    {/* será representado por novos (entrou dentro de 24 horass) */}
                                    <FontAwesomeIcon icon={faUsersSlash} size="1x" className="mr-2" />
                                    <b>{fila.cancelados}</b>
                                </small>
                            </div>
                        }

                        {parseInt(diarias.participantes) > 0 &&
                            <div className="bg-light shadow-sm rounded text-center d-inline  px-1" title="Participantes que pertecem ao evento">
                                <small className={dados.finalizado === "S" ? "text-info" : "text-warning"}>
                                    {/* será representado por novos (entrou dentro de 24 horass) */}
                                    <FontAwesomeIcon icon={faHiking} size="1x" className="mr-2" />
                                    <b>{diarias.participantes}</b>
                                </small>
                            </div>
                        }

                    </Col>
                </Row>

            </Card.Body>
        </Card>
    </RegistroComponent>

}