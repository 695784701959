import React from 'react';
import moment from 'moment';
import styles from './index.module.scss';

// componentes
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BotaoTabela from './../../../Componentes/BotaoTabela';

function Registro({
    dados,
    modo,
    clicouEditar
}){


    return <tr className={(dados.horarios.length % 2 === 1) ? 'text-danger' : ''}>
        <td 
            className='estreito text-center font-weight-bold'
        >
            {moment(dados.data).format('DD')}
        </td>
        <td className={[styles.bordaEstiloB, 'estreito text-center text-nowrap'].join(' ')}>
            {moment(dados.data).format('dddd')}
        </td>
        <td className={[styles.bordaEstiloC].join(' ')}>
            {dados.horarios.map((horario, a) => 
                <React.Fragment key={horario.id}>  
                    { (a === 0 && dados.horarios.length % 2 === 1) &&
                        <FontAwesomeIcon title='Horario não fecha' className="icone mr-1 text-danger" icon={["fas", "bell-slash"]} />
                    }

                    { a === 0 &&
                        <FontAwesomeIcon title='Entrada' className="icone mr-1 text-black-50" icon={["fas", "handshake"]} />
                    }
                    <Badge
                        className='mr-1'
                        variant={a % 2 ? 'danger' : 'success'}
                    >
                        {moment(horario.data_batida).format('HH:mm')}   
                    </Badge>

                    { a > 0

                    }

                    { (a + 1) === dados.horarios.length ?
                        <FontAwesomeIcon title='Saída' className="icone ml-1 text-black-50" icon={["fas", "door-open"]} />
                        :
                        <>
                            { (a > 0 && parseInt(moment(horario.data_batida).format('HH')) > 11 && parseInt(moment(horario.data_batida).format('HH')) < 13) &&
                                <FontAwesomeIcon title='Saída' className="icone ml-1 mr-2 text-black-50" icon={["fas", "utensils"]} />
                            }
                        </>
                    }
                </React.Fragment>
            )}
        </td>
        <td className={[styles.bordaEstiloB, 'estreito text-center font-weight-bold'].join(' ')}>
            {dados.totalHoras}
        </td>
        { modo === 'edicao' &&
            <td className='text-nowrap estreito'>
                <BotaoTabela 
                    onClick={clicouEditar}
                    icone={["fas", "pencil-alt"]}
                >Editar</BotaoTabela>
            </td>
        }
    </tr>
}

export default Registro;