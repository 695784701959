import React, { useState, useEffect } from 'react';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';

export default function Filtros({
    parametros,
    alterarParametros
}){


    return <>
        <div>
            <DebounceInput 
                placeholder="Buscar participante" 
                debounceTimeout={400}
                element={Form.Control} 
                value={parametros.texto}
                onChange={(e) =>{
                    alterarParametros({...parametros, texto: e.target.value, pagina: 1})
                }}
            />
        </div>
    </>

}