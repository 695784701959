import React, {useState, useEffect} from 'react';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import BotaoTabela from './../../../../Componentes/BotaoTabela';
import { Badge } from 'react-bootstrap';
import BotaoExcluir from './BotaoExcluir';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

// elemento registro
function Registro({
    dados, 
    evento,
    clicouEditar,
    carregarLista
}){

    return <tr className="clicavel" onClick={clicouEditar}>
        <td 
            className={[
                'estreito text-center font-weight-bold text-warning'
            ].join(` `)}
        >
            <FontAwesomeIcon className='mr-1' icon={faExclamationTriangle} />
        </td>
        <td>
            {dados.nome} <br />
            <small><Badge vartiant="secondary">{evento.cartaOferta}</Badge> {evento.nomeEvento}</small>
        </td>
        <td className="text-center">
            {dados.dataNascimento && 
                <small>{moment(dados.dataNascimento).format('DD/MM/YYYY')}</small>
            }
        </td>
        <td className="text-center">
            <small>{dados.cpf}</small>
        </td>
        <td className="text-nowrap estreitotext-right">
            <BotaoExcluir 
                id={dados.id}
                funcaoCallback={() => {
                    carregarLista()
                }}
            />
        </td>
    </tr>
}

export default Registro;