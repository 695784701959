import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import { useHistory, useParams } from "react-router-dom";
import TextoSms from './TextoSms';
import { Card, Form, Row, Col, Button, Alert, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import useIsMounted from 'ismounted';
import Tags from './../../Componentes/Tags';

// componente
export default function Cadastrar(){

    // variaveis
    const isMounted = useIsMounted();
    const history = useHistory();
    let { id } = useParams();
    let [dados, alterarDados] = useState({
        id: id || null,
        descricao: '',
        mensagem: '',
        tipo: '1' // 1: cliente, 2: parcela da emissão
    });
    let [carregando, alterarCarregando] = useState(true);
    let [salvando, alterarSalvando] = useState(false);
    let [erro, alterarErro] = useState(null);

    // inicializa
    useEffect(() => {

        // verifica se é edição
        if(dados.id === null){

            // cadastro
            alterarCarregando(false);

        }else{

            // carrega os dados do modelo
            axios.get(`/modelosSms/${dados.id}`)
            .then(({data, ...retorno}) => {
                alterarDados(data);
                alterarCarregando(false);
            });

        }

    }, []);

    // salva registro
    function salvar(){

        // salvando
        alterarSalvando(true);

        // salva registro
        axios.post(`/modelosSms`, dados)
        .then(({data, ...retorno}) => {
            
            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Modelo cadastrado!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>);

            // vai para a consulta de sms's enviados (por enquanto volta para a consulta de clientes)
            history.push('/painel/modelos-sms');
        })
        .catch(e => {})
        .then(() => {
            if (isMounted.current) {
                alterarSalvando(false);
            }
        });

    }

    // se estiver carregando
    if(carregando){
        return <>Carregando ...</>
    }

    return <>
        <Row>
            <Col lg={8}>
                <Card className={'border-0 shadow-sm'}>
                    <Card.Header className={'bg-white border-0'}>
                        <Card.Title as='h4'>Cadastrar Modelo SMS</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Row>
                            <Col lg={8}>
                                <Form.Group>
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control 
                                        value={dados.descricao}
                                        onChange={e => {
                                            dados.descricao = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group>
                                    <Form.Label>Tipo</Form.Label>
                                    <Form.Control as='select'
                                        value={dados.tipo}
                                        onChange={e => {
                                            dados.tipo = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                    >
                                        {[
                                            {value: '1', children: 'Cliente'},
                                            {value: '2', children: 'Parcela da emissão'},
                                        ].map(tipo => 
                                         <option {...tipo} key={tipo.value} />
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Group>
                            <Form.Label>Mensagem</Form.Label>
                            <TextoSms 
                                value={dados.mensagem}
                                onChange={e => {
                                    dados.mensagem = e.target.value;
                                    alterarDados({...dados});
                                }}
                                maxLength={200}
                            />
                        </Form.Group>

                        <p className={'text-right'}>
                            <span>Caracteres </span> 
                            <Badge
                                variant={dados.mensagem.length > 160 ? 'danger' : 'info'}
                                title={'O limite é 160'}
                            >
                                {dados.mensagem.length}
                            </Badge>
                        </p>

                        <Alert
                            variant='secondary'
                            className={'border-0'}
                        >
                            Se incluir as tags, é recomendado escrever a mensagem até 150 caracteres, para evitar estouro de mensagem!
                        </Alert>
                    </Card.Body>
                </Card>
                
                <Form.Group className="d-flex justify-content-around my-4">
                    <Button variant="danger" onClick={() => {
                        history.goBack();
                    }}>
                        <span>Cancelar</span>
                    </Button>
                    <Button variant="success" disabled={salvando} onClick={salvar}>
                        {salvando ? 
                            <>
                                <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                <span>Salvando</span>
                            </> : 
                            <span>Salvar</span>
                        }
                    </Button>
                </Form.Group>
            </Col>
            <Col>
                <Card className={'border-0 shadow-sm'}>
                    <Card.Body>
                        <Tags 
                            tipo={dados.tipo}
                            planoCliente={true}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
}