import React from 'react';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import ListaCheckbox from './../../../Componentes/ListaCheckbox';

// componente
export default function Parametros({
    parametros, 
    alterarParametros
}){

    return <div className="parametros">
        <div className="parametros-inicial">
            <Link to='/painel/modelos-whatsapp/cadastrar'>
                <Button variant="padrao" className="botao-cadastrar">
                    <FontAwesomeIcon className="icone" icon={["fas", "plus"]} />
                    <span>Cadastrar Modelo de WHATSAPP</span>
                </Button>
            </Link>
            <DebounceInput 
                placeholder="" 
                debounceTimeout={400}
                element={Form.Control} 
                value={parametros.texto}
                onChange={(e) =>{
                    alterarParametros({...parametros, texto: e.target.value, pagina: 1})
                }}
            />
        </div>
    </div>
}