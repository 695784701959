import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import { Button, Form, Row, Col, Container, Card, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Formulario from './Formulario';

export default function Pagamento(){

    // dados
    const [dados, alterarDados] = useState({
        id: null,
        nome: '',
        numero: '',
        vencimento: '',
        codigo: '',
        valor: '0.00',
        bandeira: null,
        vencimento_fatura: ''
    });
    const [salvando, alterarSalvando] = useState(false);
    const [erros, alterarErros] = useState({});

    // salva
    async function salvar(){
        alterarErros({});
        alterarSalvando(true);

        // faz a requisição
        try{

            let { data } = await axios.post(`/solicitacaoPagamento`, dados);
            
            // mostra mensagem de solicitação enviada
            window.alert('Seu pagamento está sendo processado');

            // limpa formulário
            alterarDados({
                id: null,
                nome: '',
                numero: '',
                vencimento: '',
                codigo: '',
                valor: '0.00',
                bandeira: null
            });

        }catch({ response }){
                
            if(response){
                
                if(response.status === 400){
                    alterarErros(response.data);
                }
            }
        }finally{
            alterarSalvando(false);
        }


    }

    return <Container 
        className={'mt-3'}
    >
        <Row>
            <Col lg={{span: 8, offset: 2}}>
                <Card
                    className={'border-0 shadown-sm'}
                >
                    <Card.Body>

                        <Formulario 
                            dados={dados}
                            alterarDados={alterarDados}
                            erros={erros}
                        />

                        { Object.keys(erros).length > 0 && 
                            <Alert
                                className='border-0'
                                variant='danger'
                            >
                                Alguns campos não foram preenchidos corretamente!
                            </Alert>
                        }

                        <Row>
                            <Col
                                style={{
                                    alignSelf: "flex-end"
                                }}
                            >
                                <div
                                    className='text-center'
                                >
                                    <a target="_blank" href="https://rocacorretora.com.br/Pol%c3%adtica-de-Privacidade.html">
                                        Ambiente Seguro - Ver Política de Privacidade
                                    </a>
                                </div>
                            </Col>
                            <Col>
                                <div
                                    className='text-right mt-3'
                                >
                                    <Button
                                        variant='success'
                                        onClick={salvar}
                                    >
                                        {salvando ? 
                                            <>
                                                <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                                <span>Aguarde</span>
                                            </> : 
                                            <span>Pagar</span>
                                        }
                                        
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
}