import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

// componentes
import Abas from './Abas';
import Geral from './Geral';
import FunilVendas from './FunilVendas';
import RelatorioParcelas from './RelatorioParcelas';
import Vigente from './Vigente';

// relatorios
function Relatorios({

}){

    // estados
    let [aba, alterarAba] = useState('funil'); // geral

    return <>
        <Abas 
           value={aba}
           onChange={novaAba => {
               alterarAba(novaAba);
           }} 
        />

        { aba === 'geral' &&
            <Geral />
        }

        { aba === 'funil' && 
            <FunilVendas />
        }
        
        { aba === 'parcelas' && 
            <RelatorioParcelas />
        }

        { aba === 'vigente' &&
            <Vigente />
        }

    </>

}

export default Relatorios;