import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

// componentes
import { Row, Col, Card, Badge } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Formulario from './../../../Atendimento/Pagamento/Formulario';

export default function Cadastrar(){
    
    // estados
    const history = useHistory();
    let { id } = useParams();
    let [modo, alterarModo] = useState('edicao'); // cadastro, edicao
    let [carregando, alterarCarregando] = useState(true);
    let [salvando, alterarSalvando] = useState(false);
    const [dados, alterarDados] = useState({
        id: id || null,
        nome: '',
        numero: '',
        vencimento: '',
        codigo: '',
        valor: '0.00',
        bandeira: null,
        data_cadastro: null
    });

    // carrega os dados
    useEffect(() => {
        carregar();
    }, []);

    // carregar os dados
    async function carregar(){
        
        // faz a requisição
        try{

            let { data } = await axios.get(`/solicitacaoPagamento/${id}`)
            alterarDados(data.dados);
            alterarCarregando(false);

        }catch({ response }){

        }finally{

        }

    }

    return <>
        <Row>
            <Col lg={8}>
                <Card
                    className='border-0 shadow-sm'
                >
                    <Card.Body>
                        { dados.data_cadastro &&
                        <p>
                            Solicitado <Badge variant='info'>{moment(dados.data_cadastro).calendar()}</Badge>
                        </p>
                        }
                        <Formulario 
                            dados={dados}
                            alterarDados={alterarDados}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
}