import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';

import { Container, Modal, Card, Form, Col, Button, Alert, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaskedInput from 'react-maskedinput';
import { intersectObject } from './../../../../../Funcoes';
import InputData from './../../../../Componentes/InputData';
import { ConsultarContexto } from './../../ConsultarContexto';
import { toast } from 'react-toastify';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {ModalParticipanteComponent} from './components';

// componente
export default function ModalParticipante({
    id,
    fechou,
    salvou
}){
    
    const [dadosEvento, alterarDadosEvento] = useState(null);
    const dadosPadrao = {
        id: null,
        idSeguroAventura: null,
        tokenParticipante: null,
        nome: null,
        dataNascimento: null,
        cpf: null,
        genero: 'M',
        email: null,
        telefone: null,
        dataEntrada: null,
        dataSaida: null
    }
    const [dados, alterarDados] = useState(Object.assign({}, dadosPadrao));
    const [erros, alterarErros] = useState([]);
    const [mostrar, alterarMostrar] = useState(true);
    const [carregando, alterarCarregando] = useState(false);
    const [salvando, alterarSalvando] = useState(false);

    // referencias
    const telefoneInput = React.createRef();
    let cpfInput = React.createRef();

    useEffect(() => {
        consultarId(id);
    }, [])
    
    async function consultarId(id){
        alterarCarregando(true);
        try{

            // faz a requisição
            const { data } = await axios.get(`/seguroAventuraParticipante/${id}`);                               
            alterarDados(data.dados);
            alterarDadosEvento(data.evento)

        }catch({response}){}
        finally{
            // remove o carregamento
            alterarCarregando(false);
        }

    }

    async function salvar(){
        alterarErros({});
        alterarSalvando(true);

        try{

            // faz a requisição
            const { data } = await axios.post(`/seguroAventuraParticipante${id ? `/${id}` : ''}`, {
                dados: intersectObject(dadosPadrao, dados),
            });
            alterarDados(data.dados);

            // mensagem de sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Participante salvo com sucesso!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>);

        }catch({response}){
            if(response && response.status && response.status === 400){
                alterarErros(response.data);
                toast(<>
                    <div
                        style={{background: '#ff6271'}}
                    >
                        <div className="toast-header">
                            <strong className="mr-auto">Houve alguns erros :(</strong>
                            <button 
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                            </button>
                        </div>
                        <div className="toast-body text-light">
                            Revise os dados do participante!
                        </div>
                    </div>
                </>);

            }
        }finally{
            alterarSalvando(false);
        }

    }
    
    return <>
        <ModalParticipanteComponent 
            show={mostrar} 
            onExited={() => {
                fechou();
            }}
            onHide={() => {
                alterarMostrar(false);
            }}
            size={'md'}
        >
            <Modal.Header className='border-0 text-center'>
                <Modal.Title className="w-100">
                    Participante {dados.numeroInscricao && `(nº ${dados.numeroInscricao})`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'position-relative'}>
                <Form.Row>
                    <Col lg={8}>
                        <Form.Group>
                            <Form.Label>Nome</Form.Label>
                            <Form.Control 
                                value={dados.nome || ''}
                                maxLength='100'
                                onChange={e => {
                                    alterarDados({...dados, nome: e.target.value})
                                }}
                                size="sm"
                                isInvalid={erros.nome}
                            />
                            {erros.nome &&
                                <Form.Control.Feedback type="invalid">
                                    {erros.nome}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group>
                            <Form.Label>Nascimento</Form.Label>
                            <InputData 
                                data={dados.dataNascimento}
                                onChange={(data) => {
                                    alterarDados({...dados, dataNascimento: data});
                                }}
                                size="sm"
                                isInvalid={erros.dataNascimento}
                            />
                            {erros.dataNascimento &&
                                <Form.Control.Feedback type="invalid">
                                    {erros.dataNascimento}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col lg={12}>
                        <Form.Group>
                            <Form.Label>CPF</Form.Label>
                            <MaskedInput 
                                className={[
                                    "form-control form-control-sm", 
                                    (erros.cpf) && 'is-invalid'
                                ].join(' ')}
                                mask="111.111.111-11"
                                value={dados.cpf}
                                ref={cpfInput}
                                onChange={(e) => {
                                    dados.cpf = cpfInput.current.mask.getRawValue().split('_').join('');
                                    alterarDados({...dados});
                                }}
                            />
                            { (erros.cpf) &&
                                <Form.Control.Feedback type="invalid">{erros.cpf}</Form.Control.Feedback>
                            }
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col lg={8}>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control 
                                value={dados.email || ''}
                                maxLength='100'
                                isInvalid={erros.email}
                                onChange={e => {
                                    alterarDados({...dados, email: e.target.value});
                                }}
                                size="sm"
                            />
                            {erros.email &&
                                <Form.Control.Feedback type="invalid">
                                    {erros.email}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group>
                            <Form.Label>Telefone</Form.Label>
                            <MaskedInput 
                                className={["form-control form-control-sm", erros.telefone ? 'is-invalid' : ''].join(` `)}
                                mask="(11) 11111-1111"
                                value={dados.telefone || ''}
                                ref={telefoneInput}
                                onChange={(e) => {
                                    dados.telefone = telefoneInput.current.mask.getRawValue().split('_').join('')
                                    alterarDados({...dados});
                                }}
                            />
                            {erros.telefone &&
                                <Form.Control.Feedback type="invalid">
                                    {erros.telefone}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                    </Col>
                </Form.Row>
                {(dadosEvento && dadosEvento.integrado === 'S') && <Form.Row className='justify-content-between'>
                     <Col lg={4}>
                         <Form.Group>
                             <Form.Label>Data da Entrada</Form.Label>
                             <InputData
                                data={dados.dataEntrada || ''}
                                onChange={(data) => {
                                    alterarDados({...dados, dataEntrada: data})
                                }}
                                size="sm"
                                style={{ width: 150 }}
                                isInvalid={erros.dataEntrada}
                             />
                             {erros.dataEntrada &&
                                 <Form.Control.Feedback type="invalid">
                                     {erros.dataEntrada}
                                 </Form.Control.Feedback>
                             }
                         </Form.Group>
                     </Col>
                     <Col lg={4}>
                         <Form.Group className='text-right'>
                             <Form.Label>Data da Saída</Form.Label>
                             <InputData 
                                data={dados.dataSaida || ''}
                                onChange={(data) => {
                                    alterarDados({...dados, dataSaida: data});
                                }}
                                size="sm"
                                style={{ width: 150, textAlign: 'right' }}
                                isInvalid={erros.dataSaida}                                
                             />
                             {erros.dataSaida &&
                                 <Form.Control.Feedback type="invalid">
                                     {erros.dataSaida}
                                 </Form.Control.Feedback>
                             }
                         </Form.Group>
                     </Col>
                 </Form.Row>
                }
                                
                <SalvarFormulario 
                    salvando={salvando}
                    cancelar={() => alterarMostrar(false)}
                    onClick={salvar}
                />

                {carregando && 
                    <div className={['carregamento']}>
                        <FontAwesomeIcon icon={faSpinner} pulse className="icone"/> Aguarde!
                    </div>
                }
            </Modal.Body>
        </ModalParticipanteComponent>
    </>

}

function SalvarFormulario({
    salvando,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <span>Salvar</span>
            }
        </Button>
    </Form.Group>
}
