import React from 'react';
import styles from './index.module.scss';

// componentes
import { Form, Row, Col, Badge, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// destinarario
function Destinatario({
    value,
    onChange,
    excluir,
    disabilitarNome=false
    // tipo=null // null, '', 'CC', 'CCO'
}){


    return <Form.Row className='mb-3'>
        <Col md='5'>
            <Form.Label>
                { (value.tipo || value.tipo === '') &&
                    <Button 
                        size='sm'
                        onClick={excluir}
                        variant='padrao'
                        className='mr-2 py-0 px-1'
                    >
                        <FontAwesomeIcon className="icone" icon={["fas", 'trash']} />
                    </Button>
                }
                { value.tipo &&
                    <>
                        { value.tipo === 'CC' &&
                            <Badge variant='warning' className='mr-3'>CC</Badge>
                        }
                        { value.tipo === 'CCO' &&
                            <Badge variant='warning' className='mr-3'>CCO</Badge>
                        }
                    </>
                }
                <span>Nome</span>
            </Form.Label>
            <Form.Control 
                value={value.nome || ''}
                onChange={e => {
                    value.nome = e.target.value;
                    onChange(value);
                }}
                disabled={disabilitarNome}
            />
        </Col>
        <Col md='7'>
            <Form.Label>Email</Form.Label>
            <Form.Control 
                value={value.email || ''}
                onChange={e => {
                    value.email = e.target.value;
                    onChange(value);
                }}
            />
        </Col>
    </Form.Row>
}

export default Destinatario;