import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import moment from 'moment';
import axios from 'axios';
import useIsMounted from 'ismounted';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Badge, Button } from 'react-bootstrap';
import RegistroProposta from './RegistroProposta';
import RegistroEmissao from './RegistroEmissao';
import RegistroCotacao from './RegistroCotacao';
import FiltroDataCabecalho from './FiltroDataCabecalho';
import Funil from './Funil';
import GraficoBarrasFunil from './GraficoBarrasFunil';
import ComboResponsavel from './../../Componentes/ComboResponsavel';
import GerarRelatorioXls from './GerarRelatorioXls';
import GraficoSeguradorasFunil from './GraficoSeguradorasFunil';

// componente
export default function FunilVendas(){

    // estados
    const isMounted = useIsMounted();
    let [carregando, alterarCarregando] = useState(true);
    let [consulta, alterarConsulta] = useState(null);
    let [funil, alterarFunil] = useState(null);
    let [parametros, alterarParametros] = useState({
        data: moment().format('YYYY-MM-DD'),
        id_responsavel: null
    });
    let [consultaRegistros, alterarConsultaRegistros] = useState(null);
    let [etapaConsulta, alterarEtapaConsulta] = useState(null);
    let [carregandoConsulta, alterarCarregandoConsulta] = useState(false);
    let [mostrarSugestao, alterarMostrarSugestao] = useState(true);

    // inicializa
    useEffect(() => {

        // consulta inicial
        consultarFunil();

    }, [parametros]);

    // faz a consulta
    async function consultarFunil(){

        // faz a consulta
        alterarCarregandoConsulta(true);

        // inicia
        try{

            // faz a requisição
            let { data } = await axios.get(`/relatorios/funil`, {
                params: parametros
            });

            // se ainda estiver renderizado
            if (isMounted.current){
                alterarFunil(data.registros[0]);
                alterarConsulta(data);
                alterarCarregando(false);
                alterarCarregandoConsulta(false);
            }

        }catch({response}){

        }finally{

        }

    }

    // faz uma consulta sobre os registros relacionados ao filtro
    function registrosFiltro(etapa, todas = false){
        
        alterarEtapaConsulta(etapa);
        alterarMostrarSugestao(true);

        // se não for todas, remove registros atual
        if(!todas){
            alterarConsultaRegistros(null);
        }

        // verifica a etapa para buscar as fichas
        switch(etapa){
            case 'cotacao': consultarCotacoesMes(); break;
            case 'proposta': consultarPropostasMes(todas); break;
            case 'emitido': consultarEmissaoMes(); break;
            case 'renovacao': consultarRenovacoes(); break;
            case 'emitidoCancelado': consultarEmissoesCanceladas(); break;
        }
        
    }

    // busca as fichas
    async function consultarPropostasMes(todas){
        alterarCarregandoConsulta(true);

        // faz a consulta
        try{

            // requisição
            let { data } = await axios.get(`/relatorios/funil/propostas`, {
                params: {
                    ano: moment(parametros.data).format('YYYY'),
                    mes: moment(parametros.data).format('MM'),
                    id_responsavel: parametros.id_responsavel,
                    data: parametros.data,
                    todas: todas ? 'S' : 'N' // traz todas, dos meses anteriores
                }
            });

            // finaliza consulta
            alterarConsultaRegistros(data.propostas);
            alterarCarregandoConsulta(false);
            
            // se já está mostrando todas, esconde sugestão
            if(todas){
                alterarMostrarSugestao(false);
            }
            
        }catch({response}){

        }finally{

        }

    }

    // consulta as emissões do mes
    async function consultarEmissaoMes(){
        alterarCarregandoConsulta(true);

        // faz a consulta
        try{

            // requisição
            let { data } = await axios.get(`/relatorios/funil/emissoes`, {
                params: {
                    ano: moment(parametros.data).format('YYYY'),
                    mes: moment(parametros.data).format('MM'),
                    id_responsavel: parametros.id_responsavel
                }
            });

            // pega os dados
            alterarConsultaRegistros(data.emissoes);
            alterarCarregandoConsulta(false);

        }catch({response}){

        }finally{

        }

    }

    // consulta as renovações
    async function consultarRenovacoes(){
        alterarCarregandoConsulta(true);

        // faz a consulta
        try{

            // requisição
            let { data } = await axios.get(`/relatorios/funil/renovacoes`, {
                params: {
                    id_responsavel: parametros.id_responsavel
                }
            });

            // finaliza
            alterarConsultaRegistros(data.renovacoes);
            alterarCarregandoConsulta(false);

        }catch({response}){

        }finally{

        }

    }

    // consulta as cotações do mês
    async function consultarCotacoesMes(){
        alterarCarregandoConsulta(true);

        // faz a consulta
        try{

            // requisição
            let { data } = await axios.get(`/relatorios/funil/cotacoes`, {
                params: {
                    ano: moment(parametros.data).format('YYYY'),
                    mes: moment(parametros.data).format('MM'),
                    id_responsavel: parametros.id_responsavel
                }
            });
            
            // dados
            alterarConsultaRegistros(data.cotacoes);
            alterarCarregandoConsulta(false);

        }catch({response}){

        }

    }
    
    // consulta as emissões que foram canceladas
    async function consultarEmissoesCanceladas(){
        alterarCarregandoConsulta(true);

        // faz a consulta
        try{

            // requisição
            let { data } = await axios.get(`/relatorios/funil/emissoesCanceladas`, {
                params: {
                    ano: moment(parametros.data).format('YYYY'),
                    mes: moment(parametros.data).format('MM'),
                    id_responsavel: parametros.id_responsavel
                }
            });

            // pega os dados
            alterarConsultaRegistros(data.emissoesCanceladas);
            alterarCarregandoConsulta(false);

        }catch({response}){

        }finally{

        }

    }

    // limpa registros

    if(carregando){
        return <div>Carregando ...</div>
    }

    return <>

        <Row>
            <Col>
                <FiltroDataCabecalho 
                    value={parametros.data}
                    onChange={novaData => {
                        parametros.data = novaData;
                        alterarParametros({...parametros});
                    }}
                    carregando={carregandoConsulta}
                />
            </Col>
            <Col lg={4}>
                <Card
                    className={'border-0 shadow-sm'}
                >
                    <Card.Body
                        className={'p-1'}
                    >
                        <div
                            className='d-flex'
                        >
                            <div
                                className='flex-grow-1 mr-2'
                            >
                                <ComboResponsavel 
                                    id={parametros.id_responsavel}
                                    alterou={idNovo => {
                                        parametros.id_responsavel = idNovo;
                                        alterarParametros({...parametros});
                                    }}
                                />
                            </div>

                            <GerarRelatorioXls 
                                parametros={parametros}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        <Row>
            <Col lg='7'>
                            
                <Card className='border-0 shadow-sm mb-4'>
                    <Card.Body>
                        <small>Últimos 6 meses</small>
                        <GraficoBarrasFunil 
                            dados={consulta.registros}
                        />
                    </Card.Body>
                </Card>

                <Card className='border-0 shadow-sm'>
                    <Card.Body>
                        {(consultaRegistros === null && carregandoConsulta) && 
                            <p className='text-center'>
                                <FontAwesomeIcon className='icone mr-2' pulse icon={['fas', 'spinner']} />
                                <span>Carregando os registros</span>
                            </p>
                        }

                        { (consultaRegistros === null && !carregandoConsulta) &&
                            <p className='text-center'>
                                Escolha uma das opções do funil para carregar
                            </p>
                        }

                        { (consultaRegistros !== null && consultaRegistros.length === 0) &&
                            <p className='text-center'>
                                Sem resultados!
                            </p>
                        }

                        { (consultaRegistros && etapaConsulta === 'cotacao') &&
                            <>
                                <h5>Cotações - {consultaRegistros.length}</h5>
                                <hr />
                                <div className={styles.registros}>
                                    { consultaRegistros.map(registro => 
                                        <RegistroCotacao 
                                            key={registro.dados.id}
                                            dados={registro.dados}
                                            cliente={registro.cliente}
                                            className={styles.registro}
                                        />
                                    )}
                                </div>
                            </>
                        }

                        {(consultaRegistros && etapaConsulta === 'proposta') &&
                            <>  
                                <h5>Propostas - {consultaRegistros.length}</h5>
                                <hr />
                                <div className={styles.registros}>
                                    {consultaRegistros.map(registro => 
                                        <RegistroProposta 
                                            key={registro.dados.id}
                                            dados={registro.dados}
                                            cliente={registro.cliente}
                                            proposta={registro.proposta}
                                            parcelasProposta={registro.parcelasProposta}
                                            className={styles.registro}
                                        />
                                    )}
                                </div>

                                { (mostrarSugestao && parseInt(funil.totalPropostas) >= 1) &&
                                    <button 
                                        className={[styles.linkCarregarMais, 'text-right mt-3 w-100'].join(' ')}
                                        disabled={carregandoConsulta}
                                        onClick={() => {
                                            registrosFiltro('proposta', true);
                                        }}
                                    >
                                        { carregandoConsulta &&
                                            <FontAwesomeIcon className='icone mr-2' pulse icon={['fas', 'spinner']} />
                                        }
                                        { parseInt(funil.totalPropostas) > 1 ?
                                            <small>Carregar todas as {funil.totalPropostas} propostas dos meses anteriores?</small>
                                            :
                                            <small>Carregar a proposta dos meses anteriores?</small>
                                        }
                                        
                                    </button>
                                }
                            </>
                        }

                        {(consultaRegistros && (etapaConsulta === 'emitido' || etapaConsulta === 'renovacao' || etapaConsulta === 'emitidoCancelado')) &&
                            <> 
                                <h5>
                                    { etapaConsulta === 'emitido' && 'Emissões'}
                                    { etapaConsulta === 'renovacao' && 'Renovações'}
                                    { etapaConsulta === 'emitidoCancelado' && 'Emissões canceladas'}
                                    <span> - {consultaRegistros.length}</span>
                                </h5>
                                <hr />
                                <div className={styles.registros}>
                                    {consultaRegistros.map(registro => 
                                        <RegistroEmissao 
                                            key={registro.dados.id}
                                            dados={registro.dados}
                                            cliente={registro.cliente}
                                            emissao={registro.emissao}
                                            parcelasEmissao={registro.parcelasEmissao}
                                            className={styles.registro}
                                            etapaConsulta={etapaConsulta}
                                        />
                                    )}
                                </div>
                            </>
                        }
                    </Card.Body>
                </Card>
            </Col>
            <Col lg='5'>
                <Card className='border-0 shadow-sm mb-4'>
                    <Card.Body>

                        <Funil 
                            cotacao={funil.totalCotacaoMes}
                            renovacao={`${funil.totalRenovacao}`}
                            proposta={`${funil.totalPropostas} (${funil.totalPropostasMes})`}
                            emitido={funil.totalEmissaoMes}
                            emitidoCancelado={funil.totalEmissaoCanceladaMes}
                            alterouEtapa={etapaValor => {
                                registrosFiltro(etapaValor);
                            }}
                            totalLiquido={funil.totalLiquido}
                        />

                    </Card.Body>
                </Card>

                <Card className='border-0 shadow-sm mb-4'>
                    <Card.Body>
                        <small>Emissões por seguradoras</small>
                        <GraficoSeguradorasFunil 
                            registros={consulta.seguradoras}
                        />
                    </Card.Body>
                </Card>
                

            </Col>
        </Row>
    </>

}