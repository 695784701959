import React, { useState } from 'react';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Container, Button, Form } from 'react-bootstrap';

// mostra modal para uma simples confirmação de sim ou não
export default function ModalExcluir({
    registro,
    excluido,
    fechou
}){

    //estados
    let [mostrar, alterarMostrar] = useState(true);
    let [salvando, alterarSalvando] = useState(false);

    // faz a exclusão
    function excluir(){

        // exclui
        alterarSalvando(true);

        // faz a requisição para excluir o registro
        axios.delete(`/solicitacaoPagamento/${registro.dados.id}`)
        .then(() => {
            excluido();
            alterarMostrar(false);
        })
        .catch(() => {})
        .then(() => {
            alterarSalvando(false);
        });

    }

    return <Modal 
        show={mostrar} 
        onExited={fechou}
        onHide={() => {}}
        size={'md'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title>
                Excluir
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                Deseja excluir essa solicitação de pagamento?
            </Container>
        </Modal.Body>
        <Modal.Footer  className='border-0'>
            <Button
                variant='padrao'
                onClick={() => {alterarMostrar(false)}}
                disabled={salvando}
            >
                <span>Cancelar</span>
            </Button>
            <Button
                onClick={excluir}
                variant='success'
                disabled={salvando}
            >
                {salvando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Excluindo</span>
                    </> : 
                    <span>Excluir</span>
                }
            </Button>
        </Modal.Footer>
    </Modal>

}