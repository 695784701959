import React, { useState } from 'react';
import styles from './index.module.scss';

// componentes
import { SketchPicker } from 'react-color';

// componente
export default function ColorPicker({
    value,
    onChange
}){

    // mostra o picker
    let [mostrar, alterarMostrar] = useState(false);
    let [cor, alterarCor] = useState('#ffaaffff');


    return <>
        <div 
            className={styles.container}
        >
            {/* <button
                onClick={() => {
                    alterarMostrar(true);
                }}
            >Cor</button> */}
            
            <div
                onClick={() => {
                    alterarMostrar(!mostrar);
                }}
                className={[styles.padrao].join(' ')}
            >
                <div className={[styles.tabua, 'rounded'].join(' ')}>
                    <div 
                        className={[
                            styles.paleta, 'rounded'
                        ].join(' ')}
                        style={{background: value || 'none'}}
                    ></div>
                </div>
            </div>

            {mostrar &&
                <SketchPicker 
                    className={styles.picker}
                    color={cor}
                    onChange={cor => {
                        alterarCor(cor.rgb);
                    }}
                    onChangeComplete={cor => {
                        onChange(`rgb(${cor.rgb.r},${cor.rgb.g},${cor.rgb.b},${cor.rgb.a})`);
                    }}
                />
            }
        </div>
    </>
}