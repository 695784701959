import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

// componentes
import { Card, Col, Row } from 'react-bootstrap';
import Carregador from './../../Componentes/Carregador';
import PaginacaoComponente from './../../Componentes/Paginacao';
import Parametros from './Parametros';
import Registro from './Registro';

// consulta
export default function Consultar(){
    
    // estados
    const history = useHistory();
    const [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10,
        ativo: ['S'],
        texto: '',
    });
    const [totalRegistros, atualizarTotalRegistros] = useState(0);
    const [carregando, mostrarCarregando] = useState(false);
    const [consulta, alterarConsulta] = useState([]);

    // consultar
    async function consultarRegistros(){
        mostrarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/eventosPadrao`, { params: parametros });
            alterarConsulta(data.registros);
            atualizarTotalRegistros(parseInt(data.totalRegistros));

        }catch({response}){}
        finally{
            mostrarCarregando(false);
        }

    }

    // inicializa
    useEffect(() => {
        consultarRegistros();
    }, [parametros]);

    // retorna
    return <>
        <Row>
            <Col>
                <Card className="painel-consulta border-0 shadow-sm" >
                    <div className="card-header ">
                        <h4 className="card-title font-weight-bolder">Eventos automáticos</h4>
                        {/* <p className="card-category">Here is a subtitle for this table</p> */}

                        <Parametros parametros={parametros} alterarParametros={alterarParametros} />
                    </div>
                    
                    <Card.Body>
                        
                        <table className="table table-hover tabela">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Descrição</th>
                                    <th>Periodo</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {consulta.map((registro, a) => 
                                    <Registro 
                                        key={a} 
                                        dados={registro}
                                        parametros={parametros}
                                        onClickTr={() => console.log('clicou TR')}
                                        clicouEditar={() => {
                                            history.push(`/painel/eventos-automaticos/alterar/${registro.id}`)
                                        }}
                                    />
                                )}
                            </tbody>
                        </table>
                        <PaginacaoComponente
                            pagina={parametros.pagina}
                            registrosPorPagina={parametros.registrosPorPagina}
                            totalRegistros={totalRegistros}
                            alterouPagina={(pagina) => {
                                alterarParametros({...parametros, pagina: pagina});
                            }}
                        />
                        {carregando && 
                            <Carregador className="rounded-bottom" />
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>


}
