import React from 'react';
import styles from './index.module.scss';

function OptionSelect({
    data, 
    ...props
}){
    
    let { innerProps, innerRef, isFocused, isDisabled } = props;
    
    // retorna componente
    return <div 
        tabIndex='-1' 
        ref={innerRef} 
        {...innerProps} 
        className={[
            styles.option, 
            isFocused && styles.focado,
            isDisabled && styles.desabilitar,
            data.__isNew__ && styles.novo
        ].join(' ')}
        >
        { (data.__isNew__) ?
            <>{data.value}</>
            :
            <>{data.label}</>
        }
        
    </div>
}


export default OptionSelect;