import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';


import { Button, Modal, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ConsultarContexto } from './../ConsultarContexto';
import Registro from './Registro';
import PaginacaoComponente from './../../../Componentes/Paginacao';
import Filtros from './Filtros';

// componente
export default function ModalDiarias({
    idSeguroAventura=null,    // para filtrar por eventos
    fechou
}){

    // estados
    const { parametros, alterarParametros } = useContext(ConsultarContexto);
    const [mostrar, alterarMostrar] = useState(true);
    const [ dados, alterarDados ] = useState([]);
    const [carregando, alterarCarregando] = useState(false);
    const [parametrosDiarias, alterarParametrosDiarias] = useState({
        pagina: 1,
        registrosPorPagina: 8,
        texto: parametros.participante,
        idSeguroAventura: idSeguroAventura,
        cancelado: ['S', 'N'],
        data: parametros.data,
        periodo: parametros.periodo
    });
    const [totalRegistros, atualizarTotalRegistros] = useState(0);
    const [excluirErro, alterarExcluirErro] = useState(null);

    // consulta os registros
    async function fazerConsulta(){
        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/seguroAventura/erros/diarias`, { params: parametrosDiarias });
            alterarDados(data.registros);
            atualizarTotalRegistros(parseInt(data.totalRegistros));


        }catch({response}){}
        finally{
            alterarCarregando(false);
        }

    }

    // inicializa
    useEffect(() => {
        fazerConsulta();
    }, [parametrosDiarias]);

    // estados
    return <>

        <Modal 
            hidden={excluirErro !== null}
            show={mostrar} 
            onExited={() => {
                fechou();
            }}
            onHide={() => {
                alterarMostrar(false);
            }}
            size={'lg'}
        >
            <Modal.Header className='border-0 text-center'>
                <Modal.Title className="w-100">
                    Erros
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Filtros 
                    parametros={parametrosDiarias}
                    alterarParametros={alterarParametrosDiarias}
                />

                { carregando && 
                    <p className="text-center mt-3">
                        <FontAwesomeIcon icon={faSpinner} pulse /> Aguarde!
                    </p>
                }

                {!carregando && 
                    <>
                        <table className="mt-3 table table-hover tabela">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th className="text-center">Nascimento</th>
                                    <th className="text-center">CPF</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {dados.map((registro, a) => 
                                    <Registro 
                                        key={registro.dados.id} 
                                        dados={registro.dados}
                                        evento={registro.evento}
                                        onClickTr={() => console.log('clicou TR')}
                                        clicouExcluir={() => {
                                            // history.push(`/painel/proposta-formularios/alterar/${registro.dados.id}`)
                                            alterarExcluirErro(registro.dados.id);
                                            
                                        }}
                                        carregarLista={() => {
                                            // recarrega lista
                                            alterarParametrosDiarias({...parametrosDiarias, pagina: 1})
                                        }}
                                    />
                                )}
                            </tbody>
                        </table>
                        <PaginacaoComponente
                            pagina={parametrosDiarias.pagina}
                            registrosPorPagina={parametrosDiarias.registrosPorPagina}
                            totalRegistros={totalRegistros}
                            alterouPagina={(pagina) => {
                                alterarParametrosDiarias({...parametrosDiarias, pagina: pagina});
                            }}
                        />
                    </>
                }

            </Modal.Body>
        </Modal>
    </>

}