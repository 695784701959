import React, { useState } from 'react';
import styles from './index.module.scss';
import TimeField from 'react-simple-timefield';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// subcomponente hora
function InputHora({
    value,
    onChange,
    className='',
    disabled=false,
    aceitarNull=true,
    size="md",
    isInvalid=false,
    ...rest
}){

    return <TimeField 
        style={{color: value === null ? '#dedede': 'black'}}
        disabled={disabled}
        value={value || ''}
        onChange={(valor) => {
            if(valor === '00:00'){
                valor = null
            }else{
                valor += ':00';
            }

            if(valor === null && aceitarNull === false){
                valor = '00:00:00';
            }

            // alterarDados({...dados, hora: value})
            onChange(valor);
        }}
        onFocus={(e) => {
            e.target.select();
        }}
        input={<Form.Control size={size} isInvalid={isInvalid} placeholder="" className={[className].join(' ')} />}
        colon=":"
        {...rest}
    />
}

// exporta
export default InputHora;