import { 
    useState, 
    useEffect, 
    useRef
} from "react";
import { 
    Map, 
    Marker, 
    GoogleApiWrapper 
} from 'google-maps-react';
import { 
    Card, 
    Row, 
    Form,
    Col 
} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from 'moment';

import iconePessoaFisica from '../../../ClientesMapa/icones/iconePessoaFisica.png';
import FiltroDataCabecalho from "../../../Componentes/FiltroDataCabecalho";

function ClientesEventosMapa({
    google
}) {

    const { state } = useLocation();
    
    const [consultando, alterarConsultando] = useState(false);
    const [clientes, alterarClientes] = useState([]);
    const [bounds, alterarBounds] = useState(undefined);
    const [parametrosConsulta, alterarParametrosConsulta] = useState(state?.parametros ?? null);
    
    // consulta clientes através dos eventos
    async function consultarClientesEventos(parametros) {

        // ativa carregamento
        alterarConsultando(true);

        try {

            // realiza a requisição 
            const { data } = await axios.get('/clientes/consultarClientesEventos', {
                params: {
                    ...parametros,
                    coordenadaObrigatorio: "S",
                    pagina: undefined,
                    registrosPorPagina: undefined
                }
            });

            // resgata registros de clientes
            alterarClientes(data.registros);                

        } catch(e) {
            console.error(e);
        } finally {

            // desativa carregamento
            alterarConsultando(false);
        }

    }

    // resgata as coordenadas dos clientes para centraliza-las no mapa
    function montarBounds() {

        // resgata coordenadas dos clientes
        const pontos = clientes.map(c => ({
            lat: parseFloat(c.latitude),
            lng: parseFloat(c.longitude)
        }));

        // prepara bounds do mapa
        const bounds = new google.maps.LatLngBounds();

        // percorre pontos
        for (let i = 0; i < pontos.length; i++) {

            // extende bounds com a coordenada
            bounds.extend(pontos[i]);
        }

        // altera estado do bounds
        alterarBounds(bounds);

    }

    useEffect(() => {
        if(parametrosConsulta) consultarClientesEventos(parametrosConsulta);
    }, [parametrosConsulta]);

    // caso não tiver parametros, retorna elemento vazio
    if(!parametrosConsulta) return <></>
    
    // se estiver carregando retorna icone de carregamento
    if(consultando) return (
        <div 
            className='w-100 d-flex align-items-center justify-content-center'
            style={{ height: '100vh' }}
        >
            <FontAwesomeIcon 
                pulse
                icon={faSpinner} 
                style={{
                    width: 100,
                    height: 100
                }}                         
            />
        </div>
    )
    
    // renderiza mapa
    return (<>
        <Map
            google={google}
            zoom={12}
            style={{
                width: '100%',
                height: '100%',
            }}
            initialCenter={{
                lat: -27.591684,
                lng: -48.551642
            }}
            bounds={bounds}
            onReady={() => {
                if(clientes.length > 0) montarBounds();
            }}
        >
            {clientes.length > 0 && clientes.map((cliente, i) => (
                <Marker
                    key={cliente.id}
                    title={cliente.nome}
                    name={cliente.nome}                
                    id={cliente.id}
                    position={{lat: cliente.latitude, lng: cliente.longitude}} 
                    icon={{
                        url: iconePessoaFisica,
                        scaledSize: new google.maps.Size(45, 45)
                    }}
                    zIndex={1}
                /> 
            ))}
        </Map>

        <Card 
            className="border-0"
            style={{
                position: 'absolute',
                top: '5rem',
                left: '1%',                
                padding: 10,
                margin: 'auto'
            }}
        >
            <Card.Body className={'p-1 overflow-hidden'}>
                <Row className="h-100 w-100 m-0 mb-2">
                    <div className="w-100 text-left">
                        <Form.Check 
                            custom
                            inline
                            type="radio"
                            label="Diario"
                            id="radio-periodo-seguro-aventura-diario"
                            name="radio-periodo-seguro-aventura"
                            checked={parametrosConsulta.periodo === "diario"}
                            onChange={e => {
                                alterarParametrosConsulta({
                                    ...parametrosConsulta, 
                                    periodo: "diario", 
                                    data: moment().format('YYYY-MM-DD')
                                });
                            }}
                        />
                        <Form.Check 
                            custom
                            inline
                            label="Mensal"
                            type="radio"
                            id="radio-periodo-seguro-aventura-mensal"
                            name="radio-periodo-seguro-aventura"
                            checked={parametrosConsulta.periodo === "mensal"}
                            onChange={e => {
                                alterarParametrosConsulta({
                                    ...parametrosConsulta, 
                                    periodo: "mensal", 
                                    data: moment().format('YYYY-MM-DD')
                                });
                            }}
                        />
                    </div>
                </Row>
                <Row style={{ minWidth: 400 }}>
                    <FiltroDataCabecalho 
                        value={parametrosConsulta.data}
                        onChange={novaData => {
                            alterarParametrosConsulta({
                                ...parametrosConsulta, 
                                data: novaData
                            });
                        }}
                        periodo={parametrosConsulta.periodo}
                        className={"w-100 shadow-none"}
                    />
                </Row>
            </Card.Body>
        </Card>
    </>)

}

export default GoogleApiWrapper(
    (props) => ({
        apiKey: (props?.location?.state?.chaveApi ?? null)        
    })
)(ClientesEventosMapa)