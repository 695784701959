import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import './axiosConfig';
import Moment from 'react-moment';
import 'moment/locale/pt-br';
import 'react-toastify/dist/ReactToastify.css';

// bibliotecas
import './scss/geral.scss';

// icones font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';


import RootReducer from './RootReducer';


library.add(fas, far, fab);

// configura padrões axios
axios.defaults.baseURL = `${process.env.REACT_APP_URL_API}/api`;
axios.defaults.headers.post['Content-Type'] = 'application/json';

// padrões do moment
Moment.globalLocale = 'pt-br';

// configuração para sincronizar as abas
const config = {
    // TOGGLE_TODO will not be triggered in other tabs
    blacklist: ['TOGGLE_TODO'],
}
const middlewares = [
    createStateSyncMiddleware(config),
];


// cria store
const store = createStore(RootReducer, {}, applyMiddleware(...middlewares));

// init state with other tabs
initStateWithPrevTab(store);


ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
