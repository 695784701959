import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import './PainelPagina.scss';
import { Route, withRouter, Redirect, useLocation } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import publicIp from 'react-public-ip';

// importa sidebar componente
import PainelSidebar from './PainelSidebar';
import PainelNavbar from './PainelNavbar';

// paginas
// import ClientesPaginas from './clientes/ClientesPagina';

// import ClienteCadastrarPagina from './clientes/ClienteCadastroPagina';
import ClientesConsultar from './Clientes/Consultar';
import ClientesCadastrar from './Clientes/Cadastrar';
import UsuariosConsultar from './Usuarios/Consultar';
import UsuariosPermissoes from './Usuarios/Permissoes';
import UsuariosTransferir from './Usuarios/Transferir';
import UsuariosIp from './Usuarios/Ip';
import ConfiguracaoSistema from './ConfiguracaoSistema';
import PainelInicio from './PainelInicio';
// import ClienteFichasCadastrar from './Clientes/Fichas/Cadastrar';
import ClienteEscreverEmail from './Clientes/EscreverEmail';
import CaixaSaidaConsultar from './CaixaSaida/Consultar';
import ModelosEmailConsultar from './ModelosEmail/Consultar';
import ModelosEmailCadastrar from './ModelosEmail/Cadastrar';
import EventosConsultar from './Eventos/Consultar';
import DispositivosConsultar from './Dispositivos/Consultar';
import DispositivosCadastrar from './Dispositivos/Cadastrar';
import GruposConsultar from './Grupos/Consultar';
import GruposCadastrar from './Grupos/Cadastrar';
import EventosPadraoConsultar from './EventosPadrao/Consultar';
import EventosPadraoCadastrar from './EventosPadrao/Cadastrar';
import PontoConsultar from './Ponto/Consultar';
import Relatorios from './Relatorios';
import SolicitacaoFormularioConsultar from './SolicitacaoFormulario/Consultar';
import SolicitacaoFormularioCadastrar from './SolicitacaoFormulario/Cadastrar';
import ClientesEmailsMassa from './Clientes/EmailsMassa';
import FormularioAcientesPropostaConsultar from './FormularioAcientesProposta/Consultar';
import FormularioAcientesPropostaCadastrar from './FormularioAcientesProposta/Cadastrar';
import FormularioAcientesPropostaConsultarAgrupado from './FormularioAcientesProposta/ConsultarAgrupado';
import ClientesMapa from './ClientesMapa';
import ClientesEnviarSms from './Clientes/EnviarSms';
import EnviarWhatsapp from './Clientes/EnviarWhatsapp';
import SmsConsultar from './Sms/Consultar';
import ClientesEnviarSmsMassa from './Clientes/EnviarSmsMassa';
import ClientesWhatsappMassa from './Clientes/EnviarWhatsappMassa';
import ModelosSmsCadastrar from './ModelosSms/Cadastrar';
import ModelosSmsConsultar from './ModelosSms/Consultar';
import ModeloWhatsappConsultar from './ModelosWhatsapp/Consultar';
import ModeloWhatsappCadastrar from './ModelosWhatsapp/Cadastrar';
import SolicitacaoPagamentoConsultar from './SolicitacaoPagamento/Consultar';
import SolicitacaoPagamentoCadastrar from './SolicitacaoPagamento/Cadastrar';
import HistoricoConsultar from './Historico/Consultar';
import WhatsappArquivoNumeros from './WhatsappArquivoNumeros';
import BackupsConsultar from './Backups/Consultar';
import FichasCadastrar from './Fichas/Cadastrar';
import SeguroAventuraConsultar from './SeguroAventura/Consultar';
import SeguroAventuraFechamento from './SeguroAventura/Fechamento';
import MensagensWhatsapp from './MensagensWhatsapp';
import ClientesEventosMapa from './SeguroAventura/Consultar/ClientesEventosMapa';

// componente
export default function PainelPagina(){

    // estados
    const location = useLocation();
    const login = useSelector(state => state.login);
    const dispatch = useDispatch();
    const verificar = useRef(true);
    const [menuEncolhido, encolherMenu] = useState(false);
    const [telaMapas, alterarTelaMapas] = useState(false);
    const [liberar, alterarLiberar] = useState(false);
    const [formulariosNaoLidos, alterarFormulariosNaoLidos] = useState(0);
    const [propostasNaoLidos, alterarPropostasNaoLidos] = useState(0);

    // inicializa
    useEffect(() => {
        preCarregar();
    }, []);

    // quando a rota muda
    useEffect(() => {
        if(location.pathname === '/painel/clientes-mapa' || location.pathname === '/painel/seguro-aventura/clientes-eventos-mapa'){
            encolherMenu(true);
            alterarTelaMapas(true);
        }else{
            alterarTelaMapas(false);
        }
    }, [location.pathname]);

    async function preCarregar(){

        // carrega ipv4
        const ipv4 = await publicIp.v4() || null;

        // define token
        axios.defaults.headers.common['tokenusuario'] = login.dadosUsuario.token;
        axios.defaults.headers.common['ipusuario'] = ipv4;

        // verifica quantidade de formulários não lidos
        verificarQuantidadeNaoLidos();
        
        // mantém a pessoa logada pelas próximas 15 horas
        if(login.horaLogin){
            let dataAtual = new Date();
            let horas = moment.duration(moment(dataAtual).diff(login.horaLogin)).hours();

            // se desde o último acesso passou 1 minuto, desloga
            if(horas >= 10){
                dispatch({
                    type: 'FAZER_LOGGOF',
                    reducer: 'login'
                });
                verificar.current = false;
            }else{

                // atualiza tempo
                dispatch({
                    type: 'ATUALIZAR_TEMPO_LOGADO',
                    reducer: 'login'
                });
            }
        }

        // se componente estiver montado
        if(verificar.current){


            // carrega as configurações iniciais
            await carregarConfiguracaoInicial();
        }


        // libera
        alterarLiberar(true);
    }

    async function carregarConfiguracaoInicial(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/usuariosPermissao/${login.dadosUsuario.id}`);
            dispatch({
                type: 'painelReducer/CONFIGURACOES_SISTEMA',
                configuracoesSistema: data.registros
            });

        }catch({response}){}

        try{

            // faz a requisição
            let { data } = await axios.get(`/configuracaoSistema`);
            dispatch({
                type: 'painelReducer/CONFIGURACOES_SISTEMA',
                configuracoesSistema: data.chaves
            });


        }catch({response}){}

        try{

            // faz a requisição
            let { data } = await axios.get(`/dispositivos/verificar`);
            dispatch({
                type: 'painelReducer/DISPOSITIVO',
                dispositivo: data.dispositivo,
                ponto: data.ponto
            });

        }catch({response}){}
    }

    // faz a requisição para ver a quantidade de formulários não lidos
    async function verificarQuantidadeNaoLidos(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/solicitacaoFormulario/quantidadeNaoLido`);
            alterarFormulariosNaoLidos(parseInt(data.quantidade));

        }catch({response}){}

        try{

            // faz a requisição
            let { data } = await axios.get(`/formularioAcidentesPessoais/quantidadeNaoLido`);
            alterarPropostasNaoLidos(parseInt(data.quantidade));

        }catch({response}){}

    }

    // rotas
    let rotas = [

        // base
        {path: '/painel', component: PainelInicio},

        // Relatorios
        {path: '/painel/relatorios', component: Relatorios},

        // dispositivos 
        {path: '/painel/dispositivos', component: DispositivosConsultar, permitido: (parseInt(login.permissao.dispositivos) > 1)},
        {path: '/painel/dispositivos/cadastrar', component: DispositivosCadastrar, permitido: (parseInt(login.permissao.dispositivos) > 2)},
        {path: '/painel/dispositivos/alterar/:id', component: DispositivosCadastrar, permitido: (parseInt(login.permissao.dispositivos) > 1)},
        
        // clientes
        {path: '/painel/clientes', component: ClientesConsultar, permitido: (parseInt(login.permissao.clientes) > 1)},
        {path: '/painel/clientes/cadastrar', component: ClientesCadastrar, permitido: (parseInt(login.permissao.clientes) > 2)},
        {path: '/painel/clientes/alterar/:id', component: ClientesCadastrar, permitido: (parseInt(login.permissao.clientes) > 1)},
        {path: '/painel/clientes/enviar-email/:idcliente', component: ClienteEscreverEmail, permitido: (login.permissao.clienteEnviarEmail === 'S')},
        {path: '/painel/clientes/abrir-email/:id', component: ClienteEscreverEmail, permitido: (login.permissao.clienteEnviarEmail === 'S')},
        {path: '/painel/clientes/enviar-email-aniversariante/:idcliente/:idade', render: (rotaProps) => <ClienteEscreverEmail {...rotaProps} aniversariante='S' /> },
        {path: '/painel/clientes/emails-massa', component: ClientesEmailsMassa},
        {path: '/painel/clientes/enviar-sms/:idcliente', component: ClientesEnviarSms},
        {path: '/painel/clientes/enviar-sms-aniversariante/:idcliente/:idade', render: (rotaProps) => <ClientesEnviarSms {...rotaProps} aniversariante='S' /> },
        {path: '/painel/clientes/enviar-sms-massa', component: ClientesEnviarSmsMassa},
        {path: '/painel/clientes/enviar-whatsapp-massa', component: ClientesWhatsappMassa},
        {path: '/painel/clientes/enviar-whatsapp/:idcliente', component: EnviarWhatsapp},
        
        // grupos 
        {path: '/painel/grupos', component: GruposConsultar},
        {path: '/painel/grupos/cadastrar', component: GruposCadastrar},
        {path: '/painel/grupos/alterar/:id', component: GruposCadastrar},

        // eventos
        {path: '/painel/eventos', component: EventosConsultar},

        // eventos automáticos ou padrão 
        {path: '/painel/eventos-automaticos', component: EventosPadraoConsultar},
        {path: '/painel/eventos-automaticos/cadastrar', component: EventosPadraoCadastrar},
        {path: '/painel/eventos-automaticos/alterar/:id', component: EventosPadraoCadastrar},

        // fichas
        {path: '/painel/clientes/alterar/:id/fichas', render: (rotaProps) => <ClientesCadastrar {...rotaProps} abaAbrir='fichas' /> },
        {path: '/painel/clientes/alterar/:id/apolices', render: (rotaProps) => <ClientesCadastrar {...rotaProps} abaAbrir='apolices' /> },
        /** a partir daqui irá ser extinto */
        // {path: '/painel/clientes/alterar/:id_cliente/fichas/cadastrar', component: ClienteFichasCadastrar },
        // {path: '/painel/clientes/alterar/:id_cliente/fichas/cadastrar/renovar/:idficharenovar', component: ClienteFichasCadastrar },
        // {path: '/painel/clientes/alterar/:id_cliente/fichas/alterar/:id', component: ClienteFichasCadastrar },
        // {path: '/painel/clientes/alterar/:id_cliente/fichas/alterar/:id/:aba_padrao', component: ClienteFichasCadastrar },
        /** até esse trecho */

        // usuários
        {path: '/painel/usuarios', component: UsuariosConsultar, permitido: (parseInt(login.permissao.usuarios) > 1)},
        {path: '/painel/usuarios/cadastrar', render: (rotaprops) => <ClientesCadastrar {...rotaprops} usuario={true} />, permitido: (parseInt(login.permissao.usuarios) > 2)},
        {path: '/painel/usuarios/alterar/:id', render: (rotaprops) => <ClientesCadastrar {...rotaprops} usuario={true} />, permitido: (parseInt(login.permissao.usuarios) > 1)},
        {path: '/painel/usuarios/permissoes/:id', component: UsuariosPermissoes, permitido: (login.permissao.usuariosPermissao === 'S')},
        {path: '/painel/usuarios/transferir/:id', component: UsuariosTransferir},
        {path: '/painel/usuarios/ip/:id', component: UsuariosIp},

        // ponto 
        {path: '/painel/ponto/:id', component: PontoConsultar, permitido: (login.permissao.habilitarPontoConsultaUsuario === 'S')}, // não ter acesso quando não tiver permissão
        {path: '/painel/meu-ponto', component: PontoConsultar},

        // caixa de saida
        {path: '/painel/caixa-saida', component: CaixaSaidaConsultar},

        // sms
        {path: '/painel/sms', component: SmsConsultar},

        // modelos de email
        {path: '/painel/modelos-email', component: ModelosEmailConsultar},
        {path: '/painel/modelos-email/cadastrar', component: ModelosEmailCadastrar},
        {path: '/painel/modelos-email/alterar/:id', component: ModelosEmailCadastrar},

        // modelos de sms
        {path: '/painel/modelos-sms', component: ModelosSmsConsultar},
        {path: '/painel/modelos-sms/cadastrar', component: ModelosSmsCadastrar},
        {path: '/painel/modelos-sms/alterar/:id', component: ModelosSmsCadastrar},

        // modelos de whatsapp
        {path: '/painel/modelos-whatsapp', component: ModeloWhatsappConsultar},
        {path: '/painel/modelos-whatsapp/cadastrar', component: ModeloWhatsappCadastrar},
        {path: '/painel/modelos-whatsapp/alterar/:id', component: ModeloWhatsappCadastrar},

        // solicitação de formulários
        {path: '/painel/solicitacao-formulario', component: SolicitacaoFormularioConsultar},
        {path: '/painel/solicitacao-formulario/cadastrar', component: SolicitacaoFormularioCadastrar},
        {path: '/painel/solicitacao-formulario/alterar/:id', component: SolicitacaoFormularioCadastrar},

        // formularios de acidentes pessoais
        {path: '/painel/proposta-formularios', component: FormularioAcientesPropostaConsultar},
        {path: '/painel/proposta-formularios/agrupado', component: FormularioAcientesPropostaConsultarAgrupado},
        {path: '/painel/proposta-formularios/alterar/:id', component: FormularioAcientesPropostaCadastrar},

        // configuração sistema
        {path: '/painel/configuracoes', component: ConfiguracaoSistema},

        // mapa dos clientes
        {path: '/painel/clientes-mapa', component: ClientesMapa},

        // solicitação de pagamentos
        {path: '/painel/solicitacao-pagamento', component: SolicitacaoPagamentoConsultar},
        {path: '/painel/solicitacao-pagamento/alterar/:id', component: SolicitacaoPagamentoCadastrar},

        // histórico, logde funções executadas
        {path: '/painel/historico', component: HistoricoConsultar},  
        
        // carrega lista de números do whatsapp
        {path: '/painel/arquivos-whatsapp', component: WhatsappArquivoNumeros},

        // carrega mensagens do whatsapp enviadas aos usuários
        {path: '/painel/mensagens-whatsapp', component: MensagensWhatsapp},

        // backups
        {path: '/painel/backups', component: BackupsConsultar}
    ];

    // inicializado corretamente
    if(!liberar){
        return <></>
    }

    return <div className={['painel-pagina', (menuEncolhido)? 'menu-encolher' : ''].join(' ')}>
        <PainelSidebar logo='Roca Seguros' className={(menuEncolhido)? 'encolher' : ''} encolherMenu={() => encolherMenu(!menuEncolhido)} links={[
            {icone: 'user-friends', descricao: 'Usuários', url: '/painel/usuarios', permitido: (parseInt(login.permissao.usuarios) > 1)},
            {icone: 'desktop', descricao: 'Dispositivos', url: '/painel/dispositivos', permitido: (parseInt(login.permissao.dispositivos) > 1)},
            {icone: 'users', descricao: 'Clientes', url: '/painel/clientes', permitido: (parseInt(login.permissao.clientes) > 1)},
            {icone: 'city', descricao: 'Grupos de Clientes', url: '/painel/grupos'},
            {icone: 'thumbtack', descricao: 'Eventos', url: '/painel/eventos'},
            {icone: 'file-alt', descricao: 'Solicitação Fiança', url: '/painel/solicitacao-formulario', formulariosNaoLidos: formulariosNaoLidos},
            // {icone: 'file-alt', descricao: 'Solicitação Eventos', url: '/painel/proposta-formularios/agrupado', formulariosNaoLidos: propostasNaoLidos},
            {icone: 'mountain', descricao: 'Seguro Aventura', url: '/painel/seguro-aventura', formulariosNaoLidos: 0},
            {icone: 'envelope', descricao: 'Caixa de Saída', url: '/painel/caixa-saida'},
            {icone: 'mobile-alt', descricao: 'Mensagens', url: '/painel/sms'},
            {icone: 'credit-card', descricao: 'Solicitação Pagamento', url: '/painel/solicitacao-pagamento'},
            {icone: 'whatsapp', fab: true, descricao: "Mensagens WhatsApp", url: '/painel/mensagens-whatsapp'},
            {icone: 'database', descricao: "Backups", url: '/painel/backups', permitido: login.permissao.habilitarConsultaBackups === "S"}
        ]} />
        <div className={["painel-pagina-corpo", telaMapas ? 'tela-mapas' : ''].join(' ')}>
            <PainelNavbar />
            <div className="content">
                <div className="container-fluid">

                    {rotas.filter(f => {

                        if(f.permitido === undefined){
                            return f;
                        }

                        if(f.permitido){
                            return f;
                        }
                    }).map((rota, a) => (
                        <Route key={a} exact {...rota} />
                    ))}

                    {/* rotas */}
                    <Route exact path={[`/painel/fichas/:id_cliente/cadastrar`, `/painel/fichas/:id_cliente/alterar/:id`, `/painel/fichas/:id_cliente/renovar/:id_ficha_renovar`]}>
                        <FichasCadastrar />
                    </Route>

                    <Route exact path={[`/painel/seguro-aventura`]}>
                        <SeguroAventuraConsultar />
                    </Route>

                    <Route exact path={['/painel/seguro-aventura/clientes-eventos-mapa']}>
                        {(props) => {
                            // caso nao tiver chave ou não estiver na rota
                            if(
                                !props?.location?.state?.chaveApi || 
                                location.pathname !== '/painel/seguro-aventura/clientes-eventos-mapa'
                            ) return <></>

                            // retorna mapa com clientes
                            return <ClientesEventosMapa 
                                {...props}
                            />
                        }}
                    </Route>

                    <Route exact path={[`/painel/seguro-aventura/fechamento/:dataMes`]}>
                        <SeguroAventuraFechamento />
                    </Route>                    

                </div>
            </div>
        </div>
    </div>;

}
