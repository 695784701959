import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import axios from 'axios';

// componentes
import OptionSelect from './OptionSelect';

// componente
export default function ComboCliente({
    id, // id do cliente
    alterou,
    filtrosExtras=null, // {}
    size='md'
}){

    // estados
    let [clientes, alterarClientes] = useState([]);
    let [carregando, alterarCarregando] = useState(true);
    let [dadosCliente, alterarDadosCliente] = useState(null);

    // se os filtros extras alterarem, refaz a busca
    useEffect(() => {
        if(JSON.stringify(filtrosExtras) !== JSON.stringify(filtrosExtras)){
            consultarClientes('');
        }
    }, [filtrosExtras]);

    // consulta id do cliente
    useEffect(() => {

        // verifica se possui cliente
        if(id !== null || (dadosCliente !== null && id !== dadosCliente.id)){
            consultarIdCliente();
        }else{
            alterarCarregando(false);
        }

    }, []);

    // ao modificar cliente externamente
    useEffect(() => {
        
        // se não tiver nenhum selecionado, faz a alteração
        if(dadosCliente === null && id !== null){
            consultarIdCliente();
        }

        // se já estiver selecionado mas for diferente da troca, faz a consulta
        if(dadosCliente !== null && id !== dadosCliente.id){
            consultarIdCliente();
        }

    }, [id]);

    // consulta cliente
    async function consultarIdCliente(){

        if(!id) return alterarDadosCliente(null);

        try {

            // faz a requisição
            const { data } = await axios.get(`/clientes/${id}`);
            alterarDadosCliente(data.dados);
            alterarCarregando(false);

        }catch({response}){}

    }

    // consulta os clientes
    async function consultarClientes(inputValue, callback = null){

        try{

            // faz a requisição
            let { data } = await axios.get(`/clientes`, {
                params: Object.assign({
                    texto: inputValue,
                    pagina: 1,
                    registrosPorPagina: 7
                }, filtrosExtras)
            });
            alterarClientes(data.registros);
            if(callback !== null){
                callback(data.registros);
            }

        }catch({response}){}

    }

    return <>
        <AsyncSelect 
            menuPlacement='auto'
            isDisabled={carregando}
            isClearable
            loadOptions={consultarClientes} // ao alterar, carrega
            defaultOptions={clientes}       // lista pré carregada
            value={dadosCliente && {
                label: dadosCliente.nome
            }}  
            onChange={(e) => {

                if(e === null){
                    alterarDadosCliente(null)
                    alterou(null);
                }else{
                    alterarDadosCliente({...e});
                    alterou(e.id);
                }
            }}
            components={{
                Option: OptionSelect
            }}
            noOptionsMessage={() => '(Opcional Grupo:) Cliente'}
            loadingMessage={() => 'Carregando...'}
            placeholder='Cliente'
            {...( size === 'sm') ?
                {
                    styles: {
                        control: base => ({
                            ...base,
                            minHeight: 30
                        }),
                        dropdownIndicator: base => ({
                            ...base,
                            padding: 4
                        }),
                        clearIndicator: base => ({
                            ...base,
                            padding: 4
                        }),
                        multiValue: base => ({
                            ...base,
                        }),
                        valueContainer: base => ({
                            ...base,
                            padding: '0px 6px'
                        }),
                        input: base => ({
                            ...base,
                            margin: 0,
                            padding: 0
                        })
                    }
                }
                :
                {}
            }
            
        />
    </>


}
