import React, { useState } from 'react';

// componentes
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faSpinner, faLongArrowAltRight, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

// exporta relatório da consulta de clientes em PDF
export default function GerarRelatorioConsulta({
    parametros
}){

    // gera relatório para seguradoras
    const [gerando, alterarGerando] = useState(false);

    // gera relatório
    async function gerarRelatorio(){
        alterarGerando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/clientes/pdf`, { params: parametros });
            
            // faz o download
            window.open(
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=temp&f=${encodeURIComponent(data.arquivo)}&n=${encodeURIComponent(data.nome)}`, 
                '_blank'
            );

        }catch({response}){}
        finally{
            alterarGerando(false);
        }
    }
    
    return <>
        
        <OverlayTrigger 
            overlay={<Tooltip>Gerar um relatório dos clientes da consulta em PDF!</Tooltip>}
            placement='bottom'
        >
            <Button
                className='mr-2 rounded-pill'
                variant='padrao'
                size='sm'
                onClick={gerarRelatorio}
                disabled={gerando}
            >
                <FontAwesomeIcon 
                    className="icone" 
                    pulse={gerando}
                    icon={gerando ? faSpinner : faAddressBook } 
                />
                {/* <FontAwesomeIcon 
                    className="icone mx-1"
                    icon={faLongArrowAltRight } 
                />
                <FontAwesomeIcon 
                    className="icone"
                    icon={faFilePdf } 
                /> */}
            </Button>
        </OverlayTrigger>
    </>

}