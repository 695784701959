import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import { useHistory, useParams } from "react-router-dom";
import { Card, Col, Row, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carregador from './../../Componentes/Carregador';
import PaginacaoComponente from './../../Componentes/Paginacao';
import Parametros from './Parametros';
import Registro from './Registro';

// componente
export default function Consultar({
    idCliente=null
}){

    // varaiveis
    let history = useHistory();
    let [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10,
        ativo: ['S'],
        id_cliente:idCliente,
        texto: '',
        statusOperadora: ['1'],
        dataProgramadoInicio: null,
        dataProgramadoFim: null
    });
    let [totalRegistros, atualizarTotalRegistros] = useState(0);
    let [carregando, mostrarCarregando] = useState(false);
    let [consulta, alterarConsulta] = useState([]);
    let [sincronizando, alterarSincronizando] = useState(false);
    let [cancelandoAgendamentos, alterarCancelandoAgendamentos] = useState(false);

    // inicializa
    useEffect(() => {

        // consulta inicial
        fazerConsulta();

    }, [parametros]);

    // carrega os registros
    function fazerConsulta(){

        // carregando
        mostrarCarregando(true);

        // faz a requisição
        axios.get('/smsEnviado', {
            params: parametros
        })
        .then(({data, ...retorno}) => {
            alterarConsulta(data.registros);
            atualizarTotalRegistros(parseInt(data.totalRegistros));
        })
        .catch(e => {})
        .then(() => {
            mostrarCarregando(false);
        });
    }

    // sincronizar mensagens não enviadas
    function sincronizar(){

        // inicia a sincronização
        alterarSincronizando(true);

        // faz a requisição
        axios.get('/smsEnviado/sincronizar')
        .then(() => {
            fazerConsulta();
        })
        .catch(() => {})
        .then(() => {
            alterarSincronizando(false);
        });

    }

    // cancelar todos os agendamentos de sms para o cliente
    function cancelarAgendamentosCliente(){

        // cancela todos os agendamentos para o cliente
        alterarCancelandoAgendamentos(true);

        // faz a requisição
        axios.delete(`/smsEnviado/cliente/${idCliente}`)
        .then(() => {
            fazerConsulta();
        })
        .catch(() => {})
        .then(() => {
            alterarCancelandoAgendamentos(false);
        });
    }

    // retorna
    return <>
        <Row>
            <Col>
                <Card className="painel-consulta border-0 shadow-sm" >
                    <div className="card-header">
                        <div className={'d-flex'}>
                            <h4 className="card-title font-weight-bolder flex-grow-1">Consultar SMS</h4>
                            <div>
                                
                                { idCliente !== null &&
                                    <OverlayTrigger 
                                        overlay={<Tooltip>Cancela todos os agendamentos de sms feitos para o cliente!</Tooltip>}
                                        placement='left'
                                    >
                                        <Button
                                            className='mr-2'
                                            variant='warning'
                                            size='sm'
                                            onClick={cancelarAgendamentosCliente}
                                            disabled={cancelandoAgendamentos}
                                        >
                                            {   cancelandoAgendamentos ?
                                                <>
                                                    <FontAwesomeIcon className="icone" pulse icon={["fas", "spinner"]} />
                                                    <span>Cancelando</span>
                                                </>
                                                :
                                                <>
                                                    <FontAwesomeIcon className="icone" icon={["fas", "trash"]} />
                                                    <span>Cancelar agendamentos</span>
                                                </>
                                            }
                                        </Button>
                                    </OverlayTrigger>
                                }
                                
                                {/* Apresenta opção se não for uma consulta da tela de clientes */}
                                {!idCliente && 
                                    <Button
                                        className='mr-2'
                                        variant='padrao'
                                        size='sm'
                                        onClick={() => {
                                            history.push('/painel/modelos-sms');
                                        }}
                                    >
                                        <FontAwesomeIcon className="icone" icon={["fas", "sms"]} />
                                        <span>Modelos de SMS</span>
                                    </Button>
                                }
                            </div>
                        </div>
                        

                        <Parametros parametros={parametros} alterarParametros={alterarParametros} />
                    </div>
                    <Card.Body className={'pt-0'}>
                        <table className="table table-hover tabela">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Mensagem</th>
                                    <th>Status</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {consulta.map((registro, a) => 
                                    <Registro 
                                        key={registro.dados.id} 
                                        dados={registro}
                                        parametros={parametros}
                                        onClickTr={() => console.log('clicou TR')}
                                        clicouFicha={() => {
                                            history.push(`/painel/fichas/${registro.cliente.id}/alterar/${registro.emitido.id_cliente_ficha}`)
                                        }}
                                        mostrarNomeCliente={idCliente === null ? true : false}
                                    />
                                )}
                            </tbody>
                        </table>
                        <PaginacaoComponente
                            pagina={parametros.pagina}
                            registrosPorPagina={parametros.registrosPorPagina}
                            totalRegistros={totalRegistros}
                            alterouPagina={(pagina) => {
                                alterarParametros({...parametros, pagina: pagina});
                            }}
                        />
                        {carregando && 
                            <Carregador className="rounded-bottom" />
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
};