import React from 'react';
import moment from 'moment';

// componentes
import { Card, Row, Form, Col, Alert } from 'react-bootstrap';
import InputDataCombo from './../../../../Componentes/InputDataCombo';
import InputValor from './../../../../Componentes/InputValor';
import InputInteiro from './../../../../Componentes/InputInteiro';

// component
import { VigenciaComponent } from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

// component
export default function Vigencia({
    dados,
    alterarDados,
    dadosVigencia,
    alterarDadosVigencia,
    dadosFormulario,
    alterarDadosFormulario,
    erros
}){

    return <>
        <VigenciaComponent>
            <Card className='shadow-sm border-0'>
                <Card.Header className='bg-white'>
                    <Card.Title as='div' className='mb-0'>
                        
                        <Form.Check 
                            inline
                            type={'switch'}
                            className='switch'
                            label="Habilitar Vigência"
                            checked={(dadosFormulario.possui_vigencia === 'S')? true : false}
                            onChange={(e) => {

                                // altera a data
                                if(e.target.checked){
                                    dadosFormulario.data_vigencia = moment(dados.data_proposta).add(1, 'years').format('YYYY-MM-DD')
                                }else{
                                    dadosFormulario.data_vigencia = null;
                                }

                                // altera os dados da ficha
                                alterarDadosFormulario({
                                    ...dadosFormulario,
                                    possui_vigencia: e.target.checked ? 'S' : 'N'
                                });
                            }}
                            id={`checkbox-possui-vigencia`}
                            size="sm"
                        />
                        { erros.possui_vigencia &&
                            <Alert variant="danger" className="border-0">
                                <FontAwesomeIcon icon={faExclamationCircle} /> {erros.possui_vigencia}
                            </Alert>
                        }
                    </Card.Title>
                </Card.Header>
                {   dadosFormulario.possui_vigencia === 'S' &&
                    <Card.Body>

                        <Form.Row>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Data</Form.Label>
                                    <InputDataCombo 
                                        data={dadosFormulario.data_vigencia}
                                        onChange={(novaData) => {
                                            alterarDadosFormulario({...dadosFormulario, data_vigencia: novaData});
                                        }}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <div className="vigencia">
                                    <Form.Label>Lembrar antes</Form.Label>
                                    <Form.Row>
                                        <Col md='5'>
                                            <InputInteiro 
                                                className="quantidade-dias"
                                                valor={dadosVigencia.quantidade_lembrar}
                                                onChange={valorNovo => {
                                                    alterarDadosVigencia({
                                                        ...dadosVigencia,
                                                        quantidade_lembrar: valorNovo
                                                    });
                                                }}
                                                size="sm"
                                            />
                                        </Col>
                                        <Col md='7'>
                                            <Form.Control as='select'
                                                value={dadosVigencia.periodo_lembrar}
                                                onChange={(e) => {
                                                    alterarDadosVigencia({
                                                        ...dadosVigencia,
                                                        periodo_lembrar: e.target.value
                                                    });
                                                }}
                                                size="sm"
                                            >
                                                {[
                                                    { value:'D', children:'Dia(s)' },
                                                    { value:'S', children:'Semana(s)' },
                                                    { value:'M', children:'Mes(es)' },
                                                    { value:'A', children:'Ano(s)' }
                                                ].map(dias => 
                                                    <option key={dias.value} {...dias} />
                                                )}
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                </div>
                            </Col>
                        </Form.Row>


                    </Card.Body>
                }
            </Card>
        </VigenciaComponent>
    </>
}