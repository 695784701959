import React from 'react';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';

// componente
function RegistroCotacao({
    dados,
    cliente,
    className
}){


    return <div className={[className, 'mb-3 pb-2'].join(' ')}>
        <p className='mb-0'>
            <FontAwesomeIcon className='icone mx-2' icon={['fas', 'user']} />
            {cliente.nome}
            <small title='Abrir ficha em nova aba!'>
                <a href={`/painel/fichas/${dados.id_cliente}/alterar/${dados.id}`} target="_blank">
                    <FontAwesomeIcon className='icone mx-2' icon={['fas', 'external-link-alt']} /> 
                </a>
            </small>
        </p>
        <p className='m-0'>{dados.titulo}</p>
    </div>
}

export default RegistroCotacao;