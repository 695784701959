import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import { useHistory, useLocation } from "react-router-dom";
import { Card, Form, Row, Col, Button, Badge } from 'react-bootstrap';
import MaskedInput from 'react-maskedinput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import useIsMounted from 'ismounted';
import TextoSms from './../../ModelosSms/Cadastrar/TextoSms';
import ParametrosSms from './../EnviarSms/ParametrosSms';
import ComboModeloSms from './../../Componentes/ComboModeloSms';
import Tags from './../../Componentes/Tags';

// componente
export default function EnviarSmsMassa(){

    // varaiveis
    const isMounted = useIsMounted();
    let location = useLocation();
    let history = useHistory();
    let [dados, alterarDados] = useState({
        filtrosClientes: location.state.parametros,
        mensagem: '',
        data_agendado: null,
        flashsms: 'N'
    });
    let [salvando, alterarSalvando] = useState(false);
    let [erro, alterarErro] = useState(null);

    // carregar modelo de email com as informaões do cliente
    function carregarModelo(idModelo){

        // faz a requisição para carregar o modelo com os dados do cliente
        axios.get(`/modelosSms/${idModelo}`)
        .then(({data, ...retorno}) => {
            dados.mensagem = data.mensagem;
            alterarDados({ ...dados });
        })
    }

    // salva
    function salvar(){

        // salva
        alterarSalvando(true);

        // faz a requisição
        axios.post(`/clientes/enviarSmsMassa`, dados)
        .then(({data, ...retorno}) => {

            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Sms registrado para os clientes!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                {/* <div className="toast-body">
                    {dadosCliente.nome}
                </div> */}
            </>);

            // vai para a consulta de sms's enviados (por enquanto volta para a consulta de clientes)
            history.push('/painel/sms');
        })
        .catch(() => {})
        .then(() => {
            if (isMounted.current) {
                alterarSalvando(false);
            }
        })

    }

    return <>

        <Row>
            <Col lg={8}>
                <Card className={'border-0 shadow-sm'}>
                    <Card.Header className={'bg-white border-0'}>
                        <Card.Title as='h4'>Enviar SMS em massa</Card.Title>
                    </Card.Header>
                    <Card.Body>

                        <Form.Group>
                            <Form.Label>Mensagem</Form.Label>
                            <TextoSms 
                                value={dados.mensagem}
                                onChange={e => {
                                    dados.mensagem = e.target.value;
                                    alterarDados({...dados});
                                }}
                                maxLength={200}
                            />
                        </Form.Group>
                        <p className={'text-right'}>
                            <span>Caracteres </span>
                            <Badge
                                title={'limite de 160'}
                                variant={dados.mensagem.length > 160 ? 'danger' : 'info'}
                            >
                                {dados.mensagem.length}
                            </Badge>
                        </p>
                    </Card.Body>
                </Card>

                <Form.Group className="d-flex justify-content-around my-4">
                    <Button variant="danger" onClick={() => {
                        history.goBack();
                    }}>
                        <span>Cancelar</span>
                    </Button>
                    <Button variant="success" disabled={salvando} onClick={salvar}>
                        {salvando ? 
                            <>
                                <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                <span>Enviando</span>
                            </> : 
                            <span>Enviar sms</span>
                        }
                    </Button>
                </Form.Group>
            </Col>
            <Col lg={4}>
                <Card className={'border-0 shadow-sm'}>
                    <Card.Body>
                        <ParametrosSms 
                            dados={dados}
                            alterarDados={alterarDados}
                        />
                        
                        { dados.mensagem === '' && 
                            <ComboModeloSms 
                                alterou={carregarModelo}
                            />
                        }
                    </Card.Body>
                </Card>

                <Card className={'border-0 shadow-sm mt-3'}>
                    <Card.Body>
                        <Tags />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>

}