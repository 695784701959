import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';

// importa componentes
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import FormularioImobiliaria from './../../../Atendimento/Fianca/FormularioImobiliaria';
import FormularioDadosLocatario from './../../../Atendimento/Fianca/FormularioDadosLocatario';
import FormularioDadosPessoais from './../../../Atendimento/Fianca/FormularioDadosPessoais';
import FormularioDadosImovelLocacao from './../../../Atendimento/Fianca/FormularioDadosImovelLocacao';
import FormularioResidente from './../../../Atendimento/Fianca/FormularioResidente';
import FormularioReferenciasPessoais from './../../../Atendimento/Fianca/FormularioReferenciasPessoais';
import Anexos from './../../Componentes/Anexos';

// componente
export default function Cadastrar(){

    // estados
    const history = useHistory();
    const { id } = useParams();
    const [modo, alterarModo] = useState('cadastro'); // cadastro, edicao
    const [carregando, alterarCarregando] = useState(true);
    const [salvando, alterarSalvando] = useState(false);
    const [dados, alterarDados] = useState({
        id: id || null,
    });

    // consultar os dados do registro
    async function consultarId(){

        // altera o modo
        alterarModo('edicao');

        try{

            // 
            let { data } = await axios.get(`/solicitacaoFormulario/${dados.id}`);

            alterarDados(data.dados);
            alterarCarregando(false);

        }catch({response}){}
    }

    // inicializa
    useEffect(() => {

        // verifica se é edição
        if(dados.id){
            consultarId();
        }else{
            alterarCarregando(false);
        }

    }, []);

    // carregando
    if(carregando){
        return <div>Carregando...</div>
    }


    return <>
        <Row>
            <Col lg='8'>
                <Card className='border-0 shadow-sm'>
                    <Card.Body>

                    <h3 className='text-center mb-4'>Cadastro fiança {modo === 'cliente' ? 'cliente' : 'imobiliária'}</h3>

                    {/* { modo === 'imobiliaria' && */}
                        <FormularioImobiliaria 
                            dados={dados}
                            alterarDados={alterarDados}
                            funcaoAlterarDados={() => {}}
                        />
                    {/* } */}

                    <hr />

                    <h5 className='text-right mb-4'>DADOS DO LOCATÁRIO</h5>
                    <FormularioDadosLocatario 
                        dados={dados}
                        alterarDados={alterarDados}
                        funcaoAlterarDados={() => {}}
                    />

                    <hr />

                    <h5 className='text-right mb-4'>DADOS PROFISSIONAIS</h5>
                    <FormularioDadosPessoais 
                        dados={dados}
                        alterarDados={alterarDados}
                        funcaoAlterarDados={() => {}}
                    />



                    { modo === 'imobiliaria' &&
                        <>
                            <hr />

                            <h5 className='text-right mb-4'>DADOS DO IMÓVEL PRETENDIDO PARA LOCAÇÃO</h5>
                            <FormularioDadosImovelLocacao 
                                dados={dados}
                                alterarDados={alterarDados}
                                funcaoAlterarDados={() => {}}
                            />
                        </>
                    }

                    <hr />

                    <h5 className='text-right mb-4'>INFORME TODOS OS RESIDENTES MAIORES DE 18 anos</h5>
                    <FormularioResidente 
                        dados={dados}
                        alterarDados={alterarDados}
                        funcaoAlterarDados={() => {}}
                        numeroResidente={'1'}
                    />
                    <FormularioResidente 
                        dados={dados}
                        alterarDados={alterarDados}
                        funcaoAlterarDados={() => {}}
                        numeroResidente={'2'}
                    />
                    <FormularioResidente 
                        dados={dados}
                        alterarDados={alterarDados}
                        funcaoAlterarDados={() => {}}
                        numeroResidente={'3'}
                    />
                    <FormularioResidente 
                        dados={dados}
                        alterarDados={alterarDados}
                        funcaoAlterarDados={() => {}}
                        numeroResidente={'4'}
                    />


                    <hr />

                    <h5 className='text-right mb-4'>PREFERENCIAS PESSOAIS</h5>
                    <h5 className='text-right mb-4'>Pelo menos 2</h5>
                    <FormularioReferenciasPessoais 
                        dados={dados}
                        alterarDados={alterarDados}
                        funcaoAlterarDados={() => {}}
                        numeroReferencia={'1'}
                    />
                    <FormularioReferenciasPessoais 
                        dados={dados}
                        alterarDados={alterarDados}
                        funcaoAlterarDados={() => {}}
                        numeroReferencia={'2'}
                    />


                    <h6>Anexos</h6>
                    <Anexos 
                        tabela='solicitacao_formulario'
                        id={dados.id}
                        salvando={(flag) => {}}
                        // size='md',
                        habilitarCadastro={false}
                        habilitarExcluir={false}
                        // login
                        externo={true}
                    />
                        
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
}
