import React, {useState, useEffect} from 'react';

// componentes
import BotaoTabela from './../../../Componentes/BotaoTabela';

// elemento registro
function Registro({
    dados, 
    clicouEditar
}){

    return <tr>
        <td className="estreito text-center font-weight-bold">{dados.id}</td>
        <td>{dados.descricao}</td>
        <td className="text-nowrap estreito">

            <BotaoTabela 
                onClick={clicouEditar}
                icone={["fas", "pencil-alt"]}
            >Editar</BotaoTabela>
        </td>
    </tr>
}

export default Registro;