import React, { 
    useState, 
    useEffect 
} from 'react';
import { 
    useParams, 
    useHistory 
} from "react-router-dom";
import { 
    Card, 
    Form, 
    Row, 
    Col, 
    Button, 
    Badge 
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import axios from 'axios';
import useIsMounted from 'ismounted';

// componentes
import TextoWhatsapp from './TextoWhatsapp';
import Tags from '../../Componentes/Tags';

// componente
export default function Cadastrar(){

    // variaveis
    const isMounted = useIsMounted();
    const history = useHistory();
    const { id } = useParams();

    const [dados, alterarDados] = useState({
        id: id || null,
        descricao: '',
        mensagem: '',
        tipo: '3' // 1: cliente, 2: parcela da emissão, 3: whatsapp
    });
    const [carregando, alterarCarregando] = useState(true);
    const [salvando, alterarSalvando] = useState(false);

    // inicializa
    useEffect(() => {

        // verifica se é edição
        if(dados.id === null){

            // cadastro
            alterarCarregando(false);

        }else{

            // carrega os dados do modelo
            axios.get(`/modelosSms/${dados.id}`)
            .then(({data, ...retorno}) => {
                alterarDados(data);
                alterarCarregando(false);
            });

        }

    }, []);

    // salva registro
    function salvar(){

        // salvando
        alterarSalvando(true);

        // salva registro
        axios.post(`/modelosSms`, dados)
        .then(({data, ...retorno}) => {
            
            // mensagem de cadastro realizado com sucesso
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Modelo cadastrado!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>);

            // vai para a consulta de sms's enviados (por enquanto volta para a consulta de clientes)
            history.push('/painel/modelos-whatsapp');
        })
        .catch(e => {})
        .then(() => {
            if (isMounted.current) {
                alterarSalvando(false);
            }
        });

    }

    // se estiver carregando
    if(carregando){
        return <>Carregando ...</>
    }

    return <>
        <Row>
            <Col lg={8}>
                <Card className={'border-0 shadow-sm'}>
                    <Card.Header className={'bg-white border-0'}>
                        <Card.Title as='h4'>Cadastrar Modelo WHATSAPP</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control 
                                        value={dados.descricao}
                                        onChange={e => {
                                            dados.descricao = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Group>
                            <Form.Label>Mensagem</Form.Label>
                            <TextoWhatsapp 
                                value={dados.mensagem}
                                onChange={e => {
                                    dados.mensagem = e.target.value;
                                    alterarDados({...dados});
                                }}
                            />
                        </Form.Group>

                        <p className={'text-right'}>
                            <span>Caractéres </span> 
                            <Badge
                                variant={'info'}
                            >
                                {dados.mensagem.length}
                            </Badge>
                        </p>

                    </Card.Body>
                </Card>
                
                <Form.Group className="d-flex justify-content-around my-4">
                    <Button variant="danger" onClick={() => {
                        history.goBack();
                    }}>
                        <span>Cancelar</span>
                    </Button>
                    <Button variant="success" disabled={salvando} onClick={salvar}>
                        {salvando ? 
                            <>
                                <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                <span>Salvando</span>
                            </> : 
                            <span>Salvar</span>
                        }
                    </Button>
                </Form.Group>
            </Col>
            <Col>
                <Card className={'border-0 shadow-sm'}>
                    <Card.Body>
                        <Tags 
                            planoCliente={true}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
}