import React from 'react';

// componente
function Geral({

}){

    return <div>Geral</div>

}

export default Geral;