import React from 'react';
import moment from 'moment';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';

// funçõs
import { converterReal } from './../../../../Funcoes';

// registro da proposta
function RegistroProposta({
    dados,
    cliente,
    proposta,
    parcelasProposta,
    className
}){

    // pega o total das parcelas
    function totalParcelas(){
        let total = 0.0;

        // faz a somatória
        parcelasProposta.forEach(parcela => {
            total += parseFloat(parcela.valor);
        });

        // retorna total
        return total;
    }

    return <div className={[className, 'mb-3 pb-2'].join(' ')}>
        <p className='mb-0'>
            <FontAwesomeIcon className='icone mr-2' icon={['fas', 'calendar-alt']} />
            {moment(proposta.data_proposta).format('DD/MM/YYYY')} - 
            <FontAwesomeIcon className='icone mx-2' icon={['fas', 'user']} /> 
            {cliente.nome}
            <small title='Abrir ficha de atendimento em nova aba!'>
                <a href={`/painel/fichas/${dados.id_cliente}/alterar/${dados.id}`} target="_blank">
                    <FontAwesomeIcon className='icone mx-2' icon={['fas', 'external-link-alt']} /> 
                </a>
            </small>
        </p>
        <p className='m-0'>{dados.titulo}</p>
        <Row className='mt-2'>
            <Col md='6'>
                {dados.data_vigencia &&
                    <small>
                        <span>Vigência <b>{moment(dados.data_vigencia).format('DD/MM/YYYY')}</b></span>
                    </small>
                }
            </Col>
            <Col md='6' className='text-right'>
                {/* <small>Total</small> */}
                <p className='m-0'>
                    R$ {converterReal(totalParcelas())}
                </p>
            </Col>
        </Row>
        
    </div>
}

export default RegistroProposta;