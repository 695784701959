import { 
    createRef, 
    useEffect, 
    useState 
} from 'react';
import { 
    Button, 
    Modal, 
    Form 
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import MaskedInput from 'react-maskedinput';
import axios from 'axios';

import './index.scss';

export default function ModalDadosUsuario({
    idCliente, 
    dadosCliente,
    alterarDadosCliente,
    fechou
}) {

    const telResInput = createRef();

    const [razaoSocial, alterarRazaoSocial] = useState(null);
    const [nomeFantasia, alterarNomeFantasia] = useState(null);
    const [cpfCnpj, alterarCpfCnpj] = useState(null);
    const [telefone, alterarTelefone] = useState(null);
    const [email, alterarEmail] = useState(null);

    const [salvando, alterarSalvando] = useState(false);
    const [erros, alterarErros] = useState({});

    async function editarDados() {

        // remove erros
        alterarErros({});

        alterarSalvando(true);

        // prepara parametros
        const parametros = {
            id: idCliente
        };

        // caso for uma pessoa física
        if(dadosCliente.tipoPessoa === "F") {
            parametros['email'] = email;
            parametros['cpf'] = cpfCnpj;
            parametros['telefoneCelular'] = telefone;
        } else {
            parametros['email'] = email;
            parametros['cnpj'] = cpfCnpj;
            parametros['responsavelJuridicoEmail'] = email;
            parametros['responsavelJuridicoTelefone'] = telefone;
        }

        try {

            // atualiza status dos eventos
            const { data } = await axios.post(`/clientes/salvarComJwt`, {
                ...parametros
            });

            // mensagem de sucesso
            toast(({ closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Dados alterados com sucesso!</strong>
                    <button
                        onClick={closeToast}
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>);
            
            // altera dados do cliente
            alterarDadosCliente({
                ...dadosCliente,
                email: data.dados.email,
                telefoneComercial: data.dados.telefoneComercial,
                responsavelJuridicoEmail: data.dados.responsavelJuridicoEmail,
                responsavelJuridicoTelefone: data.dados.responsavelJuridicoTelefone
            });
    
        } catch({ response }) {

            if (response && response.status && response.status === 400) {

                alterarErros(response.data);
                toast(<>
                    <div
                        style={{ background: '#ff6271' }}
                    >
                        <div className="toast-header">
                            <strong className="mr-auto">Houve alguns erros :(</strong>
                            <button
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                            </button>
                        </div>
                        <div className="toast-body text-light">
                            Revise seus dados!
                        </div>
                    </div>
                </>);
            }
        } finally {
            // desativa o carregamento
            alterarSalvando(false);
        }

    }

    useEffect(() => {

        // caso for uma pessoa física
        if(dadosCliente.tipoPessoa === "F") {

            // atribui informações de pessoa física aos estados
            alterarCpfCnpj(dadosCliente.cpf);
            alterarNomeFantasia(dadosCliente.nome);    
            alterarEmail(dadosCliente.email);
            alterarTelefone(dadosCliente.telefoneComercial);        
            
        } else {

            // atribui informações de pessoa jurídica aos estados
            alterarCpfCnpj(dadosCliente.cnpj);
            alterarRazaoSocial(dadosCliente.nome);
            alterarNomeFantasia(dadosCliente.nomeFantasia);
            alterarEmail(dadosCliente.responsavelJuridicoEmail);
            alterarTelefone(dadosCliente.responsavelJuridicoTelefone);

        }

    }, []);

    return <>

        <Modal 
            show={true} 
            onExited={() => {
                fechou();
            }}
            onHide={() => {
                fechou();
            }}
            size={'lg'}
            containerClassName='container-modal-dados-usuario'
            dialogClassName="dialog-modal-dados-usuario"
            contentClassName='w-75'
        >
            <Modal.Header className='text-center'>
                <div className={'w-100 d-flex align-items-center'}>
                    <Modal.Title className={['flex-grow-1 m-0 text-center'].join(' ')}>
                        Dados
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                {razaoSocial && <Form.Group>
                    <Form.Label>Razão Social</Form.Label>
                    <Form.Control
                        value={razaoSocial || ''}
                        disabled
                        size="sm"
                    />
                </Form.Group>}
                <Form.Group>
                    <Form.Label>
                        {dadosCliente.tipoPessoa === "F" ? 'Nome' : 'Nome Fantasia'}
                    </Form.Label>
                    <Form.Control
                        value={nomeFantasia || ''}
                        disabled
                        size="sm"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>CNPJ/CPF</Form.Label>
                    <Form.Control
                        value={cpfCnpj || ''}
                        disabled
                        size="sm"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        Email
                    </Form.Label>
                    <Form.Control
                        value={email || ''}
                        maxLength='100'
                        isInvalid={erros.email}
                        onChange={(e) => {
                            const valor = e.target.value; 
                            alterarEmail(valor === "" ? null : valor);
                        }}
                        size="sm"
                    />
                    {erros.email &&
                        <Form.Control.Feedback type="invalid">
                            {erros.email}
                        </Form.Control.Feedback>
                    }
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        Telefone
                    </Form.Label>
                    <MaskedInput 
                        className={["form-control form-control-sm", erros.telefone ? 'is-invalid' : ''].join(` `)}
                        mask="(11) 1111-11111"
                        value={telefone || ''}
                        ref={telResInput}
                        onChange={(e) => {
                            alterarTelefone(telResInput.current.mask.getRawValue().split('_').join(''));
                        }}
                    />
                    {erros.telefone &&
                        <Form.Control.Feedback type="invalid">
                            {erros.telefone}
                        </Form.Control.Feedback>
                    }
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="rodape">
                <Button 
                    className='ml-1' 
                    variant="danger" 
                    onClick={() => fechou()} 
                    size="lg"
                >
                    <span>Fechar</span>
                </Button>
                <Button 
                    className={'mr-1'} 
                    variant={"padrao-azul"} 
                    disabled={salvando} 
                    onClick={() => editarDados()} 
                    size={"lg"}
                >
                    {salvando ?
                        <>
                            <FontAwesomeIcon 
                                className="icone" 
                                pulse 
                                icon={["fas", 'spinner']} 
                            />
                            <span>Editando</span>
                        </> :
                        <span>Editar</span>
                    }
                </Button>
            </Modal.Footer>
        </Modal>

    </>

}