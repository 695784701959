import React, {useState, useEffect} from 'react';
import styles from './index.module.scss';
import moment from 'moment';

// componentes
import { Badge } from 'react-bootstrap';
import BotaoTabela from './../../../Componentes/BotaoTabela';

// elemento registro
function Registro({
    dados, 
    clicouBaixar
}){

    return <tr
        onClick={clicouBaixar}
        className={`clicavel`}
    >
        <td className="estreito text-center font-weight-bold">{dados.id}</td>
        <td
            className="estreito text-center font-weight-bold text-nowrap"
        >
            <Badge
                variant='info'
            >
                {moment(dados.data_cadastro).calendar()}
            </Badge>
            
        </td>
        <td>{dados.arquivo}</td>
        <td className="text-nowrap estreito">

            <BotaoTabela 
                onClick={clicouBaixar}
                icone={["fas", "download"]}
            >Baixar</BotaoTabela>
        </td>
    </tr>
}

export default Registro;