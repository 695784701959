import React from 'react';

// componentes
import { Form } from 'react-bootstrap';

function TipoPessoa({
    value,
    onChange
}){


    return <>
        <Form.Check
            inline
            label="Física"
            type={'radio'}
            value="F"
            name='tipo_pessoa'
            checked={(value === 'F')? true : false}
            id={`checkbox-tipo-cliente-f`}
            onChange={onChange}
        />
        <Form.Check
            inline
            label="Juridica"
            type={'radio'}
            value="J"
            name='tipo_pessoa'
            checked={(value === 'J')? true : false}
            id={`checkbox-tipo-cliente-j`}
            onChange={onChange}
        />
    </>
}

export default TipoPessoa;