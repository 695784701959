import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import OptionSelect from './OptionSelect';


// componente
export default function ComboGrupos({
    grupos, // []
    onChange
}){


    // let [optionsTemp, alterarOptionsTemp] = useState([{id: 1, descricao: 'Teste'}]);
    let [carregando, alterarCarregando] = useState(false);
    let [consulta, alterarConsulta] = useState([]);
    let [salvando, alterarSalvando] = useState(false);
    let [erro, alterarErro] = useState(null);

    // salva
    async function salvar(inputValue){
        alterarSalvando(true);
        alterarErro(null);

        try{

            // faz a requisição
            let { data } = await axios.post(`/grupos`, { dados: { id: null, descricao: inputValue } });
            onChange([...grupos, data.dados.dados]);

        }catch({response}){
            if(response && response.status && response.status === 400){
                alterarErro(response.data.erro);
            }
        }finally{
            alterarSalvando(false);
        }
    }

    // faz a consulta
    async function consultar(inputValue, callback = null){

        try {

            // faz a requisição
            let { data } = await axios.get(`/grupos`, {
                params: {
                    texto: inputValue,
                    registrosPorPagina: 7
                }
            });

            // filtra registros, desabilita quem já está na lista
            let registros = data.registros.map(grupo => {
                // verifica se já está adicionado
                if(grupos.find(gruposFind => {
                    if(gruposFind.id === grupo.id){
                        return true;
                    }
                    return false;
                })){
                    grupo.isDisabled = true;
                }
                return grupo;
            });

            // altera os dados
            alterarConsulta(registros);
            if(callback !== null){
                callback(registros);
            }

        }catch({response}){}
        
    }



    return <div>
        <AsyncCreatableSelect
            // isClearable
            isDisabled={salvando}
            // isLoading={salvando}
            // onChange={this.handleChange}
            // onCreateOption={this.handleCreate}
            // options={options}
            // value={value}
            loadOptions={consultar}
            isMulti
            onCreateOption={salvar}
            options={grupos}
            value={grupos.map(opcao => {
                return {label: opcao.descricao, value: opcao.id}
            })}
            components={{
                Option: OptionSelect
            }}
            onChange={(evento, validar) => {

                // verifica ações
                if(validar.action === 'pop-value'){
                    grupos.pop();
                    onChange([...grupos]);
                }else if(validar.action === 'remove-value'){
                    onChange(grupos.filter((grupo, a) => {
                        if(grupo.id === validar.removedValue.value){
                            return false;
                        }
                        return true
                    }));
                }else if(validar.action === 'clear'){
                    onChange([]);
                }else if(validar.action === 'select-option'){
                    onChange([...grupos, validar.option]);
                }

                // alterarOptionsTemp([...optionsTemp, b.option])
                
            }}
            formatCreateLabel={() => 'Salvar'}
            loadingMessage={() => 'Carregando...'}
            noOptionsMessage={() => 'Digite algo para fazer a consulta ou cadastrar'}
            placeholder='Grupos'
        />
    </div>
}
