import React, {useState, useEffect} from 'react';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import BotaoTabela from './../../../../../Painel/Componentes/BotaoTabela';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { faCalendarCheck, faCalendarTimes, faClipboardList, faHiking, faTimesCircle , faSignOutAlt, faTable, faUser } from '@fortawesome/free-solid-svg-icons';

// elemento registro
function Registro({
    dados, 
    evento,
    clicouEditar
}){
    
    return <tr className="clicavel" onClick={clicouEditar}>
        <td 
            className={[
                'estreito text-center font-weight-bold',
                evento.integrado === "S" ? 'text-success' : 'text-info'
            ].join(` `)}
        >
            {dados.cancelado === 'N' 
            ? 
                <FontAwesomeIcon className='mr-1' icon={evento.integrado === "S" ? faTable : faClipboardList} />
            : 
                <OverlayTrigger 
                    placement='top'
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Cancelado
                        </Tooltip>
                    }
                >  
                    <FontAwesomeIcon className='mr-1' icon={faTimesCircle}  color={'#dc3545'}  />
                </OverlayTrigger>
            }
        </td>
        <td>
            {dados.nome}
        </td>
        <td className="text-center">
            {dados.dataNascimento && 
                <small>{moment(dados.dataNascimento).format('DD/MM/YYYY')}</small>
            }
        </td>
        <td className="text-center">
            <small>{dados.cpf}</small>
        </td>

        {
            evento.integrado === 'S' &&
            <>
                {dados.cancelado === 'S' && 
                    <>
                        <td className='estreito text-center font-weight-bold text-nowrap text-danger'>
                            <Badge variant="danger">
                                cancelado
                            </Badge>
                        </td>
                        <td className='estreito text-center font-weight-bold text-nowrap text-danger'>
                            <Badge variant="danger">
                                <FontAwesomeIcon icon={faCalendarTimes} className="mr-1" /> 
                                {moment(dados.dataCancelado).format('DD/MM')}
                            </Badge>
                        </td>
                    </>
                }
                {dados.cancelado === 'N' && 
                    <>
                        <td className='estreito text-center font-weight-bold text-nowrap'>
                            <Badge variant="info">
                                <FontAwesomeIcon icon={faCalendarCheck} className="mr-1" /> 
                                {dados.dataEntrada ? moment(dados.dataEntrada).format('DD/MM') : ''}
                            </Badge>
                        </td>
                        <td className='estreito text-center font-weight-bold text-nowrap'>
                            <Badge variant="info">
                                <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" /> 
                                {dados.dataSaida ? moment(dados.dataSaida).format('DD/MM') : ''}
                            </Badge>
                        </td>
                    </>
                }
            </>
        }
    </tr>
}

export default Registro;