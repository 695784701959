import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Button, Modal, Row, Col } from 'react-bootstrap';
import Horario from './Horario';

// exporta
export default function ModalCadastrar({
    idUsuario,
    dia,
    fechou,
    salvou
}){

    // estados
    let [mostrar, alterarMostrar] = useState(true);
    let [carregando, alterarCarregando] = useState(true);
    let [horarios, alterarHorarios] = useState([]);
    let [removidos, alterarRemovidos] = useState([]);
    let [salvando, alterarSalvando] = useState(false);

    // salva
    async function salvar(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/ponto`, {
                ano: moment(dia).format('YYYY'),
                mes: moment(dia).format('MM'),
                id_usuario: idUsuario,
                horarios: horarios,
                removidos: removidos
            });
            salvou(data.datas);
            alterarMostrar(false);

        }catch({response}){}

    }

    // adiciona hora
    function adicionarHora(){
        
        horarios.push({
            id: null,
            data_batida: `${dia} 00:00:00`,
            id_usuario: idUsuario
        });
        alterarHorarios([...horarios]);
    }

    // exlcui hora
    function excluir(hora){

        // verifica se é registro novo
        if(hora.id !== null){
            alterarRemovidos([...removidos, hora.id]);
        }
        
        // busca objeto
        let pos = horarios.indexOf(hora);
        if(pos >= 0){
            horarios.splice(pos, 1);
        }
        alterarHorarios([...horarios]);
    }

    // consultar o ponto
    async function consultarData(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/ponto/data`, { params: {
                id_usuario: idUsuario,
                data: dia
            }});
            alterarHorarios(data.registros);
            alterarCarregando(false);

        }catch({response}){}
    }

    // faz a consulta da data
    useEffect(() => {
        consultarData();
    }, []);

    // retorna modal de carregando
    if(carregando){
        return <Modal 
            show={mostrar}
            onHide={() => {}}
        >
            <Modal.Body>
                <p>Carregando horarios <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} /></p>
            </Modal.Body>
        </Modal>
    }

    return <Modal
    show={mostrar} 
    onExited={fechou}
    onHide={() => {}}
    size={'md'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title>
                Cadastro Ponto
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {moment(dia).format('dddd[,] D [de] MMMM[, ]YYYY')}

            <Row>
                <Col md='6'>
                    <p><b>Horarios</b></p>
                    {horarios.filter(hora => hora.id && hora).map(hora => 
                        <Horario 
                            key={hora.id}
                            dados={hora}
                            excluir={() => {
                                excluir(hora)
                            }}
                            alterou={() => {
                                alterarHorarios([...horarios]);
                            }}
                        />    
                    )}
                </Col>
                <Col md='6'>
                    
                    <p>
                        <b>Horarios a adicionar</b>
                    </p>
                    {horarios.filter(hora => hora.id === null).map((hora, a) => 
                        <Horario 
                            key={a}
                            dados={hora}
                            excluir={() => {
                                excluir(hora)
                            }}
                            alterou={() => {
                                alterarHorarios([...horarios]);
                            }}
                        />    
                    )}
                    <Button
                        variant='padrao'
                        size='sm'
                        onClick={adicionarHora}
                    >   
                        <span>Adicionar</span>
                        <FontAwesomeIcon className="icone" icon={["fas", 'plus']} />
                    </Button>
                
                </Col>
            </Row>

        </Modal.Body>
        <Modal.Footer className='border-0'>
            <Button
                variant='danger'
                onClick={() => {alterarMostrar(false)}}
                disabled={salvando}
            >
                <span>Fechar</span>
            </Button>
            <Button
                onClick={salvar}
                variant='success'
                disabled={salvando}
            >
                {salvando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Salvando</span>
                    </> : 
                    <span>Salvar</span>
                }
            </Button>
        </Modal.Footer>
    </Modal>
}
