import React, { 
    useState, 
    useEffect, 
    useRef
} from 'react';
import { 
    Card, 
    Form, 
    Col, 
    Button,
    Row
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid';
import moment from 'moment';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputData from '../../../Componentes/InputData';
import ComboModeloEmail from './../../../Componentes/ComboModeloEmail';
import BotaoTabela from '../../../Componentes/BotaoTabela';
import InputValor from './../../../Componentes/InputValor';
import ComboPlanos from '../../../Componentes/ComboPlanos';
import styles from '../index.module.scss';

export default function CamposSeguroAventura({
    dados,
    grupos,
    alterarDados,
    gerandoApolice,
    emissaoContrato,
    gerandoContrato,
    downloadApolice,
    toastCopiarTexto,
    carregamentoToken,
    gerarTokenDiarias,
    realizarUploadTermo,
    realizarDownloadTermo,
    dadosContratoAutentique,
    subindoTermoResponsabilidade,
    baixandoTermoResponsabilidade
}) {

    // estados
    const configuracoes = useSelector(state => state.painel.configuracoesSistema);
    const refLinkPropriedade = useRef();
    const arquivoRef = useRef();

    const [habilitar, alterarHabilitar] = useState(false);
    const [mostrarLinkCliente, alterarMostrarLinkCliente] = useState(false);
    const [numeroDiariasInput, alterarNumeroDiariasInput] = useState(null);
    const [possuiGrupoSeguroAventura, alterarPossuiGrupoSeguroAventura] = useState(false);
    const [diariasCheckin, alterarDiariasCheckin] = useState([]);
    const [camposJson, alterarCamposJson] = useState({
        linha: null,
        nome: "",
        cpf: "",
        email: "",
        dataNascimento: "",
        dataInicio: "",
        dataFinal: "",
        genero: "",
        telefone: ""
    });

    const linkPropriedade = `${window.location.origin}/formularios/propostaSeguro/${dados.seguro_aventura_token_checkin}/propriedade`;

    // inicializa
    useEffect(() => {
        
        // se for nulo, altera para ser um array
        if (dados.observacao_grupo === null) {
            dados.observacao_grupo = [];
            alterarDados({ ...dados });
            incluirObservacao();
        }

        // se há seguro aventura propriedade selecionado
        alterarMostrarLinkCliente(grupos.some(grupo => grupo.descricao === "Seguro Aventura Propriedade"));

        // se há seguro aventura selecionado
        alterarPossuiGrupoSeguroAventura(grupos.some(grupo => grupo.descricao === "Seguro Aventura Ativo"));

        // verifica se é para habilitar ou desabilitar esse campo
        let possuiGrupo = grupos.find(grupo => {
            if (grupo.id === configuracoes.habilitarCamposSeguroAventuraGrupo) {
                return true;
            }
            return false;
        });

        // verifica se encontrou
        if (possuiGrupo !== habilitar) {
            alterarHabilitar(possuiGrupo);
        }

    }, [grupos]);
    
    useEffect(() => {       
        
        // precisa estar no grupo seguro aventura
        // deve possuir cpf ou cnpj
        if(      
            possuiGrupoSeguroAventura && 
            (
                dados.cpf.length === 11 || 
                dados.cnpj.length === 14
            ) && 
            (
                !dados.carta_oferta ||
                dados.carta_oferta.length === 0
            )
        ) {      

            // monta carta oferta
            const cartaOfertaFinal = montaCartaOferta();

            // se possui carta oferta
            if(
                cartaOfertaFinal && 
                cartaOfertaFinal.length > 4
            ) {

                // resgata a carta oferta
                const carta =  cartaOfertaFinal;

                // obtém o restante da carta oferta e inverte sua ordem
                const restoInvertido = carta.substring(4).split('').reverse().join('')
                    .replace(/[^a-zA-Z0-9]/g, '')
                    .replace('/', '')
                    .replace('-', '')
                ;
                
                // atualiza numero de apolice
                dados.numero_apolice = restoInvertido;

            }

            // altera estado com carta oferta montada
            alterarDados({ 
                ...dados, 
                carta_oferta: cartaOfertaFinal 
            });

        } 

    }, [possuiGrupoSeguroAventura, dados.cpf, dados.cnpj]);

    // se informado link preenche
    useEffect(() => {
        if (dados.seguro_link_xls !== null) {
            alterarDados({ ...dados, seguro_link_xls_campos: JSON.stringify(camposJson) });
        }
    }, [dados.seguro_link_xls, camposJson]);

    useEffect(() => {

        // verifica se possui objeto
        if(dados.diarias_evento_checkin !== null) {

            // tranforma json em objeto
            let arrayDiariasCheckin = JSON.parse(dados.diarias_evento_checkin);

            // faz a ordenação
            arrayDiariasCheckin = arrayDiariasCheckin
            .sort((a, b) => {

                // resgata número de diárias
                const num1 = a.numeroDiarias;            
                const num2 = b.numeroDiarias;

                // faz as verificações e retorna true ou false para o sort
                if (num1 < num2) return -1;        
                if (num1 > num2) return 1;
    
                // mantem
                return 0;
                
            });

            // altera diaria checkin
            alterarDiariasCheckin(arrayDiariasCheckin); 

        }

    }, [dados.diarias_evento_checkin]);

    // inicializa os campos json
    useEffect(() => {        

        if (dados.seguro_link_xls_campos !== null) {
            alterarCamposJson({ ...camposJson, ...JSON.parse(dados.seguro_link_xls_campos) });
        }

    }, []);
    
    // incluir nova observacao
    function incluirObservacao() {
        dados.observacao_grupo.push({
            descricao: "",
            id: uniqid()
        });
        alterarDados({ ...dados });
    }

    // faz a montagem de uma carta oferta
    function montaCartaOferta() {
        
        // resgata ano atual
        const anoAtual = moment(new Date()).format('Y');  

        // prepara cpf ou cnpj
        const cpfCnpj = (dados.cpf && dados.cpf.length === 11) ? dados.cpf : ((dados.cnpj && dados.cnpj.length === 14) ? dados.cnpj : '1234');

        // resgata as 6 primeiras letras
        const cpfCnpjFormatado = cpfCnpj.slice(0, 6);

        // prepara números aleatorios
        let numerosAleatorios = '';

        // percorre para gerar 4 numeros aleatorios
        for (let i = 0; i < 4; i++) {
            
            // concatena números aleatórios
            numerosAleatorios += Math.floor(Math.random() * 10);
            
        }            
        
        // monta carta oferta
        return anoAtual + cpfCnpjFormatado + numerosAleatorios;

    }

    // função para controlar a copia do texto
    async function copiarParaTeclado(texto) {

        try {

            // copia para o teclado
            await navigator.clipboard.writeText(texto);

            // chama o toast para mostar a mensagem de sucesso
            toastCopiarTexto(
                "Copiado!", 
                "O texto selecionado foi copiado", 
                "success"
            );

        } catch (err) {

            // chama o toast dizendo o erro
            toastCopiarTexto(
                "Erro", 
                "Houve algum erro ao copiar o texto", 
                "danger"
            );

        }

    }

    // loga com a carta oferta do cliente
    function loginCliente() {

        // resgata servidor
        const { origin } = window.location
        
        // vai para a url designada para efetuar o login
        window.open(`${origin}/formularios/propostaSeguro?${dados.carta_oferta || ''}`);

    }

    // se não estiver configurado, retorna falso
    if (configuracoes.habilitarCamposSeguroAventuraGrupo === null) {
        return <></>
    }

    // se o valor padrão for nulo, não renderiza ainda,
    if (dados.observacao_grupo === null) {
        return <></>
    }

    // se não for para habilitar
    if (!habilitar) {
        return <></>
    }
    
    return <>
        <Card
            className={'border-0 shadow-sm mt-3'}
        >
            <Card.Body
                className='pl-4'
            >
                <Form.Group>
                    <Form.Label
                        className='font-weight-bold text-left fs-3'
                        style={{
                            fontSize: 24,
                            marginLeft: -10
                        }}
                    >
                        Evento
                    </Form.Label>
                </Form.Group>
                <Form.Group>
                    <div className='d-flex flex-row align-items-center justify-content-between'>
                        <Form.Label>
                            Código Referência
                        </Form.Label>
                        <button
                            onClick={loginCliente}
                            className='ml-2 text-decoration-none small btn btn-link d-flex align-items-start h-100 p-0 cursor-pointer mb-2'
                        >
                            <small>Logar</small>
                        </button>
                    </div>
                    <Form.Control
                        value={dados.carta_oferta || ''}
                        onChange={e => {
                            dados.carta_oferta = e.target.value;

                            // se possui carta oferta
                            if(
                                e.target.value && 
                                e.target.value.length > 4
                            ) {

                                // resgata a carta oferta
                                const carta =  e.target.value;

                                // obtém o restante da carta oferta e inverte sua ordem
                                const restoInvertido = carta.substring(4).split('').reverse().join('')
                                    .replace(/[^a-zA-Z0-9]/g, '')
                                    .replace('/', '')
                                    .replace('-', '')
                                ;
                                
                                // atualiza numero de apolice
                                dados.numero_apolice = restoInvertido;

                            }

                            // atualiza os dados
                            alterarDados({ ...dados });
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <div className='d-flex flex-row align-items-center justify-content-between'>
                        <Form.Label>Nº. da Apólice</Form.Label>
                        <button
                            onClick={downloadApolice}
                            className='ml-2 text-decoration-none small btn btn-link d-flex align-items-start h-100 p-0 cursor-pointer mb-2'
                        >
                            {
                                gerandoApolice ? 
                                <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} /> : 
                                <small>Emitir Apólice</small>
                            }                            
                        </button>
                    </div>
                    <Form.Control
                        value={dados.numero_apolice || ''}
                        onChange={e => {
                            dados.numero_apolice = e.target.value;
                            alterarDados({ ...dados });
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Vigência da Apólice</Form.Label>
                    <InputData 
                        data={dados.data_vigencia_apolice}
                        onChange={(data) => {
                            dados.data_vigencia_apolice = data;
                            alterarDados({...dados});
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <div className='d-flex flex-row align-items-center justify-content-between'>
                        <Form.Label>Nº. do Contrato</Form.Label>
                        {(dados.id && dadosContratoAutentique) ?
                            <>
                                <button
                                    onClick={emissaoContrato}
                                    className='ml-2 text-decoration-none small btn btn-link d-flex align-items-start h-100 p-0 cursor-pointer mb-2'
                                >
                                    {
                                        gerandoContrato ? 
                                        <FontAwesomeIcon 
                                            className="icone" 
                                            pulse 
                                            icon={["fas", 'spinner']} 
                                        /> : 
                                        <small>{
                                            dadosContratoAutentique.documento_assinado_cliente === "N" ? 
                                                "Esperando assinatura do cliente" : 
                                                "Gerar novamente"
                                        }</small>
                                    }                            
                                </button>
                                <button
                                    onClick={() => {
                                        // faz o download
                                        window.open(
                                            dadosContratoAutentique.link_documento_autentique, 
                                            '_blank'
                                        );
                                    }}
                                    className='ml-2 text-decoration-none small btn btn-link d-flex align-items-start h-100 p-0 cursor-pointer mb-2'
                                >
                                    <small>Baixar contrato</small>
                                </button>    
                            </>
                            :
                            <button
                                onClick={emissaoContrato}
                                className='ml-2 text-decoration-none small btn btn-link d-flex align-items-start h-100 p-0 cursor-pointer mb-2'
                            >
                                {
                                    gerandoContrato ? 
                                    <FontAwesomeIcon 
                                        className="icone" 
                                        pulse 
                                        icon={["fas", 'spinner']} 
                                    /> : 
                                    <small>Emitir Contrato</small>
                                }                            
                            </button>
                        }
                    </div>                    
                    <Form.Control
                        value={dados.numero_contrato || ''}
                        onChange={e => {
                            dados.numero_contrato = e.target.value;
                            alterarDados({ ...dados });
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Nome do evento</Form.Label>
                    <Form.Control
                        value={dados.seguro_evento_nome || ''}
                        onChange={e => {
                            dados.seguro_evento_nome = e.target.value;
                            alterarDados({ ...dados });
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Descrição do evento</Form.Label>
                    <Form.Control
                        value={dados.seguro_evento_descricao || ''}
                        onChange={e => {
                            dados.seguro_evento_descricao = e.target.value;
                            alterarDados({ ...dados });
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Local do evento</Form.Label>
                    <Form.Control
                        value={dados.seguro_evento_local || ''}
                        onChange={e => {
                            dados.seguro_evento_local = e.target.value;
                            alterarDados({ ...dados });
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        inline
                        label="Adicional de Deslocamento"
                        type={'checkbox'}
                        checked={dados.plano_adicional_deslocamento === 'S'}
                        id={`checkbox-seguro-aventura-comprovante-participantes`}
                        onChange={(e) => {
                            const checado = e.target.checked;
                            dados.plano_adicional_deslocamento = checado ? 'S' : 'N';

                            // se estiver checado
                            if(checado) {                                
                                dados.seguro_valor_diaria = (parseFloat(dados.seguro_valor_diaria) + 0.29).toString();
                            } else {
                                dados.seguro_valor_diaria = (parseFloat(dados.seguro_valor_diaria) - 0.29).toString();
                            }

                            alterarDados({ ...dados });
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Planos</Form.Label>
                    <ComboPlanos
                        id={dados.id_plano}
                        alterou={(dadosPlano) => {          
                            
                            // altera dados do valor segundo o plano
                            dados.id_plano = dadosPlano?.id ?? null;

                            // verifica se o plano é personalizado
                            const planoPersonalizado = dadosPlano && parseInt(dados.id_plano) === 15;
                            
                            // se não for plano personalizado
                            if(dadosPlano && !planoPersonalizado) {
                                // atualiza valores
                                dados.seguro_valor_diaria = (dados.plano_adicional_deslocamento === 'S' && dadosPlano?.valor) ? (parseFloat(dadosPlano.valor) + 0.29).toString() : dadosPlano?.valor;
                                dados.seguro_valor_morte_acidental = dadosPlano?.valorMorteAcidental;
                                dados.seguro_valor_invalidez = dadosPlano?.valorInvalidez;
                                dados.seguro_valor_dmho = dadosPlano?.valorDmho;
                                dados.numero_contrato = dadosPlano?.numeroContrato;
                                dados.seguro_id_modelo_email_participante = dados.plano_adicional_deslocamento === 'S' ? dadosPlano?.idModeloEmailDeslocamento : dadosPlano?.idModeloEmail;                            
                            } else {
                                // remove deslocamento se for personalizado
                                dados.plano_adicional_deslocamento = "N";
                            }
                            
                            // atualiza dados
                            alterarDados({ ...dados });
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Modelo de email para participante </Form.Label>
                    {/* seguro_id_modelo_email_participante */}
                    <ComboModeloEmail
                        id={dados.seguro_id_modelo_email_participante}
                        tipo={3}
                        alterou={idNovo => {
                            dados.seguro_id_modelo_email_participante = idNovo;
                            alterarDados({ ...dados });
                        }}
                        desabilitado={dados.id_plano && parseInt(dados.id_plano) !== 15}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Valor</Form.Label>
                    <InputValor
                        valor={dados.seguro_valor_diaria || '0.0'}
                        onChange={valorNovo => {
                            dados.seguro_valor_diaria = valorNovo;
                            alterarDados({ ...dados });
                        }}
                        disabled={dados.id_plano && parseInt(dados.id_plano) !== 15} // somente desabilita se o plano não for o personalizado
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Valor morte acidental</Form.Label>
                    <InputValor
                        valor={dados.seguro_valor_morte_acidental || '0.0'}
                        onChange={valorNovo => {
                            dados.seguro_valor_morte_acidental = valorNovo;
                            alterarDados({ ...dados });
                        }}
                        disabled={dados.id_plano && parseInt(dados.id_plano) !== 15} // somente desabilita se o plano não for o personalizado
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Valor invalidez</Form.Label>
                    <InputValor
                        valor={dados.seguro_valor_invalidez || '0.0'}
                        onChange={valorNovo => {
                            dados.seguro_valor_invalidez = valorNovo;
                            alterarDados({ ...dados });
                        }}
                        disabled={dados.id_plano && parseInt(dados.id_plano) !== 15} // somente desabilita se o plano não for o personalizado
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Valor DMHO {/* despesas médico-hospitalares odontológicas */}</Form.Label>
                    <InputValor
                        valor={dados.seguro_valor_dmho || '0.0'}
                        onChange={valorNovo => {
                            dados.seguro_valor_dmho = valorNovo;
                            alterarDados({ ...dados });
                        }}
                        disabled={dados.id_plano && parseInt(dados.id_plano) !== 15} // somente desabilita se o plano não for o personalizado
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Check
                        inline
                        label="Enviar comprovante para os participantes?"
                        type={'checkbox'}
                        checked={dados.seguro_habilitar_comprovante_participante === 'S'}
                        id={`checkbox-seguro-aventura-comprovante-participantes`}
                        onChange={(e) => {
                            alterarDados({
                                ...dados,
                                seguro_habilitar_comprovante_participante: e.target.checked ? 'S' : 'N'
                            });
                        }}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Check
                        inline
                        label="Enviar comprovante individual para o participante?"
                        type={'checkbox'}
                        checked={dados.seguro_habilitar_comprovante_participante_individual === 'S'}
                        id={`checkbox-seguro-aventura-comprovante-participante`}
                        onChange={(e) => {
                            alterarDados({
                                ...dados,
                                seguro_habilitar_comprovante_participante_individual: e.target.checked ? 'S' : 'N'
                            });
                        }}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Check
                        inline
                        label="Permitir cadastrar cliente após evento finalizado?"
                        type={'checkbox'}
                        checked={(dados.seguro_evento_finalizado_permite_cliente_novo === 'S') ? true : false}
                        id={`checkbox-seguro-aventura-permitir-cadastrar-evento-apos-finalizado`}
                        onChange={(e) => {
                            alterarDados({
                                ...dados,
                                seguro_evento_finalizado_permite_cliente_novo: e.target.checked ? 'S' : 'N'
                            });
                        }}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Check
                        inline
                        label="Não enviar e-mails dos eventos?"
                        type={'checkbox'}
                        checked={(dados.bloquear_envio_emails_seguro_aventura === 'S') ? true : false}
                        id={`checkbox-bloquear-envio-emails-seguro-aventura`}
                        onChange={(e) => {
                            alterarDados({
                                ...dados,
                                bloquear_envio_emails_seguro_aventura: e.target.checked ? 'S' : 'N'
                            });
                        }}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Check
                        inline
                        label="Incluir Termo de Responsabilidade?"
                        type={'checkbox'}
                        checked={dados.exibir_termos === 'S'}
                        id={`checkbox-exibir-termos`}
                        onChange={(e) => {
                            alterarDados({
                                ...dados,
                                exibir_termos: e.target.checked ? 'S' : 'N'
                            });
                        }}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Check
                        inline
                        label="Dados dos participantes Obrigatório"
                        type={'checkbox'}
                        checked={dados.dados_participante_obrigatorio === 'S'}
                        id={`checkbox-dados-participante-obrigatorio`}
                        onChange={(e) => {
                            alterarDados({
                                ...dados,
                                dados_participante_obrigatorio: dados.dados_participante_obrigatorio === 'S' ? 'N' : 'S'
                            });
                        }}
                    />
                </Form.Group>

                {(dados.exibir_termos === 'S') && <Form.Group
                    className='d-flex direction-row align-items-center justify-content-start'
                >
                    <div 
                        className={['d-flex overflow-hidden align-items-center justify-content-start p-2', styles.containerArquivo].join(' ')}                                                
                    >                                            
                        <FontAwesomeIcon 
                            style={{ color: 'red' }} 
                            className="icone" 
                            icon={["fas", "file-pdf"]} 
                            size={"1x"}
                        />
                        <Form.Label 
                            className='ml-2 mb-0 text-truncate'                                            
                        >
                            Termo de responsabilidade
                        </Form.Label>
                    </div>   
                    <div>
                        <BotaoTabela 
                            carregando={subindoTermoResponsabilidade}
                            onClick={() => {
                                if(arquivoRef && arquivoRef.current) arquivoRef.current.click();
                            }}
                            icone={["fas", "upload"]}
                            tamanhoIcone={22}
                            color={'#000'}
                            classe={styles.botaoArquivo}
                            estiloBotao={{
                                height: 40,
                                border: '1px solid #284787',
                                padding: '0.5rem 10px'
                            }}
                        >                                            
                            Fazer o upload do Termo de Responsabilidade
                        </BotaoTabela>
                        <Form.File 
                            className='d-none' 
                            ref={arquivoRef} 
                            accept="application/pdf"
                            onChange={realizarUploadTermo}
                        />                                                                                        
                    </div>
                    <BotaoTabela 
                        carregando={baixandoTermoResponsabilidade}
                        onClick={realizarDownloadTermo}
                        tamanhoIcone={22}
                        color={'#000'}
                        icone={["fas", "download"]}
                        classe={styles.botaoArquivo}
                        estiloBotao={{
                            height: 40,
                            border: '1px solid #284787',
                            padding: '0.5rem 10px',
                            borderLeftWidth: 0,
                            borderTopRightRadius: 5,
                            borderBottomRightRadius: 5
                        }}
                    >
                        Fazer o download do Termo de Responsabilidade
                    </BotaoTabela>
                </Form.Group>}

            </Card.Body>
        </Card>

        <Card 
            className='border-0 mt-4 shadow-sm'
        >
            <Card.Body
                className='pl-4'
            >
                <Form.Group>
                    <Form.Label
                        className='font-weight-bold text-left fs-3'
                        style={{
                            fontSize: 24,
                            marginLeft: -10
                        }}
                    >
                        Links
                    </Form.Label>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Link XLS</Form.Label>
                    <Form.Control
                        value={dados.seguro_link_xls || ""}
                        onChange={e => {
                            dados.seguro_link_xls = e.target.value;
                            if (dados.seguro_link_xls === "") {
                                dados.seguro_link_xls = null;
                            }
                            alterarDados({ ...dados });
                        }}
                    />
                </Form.Group>

                {(dados.seguro_link_xls !== null && dados.seguro_link_xls_campos !== null) &&
                    <>
                        <Form.Group>
                            <Form.Row>
                                <Form.Label as={Col} lg={9}>Linha</Form.Label>
                                <Col lg={3}>
                                    <Form.Control
                                        placeholder='2'
                                        value={camposJson.linha || ""}
                                        onChange={e => {
                                            let linha = isNaN(parseInt(e.target.value)) ? null : parseInt(e.target.value);
                                            camposJson.linha = linha;
                                            alterarCamposJson({ ...camposJson });
                                        }}
                                        size={'sm'}
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>

                        <Form.Group>
                            <Form.Row>
                                <Form.Label as={Col} lg={9}>Nome</Form.Label>
                                <Col lg={3}>
                                    <Form.Control
                                        placeholder='ABC'
                                        value={camposJson.nome}
                                        onChange={e => {
                                            camposJson.nome = e.target.value;
                                            alterarCamposJson({ ...camposJson });
                                        }}
                                        size={'sm'}
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>

                        <Form.Group>
                            <Form.Row>
                                <Form.Label as={Col} lg={9}>Cpf/Cnpj</Form.Label>
                                <Col lg={3}>
                                    <Form.Control
                                        placeholder='ABC'
                                        value={camposJson.cpf}
                                        onChange={e => {
                                            camposJson.cpf = e.target.value;
                                            alterarCamposJson({ ...camposJson });
                                        }}
                                        size={'sm'}
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>

                        <Form.Group>
                            <Form.Row>
                                <Form.Label as={Col} lg={9}>Email</Form.Label>
                                <Col lg={3}>
                                    <Form.Control
                                        placeholder='ABC'
                                        value={camposJson.email}
                                        onChange={e => {
                                            camposJson.email = e.target.value;
                                            alterarCamposJson({ ...camposJson });
                                        }}
                                        size={'sm'}
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>

                        <Form.Group>
                            <Form.Row>
                                <Form.Label as={Col} lg={9}>Data Nascimento</Form.Label>
                                <Col lg={3}>
                                    <Form.Control
                                        placeholder='ABC'
                                        value={camposJson.dataNascimento}
                                        onChange={e => {
                                            camposJson.dataNascimento = e.target.value;
                                            alterarCamposJson({ ...camposJson });
                                        }}
                                        size={'sm'}
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>

                        <Form.Group>
                            <Form.Row>
                                <Form.Label as={Col} lg={9}>Data Inicio</Form.Label>
                                <Col lg={3}>
                                    <Form.Control
                                        placeholder='ABC'
                                        value={camposJson.dataInicio}
                                        onChange={e => {
                                            camposJson.dataInicio = e.target.value;
                                            alterarCamposJson({ ...camposJson });
                                        }}
                                        size={'sm'}
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>

                        <Form.Group>
                            <Form.Row>
                                <Form.Label as={Col} lg={9}>Data Final</Form.Label>
                                <Col lg={3}>
                                    <Form.Control
                                        placeholder='ABC'
                                        value={camposJson.dataFinal}
                                        onChange={e => {
                                            camposJson.dataFinal = e.target.value;
                                            alterarCamposJson({ ...camposJson });
                                        }}
                                        size={'sm'}
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>

                        <Form.Group>
                            <Form.Row>
                                <Form.Label as={Col} lg={9}>Genero</Form.Label>
                                <Col lg={3}>
                                    <Form.Control
                                        placeholder='ABC'
                                        value={camposJson.genero}
                                        onChange={e => {
                                            camposJson.genero = e.target.value;
                                            alterarCamposJson({ ...camposJson });
                                        }}
                                        size={'sm'}
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>

                        <Form.Group>
                            <Form.Row>
                                <Form.Label as={Col} lg={9}>Telefone</Form.Label>
                                <Col lg={3}>
                                    <Form.Control
                                        placeholder='ABC'
                                        value={camposJson.telefone}
                                        onChange={e => {
                                            camposJson.telefone = e.target.value;
                                            alterarCamposJson({ ...camposJson });
                                        }}
                                        size={'sm'}
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>

                    </>
                }

                {/* verifica e mostra link propriedade */}
                {(mostrarLinkCliente && dados.seguro_aventura_token_checkin !== null) && <>
                    
                    <Form.Group>
                        <Form.Label>Link do Cliente Propriedade</Form.Label>
                        <Form.Row
                            className='ml-0 mr-0 justify-content-between d-flex'
                        >
                            <Col lg={10} className='p-0 d-flex'>
                                <Form.Control
                                    value={linkPropriedade}
                                    disabled={true}
                                    ref={refLinkPropriedade}
                                />
                            </Col>
                            <Col lg={2} className='d-inline-flex align-items-start justify-content-end pr-0 pb-1'>
                                <Button
                                    variant='padrao'
                                    onClick={() => copiarParaTeclado(refLinkPropriedade.current.value)}
                                >
                                    <FontAwesomeIcon icon={["fas", 'copy']} />
                                </Button>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Token do Cliente Propriedade</Form.Label>
                        <Form.Control
                            value={dados.seguro_aventura_token_checkin ? dados.seguro_aventura_token_checkin : ''}
                            placeholder='Token seguro aventura propriedade'
                            onChange={e => {
                                alterarDados({
                                    ...dados,
                                    seguro_aventura_token_checkin: e.target.value
                                });
                            }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Link com diárias</Form.Label>
                        <Form.Row 
                            className='ml-0 mr-0 justify-content-between d-flex'
                        >
                            <Col lg={10} className='p-0 d-flex'>
                                <Form.Control
                                    value={numeroDiariasInput ?? ''}                                
                                    placeholder={'Número de diárias'}
                                    type={'number'}
                                    onChange={(e) => alterarNumeroDiariasInput(e.target.value)}
                                />                                
                            </Col>
                            <Col lg={2} className='d-inline-flex align-items-start justify-content-end pr-0 pb-1'>
                                <Button
                                    variant='padrao'
                                    onClick={() => {
                                        alterarNumeroDiariasInput(null);
                                        gerarTokenDiarias(parseInt(numeroDiariasInput))
                                    }}
                                    disabled={carregamentoToken}
                                >
                                    { carregamentoToken ?
                                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} /> :
                                        <FontAwesomeIcon icon={["fas", 'plus']} />
                                    }                                    
                                </Button>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                    {diariasCheckin.length > 0 && diariasCheckin.map((diariaCheckin, index) => {

                        const linkPropriedadeDiarias = linkPropriedade + `/diarias/${diariaCheckin.numeroDiariasToken}`;

                        return <Form.Group key={index}>
                            <Form.Label>Número de diárias: {diariaCheckin.numeroDiarias}</Form.Label>
                            <Form.Row 
                                className='ml-0 mr-0 justify-content-between d-flex'
                            >
                                <Col lg={10} className='p-0 d-flex'>
                                    <Form.Control
                                        value={linkPropriedadeDiarias}                                
                                        disabled                                    
                                    />                                
                                </Col>
                                <Col lg={2} className='d-inline-flex align-items-start justify-content-end pr-0 pb-1'>
                                    <Button
                                        variant='padrao'
                                        onClick={() => copiarParaTeclado(linkPropriedadeDiarias)}
                                    >
                                        <FontAwesomeIcon icon={["fas", 'copy']} />
                                    </Button>
                                </Col>
                            </Form.Row>
                        </Form.Group>
                    })}
                    
                </>}

            </Card.Body>
        </Card>
    </>
}