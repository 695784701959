import React, { useState, useEffect } from 'react';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row, Button } from 'react-bootstrap';
import Carregador from './../../Componentes/Carregador';
import PaginacaoComponente from './../../Componentes/Paginacao';
import Registro from './Registro';
import Parametros from './Parametros';
import ModalExcluir from './ModalExcluir';

// componente
function Consultar(){

    // reducers
    let loginReduder = useSelector(state => state.login);
    let history = useHistory();

    // estados
    let [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10,
        texto: '',
        ativo: ['S']
    });
    let [totalRegistros, atualizarTotalRegistros] = useState(0);
    let [carregando, mostrarCarregando] = useState(false);
    let [consulta, alterarConsulta] = useState([]);
    let [excluirRegistro, alterarExcluirRegistro] = useState(null);

    // inicia
    useEffect(() => {

        carregarRegistros();

    }, [parametros]);

    function carregarRegistros(){

        // carregando
        mostrarCarregando(true);

        // faz a requisição
        axios.get(`/modelosEmail`, {
            params: parametros
        })
        .then(({data, ...retorno}) => {
            alterarConsulta(data.registros);
            atualizarTotalRegistros(parseInt(data.totalRegistros));
        })
        .catch(() => {})
        .then(() => {
            mostrarCarregando(false);
        });
    }

    return <>
        { excluirRegistro && 
            <ModalExcluir 
                registro={excluirRegistro}
                excluido={() => {
                    carregarRegistros();
                }}
                fechou={() => {
                    alterarExcluirRegistro(null);
                }}
            />
        }
        <Row>
            <Col>
                <Card className="painel-consulta border-0 shadow-sm" >
                    <div className="card-header">
                        <h4 className="card-title font-weight-bolder">Modelos de Email</h4>
                        <Parametros parametros={parametros} alterarParametros={alterarParametros} />
                    </div>
                    
                    <Card.Body>
                        
                        <table className="table table-hover tabela">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Descrição</th>
                                    <th>Titulo</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {consulta.map((registro, a) => 
                                    <Registro 
                                        key={registro.id}
                                        dados={registro}
                                        clicouEditar={() => {
                                            history.push(`/painel/modelos-email/alterar/${registro.id}`);
                                        }}
                                        clicouExcluir={() => {
                                            console.log(registro);
                                            alterarExcluirRegistro(registro);
                                        }}
                                    />
                                )}
                            </tbody>
                        </table>
                        <PaginacaoComponente
                            pagina={parametros.pagina}
                            registrosPorPagina={parametros.registrosPorPagina}
                            totalRegistros={totalRegistros}
                            alterouPagina={(pagina) => {
                                alterarParametros({...parametros, pagina: pagina});
                            }}
                        />
                        {carregando && 
                            <Carregador className="rounded-bottom" />
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
}

// retorna página de consulta
export default Consultar;
