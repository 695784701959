import React from 'react';
import rocaFundo from './../imagensRoca/linkSeguroFundo.jpg';

// componentes
import { Switch, Route } from 'react-router-dom';
import Cabecalho from './Cabecalho';
import Opcoes from './Opcoes';
import Apolice from './Apolice';
import Fianca from './Fianca';
import Pagamento from './Pagamento';

// atendimento ao cliente
function Atendimento({

}){


    return <div style={{backgroundImage: `url(${rocaFundo})`, backgroundSize: 'cover', minHeight: '100vh', backgroundPosition: 'top right'}}>
        <Cabecalho />

        <Switch>

            <Route exact path='/meuseguro/pagamento' component={Pagamento} />
            <Route exact path='/meuseguro' component={Opcoes} />
            <Route exact path='/meuseguro/apolice' component={Apolice} />
            <Route exact path='/meuseguro/apolice/:codigo' component={Apolice} />
            {/* <Route exact path='/meuseguro/fianca-cliente' component={Fianca} />
            <Route exact path='/meuseguro/fianca-imobiliaria' render={(propsRoute => <Fianca {...propsRoute} modo='imobiliaria' />)} /> */}


            {/* <Route exact path='/meuseguro/proposta-seguro-fianca' component={Apolice} /> */}
            {/* <Route exact path='/meuseguro/proposta-seguro-imobiliaria' component={Apolice} /> */}

        </Switch>
    </div>

}

export default Atendimento;