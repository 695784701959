import React from 'react';

// componentes
import Select from 'react-select';
import { Form, Row, Col } from 'react-bootstrap';
import ComboResponsavelConsulta from './../../../Componentes/ComboResponsavelConsulta';
import InputDataCombo from './../../../Componentes/InputDataCombo';
import moment from 'moment';

// componente
export default function ParametrosConsulta({
    parametros,
    alterarParametros
}){

    let funcoesDisponiveis = [
        {value: 'clientes/consultarId', label: 'Consultou cliente'},
        {value: 'relatorioGeral/funil', label: 'Consultou funil'},
        {value: 'relatorioGeral/emissoes', label: 'Relatório, consultou emissões'},
        {value: 'eventos/consultar', label: 'Consultou eventos'},
        {value: 'arquivos/consultar', label: 'Consultou arquivos'},
        {value: 'clientesFichas/consultar', label: 'Consultou fichas de atendimento'},
        {value: 'clientesFichas/consultarId', label: 'Abriu cadastro de ficha de atendimento'},
        {value: 'relatorioGeral/funilXls', label: 'Exportou relatório para excel'},
        {value: 'clientes/consultarApoliceClienteExterno', label: 'Consulta de apólices externo'},
        {value: 'clientes/salvar', label: 'Cadastrou cliente'},
        {value: 'clientes/foto', label: 'Salvou foto de cliente'},
        {value: 'clientesFichas/salvar', label: 'Cadastrou ficha de atendimento'},
        {value: 'arquivos/salvar', label: 'Salvou arquivo'},
        {value: 'eventos/concluir', label: 'Concluiu evento'},
        {value: 'relatorioGeral/propostas', label: 'Relatório, consultou proporstas'},
        {value: 'relatorioGeral/emissoesCanceladas', label: 'Relatório, consultou emissões canceladas'},
        {value: 'arquivos/excluir', label: 'Excluiu arquivo'},
        {value: 'relatorioGeral/cotacoes', label: 'Relatório: consultou as cotações'},
        {value: 'modelosEmail/consultar', label: 'Consultou modelos de email'},
        {value: 'imagensEmails/consultarId', label: 'Consultou imagens de email'},
        {value: 'emailsEnviado/enviar', label: 'Enviou um email'},
        {value: 'emailsEnviado/consultar', label: 'Consultou os emails enviados'},
        {value: 'solicitacaoPagamento/salvar', label: 'Salvou uma solicitação de pagamento'},
        {value: 'solicitacaoPagamento/consultar', label: 'Consultou as solicitações de pagamento'},
        {value: 'solicitacaoPagamento/consultarId', label: 'Abriu cadastro de pagamento'},
        {value: 'eventos/consultarId', label: 'Abriu cadastro de evento'},
        {value: 'eventos/salvar', label: 'Salvou evento'},
        {value: 'relatorioGeral/renovacoes', label: 'Relatório, consultou as renovações'},
        {value: 'clientes/solicitarAcessoApolice', label: 'Cliente solicitou acesso a apolices'},
        {value: 'modelosSms/consultar', label: 'Consultou modelos de sms'},
        {value: 'smsEnviado/enviar', label: 'Enviou sms'},
        {value: 'grupos/consultar', label: 'Consultou os grupos'}
    ];


    return <>
        <Row>
            <Col lg={6}>
                <Form.Group>
                    <Form.Label>A partir de</Form.Label>
                    <InputDataCombo 
                        data={parametros.data_inicio}
                        onChange={(data) => {
                            parametros.data_inicio = (data === null) ? moment().format('YYYY-MM-DD') : data;
                            alterarParametros({...parametros});
                        }}
                    />
                </Form.Group>
            </Col>
            <Col lg={6}>
                <Form.Group>
                    <Form.Label>Até</Form.Label>
                    <InputDataCombo 
                        data={parametros.data_fim}
                        onChange={(data) => {
                            parametros.data_fim = (data === null) ? moment().format('YYYY-MM-DD') : data;
                            alterarParametros({...parametros});
                        }}
                    />
                </Form.Group>
            </Col>
        </Row>
        
        <Form.Group>
            <Form.Label>Eventos</Form.Label>
            <Select
                value={funcoesDisponiveis.filter(funcaoEvento => {
                    if(parametros.funcao.includes(funcaoEvento.value)){
                        return true;
                    }
                    return false;
                })}
                options={funcoesDisponiveis}
                isMulti={true}
                placeholder='Funções'
                onChange={(e) => {
                    e = e || []; // mantem vazio se for null

                    // altera os filtros selecionados
                    parametros.funcao = e.map((option) => option.value);
                    alterarParametros({...parametros});
                }}
            />
        </Form.Group>

        <Form.Group>
            <Form.Label>Usuários</Form.Label>
            <ComboResponsavelConsulta 
                value={parametros.id_usuario}
                onChange={responsaveisArray => {
                    parametros.id_usuario = responsaveisArray;
                    alterarParametros({...parametros});
                }}
                placeholder={'Usuários'}
            />
        </Form.Group>
    
    </>
}