import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Container, Button, Form } from 'react-bootstrap';

// componente
export default function GerarRelatorio({
    idCliente,
    fechou
}){

    // reducers
    let loginReducer = useSelector(state => state.login);
    let configuracoesSistema = useSelector(state => state.painel.configuracoesSistema);

    // estados
    let [mostrar, alterarMostrar] = useState(true);
    let [gerando, alterarGerando] = useState(false);
    let [filtros, alterarFiltros] = useState({
        ativo: ['S'],
        id_cliente: idCliente,
        data_inicio: null,
        data_final: null,
        tipo: [1,2,3,4,5],
        habilitar_observacoes: 'S',
        habilitar_eventos_concluidos_ficha: ['N', 'S'],
        habilitar_eventos_concluidos_cliente: ['N', 'S']
    });

    // inicializa
    useEffect(() => {
        console.log(loginReducer);
        console.log(configuracoesSistema);
    }, []);

    // gerar relatorio
    async function baixarRelatorio(){
        alterarGerando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/fichas/relatorio`, {
                idCliente: idCliente,
                consulta: filtros
            });

            // faz o download
            window.open(
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=temp&f=${encodeURIComponent(data.arquivo)}&n=${encodeURIComponent(data.nome)}`, 
                '_blank'
            );

            // finaliza
            alterarGerando(false);


        }catch({response}){}

    }

    return <Modal 
        show={mostrar} 
        onExited={fechou}
        onHide={() => {}}
        size={'md'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title>
                Gerar relatório
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                { [
                    {id: 1, label: 'Direito'},
                    {id: 2, label: 'Seguro'},
                    {id: 3, label: 'Consultoria'},
                    {id: 4, label: 'Avaliação'},
                    {id: 5, label: 'Imóveis'}
                ].map((tipo, i) => 
                    <div key={i} className="mb-3">
                        <Form.Check type={'checkbox'} id={`checkbox-tipo-${tipo.id}`}>
                            <Form.Check.Input 
                                checked={filtros.tipo.includes(tipo.id)} 
                                onChange={e => {
                                    if(e.target.checked){
                                        filtros.tipo.push(tipo.id);
                                    }else{
                                        filtros.tipo.splice(filtros.tipo.indexOf(tipo.id), 1);
                                    }
                                    alterarFiltros({...filtros});
                                }}
                            />
                            <Form.Check.Label>{tipo.label}</Form.Check.Label>
                        </Form.Check>
                    </div>
                )}


                {/*  */}
                <div className="mb-3">
                    <Form.Check type={'checkbox'} id={`checkbox-eventos-ficha`}>
                        <Form.Check.Input 
                            checked={filtros.habilitar_eventos_concluidos_ficha.includes('S')} 
                            onChange={e => {
                                if(e.target.checked){
                                    filtros.habilitar_eventos_concluidos_ficha.push('S');
                                }else{
                                    filtros.habilitar_eventos_concluidos_ficha.splice(filtros.habilitar_eventos_concluidos_ficha.indexOf('S'), 1);
                                }
                                alterarFiltros({...filtros});
                            }}
                        />
                        <Form.Check.Label>Mostrar eventos da ficha concluidos!</Form.Check.Label>
                    </Form.Check>
                </div>
                <div className="mb-3">
                    <Form.Check type={'checkbox'} id={`checkbox-eventos-ficha-nao`}>
                        <Form.Check.Input 
                            checked={filtros.habilitar_eventos_concluidos_ficha.includes('N')} 
                            onChange={e => {
                                if(e.target.checked){
                                    filtros.habilitar_eventos_concluidos_ficha.push('N');
                                }else{
                                    filtros.habilitar_eventos_concluidos_ficha.splice(filtros.habilitar_eventos_concluidos_ficha.indexOf('N'), 1);
                                }
                                alterarFiltros({...filtros});
                            }}
                        />
                        <Form.Check.Label>Mostrar eventos da ficha não concluidos!</Form.Check.Label>
                    </Form.Check>
                </div>
                
                <div className="mb-3">
                    <Form.Check type={'checkbox'} id={`checkbox-eventos-cliente`}>
                        <Form.Check.Input 
                            checked={filtros.habilitar_eventos_concluidos_cliente.includes('S')} 
                            onChange={e => {
                                if(e.target.checked){
                                    filtros.habilitar_eventos_concluidos_cliente.push('S');
                                }else{
                                    filtros.habilitar_eventos_concluidos_cliente.splice(filtros.habilitar_eventos_concluidos_cliente.indexOf('S'), 1);
                                }
                                alterarFiltros({...filtros});
                            }}
                        />
                        <Form.Check.Label>Mostrar eventos do cliente concluidos!</Form.Check.Label>
                    </Form.Check>
                </div>
                <div className="mb-3">
                    <Form.Check type={'checkbox'} id={`checkbox-eventos-cliente-nao`}>
                        <Form.Check.Input 
                            checked={filtros.habilitar_eventos_concluidos_cliente.includes('N')} 
                            onChange={e => {
                                if(e.target.checked){
                                    filtros.habilitar_eventos_concluidos_cliente.push('N');
                                }else{
                                    filtros.habilitar_eventos_concluidos_cliente.splice(filtros.habilitar_eventos_concluidos_cliente.indexOf('N'), 1);
                                }
                                alterarFiltros({...filtros});
                            }}
                        />
                        <Form.Check.Label>Mostrar eventos do cliente não concluidos!</Form.Check.Label>
                    </Form.Check>
                </div>


                
            </Container>
        </Modal.Body>
        <Modal.Footer  className='border-0'>
            <Button
                variant='danger'
                onClick={() => {alterarMostrar(false)}}
                disabled={gerando}
            >
                <span>Fechar</span>
            </Button>
            <Button
                onClick={baixarRelatorio}
                variant='success'
                disabled={gerando}
            >
                {gerando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Gerando</span>
                    </> : 
                    <>
                        <FontAwesomeIcon className="icone" icon={["fas", 'file-download']} />
                        <span>Gerar relatório</span>
                    </>
                }
            </Button>
        </Modal.Footer>
    </Modal>

}
