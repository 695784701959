import React from 'react';
import moment from 'moment';
import * as R from 'ramda';
import uniqid from 'uniqid';

// componentes
import { ParcelasComponent, ParcelaComponent } from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import InputDataCombo from './../../../../Componentes/InputDataCombo';
import InputValor from './../../../../Componentes/InputValor';
import ComboModeloSms from './../../../../Componentes/ComboModeloSms';

// componente
export default function Parcelas({
    modo="emissao",
    children
}){

    return <>
        <ParcelasComponent>
            <table className="table table-hover tabela mb-0">
                <thead>
                    <tr>
                        <th>Nº</th>
                        <th>Vencimento</th>
                        <th>Valor</th>
                        <th>Encargos</th>
                        <th>Total</th>
                        { modo === 'emissao' &&
                            <th>PG</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        </ParcelasComponent>
    </>

}

// parcela
export function Parcela({
    dados,
    alterarDados,
    statusSms=null,
    modo="emissao",
    alterouVencimento
}){

    let somatorio = parseFloat(dados.valor) + parseFloat(dados.valor_juros);

    // titulo do status do sms
    let tituloStatusSms = 'Sem agendamento';
    if(modo === 'emissao' && statusSms){
        
        switch(statusSms.status){
            case 2: tituloStatusSms = 'Agendado' ; break;
            case 3: tituloStatusSms = 'Enviado' ; break;
            case 4: tituloStatusSms = 'Cancelado' ; break;
            case 5: tituloStatusSms = 'Erro' ; break;
        }
    }

    return <>
        <ParcelaComponent
            alterado={dados.alterado_manualmente === 'S' ? true : false}
        >
            <td className='numero estreito text-center font-weight-bold'>
                {dados.numero}
            </td>
            <td>
                <InputDataCombo 
                    data={dados.vencimento}
                    onChange={novaData => {
                        dados.vencimento = novaData;
                        alterarDados(dados);
                        alterouVencimento();
                    }}
                    className='data border-0'
                    size="sm"
                />
            </td>
            <td>
                <InputValor 
                    valor={dados.valor}
                    onChange={valorNovo => {
                        if(dados.valor !== valorNovo){
                            dados.alterado_manualmente = 'S';
                        }
                        dados.valor = valorNovo;
                        alterarDados({...dados});
                    }}
                    onkeyDown={(e) => {
                        if(e.keyCode === 27){
                            dados.alterado_manualmente = 'N';
                            alterarDados({...dados});
                        }
                    }}
                    className='valor border-0'
                    size="sm"
                />
            </td>
            <td>
                <InputValor 
                    valor={dados.valor_juros}
                    onChange={valorNovo => {
                        if(dados.valor_juros !== valorNovo){
                            dados.alterado_manualmente = 'S';
                        }
                        dados.valor_juros = valorNovo;
                        alterarDados({...dados});
                    }}
                    onkeyDown={(e) => {
                        if(e.keyCode === 27){
                            dados.alterado_manualmente = 'N';
                            alterarDados({...dados});
                        }
                    }}
                    className='valor border-0'
                    size="sm"
                />
            </td>
            <td>
                <InputValor 
                    valor={somatorio.toString()}
                    disabled={true}
                    className='valor-total border-0'
                    size="sm"
                />
            </td>

            { modo === 'emissao' && 
                <td className='pago estreito text-left text-nowrap'>
                    <Form.Check
                        className='input-check'
                        inline
                        checked={(dados.pago === 'S')? true : false}
                        id={`checkbox-pago`}
                        onChange={(e) => {
                            dados.data_pago = null;
                            dados.pago = (e.target.checked) ? 'S' : 'N';
                            if(e.target.checked){
                                dados.data_pago = moment().format('YYYY-MM-DD');
                            }
                            alterarDados({...dados});
                        }}
                    />

                    { (modo === 'emissao' && statusSms) &&
                        <>
                            <FontAwesomeIcon 
                                className={[
                                    "icone ml-2 sms-status",
                                    statusSms.status === 1 ? 'text-light' : '',
                                    statusSms.status === 2 ? 'text-primary' : '',
                                    statusSms.status === 3 ? 'text-success' : '',
                                    statusSms.status === 4 ? 'text-danger' : '',
                                    statusSms.status === 5 ? 'text-danger' : '',
                                ].join(' ')} 
                                icon={["fas", 'sms']} 
                                title={tituloStatusSms}
                            />
                        </>
                    }
                </td>
            }
        </ParcelaComponent>
    </>

}

// agendamento de sms
export function AgendarSms({
    dados,
    alterarDados
}){

    return <>
        <div className={'text-right mt-2'}>
            <Form.Check 
                type="switch"
                id="switch-agendar-sms-parcelas"
                label={<div>
                    <span>Agendar sms</span>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>
                            Agenda sms para todas as parcelas que não possuem nenhum sms agendado!
                        </Tooltip>}
                    >
                        <FontAwesomeIcon className="icone ml-1 text-secondary" icon={["far", 'question-circle']} />
                    </OverlayTrigger>
                    
                </div>}
                checked={dados.agendar === 'S' ? true : false}
                onChange={e => {
                    dados.idSmsModelo = null;
                    dados.agendar = e.target.checked ? 'S' : 'N';
                    alterarDados({...dados});
                }}
                
            />
        </div>
        { dados.agendar == 'S' &&
            <div className={'mt-2'}>
                <Row>
                    <Col lg={{span: 6, offset: 6}}>
                        <ComboModeloSms 
                            alterou={idSmsModelo => {
                                dados.idSmsModelo = idSmsModelo;
                                alterarDados({...dados});
                            }}
                            tipo={['2']}
                        />
                    </Col>
                </Row>
            </div>
        }
    </>
}

// ajusta o limite de parcelas
export function ajustarLimiteParcelas(modo = 'emissao', parcelasRegistros, qtd, valorBruto, dadosCustos){

    // retorna as parcelas novas
    let novoArrayParcelas = R.clone(parcelasRegistros);
    let qtdAtual = novoArrayParcelas.length;
    if(qtd > qtdAtual){
        for(let a = qtdAtual; a < qtd; a++){
            novoArrayParcelas.push(novaParcela(modo, novoArrayParcelas));
        }
    }else if(qtd < qtdAtual){
        novoArrayParcelas.splice(qtd);
    }
    recalcularValorParcelas(modo, novoArrayParcelas, valorBruto, dadosCustos);

    // retorna o novo array
    return novoArrayParcelas;

}

// ajusta valor das parcelas
export function ajustarValorParcelas(modo = 'emissao', parcelasRegistros, valorBruto, dadosCustos){

    let novoArrayParcelas = R.clone(parcelasRegistros);
    recalcularValorParcelas(modo, novoArrayParcelas, valorBruto, dadosCustos);
    return novoArrayParcelas;

}

// ajusta vencimento das parcelas a partir da parcela selecionada
export function ajustarVencimentoParcelas(modo = 'emissao', parcelasRegistros, aPartirParcela){

    // retorna novo array
    let novoArrayParcelas = R.clone(parcelasRegistros);
    let parcelaEncontrada = false;
    let parcelaAnterior = null;
    parcelasRegistros.map((parcela, pos) => {

        if(parcelaEncontrada){
            let novaData = moment(parcelaAnterior.dados.vencimento);
            novaData.add(1, 'month');
            novoArrayParcelas[pos].dados.vencimento = novaData.format('YYYY-MM-DD');
        }

        // se encontrou a parcela
        if(parcela === aPartirParcela){
            parcelaEncontrada = true
        }

        // grava parcela anterior
        parcelaAnterior = novoArrayParcelas[pos];

    });

    // retorna novo array
    return novoArrayParcelas;

}

// calcula a comsissão
export function calcularComissao(modo = 'emissao', valorBruto, dadosCustos){
    let comissao = parseFloat(dadosCustos.comissao);
    return ((comissao / 100) * calcularValorLiquido(modo, valorBruto, dadosCustos));
}

// calcula o valor liquido
export function calcularValorLiquido(modo = 'emissao', valorBruto, dadosCustos){
    let valorBrutoP = parseFloat(valorBruto);
    let valorImpostos = parseFloat(dadosCustos.valor_impostos);
    let valorJuros = parseFloat(dadosCustos.juros);
    let valorAdicionalFracionamento = parseFloat(dadosCustos.adicional_fracionamento);

    // retorna total liquido
    return valorBrutoP - valorImpostos - valorJuros - valorAdicionalFracionamento;
}

// cria registro de parcela nova
function novaParcela(modo, novoArrayParcelas, dadosParcela = {}){

    // pega o vencimento da última parcela e incrementa mais 1 mês
    let vencimento = moment();
    if(novoArrayParcelas.length > 0){
        let ultimaParcela = novoArrayParcelas[novoArrayParcelas.length-1];
        vencimento = moment(ultimaParcela.dados.vencimento).add(1, 'month');
    }

    // parcela nova
    let parcelaNova = {
        dados: {
            id: null,
            // id_cliente_ficha_proposta: dadosProposta.id,
            numero: novoArrayParcelas.length + 1,
            valor: '0.00',
            vencimento: vencimento.format('YYYY-MM-DD'),
            valor_juros: '0.00',
            alterado_manualmente: 'N',
            ...dadosParcela
        },
        temp_id: uniqid()
    };

    // se for emissão
    if(modo === 'emissao'){
        parcelaNova.dados.pago = 'N';
        parcelaNova.dados.data_pago = null;
    }

    // retorna
    return parcelaNova;

}

// recalcula o valor das parcelas, baseado no valor bruto
function recalcularValorParcelas(modo, novoArrayParcelas, valorBruto, dadosCustos){

    // parcelas para alteracao
    let parcelasAlterar = novoArrayParcelas.filter(parcela => {
        if(modo === 'emissao'){

            // se não foi pago, e nem alterado manualmente, automatiza
            if(parcela.dados.alterado_manualmente === 'N' && parcela.dados.pago === 'N'){
                return parcela;
            }

        }else if(parcela.dados.alterado_manualmente === 'N'){
            return parcela;
        }
        return false;
    });

    // pega valor bruto
    let valorBrutoP = parseFloat(valorBruto);
    let valorImpostos = parseFloat(dadosCustos.valor_impostos);
    let valorJuros = parseFloat(dadosCustos.juros);
    let valorAdicionalFracionamento = parseFloat(dadosCustos.adicional_fracionamento);
    let totalEncargos = (valorImpostos + valorJuros + valorAdicionalFracionamento);

    // reajusta valores
    novoArrayParcelas.map(parcela => {
        if(parcela.dados.alterado_manualmente === 'S' || parcela.dados.pago === 'S'){
            valorBrutoP -= parseFloat(parcela.dados.valor);
            totalEncargos -= parseFloat(parcela.dados.valor_juros);
        }
    });

    // calculos
    let valorParcela = valorBrutoP / parcelasAlterar.length;
    let encargos = totalEncargos / parcelasAlterar.length;
    
    // distribui o valor nas parcelas
    let totalParcelas = 0.0;
    let totalParcelasJuros = 0.0;
    parcelasAlterar.map((parcela, a) => {

        // aplica valores
        parcela.dados.valor = valorParcela.toFixed(2);
        parcela.dados.valor_juros = encargos.toFixed(2);
        totalParcelas += parseFloat(parcela.dados.valor);
        totalParcelasJuros += parseFloat(parcela.dados.valor_juros);

        // ajusta o centavo da última parcela
        if((a + 1) === parcelasAlterar.length){
            valorParcela = parseFloat(parcela.dados.valor) + (parseFloat(valorBrutoP.toFixed(2)) - parseFloat(totalParcelas.toFixed(2)));
            encargos = parseFloat(parcela.dados.valor_juros) + (parseFloat(totalEncargos.toFixed(2)) - parseFloat(totalParcelasJuros.toFixed(2)));
            parcela.dados.valor = valorParcela.toFixed(2);
            parcela.dados.valor_juros = encargos.toFixed(2);
        }
    });

    // retorna novo array
    return novoArrayParcelas;

}
