import React, { useState, useContext, useRef } from 'react';
import { Button, Card } from 'react-bootstrap';
import styles from './index.module.scss';

// contextos
import { ClientesMapaContexto } from './../../ClientesMapa';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ComboCliente from './../../Componentes/ComboCliente';
import ComboGruposConsulta from './../../Componentes/ComboGruposConsulta';
import Estados from '../../Clientes/Cadastrar/Estados';

function ClienteBuscar({

}){

    // dados do mapa
    let mapaDados = useContext(ClientesMapaContexto);
    
    // estados
    const [mostrarFiltros, alterarMostrarFiltros] = useState(false);

    const refSelect = useRef(null);
    
    return <Card
        className={[styles.buscar, 'border-0'].join(' ')}
    >
        <Card.Body
            className={[styles.corpo, 'p-1'].join(' ')}
        >

            {/* filtros extras */}
            <div className='d-flex flex-align-center'>
                <Button
                    variant='padrao'
                    className={[styles.botaoFiltro, 'mr-2'].join(' ')}
                    onClick={() => {
                        alterarMostrarFiltros(!mostrarFiltros);
                    }}
                >
                    <FontAwesomeIcon className="icone fa-lg" icon={["fas", mostrarFiltros ? "search-minus" : "search-plus"]} />
                </Button>
                
                {/* <input /> */}
                <div className={styles.combo}>
                    <ComboCliente 
                        id={mapaDados.cliente}
                        filtrosExtras={mapaDados.filtros}
                        alterou={(clienteNovo) => {
                            mapaDados.alterarCliente(clienteNovo);
                        }}
                    />
                </div>
            </div>

            <div hidden={!mostrarFiltros}>
                <div className='mt-2 d-flex flex-1 flex-column justify-content-between'>
                    <ComboGruposConsulta 
                        ids={mapaDados.filtros.grupos}
                        alterou={(gruposArray) => {
                            mapaDados.filtros.grupos = gruposArray;
                            mapaDados.alterarFiltros({...mapaDados.filtros});
                        }}
                    />
                    <div 
                        className='d-flex flex-row justify-content-between align-items-center flex-1'
                    >
                        <Estados 
                            value={mapaDados.filtros.estado} 
                            resgatarRef={(ref) => refSelect.current = ref}
                            size={'md'}                        
                            onChange={(e) => {
                                mapaDados.filtros.estado = e.target.value;
                                mapaDados.alterarFiltros({...mapaDados.filtros});
                            }}         
                            placeholder={'Estados'}
                            primeiraOption={
                                <option 
                                    value="" 
                                    disabled 
                                    selected
                                >
                                    Estados
                                </option>
                            }
                            style={{
                                width: 'auto',
                                padding: 7,
                                marginTop: 5,
                                opacity: 0.6,
                                flex: 1                              
                            }}               
                        />
                        {(mapaDados.filtros && mapaDados.filtros.estado) && <FontAwesomeIcon 
                            className="icone fa-lg" 
                            icon={["fas", "times"]}
                            onClick={() => {
                                if(refSelect.current) refSelect.current.selectedIndex = 0;
                                mapaDados.filtros.estado = null;
                                mapaDados.alterarFiltros({...mapaDados.filtros});
                            }}
                            style={{
                                cursor:'pointer',
                                marginLeft: 10,
                                marginRight: 5,
                                marginTop: 5
                            }} 
                        />}                        
                    </div>
                </div>
            </div>
        </Card.Body>
    </Card>

}

export default ClienteBuscar;