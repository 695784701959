import styled from 'styled-components';

export const CadastroComponent = styled.div.attrs({
    className: `pb-5`
})`

    background: #595959;
    min-height: 100vh;

    .coluna {
        
        &.responsavel {
            padding-right: 8px !important;
            @media only screen and (max-width: 980px) {
                padding-left: 15px !important;
                padding-right: 15px !important;
            }
        }        

        &.evento {
            padding-left: 8px !important;
            @media only screen and (max-width: 980px) {
                padding-left: 15px !important;
                padding-right: 15px !important;                
            }

            &.card-evento {
                @media only screen and (max-width: 950px) {
                    height: 700px ;
                }
            }
        }

        &.card-evento {
            height: 475px;
            @media only screen and (max-width: 980px) {
                height: auto;
            }
        }

        .custom-switch {

            height: 65px;
            padding-left: 0px;

            .custom-control-label {
                height: 100%;
                width: 100%;       
                font-size: 0.9rem;
                text-align: right;
                vertical-align: top;
                text-overflow: ellipsis;
            }

            .custom-control-input {
                height: 100%;
                outline: none;
                width: 100%;                
                z-index: 1;
                cursor: pointer;
            }

            .custom-control-label::before {
                width: 50px !important;
                border-radius: 1.5rem !important;
                top: auto !important;
                bottom: 5px !important;
                left: auto !important;
                right: 0px !important;
                height: 1.25rem !important;
                outline: none;
                box-shadow: none;
            }            

            .custom-control-input:checked~.custom-control-label::after {
                background-color: #fff;
                transform: translateX(1rem)!important;
                top: auto !important;
                bottom: 9px !important;
                left: auto !important;
                right: 20px !important;
            }

            .custom-control-input:not(:checked)~.custom-control-label::after {
                background-color: gray;
                transform: translateX(1rem)!important;
                top: auto !important;
                bottom: 9px !important;
                left: auto !important;
                right: 50px !important;
            }

        }

    }



    .formulario {

        .logo {
            max-width: 200px;
            margin: auto;

            img {
                width: 100%;
            }
        }

        .titulo {
            font-size: 1.2rem;
            font-weight: bold
        }

        label {
            font-size: 0.9rem;
        }

        .container-arquivo {            
            height: 40px;
            border: 1px solid #284787;
            border-right-width: 0px;
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
        }

        .botao-arquivo {

            &:hover {
                background: #4175e1 !important;
            }

        }
        
    }

    .participantes {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;

        @media only screen and (max-width: 900px) {
            grid-template-columns: 1fr 1fr;
        }


        @media only screen and (max-width: 600px) {
            grid-template-columns: 1fr;
        }

        
    }

    button {
        box-shadow: none !important;
    }

`