import React from 'react';
import moment from 'moment';

// componentes
import { Card, Row, Form, Col } from 'react-bootstrap';
import InputDataCombo from './../../../../Componentes/InputDataCombo';
import InputValor from './../../../../Componentes/InputValor';

import { CancelamentoComponent } from './components';

// component
export default function Cancelamento({
    dados,
    alterarDados,
    modo="emissao",
    desabilitar
}){

    return <>
        
            <Card className='shadow-sm border-0'>
                <Card.Header className='bg-white'>
                    <Card.Title as='div' className='mb-0'>
                        <CancelamentoComponent>
                            <Form.Check
                                type="switch"
                                inline
                                label="Cancelado"
                                className='switch'
                                checked={(dados.cancelado === 'S')? true : false}
                                disabled={desabilitar}
                                id={`checkbox-${modo}-cancelado`}
                                onChange={(e) => {

                                    // altera a data
                                    if(e.target.checked){
                                        dados.data_cancelado = moment().format('YYYY-MM-DD')
                                    }else{
                                        dados.data_cancelado = null;
                                    }

                                    // altera os dados da ficha
                                    dados.cancelado = e.target.checked ? 'S' : 'N';
                                    alterarDados({...dados});
                                }}
                                size="sm"
                            />
                        </CancelamentoComponent>
                    </Card.Title>
                </Card.Header>
                {   dados.cancelado === 'S' &&
                    <Card.Body>
                        <CancelamentoComponent>
                                    <Form.Group>
                                        <Form.Label>Data de cancelamento</Form.Label>
                                        <InputDataCombo 
                                            data={dados.data_cancelado}
                                            onChange={(novaData) => {
                                                alterarDados({...dados, data_cancelado: novaData});
                                            }}
                                            size="sm"
                                        />
                                    </Form.Group>
                                { (modo === 'emissao') && 
                                    <Form.Group>
                                        <Form.Label>Valor extornado</Form.Label>
                                        <InputValor 
                                            valor={dados.valor_extornado || '0.0'}
                                            onChange={valorNovo => {
                                                dados.valor_extornado = valorNovo;
                                                alterarDados({...dados});
                                            }}
                                            size="sm"
                                        />
                                    </Form.Group>
                                }
                                <Form.Group>
                                    <Form.Label>Motivo</Form.Label>
                                    <Form.Control 
                                        as='textarea' 
                                        maxLength='1000'
                                        value={dados.motivo_cancelado || ''}
                                        onChange={e => {
                                            dados.motivo_cancelado = e.target.value;
                                            alterarDados({...dados});
                                        }}
                                        rows={2}
                                        size="sm"
                                    />
                                </Form.Group>
                        </CancelamentoComponent>

                    </Card.Body>
                }
            </Card>
    </>

}