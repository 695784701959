import styled from 'styled-components';

export const ParticipanteComponent = styled.div.attrs({
    className: ``
})`
    position: relative;

    label, .aviso-data {
        font-size: 0.8rem;
    }

    .opcoes {
        /* position: absolute; */
        bottom: -19px;
        right: 0;
    }

    ${props => props.excluido && `
        .label {
            background: red !important;
        }
    `}

`;