import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux'

// componentes
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// faz o cadastro
export default function Cadastrar(){

    // estados
    const history = useHistory();
    const { id } = useParams();
    const { login } = useSelector(state => state);
    const [modo, alterarModo] = useState('cadastro'); // cadastro, edicao
    const [carregando, alterarCarregando] = useState(true);
    const [salvando, alterarSalvando] = useState(false);
    const [dados, alterarDados] = useState({
        id: id || null,
        descricao: '',
        habilitar_ponto: 'N'
    });

    // consultar o id
    async function consultarId(){

        // altera o modo
        alterarModo('edicao');
        
        try{

            // faz a requisição
            let { data } = await axios.get(`/dispositivos/${id}`);
            delete data.hashcookie; // evitar sobrescrever
            alterarDados(data.dados);
            alterarCarregando(false);

        }catch({response}){}

    }

    // inicializa
    useEffect(() => {

        // verifica se é edição
        if(id){
            consultarId();
        }else{
            alterarCarregando(false);
        }

    }, []);

    // salva
    async function salvar(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/dispositivos`, { dados: dados });
            toast(({closeToast }) => <>
                <div className="toast-header border-0">
                    <strong className="mr-auto">Dispositivo salvo com sucesso!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>);
            history.push('/painel/dispositivos');

        }catch({response}){}

    }

    // carregando
    if(carregando){
        return <div>Carregando...</div>
    }

    return <>
        <Row>
            <Col md='8'>
                <Card className='border-0 shadow-sm'>
                    <Card.Header className='bg-white border-0 mb-0 pb-0'>
                        <Card.Title>Cadastro de Dispositivo</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Group>
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control 
                                value={dados.descricao}
                                onChange={e => {
                                    dados.descricao = e.target.value;
                                    alterarDados({...dados});
                                }}
                            />
                        </Form.Group>
                        <Form.Check 
                            inline
                            label='Permite que os usuários batam o ponto nesse dispositivo'
                            id='habilita-ponto-dispositivo'
                            checked={dados.habilitar_ponto === 'S'}
                            onChange={e => {
                                dados.habilitar_ponto = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                        />
                    </Card.Body>
                </Card>

                { parseInt(login.permissao.dispositivos) > 2 &&
                    <SalvarFormulario 
                        salvando={salvando}
                        onClick={() => salvar()}
                        cancelar={() => {
                            history.push('/painel/dispositivos');
                        }}
                    />
                }
            </Col>
        </Row>
    </>
}

function SalvarFormulario({
    salvando,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <span>Salvar</span>
            }
        </Button>
    </Form.Group>
}
