import React from 'react';

import { Form, Row, Col } from 'react-bootstrap';
import InputData from './../../../Painel/Componentes/InputData';

function FormularioDadosPessoais({
    dados,
    alterarDados,
    funcaoAlterarDados
}){


    return <>

        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Empresa</Form.Label>
                <Form.Control
                    value={dados.profissional_empresa}
                    name='profissional_empresa'
                    onChange={funcaoAlterarDados}
                    maxLength='200'
                />
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Telefone empresa</Form.Label>
                <Form.Control
                    value={dados.profissional_telefone}
                    name='profissional_telefone'
                    onChange={funcaoAlterarDados}
                    maxLength='30'
                />
            </Form.Group>
        </Form.Row>
        
        {/* mascara */}
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Data de Adminissão</Form.Label>
                <InputData 
                    data={dados.profissional_data_adminissao}
                    onChange={(data) => {
                        dados.profissional_data_adminissao = data;
                        alterarDados({...dados});
                    }}
                />
            </Form.Group>
        </Form.Row>
        
        <Form.Row>
            <Form.Group as={Col} lg='8'>
                <Form.Label>Função</Form.Label>
                <Form.Control
                    value={dados.profissional_funcao}
                    name='profissional_funcao'
                    onChange={funcaoAlterarDados}
                    maxLength='100'
                />
            </Form.Group>
        </Form.Row>

        
        <Form.Row>
            <Form.Group as={Col} lg='4'>
                <Form.Label>Vínculo Empregatício</Form.Label>
                <Form.Control
                    as='select'
                    value={dados.profissional_vinculo_empregaticio}
                    name='profissional_vinculo_empregaticio'
                    onChange={funcaoAlterarDados}
                >
                    {[
                        {value: '1', children: 'Aposentado / pensionista'},
                        {value: '2', children: 'Autônomo'},
                        {value: '3', children: 'CLT'},
                        {value: '4', children: 'Estagiário'},
                        {value: '5', children: 'Funcionário Público'},
                        {value: '6', children: 'Profissional Liberal'},
                        {value: '7', children: 'PJ'},
                        {value: '8', children: 'Proprietário'},
                    ].map(opcao => 
                        <option key={opcao.value} {...opcao} />
                    )}
                </Form.Control>
            </Form.Group>
        </Form.Row>

        
        <Form.Row>
            <Form.Group as={Col} lg='4'>
                <Form.Label>Renda Mensal</Form.Label>
                <Form.Control
                    value={dados.profissional_renda_mensal}
                    name='profissional_renda_mensal'
                    onChange={funcaoAlterarDados}
                    maxLength='40'
                />
            </Form.Group>
            <Form.Group as={Col} lg='4'>
                <Form.Label>Demais Rendas</Form.Label>
                <Form.Control
                    value={dados.profissional_demais_rendas}
                    name='profissional_demais_rendas'
                    onChange={funcaoAlterarDados}
                    maxLength='40'
                />
            </Form.Group>
        </Form.Row>

    </>
}

export default FormularioDadosPessoais;