import React from 'react';
import styles from './index.module.scss';
import { useHistory } from 'react-router-dom';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';

// componente
export default function Funcao({
    dados
}){


    // estados
    const history = useHistory();

    //variaveis
    let descricao = dados.dados.funcao;
    let imagem = null;
    let urlRedirecionar = null;
    let icone = ["fas", "quote-left"];
    switch(dados.dados.funcao){
        case 'clientes/consultarId': 
            if(dados.cliente.foto !== null){
                imagem = { url: `${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/fotos/${dados.cliente.foto}` };
            }
            descricao = 'Consultou cliente'; 
            urlRedirecionar = `/painel/clientes/alterar/${dados.cliente.id}`;
        break;
        case 'relatorioGeral/funil': descricao = 'Consultou funil'; break;
        case 'relatorioGeral/emissoes': descricao = 'Relatório, consultou emissões'; break;
        case 'eventos/consultar': descricao = 'Consultou eventos'; break;
        case 'arquivos/consultar': descricao = 'Consultou arquivos'; break;
        case 'clientesFichas/consultar': descricao = 'Consultou fichas de atendimento'; break;
        case 'clientesFichas/consultarId': descricao = 'Abriu cadastro de ficha de atendimento'; break;
        case 'relatorioGeral/funilXls': descricao = 'Exportou relatório para excel'; break;
        case 'clientes/consultarApoliceClienteExterno': descricao = 'Consulta de apólices externo'; break;
        case 'clientes/salvar': descricao = 'Cadastrou cliente'; break;
        case 'clientes/foto': descricao = 'Salvou foto de cliente'; break;
        case 'clientesFichas/salvar': descricao = 'Cadastrou ficha de atendimento'; break;
        case 'arquivos/salvar': descricao = 'Salvou arquivo'; break;
        case 'eventos/concluir': descricao = 'Concluiu evento'; break;
        case 'relatorioGeral/propostas': descricao = 'Relatório, consultou proporstas'; break;
        case 'relatorioGeral/emissoesCanceladas': descricao = 'Relatório, consultou emissões canceladas'; break;
        case 'arquivos/excluir': descricao = 'Excluiu arquivo'; break;
        case 'relatorioGeral/cotacoes': descricao = 'Relatório: consultou as cotações'; break;
        case 'modelosEmail/consultar': descricao = 'Consultou modelos de email'; break;
        case 'imagensEmails/consultarId': descricao = 'Consultou imagens de email'; break;
        case 'emailsEnviado/enviar': descricao = 'Enviou um email'; break;
        case 'emailsEnviado/consultar': descricao = 'Consultou os emails enviados'; break;
        case 'solicitacaoPagamento/salvar': descricao = 'Salvou uma solicitação de pagamento'; break;
        case 'solicitacaoPagamento/consultar': descricao = 'Consultou as solicitações de pagamento'; break;
        case 'solicitacaoPagamento/consultarId': descricao = 'Abriu cadastro de pagamento'; break;
        case 'eventos/consultarId': descricao = 'Abriu cadastro de evento'; break;
        case 'eventos/salvar': descricao = 'Salvou evento'; break;
        case 'relatorioGeral/renovacoes': descricao = 'Relatório, consultou as renovações'; break;
        case 'clientes/solicitarAcessoApolice': descricao = 'Cliente solicitou acesso a apolices'; break;
        case 'modelosSms/consultar': descricao = 'Consultou modelos de sms'; break;
        case 'smsEnviado/enviar': descricao = 'Enviou sms'; break;
        case 'grupos/consultar': descricao = 'Consultou os grupos'; break;
        
    }

    return <>
        <Card
            className={['bg-light border-0 m-1', styles.funcao].join(' ')}
        >
            <Card.Body
                className={['align-items-center p-2'].join(' ')}
            >
                <div
                    className={styles.funcaoDescricao}
                >
                    { (imagem === null) ?
                        <FontAwesomeIcon className={styles.icone} icon={icone}></FontAwesomeIcon>
                        :
                        <img 
                            src={imagem.url} 
                            className={[
                                styles.imagem,
                                urlRedirecionar === null ? '' : styles.redirecionar 
                            ].join(' ')} 
                            onClick={urlRedirecionar === null ? undefined : () => {
                                history.push(urlRedirecionar);
                            }}
                        />
                    }
                    
                    <div className={styles.texto}>
                        {descricao}
                    </div>
                </div>
            </Card.Body>
        </Card>
    </>

}