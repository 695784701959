import styled from 'styled-components';

export const RegistroComponent = styled.div.attrs({
    className: ``
})`

    .card {
        height: 100%;
    }

    .card-erros{
        cursor: pointer;
        padding: 2px;

        &:hover {
            background-color: #e8ba2f !important;
        }
    }

`

export const BotaoPdf = styled.button`

    background: none;
    border: none;
    box-shadow: none;
    text-decoration: none;
    padding: 0px;

`;