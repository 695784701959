import React from 'react';

// componentes
import BotaoTabela from './../../../Componentes/BotaoTabela';

function Registro({
    dados,
    clicouSelecionar
}){

    return <tr>
        <td>{dados.descricao}</td>
        <td className="text-nowrap estreito">
            <BotaoTabela 
                onClick={clicouSelecionar}
                icone={["fas", "check"]}
            >Selecionar</BotaoTabela>
        </td>
    </tr>
}

export default Registro;