import React, { 
    useState, 
    useEffect 
} from 'react';
import { 
    Col, 
    Row,     
    Card, 
    Button 
} from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

import PaginacaoComponente from '../Componentes/Paginacao';
import Parametros from './Parametros';
import Registro from './Registro';
import Carregador from '../Componentes/Carregador';

// componente
export default function Consultar({
    idCliente=null
}){

    // hooks
    const history = useHistory();
    const [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10,
        ativo: ['S'],
        erro: ['N', 'S'],
        idCliente,
        texto: '',
        dataProgramadoInicio: null,
        dataProgramadoFim: null
    });
    const [totalRegistros, alterarTotalRegistros] = useState(0);
    const [carregando, alterarCarregando] = useState(false);
    const [registros, alterarRegistros] = useState([]);    

    // carrega os registros
    async function consulta(){

        // carregando
        alterarCarregando(true);

        try {

            // realiza a requisição
            const { data } = await axios.get('/whatsapp-enviado', {
                params: parametros
            });

            // atualiza estados
            alterarRegistros(data.registros);
            alterarTotalRegistros(parseInt(data.totalRegistros));

        } catch(e) {
            console.error(e);
        } finally {

            // desativa carregamento
            alterarCarregando(false);
        }

    }

    // inicializa
    useEffect(() => {

        // consulta inicial
        consulta();

    }, [parametros]);

    // retorna
    return (
        <Row>
            <Col>
                <Card className="painel-consulta border-0 shadow-sm" >
                    <div className="card-header">
                        <div className={'d-flex'}>
                            <h4 className="card-title font-weight-bolder flex-grow-1">
                                Mensagens Whatsapp
                            </h4>
                            <div>
                                
                                {/* Apresenta opção se não for uma consulta da tela de clientes */}
                                {!idCliente && <>
                                    <Button
                                        className='mr-2'
                                        variant='padrao'
                                        size='sm'
                                        onClick={() => {
                                            history.push('/painel/modelos-whatsapp');
                                        }}
                                    >
                                        <FontAwesomeIcon className="icone" icon={["fab", "whatsapp-square"]} />
                                        <span>Modelos de Whatsapp</span>
                                    </Button>
                                </>}
                            </div>
                        </div>                
                        <Parametros 
                            parametros={parametros} 
                            alterarParametros={alterarParametros} 
                        />
                    </div>
                    <Card.Body className={'pt-0'}>
                        <table className="table table-hover tabela">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Mensagem</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {registros.map((registro) => 
                                    <Registro 
                                        key={registro.dados.id} 
                                        dados={registro}
                                        parametros={parametros}
                                        mostrarNomeCliente={!idCliente ? true : false}
                                    />
                                )}
                            </tbody>
                        </table>
                        <PaginacaoComponente
                            pagina={parametros.pagina}
                            registrosPorPagina={parametros.registrosPorPagina}
                            totalRegistros={totalRegistros}
                            alterouPagina={(pagina) => alterarParametros({...parametros, pagina: pagina})}
                        />
                        {carregando && 
                            <Carregador className="rounded-bottom" />
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
};