import React from 'react';

// componentes
import { Form } from 'react-bootstrap';

// componente
export default function TextoWhatsapp({
    value,
    onChange
}){

    return <Form.Control 
        as='textarea'
        value={value}
        onChange={e => {;
            onChange(e);
        }}
    />

}