import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Modal, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ModalExclusaoComponent } from './components';

// componente
export default function ModalParticipanteExclusao({
    id,
    tokenParticipante,
    fechou,
    excluiu
}){
  
    const [dados, alterarDados] = useState({
        id: null,
        nome: null
    });
    const [mostrar, alterarMostrar] = useState(true);
    const [carregando, alterarCarregando] = useState(false);
    const [salvando, alterarSalvando] = useState(false);

    useEffect(() => {
        consultarId(id);
    }, [])
    
    // consulta participante por id
    async function consultarId(id) {

        alterarCarregando(true);

        try {

            // faz a requisição
            let { data } = await axios.get(`/seguroAventuraParticipante/${id}`);
            alterarDados(data.dados);

        }catch({response}){}
        finally{
            // remove o carregamento
            alterarCarregando(false);
        }

    }

    // exclui participante por id
    async function excluir() {

        // mostra carregamento
        alterarSalvando(true);

        try {

            // faz a requisição
            await axios.delete(`/seguroAventuraParticipante/${tokenParticipante}`);        

            // excluir
            excluiu();                  

            // mensagem de sucesso
            toast(({ closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Participante excluido com sucesso!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>);                 
            
            // fecha modal
            fechou();

        }catch({response}){
            if(response && response.status && response.status === 400){
                toast(<>
                    <div
                        style={{background: '#ff6271'}}
                    >
                        <div className="toast-header">
                            <strong className="mr-auto">Houve alguns erros :(</strong>
                            <button 
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                            </button>
                        </div>
                        <div className="toast-body text-light">
                            Não foi possível remover o participante do evento!
                        </div>
                    </div>
                </>);

            }
        }finally{
            alterarSalvando(false);
        }

    }

    return <>
        <ModalExclusaoComponent 
            show={mostrar} 
            onExited={() => {
                fechou();
            }}
            onHide={() => {
                alterarMostrar(false);
            }}
            size={'md'}
        >

            <Modal.Header className='border-0 text-center'>
                <Modal.Title className="w-100">
                    Você deseja excluir o participante 
                    <p style={{ fontWeight: 'bold' }}>{dados.nome || ''} ?</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'position-relative'}>                  
                                
                <SalvarFormulario 
                    salvando={salvando}
                    cancelar={() => alterarMostrar(false)}
                    onClick={excluir}
                />
                
                {carregando && 
                    <div className={['carregamento']}>
                        <FontAwesomeIcon icon={faSpinner} pulse className="icone"/> Aguarde!
                    </div>
                }

            </Modal.Body>
        </ModalExclusaoComponent>

    </>

}

function SalvarFormulario({
    salvando,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Excluindo</span>
                </> : 
                <span>Excluir</span>
            }
        </Button>
    </Form.Group>
}