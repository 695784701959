import React from 'react';
import styles from './index.module.scss';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';

// botão
function BotaoAniversariante({
    label,
    onClick,
    icone,
    className=''
}){

    return <ButtonToolbar >
        <OverlayTrigger
            placement='top'
            overlay={<Tooltip>{label}</Tooltip>}
            
        >
            <button
                className={className}
                onClick={onClick}
            >
                <FontAwesomeIcon className={styles.icone} icon={icone} />
            </button>
        </OverlayTrigger>
    </ButtonToolbar>
}

// exporta
export default BotaoAniversariante;