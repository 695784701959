import React, { useState, useEffect } from 'react';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import ListaCheckbox from './../../../Componentes/ListaCheckbox';

// cria os parametros
export default function Parametros({
    parametros, 
    alterarParametros
}){

    // expande filtros
    let [mostrarMais, expandirParametros] = useState(false);

    // parametros
    return <div className="parametros mt-5">
        <div className="parametros-inicial">
            {/* <Link to='/painel/eventos-automaticos'>
                <Button variant="padrao" className="botao-cadastrar">
                    <FontAwesomeIcon className="icone" icon={["fas", "list"]} />
                    <span>Eventos Automáticos</span>
                </Button>
            </Link> */}
            <DebounceInput 
                placeholder="Buscar por código" 
                debounceTimeout={400}
                element={Form.Control} 
                value={parametros.texto}
                onChange={(e) =>{
                    alterarParametros({...parametros, texto: e.target.value, pagina: 1})
                }}
            />
            <div className="campo-flags">
                <ListaCheckbox 
                    itens={[
                        {id: 'S', label: 'Lidos'},
                        {id: 'N', label: 'Não Lidos'}
                    ]}
                    value={parametros.lido}
                    label='lido'
                    onChange={(ids) => {
                        parametros.lido = ids;
                        alterarParametros({...parametros, pagina: 1});
                    }}
                />
            </div>
            {/* {!mostrarMais && 
                <Button 
                    variant="padrao"
                    onClick={() => expandirParametros(true)}
                >
                    <FontAwesomeIcon className="icone" icon={["fas", "search-plus"]} />
                </Button>
            } */}
        </div>

        {mostrarMais && 
            <Row className='mt-3'>
                <Col md='9'>

                </Col>
            </Row>
        }
    </div>

}