import styled from 'styled-components';

export const CancelamentoComponent = styled.div.attrs({
    className: ''
})`

    .switch {
        align-items: center;
        
        label:before {
            top: 2px;
        }

        label:after {
            top: 4px;
        }

    }

    label , .switch{
        font-size: 0.85rem;
    }

`