import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

export default function ModalEditar({
    clicouCancelar,
    clicouConfirmar,
    registro,
    valorMinimo
}){
    
    const [registroAlterado, alterarRegistroAlterado] = useState({
        dados: registro,
        grupos: []
    });
    const [salvando, alterarSalvando] = useState(false);
    const [erro, alterarErro] = useState(false);
    
    // função para fazer as verificações do valor da diária
    function verificaValor() {
        
        // verifica se não foi passado valor mínimo e nem editado
        if(!valorMinimo && !registroAlterado.dados.seguro_valor_diaria_editado) {
            
            // retorna valor multiplicado
            return parseInt(registro.diarias.periodo) * parseFloat(registroAlterado.dados.seguro_valor_diaria);

        } else {

            // se tiver um valor editado
            if(registroAlterado.dados.seguro_valor_diaria_editado) {

                // se foi passado um valor mínimo
                if(valorMinimo) {

                    // verifica se o valor editado é menor que o valor mínimo
                    if(parseFloat(registroAlterado.dados.seguro_valor_diaria_editado) < parseFloat(valorMinimo)) {

                        // retorna o valor mínimo
                        return valorMinimo;
        
                    } else {

                        // retorna o valor editado
                        return registroAlterado.dados.seguro_valor_diaria_editado;

                    }

                }

                // retorna o valor editado
                return registroAlterado.dados.seguro_valor_diaria_editado;

            } else {

                // se foi passado o valor mínimo
                if(valorMinimo) {

                    // verifica se o valor do valor é menor que o valor mínimo
                    if((parseInt(registro.diarias.periodo) * parseFloat(registroAlterado.dados.seguro_valor_diaria)) < parseFloat(valorMinimo)) {

                        // retorna o valor mínimo
                        return valorMinimo;
        
                    } else {

                        // retorna valor multiplicado
                        return parseInt(registro.diarias.periodo) * parseFloat(registroAlterado.dados.seguro_valor_diaria);        

                    }

                }

                // retorna valor multiplicado
                return parseInt(registro.diarias.periodo) * parseFloat(registroAlterado.dados.seguro_valor_diaria);

            }

        }

    }

    // consulta dados do cliente
    async function consultarCliente() {

        // mostra carregamento
        alterarSalvando(true);

        try {

            // faz a requisição
            const { data } = await axios.get(`/clientes/${registro.cliente.id}`);

            // altera os dados
            alterarRegistroAlterado(data);

        } catch(e) {
            console.log(e);
        } finally {
            alterarSalvando(false);
        }
        
    }
 
    useEffect(() => {

        // chama função
        (async () => await consultarCliente())();

    }, [])

    return <Modal 
        show={true} 
        onExited={() => {}}
        onHide={() => {}}
        size={'md'}
    >
        <Modal.Header className='border-0'>
            <Modal.Title>
                {registroAlterado.dados.nome}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p className="text-center">Edite o valor da diária do cliente abaixo:</p>
            <Form.Group>
                <Form.Control 
                    value={salvando ? 'Carregando...' : (typeof registroAlterado.dados.seguro_valor_diaria_editado !== 'string' ? verificaValor() : registroAlterado.dados.seguro_valor_diaria_editado)}
                    onChange={e => {
                        alterarRegistroAlterado({
                            ...registroAlterado,
                            dados: {
                                ...registroAlterado.dados,
                                seguro_valor_diaria_editado: e.target.value
                            }
                        })
                    }}
                    isInvalid={erro}
                    disabled={salvando}
                    size="sm"
                />
                {erro && <Form.Control.Feedback type="invalid">
                    Insira um valor
                </Form.Control.Feedback>}
            </Form.Group>
        </Modal.Body>
        <Modal.Footer className='border-0'>
            <Button
                variant='padrao'
                onClick={clicouCancelar}
            >
                <span>Cancelar</span>
            </Button>
            <Button
                onClick={() => {
                    // se estiver vazio, retorna erro
                    if(registroAlterado.dados.seguro_valor_diaria_editado === '') {
                        alterarErro(true);
                    } else {
                        clicouConfirmar(registroAlterado)
                    }
                }}
                variant='success'
            >
                {salvando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    </> : 
                    <span>Confirmar</span>
                }
            </Button>
        </Modal.Footer>
    </Modal>

}